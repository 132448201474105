import React, { useState, useEffect } from "react";
import { Table } from "antd";
import AxiosInstance from "../../../../service/AxiosInstance";
import { MDBDataTableV5, MDBCard, MDBRow, MDBCol } from "mdbreact";
import MeBar from "../MeBar";
import moment from "moment";
const Attendance = () => {
  const [reportingEmployee, setReportingEmployee] = useState([]);
  const [employees, setEmployees] = useState([]);
  const user = JSON.parse(localStorage.getItem("userdata"));
  const [datatable, setDatatable] = React.useState({});
  const [attendanceLogs, setAttendanceLogs] = useState([]);
  const [isCheck, setisCheck] = useState(true);
  const [checkInData, setCheckInData] = useState(null);
  useEffect(() => {
    AxiosInstance.get("auth/faceLoginList/"+ JSON.parse(localStorage.getItem("userdata"))[0]._id).then(async (res) => {
      setAttendanceLogs(res.data.data);
    });
  }, []);
  useEffect(() => {
    setDatatable({
      columns: [
        {
          label: "Date",
          field: "date",
          width: 150,
          sort: "disabled",
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Face In Logs",
          field: "facein",
          sort: "asc",
          width: 200,
        },
        {
          label: "Face Out Logs",
          field: "faceOut",
          sort: "asc",
          width: 100,
        },
      ],
      rows: reportingEmployee,
    });
  }, [reportingEmployee]);
  useEffect(() => {
    let data = [];
    attendanceLogs?.map((row) => {
      console.log(row.checkIn, "check in")
      let usedetail = {
        no: "#",
        date: moment(row.date).format("DD-MM-YYYY"),
        checkInOut: "10 Am - 07.30 Pm",
        facein:
          row.checkIn.split(" ").length > 0
            ? row.checkIn.split(" ")[1]
            : "" + " - ",
        faceOut:
          row.checkOut && row.checkOut.split(" ").length > 0
            ? row.checkOut.split(" ")[1]
            : "",
      };

      data.push(usedetail);
    });
    setReportingEmployee(data.reverse());
  }, [attendanceLogs]);
  const CheckinFun = () => {
    AxiosInstance.get("auth/report").then(async (res) => {
      if (res.data.status === 1) {
        setCheckInData(res.data.data[0] ?? null);
        setisCheck(false);
      } else {
        setisCheck(true);
      }
    });
  };

  useEffect(() => {
    CheckinFun();
  }, []);
  return (
    <>
      <div className="demo">
        <MeBar />
        <MDBCard className="page-content">
          <div>
            <div className="create-project">
              <MDBCol>
                <div>
                  <div className="team-text">
                    <p style={{ margin: 0 }}>Attendance Log</p>
                  </div>
                  <div className="tbl-attendance">
                    <MDBDataTableV5
                      hover
                      entriesOptions={[20, 25, 30]}
                      entries={20}
                      pagesAmount={4}
                      data={datatable}
                      searchTop
                      searchBottom={false}
                    />
                  </div>
                </div>
              </MDBCol>
            </div>
          </div>
        </MDBCard>
      </div>
    </>
  );
};

export default Attendance;
