import { Typography } from "antd";
import React, { useEffect, useState } from "react";
import AxiosInstance from "../../../../service/AxiosInstance";
import moment from "moment";

export default function DueCount({ count, allLengthData }) {
  return (
    <div className="flex-count-details">
      <div className="flex-count-inner">
        <div className="count-cols">
          <div className="count-card">
            <div className="count-card-body">
              <h5 className="count-card-title">Milestone Due</h5>
              {/* <p className="count-card-amount">{count?.milestoneDueCount}</p> */}
              <p className="count-card-amount">
                {allLengthData ? allLengthData[0] : ""}
              </p>
            </div>
          </div>
        </div>
        <div className="count-cols">
          <div className="count-card">
            <div className="count-card-body">
              <h5 className="count-card-title">Sprint Due</h5>
              {/* <p className="count-card-amount">{count?.sprintDueCount}</p> */}
              <p className="count-card-amount">
                {allLengthData ? allLengthData[1] : ""}
              </p>
            </div>
          </div>
        </div>
        <div className="count-cols">
          <div className="count-card">
            <div className="count-card-body">
              <h5 className="count-card-title">Story Due</h5>
              <p className="count-card-amount">
                {allLengthData ? allLengthData[2] : ""}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
