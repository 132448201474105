import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
const Footer = () => {
  return (<></>);
  return (
    <MDBFooter
      bgColor="primary"
      className="text-white text-center text-lg-left footer-set"
    >
      <div
        className="text-center p-3 footer-color"
        // style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
      >
        &copy; {new Date().getFullYear()} Copyright:{' '}
        <a className="text-white" href="#">
          Virtual Height
        </a>
      </div>
    </MDBFooter>
  );
};

export default Footer;
