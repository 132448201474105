import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import "antd/dist/antd.css";
import { Link, useHistory } from "react-router-dom";

import { MDBNavbarItem, MDBNavbarLink, MDBNavbarNav } from "mdb-react-ui-kit";
import { useSelector } from "react-redux";

const DashboardBar = (props) => {
  const history = useHistory();
  let user_data = JSON.parse(localStorage.getItem("userdata"));
  const [loading, setLoading] = useState(false);
  const permssionsState = useSelector((state) => state.Permissions.Permissions);
  const [rolePermissions, setRolepermissions] = useState(permssionsState);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userdata");
    history.push("/login");
    window.location.reload();
  };
  useEffect(() => {
    setRolepermissions(permssionsState);
  }, [permssionsState]);
  return (
    <>
      <MDBNavbarNav className="page-menu-nav">
        <MDBNavbarItem>
          <Link to="/dashboard">
            <MDBNavbarLink href="#">Dashboard</MDBNavbarLink>
          </Link>
        </MDBNavbarItem>
        <MDBNavbarItem>
          <Link to="/my-dashboard">
            {" "}
            <MDBNavbarLink>My Dashboard</MDBNavbarLink>
          </Link>
        </MDBNavbarItem>
        {rolePermissions?.Dashboard?.PM_TPM_Dashboard == "1" ? (
          <MDBNavbarItem>
            <Link to="/pm-dashboard">
              {" "}
              <MDBNavbarLink>PM/TPM Dashboard</MDBNavbarLink>
            </Link>
          </MDBNavbarItem>
        ) : (
          ""
        )}
        {rolePermissions?.Dashboard?.Task_Dashboard == "1" ? (
          <MDBNavbarItem>
            <Link to="/task-dashboard">
              {" "}
              <MDBNavbarLink>Task Dashboard</MDBNavbarLink>
            </Link>
          </MDBNavbarItem>
        ) : (
          ""
        )}
        {rolePermissions?.Dashboard?.Resource_Manager_Dashboard == "1" ||
        user_data[0]?._id == "61b727ca37a0b8573dde2d97" ||
        user_data[0]?._id == "61b045deda15f03ff00db936" ? (
          <MDBNavbarItem>
            <Link to="/resource-manager">
              {" "}
              <MDBNavbarLink>Resource-Manager</MDBNavbarLink>
            </Link>
          </MDBNavbarItem>
        ) : (
          ""
        )}
        {rolePermissions?.Dashboard?.HR_Dashboard == "1" ? (
          <MDBNavbarItem>
            <Link to="/hr-dashboard">
              {" "}
              <MDBNavbarLink>HR Dashboard</MDBNavbarLink>
            </Link>
          </MDBNavbarItem>
        ) : (
          ""
        )}
        {/* {rolePermissions?.Dashboard?.Resource_Manager_Dashboard == "0" && (user_data[0]?._id == "61b727ca37a0b8573dde2d97" || user_data[0]?._id == "61b045deda15f03ff00db936") ? (
          <MDBNavbarItem>
            <Link to="/resource-manager">
              {" "}
              <MDBNavbarLink>Resource-Manager</MDBNavbarLink>
            </Link>
          </MDBNavbarItem>
        ) : ("")} */}
        <Spin spinning={loading} style={{ marginTop: 100 }}>
          {" "}
        </Spin>
      </MDBNavbarNav>
    </>
  );
};

export default DashboardBar;
