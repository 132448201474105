import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Row, Col, Select, message } from "antd";
import AxiosInstance from "../../../service/AxiosInstance";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SelectEmpployee from "../../../components/common/SelectEmpployee";
const { Option } = Select;

function AddEscalation({ setIsOpen, reloadList }) {
  const [form] = Form.useForm();
  const [escalationData, setEscalationData] = useState(true);
  const [escalation, setEscalation] = useState("");
  const [loading, setLoading] = useState(false);
  const [escalationTypeId, setEscalationTypeId] = useState([]);
  const [employeesData, setEmployeesData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [textEditer, setTextEditer] = useState("");
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [notifiedEmployee, setNotifiedEmployee] = useState([]);

  const handleCancel = (errorInfo) => {
    form.resetFields();
    setEscalationData(false);
    setTextEditer("");
    setIsOpen(false);
  };

  const showConfirmModal = () => {
    setIsConfirmVisible(true);
  };

  const handleConfirmOk = () => {
    setIsConfirmVisible(false);
    form.submit();
  };

  const handleConfirmCancel = () => {
    setIsConfirmVisible(false);
  };

  const onFinish = (values) => {
    const urlencoded = new URLSearchParams();

    let escalationTypeId, projectId, employeeId, severity;

    try {
      escalationTypeId = values?.EscalationTypeId
        ? JSON.parse(values.EscalationTypeId)?.id
        : undefined;
      projectId = values?.ProjectId
        ? JSON.parse(values.ProjectId)?.id
        : undefined;
      employeeId = values?.EmployeeId
        ? JSON.parse(values.EmployeeId)?.id
        : undefined;

      severity = values?.Severity;
      // notifiedEmployee = values?.Notified_Employee
      //   ? JSON.parse(values.Notified_Employee)?.id
      //   : undefined;
    } catch (error) {
      console.error("Error parsing JSON:", error);
      message.error("Failed to parse input data.");
      return;
    }

    if (escalationTypeId !== undefined) {
      urlencoded.append("EscalationTypeId", escalationTypeId);
    }
    if (projectId !== undefined) {
      urlencoded.append("ProjectId", projectId);
    }
    if (employeeId !== undefined) {
      urlencoded.append("EmployeeId", employeeId);
    }
    if (textEditer !== undefined) {
      urlencoded.append("Description", textEditer);
    }
    if (severity !== undefined) {
      urlencoded.append("Severity", severity);
    }
    urlencoded.append("Notified_Employee", notifiedEmployee);

    AxiosInstance.post("/auth/add/escalation", urlencoded).then((res) => {
      if (res?.data?.status === 1) {
        message.success(res?.data?.message);
        setTextEditer("");
        setIsOpen(false);
        reloadList();
      } else {
        message.error(res?.data?.message);
      }
    });
  };

  const getAllEscalationType = () => {
    AxiosInstance.get("/auth/get/escalation/type").then((res) => {
      setLoading(false);
      if (res.data.status == 1) {
        setEscalationTypeId(res?.data?.data);
      } else {
        message.error(res?.data?.message);
      }
    });
  };
  const getEmployeeList = () => {
    AxiosInstance.get("auth/employees").then((res) => {
      setLoading(false);
      if (res.data.status == 1) {
        setEmployeesData(res?.data?.data);
      } else {
        message.error(res?.data?.message);
      }
    });
  };

  const getAllProjectData = () => {
    AxiosInstance.get("auth/projects").then((res) => {
      if (res?.data?.data) {
        const filteredProjects = res.data.data.filter(
          (project) => !["Completed", "Hold"].includes(project.Status)
        );
        setProjectData(filteredProjects);
      }
    });
  };

  useEffect(() => {
    getAllEscalationType();
    getEmployeeList();
    getAllProjectData();
  }, []);

  let config = {
    toolbarGroups: [
      // { name: "document", groups: ["mode", "document", "doctools"] },
      {
        name: "editing",
        groups: ["find", "selection", "spellchecker", "editing"],
      },
      { name: "forms", groups: ["forms"] },
      { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
      {
        name: "paragraph",
        groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
      },
      { name: "styles", groups: ["Styles", "Format", "Font", "FontSize"] },
      { name: "clipboard", groups: ["clipboard", "undo"] },
      { name: "others", groups: ["others"] },
      { name: "about", groups: ["about"] },
    ],
    Buttons:
      "Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo",
    fontSize_sizes: "16/16px;24/24px;48/48px;",
    font_names:
      "Arial/Arial, Helvetica, sans-serif;" +
      "Times New Roman/Times New Roman, Times, serif;" +
      "Verdana",
    allowedContent: true,
  };

  return (
    <>
      <Modal
        title="Add Escalation"
        centered
        open={escalationData}
        onOk={() => setEscalationData(false)}
        onCancel={handleCancel}
        width={800}
        height={640}
        bodyStyle={{ padding: "20px 15px" }}
        footer={null}
        maskClosable={false}
      >
        <Form
          form={form}
          name="control-hooks"
          layout="escalation Type"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row className="res-full-width">
            <Col span={11} className="mr-55">
              <Form.Item label="Escalation Type Name" name="EscalationTypeId">
                <Select
                  onChange={(e) => {
                    setEscalation(e);
                  }}
                  placeholder="Please Select Escalation Type"
                  showSearch="true"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                >
                  {escalationTypeId?.map((item, index) => {
                    return (
                      <Option
                        key={index}
                        value={JSON.stringify({
                          id: item._id,
                          name: item.Escalation_Type,
                        })}
                      >
                        {item.Escalation_Type}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={11}>
              <Form.Item label="Project Name" name="ProjectId">
                <Select
                  onChange={(e) => {
                    setEscalation(e);
                  }}
                  placeholder="Please Select Project Name"
                  showSearch="true"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                >
                  {projectData?.map((item, index) => {
                    return (
                      <Option
                        key={index}
                        value={JSON.stringify({
                          id: item._id,
                          name: item.Project_name,
                        })}
                      >
                        {item.Project_name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row className="res-full-width">
            <Col span={11} className="mr-55">
              <Form.Item label="Employee Name" name="EmployeeId">
                <Select
                  onChange={(e) => {
                    setEscalation(e);
                  }}
                  placeholder="Please Select Employee Name"
                  showSearch="true"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                >
                  {employeesData?.map((item, index) => {
                    return (
                      <Option
                        key={index}
                        value={JSON.stringify({
                          id: item._id,
                          name: item.First_name,
                        })}
                      >
                        {`${item.First_name} ${item.Middle_name} ${item.Last_name}`}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={11}>
              <Form.Item
                label="Notify Employee"
                name="Notified_Employee"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: false,
                    message: "Please Enter Reason!",
                  },
                ]}
              >
                <SelectEmpployee
                  mode="multiple"
                  setEmployeeIds={(value) => setNotifiedEmployee(value)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="res-full-width">
            <Col span={11} className="mr-55">
              <Form.Item label="Severity" name="Severity">
                <Select placeholder="Please Select Severity">
                  <Option value="GREEN">GREEN</Option>
                  <Option value="YELLOW">YELLOW</Option>
                  <Option value="RED">RED</Option>
                  <Option value="N/A">N/A</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <div className="">
            <Col>
              <div
                className="dark-modal-description"
                style={{ marginBottom: "10px" }}
              >
                Description<span style={{ color: "red" }}>*</span>
              </div>
              <div>
                <CKEditor
                  className="cknew-editor"
                  editor={ClassicEditor}
                  config={config}
                  content={textEditer}
                  events={{}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setTextEditer(data);
                  }}
                />
              </div>
            </Col>
          </div>

          <Form.Item>
            <div className="" style={{ marginTop: "10px" }}>
              <Button
                type="primary"
                className="pms-same-btn"
                onClick={showConfirmModal}
                loading={loading}
                style={{ marginRight: "10px" }}
              >
                Submit
              </Button>
              <Button onClick={handleCancel} className="pms-same-btn-cancel">
                Cancel
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Are you sure you want to send Escalation?"
        centered
        open={isConfirmVisible}
        onOk={handleConfirmOk}
        onCancel={handleConfirmCancel}
        width={450}
        footer={null}
        className="delete-modal"
      >
        <Button
          className="pms-same-btn"
          type="primary"
          onClick={handleConfirmOk}
          style={{ marginRight: "10px" }}
        >
          Submit
        </Button>
        <Button onClick={handleConfirmCancel} className="pms-same-btn-cancel">
          Cancel
        </Button>
      </Modal>
    </>
  );
}

export default AddEscalation;
