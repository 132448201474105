import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { MDBCol, MDBBtn } from "mdb-react-ui-kit";
import AxiosInstance from "../../../service/AxiosInstance";

const imageUrl = "https://bucket-pms.s3.ap-south-1.amazonaws.com/s3-upload/";

const Comments = ({ User, TaskId, Type }) => {
  const [form] = Form.useForm();
  const [comments, setComments] = useState([]);
  const [count2, setCount2] = useState(false);

  useEffect(() => {
    const formData = new URLSearchParams();
    formData.append("Task_id", TaskId);
    formData.append("Task_id", TaskId);
    AxiosInstance.post("auth/tasks/comments", formData).then(async (res) => {
      if (res.data.status === 1) {
        console.log(res, "res--details---");
        setComments(res.data.data);
      }
    });
    // }
  }, [count2]);
  const onFinish = (values) => {
    if (values.comments.trim()) {
      // const User = JSON.parse(localStorage.getItem('userdata'));
      const formData = new URLSearchParams();
      formData.append("User_id", User[0]._id);
      formData.append("First_name", User[0].First_name);
      formData.append("Last_name", User[0].Last_name);
      formData.append("Task_id", TaskId);
      formData.append("Type", Type);

      if (Type == "Project") {
        formData.append("Project_id", TaskId);
      }

      formData.append("Comment", values.comments);

      AxiosInstance.post("auth/tasks/comment/add", formData)
        .then((res) => {
          setCount2(!count2);
          form.resetFields();
        })
        .catch((err) => console.log(err, "err"));
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // console.log(comments, 'commentsout');

  function Text({ content }) {
    const words = content.split(" ");
    const URL_REGEX =
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
    return (
      <p>
        {words.map((word) => {
          return word.match(URL_REGEX) ? (
            <>
              <a href={word} target="_blank">
                {word}
              </a>{" "}
            </>
          ) : (
            word + " "
          );
        })}
      </p>
    );
  }
  return (
    <>
      {/* #region Comment start */}
      <div className="cardmargine">
        <MDBCol size="12" className="box-width">
          <h5 className="Comments">comment</h5>
          <Form
            className="max-w"
            form={form}
            name="comments"
            // labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item name="comments">
              <Input.TextArea rows={5} maxLength={1000} showCount />
            </Form.Item>
            <Form.Item>
              <MDBBtn
                // style={{ float: 'right' }}
                className="btn-right pms-same-btn"
                gradient="blue"
                htmlType="submit"
              >
                Add
              </MDBBtn>
              {/* <MDBBtn rounded htmlType="submit" style={{float:"right"}} >Add</MDBBtn> */}
            </Form.Item>
            <div className="commentList"></div>
          </Form>
        </MDBCol>
      </div>
      {/* comment listing */}
      {comments &&
        comments.map((item, number) => {
          let time = 0;
          let current = Date.now();
          let before = new Date(item.Create_at).getTime();

          //console.log('x> y',before>current);
          let difrence = current - before;
          const days = Math.floor(difrence / (24 * 60 * 60 * 1000));
          const hours = Math.floor(difrence / (60 * 60 * 1000));
          const minutes = Math.floor(difrence / (60 * 1000));
          const sec = Math.floor(difrence / 1000);
          if (days > 0) {
            time = `${days} days ago`;
          } else if (hours > 0) {
            time = `${hours} hours ago`;
          } else if (minutes === 0 || minutes === 1) {
            time = `Just now`;
          } else {
            time = `${minutes} minutes ago`;
          }
          return (
            <div className="col-md-12 ">
              <div className="media g-mb-30 media-comment ">
                <div className="media-body u-shadow-v18 g-bg-secondary g-pa-30">
                  <div className="g-mb-15">
                    <h6 style={{ fontWeight: 400 }}>
                      <span>
                        <img
                          className=" g-width-25 g-height-25 rounded-circle g-mt-3 g-mr-15"
                          src={
                            item.User_id && item.User_id.Profile_pic
                              ? imageUrl + item.User_id.Profile_pic
                              : "https://bootdey.com/img/Content/avatar/avatar1.png"
                          }
                          alt="Image Description"
                        />
                      </span>
                      {item.First_name} {item.Last_name}
                      <span className="g-color-gray-dark-v4 g-font-size-12 flot-right ">
                        {`${time}`}
                      </span>
                    </h6>
                  </div>
                  <p
                    className="note note-primary"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    <>{<Text content={item.Comment}></Text>}</>
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      {/* #region Comment end */}
    </>
  );
};

export default Comments;
