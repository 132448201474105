import React, { useEffect, useState } from "react";
import "./App.css";
import "../src/assets/css/style.css";
import { HashRouter, Switch, Route } from "react-router-dom";
import Login from "./container/auth/login/Login";
import DefaultLayout from "./components/defaultLayout/DefaultLayout";
import { ToastContainer } from "react-toastify";

import AxiosInstance from "./service/AxiosInstance";
import moment from "moment";

function App() {
  const [isLogedIn, setIsLogedIn] = useState("");

  // setTimeout(() => {
  //   checkYestrdayPresent();
  // }, 3.6e+6);

  // let isLogedIn = localStorage.getItem('token');
  useEffect(() => {
    setIsLogedIn(localStorage.getItem("token"));
  }, []);
  return (
    <>
      {/* <Modal
        title="Attendance Alert"
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={handleCancel}
        width={550}
        className="leave-form modal-back modal-title close-btn"
        footer={null}
      >
        <Typography component="h2" variant="h2">
          Your yesterday attendanace should be mark as absend, please create a
          request for the amendments otherwise it will be close in 24 hours
        </Typography>
      </Modal> */}

      <ToastContainer />
      <HashRouter>
        <Switch>
          {!isLogedIn && <Route exact path="/login" render={() => <Login />} />}
          <Route
            path="/"
            name="Home"
            render={(props) => <DefaultLayout {...props} />}
          />
        </Switch>
      </HashRouter>
    </>
  );
}

export default App;

