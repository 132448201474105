import React, { useState } from "react";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import ProjectSidebar from "../projects/ProjectSidebar";
import { Button, Modal, Form, Row, Col, Upload } from "antd";
import PmsDatePicker from "../me/leaveModule/PmsDatePicker";
import { ExportOutlined, UploadOutlined } from "@ant-design/icons";
import AxiosInstance from "../../../service/AxiosInstance";
import EmployeePresentList from "./EmployeePresentList";

const EmployeePresentReport = () => {
  const baseUrl = "https://api.virtualheight.com/";
  // const baseUrl = 'http://localhost:3000/';

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [selectedFile, setSelecttedFile] = useState([]);
  const [date, setDate] = useState("");

  const handleCancel = (errorInfo) => {
    setVisible(false);
  };

  const props = {
    onChange({ file, fileList }) {
      if (file.status !== "uploading") {
        setSelecttedFile(file.originFileObj);
      }
    },
  };

  const onFinish = (values) => {
    const formData = new FormData();

    formData.append("date", date);
    formData.append("employee_file", selectedFile);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    AxiosInstance.post("/employeePresentReport", formData, config).then(
      (res) => {
        if (res.data.status == 1) {
          form.resetFields();
          setVisible(false);
          console.log(res.data);
        }
      }
    );
  };

  return (
    <>
      <div className="demo">
        <div className="page-content">
          <div className="project-body">
            <MDBRow className="project-title-div  page-container">
              <MDBCol
                style={{ marginLeft: "10px" }}
                size="md"
                className="col-example"
              >
                <h3 className="tablHeader">
                  Add Employee Present

                  <Button
                    type="primary"
                    onClick={() => {
                      setVisible(true);
                    }}
                    style={{ float: "right" }}
                  >
                    Add
                  </Button>
                </h3>
              </MDBCol>
            </MDBRow>
          </div>
          <div>
            <MDBCol
              size="md"
              className="col-example"
            >
              <h5 className="tablHeader">
                Add Employee Present

                <Button
                  className="pms-same-btn"
                  type="primary"
                  onClick={() => {
                    setVisible(true);
                  }}
                  style={{ float: "right" }}
                >
                  Add
                </Button>
              </h5>
            </MDBCol>
            <div>
              <EmployeePresentList />
            </div>
          </div>
        </div>


      </div>

      <Modal
        title="Upload file"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={handleCancel}
        width={700}
        height={640}
        bodyStyle={{ padding: "20px 15px" }}
        footer={null}
        maskClosable={false}
      >
        <Form
          form={form}
          name="control-hooks"
          layout="vertical"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          //onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="left-margin"
        >
          <Row>
            <Col span={12} style={{ paddingRight: "12px" }}>
              <Form.Item
                label="Date"
                name="date"
                // required
                rules={[
                  {
                    required: true,
                    message: "Please Select Date",
                    // whitespace: true,
                  },
                ]}
              >
                <PmsDatePicker
                  format={"DD/MM/YYYY"}
                  onChange={(e) => setDate(e._d)}
                />
              </Form.Item>
            </Col>
            <Col span={12} style={{ paddingRight: "12px" }}>
              <Form.Item
                label="Upload file"
                name="file"
                rules={[
                  {
                    required: true,
                    message: "Please Select File",
                    // whitespace: true,
                  },
                ]}
              >
                <Upload {...props}>
                  <Button
                    className="uploadFile uploadfile-btn pms-same-btn"
                    style={{ width: "16rem", color: "black" }}
                    icon={<UploadOutlined />}
                  >
                    Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <div style={{ textAlign: "right", marginTop: "15px" }}>
            <Button
              className="pms-same-btn"
              type="primary"
              htmlType="submit"
              // onClick={handleUpload}
              style={{ marginRight: "10px" }}
            >
              Submit
            </Button>
            <Button
              className="pms-same-btn-cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>

        </Form>
      </Modal>
    </>
  );
};

export default EmployeePresentReport;

