import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Modal, Form, Button, Select } from "antd";
import {
  Box,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

import SearchIcon from "@mui/icons-material/Search";
import { Link, useHistory } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarNav,
  MDBIcon,
  MDBCol,
} from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import AxiosInstance from "../../../service/AxiosInstance";
import AddTask from "../tasks/Add";
import { Png } from "../../../assets/png";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const ProjectBar = ({
  projectId,
  updateList,
  setIsFilterModalOpen,
  setCount,
  count,
  id,
  setList,
  taskList,
}) => {
  const [projectList, setProjectList] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [projectDetail, setProjectDetail] = useState([]);
  const [taskAddIsShow, setTaskAddIsShow] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [form] = Form.useForm();
  const [isFilterShow, setIsFilterShow] = useState(false);
  const [visible, setVisible] = useState(false);
  const rolePermissions = useSelector((state) => state.Permissions.Permissions);
  let userData = JSON.parse(localStorage.getItem("userdata"));
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    //getRolePermissionList();
  }, []);

  useEffect(() => {
    const formData = new URLSearchParams();
    formData.append("id", projectId);
    AxiosInstance.post("auth/projects/detail?detailprojectbar", formData).then(
      async (res) => {
        if (res.data.status == 1) {
          setProjectDetail(res?.data?.data);
          taskList = { taskList };
        }
      }
    );
  }, [count]);

  const history = useHistory();

  useEffect(() => {
    const path = location.pathname;
    setIsFilterShow(path.startsWith("/project/tasks/"));
  }, [location.pathname]);

  return (
    <div>
      <MDBNavbarNav className="page-menu-nav page-menu-nav-pro">
        <div className="page-menu-nav2">
          <MDBNavbarItem>
            <Link to={`/project/view/${projectId}`} active>
              {" "}
              <MDBNavbarLink active aria-current="page" href="#">
                Dashboard
              </MDBNavbarLink>
            </Link>
          </MDBNavbarItem>
          {rolePermissions?.Project?.All_Task == 1 ? (
            <MDBNavbarItem>
              <Link to={`/project/tasks/${projectId}`} active>
                <MDBNavbarLink>All Tasks</MDBNavbarLink>
              </Link>
            </MDBNavbarItem>
          ) : (
            ""
          )}
          {/* {rolePermissions?.Project?.Backlog == 1 ? ( */}
          <MDBNavbarItem>
            <Link to={`/project/backlog/${projectId}`} active>
              {" "}
              <MDBNavbarLink>Backlog</MDBNavbarLink>
            </Link>
          </MDBNavbarItem>
          {/* ) : ( */}
          {/* "" */}
          {/* )} */}

          {rolePermissions?.Project?.View_Member == 1 ? (
            <MDBNavbarItem>
              <Link to={`/project/resource/${projectId}`}>
                {" "}
                <MDBNavbarLink>Resource</MDBNavbarLink>
              </Link>
            </MDBNavbarItem>
          ) : (
            ""
          )}
          {rolePermissions?.Milestone_Project &&
          Object.values(rolePermissions?.Milestone_Project).includes("1") ==
            true ? (
            <MDBNavbarItem>
              <Link to={`/project/milestone/${projectId}`}>
                {" "}
                <MDBNavbarLink>Milestone</MDBNavbarLink>
              </Link>
            </MDBNavbarItem>
          ) : (
            ""
          )}

          {rolePermissions?.Sprint_Project &&
          Object.values(rolePermissions?.Sprint_Project).includes("1") ==
            true ? (
            <MDBNavbarItem>
              <Link to={`/project/sprint/${projectId}`}>
                {" "}
                <MDBNavbarLink>Sprint</MDBNavbarLink>
              </Link>
            </MDBNavbarItem>
          ) : (
            ""
          )}
        </div>

        <div className="pro-add-task">
          {rolePermissions?.Project?.Create_Task == "1" ? (
            <>
              {isFilterShow == true && (
                <Tooltip title="Filter">
                  <Box
                    onClick={() => {
                      setIsFilterModalOpen(true);
                    }}
                  >
                    <img src={Png.filterIcon} className="filter-icon" />
                  </Box>
                </Tooltip>
              )}
              {/* <FilterModal  open={isFilterModalOpen} onClose={() => setIsFilterModalOpen(false)}  /> */}

              <Button
                className="btn-add-link add-task-btn"
                onClick={() => {
                  if (projectDetail?.Status !== "Completed") {
                    setTaskAddIsShow(true);
                  } else {
                    toast.error(
                      "This Project has been completed, Please change the project status to do any activity"
                    );
                  }
                }}
              >
                <MDBIcon className="plus-btn" fas icon="plus" />
                <span
                  style={{
                    paddingLeft: "15px",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  Create Task
                </span>
              </Button>
            </>
          ) : (
            ""
          )}

          {taskAddIsShow ? (
            <AddTask
              inSidebar={true}
              btnStyl={true}
              projectDetail={projectDetail}
              setFlage={setCount}
              setTaskAddIsShow={(a) => {
                setTaskAddIsShow(a);
              }}
              updateList={updateList}
              taskList={taskList}
              data={localStorage.getItem("ProjectName")}
            />
          ) : null}
        </div>
      </MDBNavbarNav>
    </div>
  );
};

export default ProjectBar;
