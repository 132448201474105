import React, { useEffect, useState } from "react";
import { MDBDataTableV5, MDBCard, MDBIcon } from "mdbreact";
import { MDBCol } from "mdb-react-ui-kit";
import { Modal, Form, Button, message } from "antd";
import AxiosInstance from "../../../service/AxiosInstance";
import ReactLoader from "../../../components/common/ReactLoader";
import AddEscalation from "./AddEscalation";
import { useSelector } from "react-redux";
import moment from "moment";
import { Link } from "@mui/material";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import ViewEscalation from "./ViewEscalation";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const getSeverityClass = (severity) => {
  switch (severity) {
    case "OPEN":
      return "escalation-open";
    case "WORKING ON CONFLICT REPORT":
      return "escalation-status-blue";
    case "CLOSE":
      return "escalation-status-green";
    default:
      return "";
  }
};

function EscalationList() {
  const [isOpen, setIsOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [escalationList, setEscalationList] = useState([]);
  const permssionsState = useSelector((state) => state.Permissions.Permissions);
  const [rolePermissions, setRolepermissions] = useState(permssionsState);
  const [selectedEscalation, setSelectedEscalation] = useState();
  const [escalationId, setEscalationId] = useState();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const history = useHistory();

  const [datatable, setDatatable] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setDatatable({
      columns: [
        {
          label: <MDBIcon fas icon="hashtag" />,
          field: "icon",
          sort: "disabled",
          width: 10,
        },
        {
          label: "Type of Escalation",
          field: "EscalationTypeId",
          width: 100,
          sort: "disabled",
        },
        {
          label: "Project Name",
          field: "ProjectId",
          width: 270,
          sort: "disabled",
        },
        {
          label: "Employee Name",
          field: "EmployeeId",
          width: 100,
          sort: "disabled",
        },
        {
          label: "Created By",
          field: "Created_by",
          width: 100,
          sort: "disabled",
        },
        {
          label: "Status",
          field: "Status",
          width: 100,
          sort: "disabled",
        },
        {
          label: "Created Date",
          field: "createdAt",
          width: 100,
          sort: "disabled",
        },
        {
          label: "Acton",
          field: "Action",
          width: 50,
          sort: "disabled",
        },
      ],

      rows: escalationList,
    });
  }, [escalationList]);

  const stripHtmlTags = (html) => {
    return html.replace(/<\/?[^>]+(>|$)/g, "");
  };

  const getAllEscalation = () => {
    setLoading(true);
    const endpoint =
      rolePermissions?.Escalation?.Escalation_List === "1"
        ? "/auth/getAll/escalation/permissions"
        : "/auth/getAll/escalation";

    AxiosInstance.get(endpoint)
      .then((res) => {
        setLoading(false);
        const list1 = res.data.data.map((item, index) => ({
          icon: index + 1,
          EscalationTypeId: item?.EscalationTypeId?.Escalation_Type,
          ProjectId: item?.ProjectId?.Project_name,
          EmployeeId: `${item?.EmployeeId?.First_name} ${item?.EmployeeId?.Last_name}`,
          Description: stripHtmlTags(item?.Description),
          Created_by: `${item?.Created_by?.First_name} ${item?.Created_by?.Last_name}`,
          Status: (
            <div className={`status ${getSeverityClass(item?.Status)}`}>
              {item?.Status}
            </div>
          ),
          createdAt: moment(item?.createdAt).format("DD-MM-YYYY"),
          Action: (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Button
                type="primary"
                onClick={() => {
                  history.push(`/View-escalation/${item?._id}`);
                }}
              >
                <MDBIcon fas icon="eye" />
              </Button>
              {rolePermissions?.Escalation?.Delete == "1" ? (
                <Button
                  danger
                  className="dlt-btn"
                  type="primary"
                  onClick={() => {
                    setEscalationId(item?._id);
                    setIsOpenDeleteModal(true);
                  }}
                >
                  <DeleteOutlined />
                </Button>
              ) : (
                ""
              )}
            </div>
          ),
        }));
        setEscalationList(list1);
      })
      .catch((error) => {
        console.error("Error fetching escalations: ", error);
        setLoading(false);
      });
  };

  const deleteEscalation = (id) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    AxiosInstance.post("/auth/delete/escalation-assignees", urlencoded).then(
      (res) => {
        if (res?.data?.status == 1) {
          message.success(res?.data?.message);
          setIsOpenDeleteModal(false);
          getAllEscalation();
        } else {
          message.error(res?.data?.message);
        }
      }
    );
  };

  const handleCancel = (errorInfo) => {
    setIsOpenDeleteModal(false);
  };

  useEffect(() => {
    getAllEscalation();
  }, []);

  const reloadList = () => {
    setIsOpen(false);
    getAllEscalation();
  };
  const reloadViewList = () => {
    setIsViewOpen(false);
    getAllEscalation();
  };

  return (
    <>
      <Modal
        title="Are you sure to delete this record"
        centered
        open={isOpenDeleteModal}
        onOk={() => setIsOpenDeleteModal(false)}
        onCancel={handleCancel}
        width={380}
        footer={null}
        className="delete-modal"
      >
        <Button
          className="pms-same-btn"
          type="danger"
          onClick={() => deleteEscalation(escalationId)}
          style={{
            marginRight: "7%",
          }}
        >
          Delete
        </Button>
        <Button onClick={handleCancel} className="pms-same-btn-cancel">
          Cancel
        </Button>
      </Modal>
      <div className="">
        <div className="demo">
          <MDBCard>
            <div className="page-container">
              <div className="mx-0 create-project">
                <MDBCol size="md" className="col-example">
                  <h3 className="tablHeader">Escalation</h3>
                </MDBCol>
                <MDBCol size="md" className="col-example float-right">
                  <span className="btn-right">
                    {rolePermissions?.Escalation?.Add_Escalation == "1" ? (
                      <>
                        <Button
                          className="pms-same-btn"
                          type="primary"
                          onClick={() => {
                            setIsOpen(true);
                          }}
                        >
                          Create Escalation
                        </Button>
                        {isOpen ? (
                          <AddEscalation
                            inSidebar={true}
                            btnStyl={true}
                            reloadList={reloadList}
                            setIsOpen={(a) => {
                              setIsOpen(a);
                            }}
                          />
                        ) : null}
                      </>
                    ) : (
                      ""
                    )}
                  </span>
                </MDBCol>
              </div>
            </div>

            {loading ? (
              <ReactLoader loading={loading} />
            ) : (
              <div class="tasklisttbl page-container all-project-list">
                <MDBDataTableV5
                  entriesOptions={[20, 25, 30]}
                  entries={20}
                  pagesAmount={4}
                  data={datatable}
                  searchTop
                  searchBottom={false}
                  className="directory-list-table project_list_table cus-project_list_table custom-table-escalation"
                />
              </div>
            )}
          </MDBCard>
        </div>
      </div>

      {isViewOpen && selectedEscalation && (
        <ViewEscalation
          escalationId={selectedEscalation._id}
          reloadViewList={reloadViewList}
          setIsViewOpen={setIsViewOpen}
          isViewOpen={isViewOpen}
        />
      )}
    </>
  );
}

export default EscalationList;
