import React from 'react'
import { DatePicker } from 'antd';
const {RangePicker} = DatePicker
const PmsRangePicker = (props) => {
  return (
   <RangePicker 
   className = {props.className}
   onChange = {props.onChange}
   onClick = {props.onClick}
   format = {props.format}
   
   ></RangePicker>
  )
}

export default PmsRangePicker;