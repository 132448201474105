import React, { useEffect, useState } from "react";
import { Input, Tabs, Avatar, Badge, Tooltip, Button } from "antd";
import { Link } from "react-router-dom";

import { Col, Row } from "antd";
import DueMilestone from "./Milestone/DueMilestone";
import DueSprint from "./Sprint/DueSprint";
import MeBar from "../me/MeBar";

const DueDashboard = () => {
  return (
    <>
      <div className="event-main">
        <MeBar />

        <div className="page-content">
          <div className="create-project">
            <h3 className="tablHeader">Project Dashboard</h3>
          </div>
          <div>
            <Col className="col" xs={24} sm={24} md={24} lg={24} xl={24}>
              <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Due Milestone" key="1">
                  <div className="all-task">
                    <DueMilestone />
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Due Sprint" key="3">
                  <DueSprint />
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </div>
        </div>
      </div>
    </>
  );
};

export default DueDashboard;
