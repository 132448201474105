import axios from "axios";
// const isToken = localStorage.getItem('client-login');
var isToken = localStorage.getItem("token");

var baseUrl = "https://api.virtualheight.com/";

// var baseUrl = "http://localhost:3001/";
var token = isToken ? isToken : "";

export var imageUrl = "http://localhost:3000/images";

let header = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};
if (token) {
  header = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      auth: token,
    },
  };
} else {
  header = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
}

const AxiosInstance = axios.create({
  baseURL: baseUrl,
  headers: header.headers,
});

export default AxiosInstance;
