import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Row, Col, Select, message } from "antd";
import AxiosInstance from "../../../../service/AxiosInstance";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AttendanceDeviceModal({
  setIsOpen,
  reloadList,
  deviceId,
}) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(true);
  const [locationId, setLocationId] = useState("");
  const [locationName, setLocationName] = useState([]);

  useEffect(() => {
    AxiosInstance.get("/location/getAllLocations")
      .then((res) => {
        if (res?.data?.status === 1) {
          setLocationName(res.data.data);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch locations:", error);
      });
  }, []);

  const handleCancel = () => {
    form.resetFields();
    setVisible(false);
    setIsOpen(false);
  };

  const onFinish = (values) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("device_name", values.device_name);
    urlencoded.append("device_id", values.device_id);
    urlencoded.append("Location_id", locationId);

    AxiosInstance.post("/auth/add/deviceId", urlencoded)
      .then((res) => {
        if (res?.data?.status == 1) {
          message.success("Device id created successfully");
          setIsOpen(false);
          reloadList();
        } else {
          message.error("Device id already created");
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || "Failed to add device");
      });
  };

  return (
    <div>
      <Modal
        className="ant-modal-centered"
        title="Add Attendance Device"
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={handleCancel}
        width={400}
        bodyStyle={{ padding: "20px 15px" }}
        footer={null}
        maskClosable={false}
      >
        <Form
          form={form}
          name="control-hooks"
          layout="device"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row>
            <Col span={24}>
              <div>
                Device Name<span style={{ color: "red" }}>*</span>
              </div>
              <Form.Item
                name="device_name"
                rules={[
                  { required: true, message: "Please Enter Device Name" },
                ]}
                placeholder="Please Select Location Name"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <div>
                Device ID<span style={{ color: "red" }}>*</span>
              </div>
              <Form.Item
                name="device_id"
                rules={[{ required: true, message: "Please Enter Device ID" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <div>
                Location<span style={{ color: "red" }}>*</span>
              </div>
              <Form.Item
                name="Location_id"
                rules={[
                  {
                    required: true,
                    message: "Please Select Location Name",
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  onChange={(e) => setLocationId(e)}
                  placeholder="Please Select Location Name"
                >
                  {locationName?.map((item) => {
                    return (
                      <Select.Option value={item._id} key={item._id}>
                        {item.Location_name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <div style={{ textAlign: "end", marginTop: "10px" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: "10px" }}
                className="pms-same-btn"
              >
                Submit
              </Button>
              <Button className="pms-same-btn-cancel" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
