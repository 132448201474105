import { useState } from "react";
import ContactBar from "./ContactBar";
import { Link } from "react-router-dom";
import { MDBCard } from "mdbreact";
import { MDBCol } from "mdb-react-ui-kit";
import { Png } from "../../../assets/png";
import { JPG } from "../../../assets/jpg";
function HandBook() {
  const [loading, setLoading] = useState(false);
  return (
    <>
      <div className="leave-apply-book">
        <MDBCard className="page-content">
          <div className="page-wrapper-main handbook-hr-details">
            <h3 className="page-subtitle-main">PLEASE READ CAREFULLY</h3>
            <p className="page-para">
              Your leave will be approve by your CH(Cluster Head)
            </p>
            <p className="page-para">
              CH, Pool, Bench and Rest Employees leave will be approved by HR
            </p>

            {/*  */}
            <div className="policy-main-section">
              <div className="page-content-section">
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Casual Leave (1 to 8+ Days)
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      If you want to apply for 1 day leave then you have to
                      apply leave before 2 days and it will auto approve before
                      24 hours of leave date, if not approved by the CH in given
                      time.
                    </li>
                    <li className="page-li">
                      If you applied for 2 to 4 days leave then you have to
                      apply leave before 6 days and it will auto approve before
                      2 days of leave date., if not approved by the CH in given
                      time.
                    </li>
                    <li className="page-li">
                      If you applied for 5 to 7 days leave then you have to
                      apply leave before 15 days and it will auto approve before
                      5 days of leave date., if not approved by the CH in given
                      time.
                    </li>
                    <li className="page-li">
                      If you applied for 8 to 8+ day leave then you have to
                      apply leave before 30 days and it will auto approve on
                      10th day of applied date,, if not approved by the CH in
                      given time.
                    </li>
                  </ul>
                </div>
                <div className="page-content-main">
                  <h4 className="page-content-title-text">Sick Leave</h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      Employee can take max 5 sick leaves in year
                    </li>
                    <li className="page-li">
                      Employee has option to select 1 to 5 days
                    </li>
                    <li className="page-li">
                      For 1 to 2 days sick leave will be auto approve same day
                    </li>
                    <li className="page-li">
                      Employee has to attach document if applied for more then 2
                      days.
                    </li>
                  </ul>
                </div>
                <div className="page-content-main">
                  <h4 className="page-content-title-text">Emergency Leave</h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      Employee can take max 6 emergency leave in year.
                    </li>
                    <li className="page-li">
                      Emergency Leave will be same day only and it will be auto
                      approve same day
                    </li>
                  </ul>
                </div>
                <div>
                  <h4 className="page-content-title-text">
                    NOTE - Emergency leave will be counted from quota of CL.{" "}
                  </h4>
                </div>
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Attendance Changes
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      If any reason PMS showing absent in check in then you have
                      to contact HR to add check in time on either the same day
                      on max next day. Afterwards no request will be
                      entertained.
                    </li>
                    <li className="page-li">
                      If any reason checkout not done then next day you have to
                      inform HR for the same and it should update same day only.
                      Afterwards no request will be entertained.
                    </li>
                    <li className="page-li">
                      If you are on leave and not apply leave then in attendance
                      first and second half showing as Red in this case you can
                      apply for leave and that leave will Approve/Reject by
                      CPO(Shivani Mam) only on medical and emergency ground.
                    </li>
                    <li className="page-li">
                      If you did not complete your 8.30 hours then that day will
                      be counted as HALF DAY.
                    </li>
                    <li className="page-li">
                      Check you attendance daily and if you find anything wrong
                      immediately contact HR.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </MDBCard>
      </div>
    </>
  );
}
export default HandBook;
