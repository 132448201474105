import { Select, Spin, Table } from "antd";
import React, { useState, useEffect } from "react";
import AxiosInstance from "../../../../service/AxiosInstance";
import { MDBIcon } from "mdb-react-ui-kit";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Status } from "../../Options";
import { IssueType } from "../../Options";
import {
  taskPriorityBadge,
  taskStatusBadge,
} from "../../../../components/common/Coomon";
import { actionMyTaskAction } from "../../../../redux/actions/TaskActions";
import { Png } from "../../../../assets/png";

const TaskTable = ({ employeeDetails }) => {
  const MyTask = useSelector((state) => state.Task?.MyTask);
  const [myTeamTask, setmyTeamTask] = useState(MyTask || []);
  const [myTask, setMyTask] = useState([]);
  const [statusSort, setStatusSort] = useState("ALL");
  const [taskSort, setTaskSort] = useState("ALL");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const columns = [
    {
      title: "#",
      dataIndex: "taskKey",
    },
    {
      title: "Summary",
      dataIndex: "shortSummary",
    },
    {
      title: "P",
      dataIndex: "priority",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "AssignedDate",
      dataIndex: "assignedDate",
    },
    {
      title: "Time",
      dataIndex: "assignedTime",
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
    },
  ];

  useEffect(() => {
    if (employeeDetails?.id) {
      getByUserIdTasks();
    } else {
      getDefaultUserTasks();
    }
  }, [employeeDetails]);

  useEffect(() => {
    getTaskList();
  }, [myTeamTask, statusSort, taskSort]);

  const getDefaultUserTasks = () => {
    setLoading(true);
    AxiosInstance.get("auth/employeetasks/list/Default").then(async (res) => {

      setLoading(false);
      dispatch(actionMyTaskAction(res.data.data));
      setmyTeamTask(res.data.data);
    });
  };

  const getByUserIdTasks = () => {
    const urlId = new URLSearchParams();
    urlId.append("id", employeeDetails?.id);
    setLoading(true);
    AxiosInstance.post("employeetasks/list", urlId).then(async (res) => {
      setLoading(false);
      const reverseData = [...res.data.data].reverse();
      setmyTeamTask(reverseData);
    });
  };

  const getTaskList = () => {
    const filteredTasks = myTeamTask
      .filter((row) => row?.Status !== "CLOSE")
      .filter((row) =>
        statusSort === "ALL" ? true : row?.Status === statusSort
      );

    const sortedTasks = filteredTasks.filter((item) =>
      taskSort === "ALL" ? true : item?.Issue_type === taskSort
    );

    const data = sortedTasks.map((row) => {
      let taskIcon;
      switch (row.Issue_type) {
        case "Bug":
          taskIcon = (
            <MDBIcon
              fas
              icon="bug"
              style={{ color: "red", marginRight: "8px" }}
            />
          );
          break;
        case "Story":
          taskIcon = (
            <MDBIcon
              fas
              icon="bookmark"
              style={{ color: "green", marginRight: "8px" }}
            />
          );
          break;
        case "Suggestion":
          taskIcon = (
            <img
              src={Png.SuggestionIcon}
              style={{ width: "18px", marginRight: "8px" }}
              alt="Suggestion Icon"
            />
          );
          break;
        default:
          taskIcon = (
            <MDBIcon
              fas
              icon="check"
              style={{ color: "#1890ff", marginRight: "8px" }}
            />
          );
          break;
      }

      return {
        ...row,
        taskKey: (
          <span>
            {taskIcon}
            <Link style={{ fontWeight: "500" }} to={`/task/${row?._id}`}>
              {row?.Task_key}
            </Link>
          </span>
        ),
        shortSummary: `${row?.Short_summary.substring(0, 50)}`,
        priority: taskPriorityBadge(row?.Priority),
        status: taskStatusBadge(row?.Status),
        assignedDate:
          row?.Assigned_date != "-"
            ? moment(row?.Assigned_date).format("DD/MM/YYYY")
            : "-",
        assignedTime:
          row?.Task_duration && row?.Task_duration.trim() !== "undefined"
            ? `${row?.Task_duration} Hr`
            : "-",
        dueDate:
          row?.Due_date &&
          row?.Due_date !== "-" &&
          row?.Due_date !== "Invalid date"
            ? moment(row?.Due_date).format("MMM DD")
            : "-",
      };
    });

    setMyTask(data);
  };

  return (
    <>
      <div className="my-task-dropdown my-task-dropdown-flex">
        <Select
          className="select-dropdown box-width"
          value={statusSort}
          options={Status}
          onChange={(e) => setStatusSort(e)}
        />
        <Select
          className="select-dropdown box-width"
          value={taskSort}
          options={IssueType}
          onChange={(e) => setTaskSort(e)}
        />
        <Spin spinning={loading} />
      </div>
      <Table className="team-table" columns={columns} dataSource={myTask} />
    </>
  );
};

export default TaskTable;
