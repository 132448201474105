import React, { useState, useEffect } from "react";
import { Select, Spin, Table } from "antd";
import AxiosInstance from "../../../../service/AxiosInstance";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionMyDailyReport } from "../../../../redux/actions/TaskActions";
const MyReport = () => {
  const MyDailyTaskReport = useSelector(
    (state) => state.Task?.MyDailyTaskReport
  );

  const [myReport, setMyReport] = useState(MyDailyTaskReport);
  const [myTask, setMyTask] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const columns = [
    {
      title: "Task",
      dataIndex: "Task",
    },
    {
      title: "Summary",
      dataIndex: "shortSummary",
    },
    {
      title: "Start Time",
      dataIndex: "Starttime",
    },
    {
      title: "End Time",
      dataIndex: "Endtime",
    },
    {
      title: "Total Time",
      dataIndex: "totaltime",
    },
  ];
  const getDefaultReport = () => {
    setLoading(true);
    AxiosInstance.get("auth/working-hours").then((res) => {
      setLoading(false);
      dispatch(actionMyDailyReport(res.data.data));
      setMyReport(res.data.data);
    });
  };

  const hourLogs = (startDate) => {
    const newStartDate = new Date(startDate);
  };
  const GetMyReport = () => {
    let data = [];
    myReport?.map((row) => {
      var duration = moment.duration(
        moment(row?.Check_Out).diff(moment(row.Check_In))
      );
      var seconds = duration.asSeconds();

      let minutes = Math.floor(seconds) % 3600;
      let durationTime =
        Math.floor(seconds / 3600) +
        " hours " +
        Math.floor(minutes / 60) +
        " mins " +
        Math.floor(seconds % 60) +
        " seconds ";
      let detail = {
        Task: (
          <Link style={{ fontWeight: "500" }} to={`/task/${row?.Task_id._id}`}>
            {row?.Task_id.Task_key}
          </Link>
        ),
        shortSummary: row.Task_id.Short_summary,
        Starttime: `${moment(row.Check_In).format("hh:mm:ss")}`,
        Endtime: `${moment(row.Check_Out).format("hh:mm:ss")}`,
        totaltime: durationTime,
      };
      data.push(detail);
    });
    setMyTask(data);
  };
  useEffect(() => {
    GetMyReport();
  }, [myReport]);

  useEffect(() => {
    getDefaultReport();
  }, []);

  return (
    <>
      {" "}
      <div className="my-task-dropdown">
      </div>
      <Spin spinning={loading}></Spin>
      <Table className="team-table" columns={columns} dataSource={myTask} />
    </>
  );
};

export default MyReport;
