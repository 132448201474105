import moment from "moment";
import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import AxiosInstance from "../../../../service/AxiosInstance";
import { MyprojectStatusBadge } from "../../../../components/common/Coomon";
import { Link } from "react-router-dom";

const MyProject = ({ loading, milestoneData }) => {
  // const [milestoneData, setMilestoneData] = useState([]);
  // const [loading, setLoading] = useState(false);

  // const GetMyProject = () => {
  //   setLoading(true)
  //   AxiosInstance.get("/auth/details-project").then((res) => {
  //     setLoading(false)
  //     let sortedList = res.data.data.sort((a, b) => moment(a.Project_due_date) - moment(b.Project_due_date));
  //     let list1 = sortedList.map((item, index) => {
  //       return {
  //         icon: index + 1,
  //         project_name: item?.Project_name,
  //         status: MyprojectStatusBadge(item?.Status),
  //         Tech_stack: item?.Tech_stack,
  //         dueDate: item?.Project_due_date=="-"? "-" :
  //           moment(item?.Project_due_date).format("DD/MM/YYYY"),
  //         Date: item?.Project_start_date=="-"? "-" :
  //            `${moment(item?.Project_start_date).format("DD-MMM")} - ${moment(item?.Project_due_date).format("DD-MMM")}`,
  //         Border_left: item?.Project_due_date
  //           ? item?.Project_due_date
  //           : "",
  //       };
  //     });

  //     setMilestoneData(list1);
  //   });
  // };

  // useEffect(() => {
  //   GetMyProject();
  // }, []);
  return (
    <>
      <div className="dash-col-milestone">
        <div className="main-milestone">
          <div className="milstone-flex">
            <div className="milestone-card-head">
              <p>My Project</p>
            </div>
          </div>
          <div className="milestone-card-body">
            {milestoneData &&
              milestoneData.map((item) => {
                return (
                  <Link
                    className="mile-body-content"
                    to={`/project/view/${item?.id}`}
                    target="_blank"
                    style={{
                      borderLeft: moment(item?.Border_left).isBefore(
                        moment(),
                        "day"
                      )
                        ? "4px solid red"
                        : moment(item?.Border_left).diff(moment(), "day") <= 15
                        ? "4px solid yellow"
                        : "4px solid grey",
                    }}
                  >
                    <div className="mile-date">
                      <p>
                        {" "}
                        {item?.dueDate ? item?.dueDate?.split("/")[0] : "-"}
                        <span>
                          {item?.dueDate !== "-"
                            ? moment(item?.dueDate?.split("/")[1], "MM").format(
                                "MMM"
                              )
                            : ""}
                        </span>
                      </p>
                    </div>
                    <div className="mail-right-detail">
                      <p className="mile-name">{item?.project_name}</p>
                      <p className="mile-pro-name">{item?.Tech_stack}</p>
                      <div className="mile-status-date">
                        <p className="mile-status">{item?.status}</p>
                        <p className="">
                          {item.Date ? (
                            <>
                              <i className="far fa-calendar-alt"></i>{" "}
                              {item.Date}
                            </>
                          ) : (
                            <>
                              Invalid date{" "}
                              <i className="far fa-calendar-alt"></i>
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  </Link>
                );
              })}

            <Spin spinning={loading} style={{ marginTop: 100 }}>
              {" "}
            </Spin>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProject;
