import React from "react";
import { useEffect, useState } from "react";
import { MDBDataTableV5, MDBCard } from "mdbreact";
import { MDBCol } from "mdb-react-ui-kit";
import moment from "moment";
import { Input, Modal, Select } from "antd";
import ReactLoader from "../../../components/common/ReactLoader";
import PmsRangePicker from "../me/leaveModule/RangePicker";
import AxiosInstance from "../../../service/AxiosInstance";
const { Option } = Select;

function EmployeeHoursList() {
  const [employeeHoursList, setEmployeeHoursList] = useState([]);
  const [datatable, setDatatable] = useState({});
  const [dateRange, setDateRange] = useState([]);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [username, setUsername] = useState("");
  const [isModalTaskList, setIsModalTaskList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const getEmployeeHoursList = () => {
    const formData = new URLSearchParams();
    formData.append("startDate", dateRange?.[0] || "");
    formData.append("endDate", dateRange?.[1] || "");
    formData.append("employeeId", username || "");

    setLoading(true);
    AxiosInstance.post("/auth/emaplyee/hours-list", formData)
      .then((res) => {
        setLoading(false);
        console.log(res.data, 123);

        if (res?.data?.status == 1) {
          setEmployeeHoursList(res?.data?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    const transformedData = employeeHoursList.map((employee, index) => {
      const projectsList = employee.projects.map((project) => (
        <div className="project-name-task-hours" key={project.Project_name}>
          {project.Project_name}
        </div>
      ));
      const projectTotalTaskHours = employee.projects.map(
        (project, projectIndex) => (
          <div
            key={projectIndex}
            className="project-name-task-hours"
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() =>
              showTaskListModal(
                project.tasks,
                `${employee.Employee_name} - ${project.Project_name}`
              )
            }
          >
            {project.projectTaskHours}
          </div>
        )
      );

      return {
        icon: index + 1,
        Employee_name: employee.Employee_name,
        Project_name: projectsList,
        Hours: projectTotalTaskHours,
      };
    });

    setDatatable({
      columns: [
        {
          label: "No",
          field: "icon",
          sort: "disabled",
          width: 10,
        },
        {
          label: "Employee Name",
          field: "Employee_name",
          width: 150,
          sort: "disabled",
        },
        {
          label: "Project Name",
          field: "Project_name",
          width: 150,
          sort: "disabled",
        },
        {
          label: "Hours",
          field: "Hours",
          width: 150,
          sort: "disabled",
        },
        // {
        //   label: "Start Date",
        //   field: "StartDate",
        //   width: 270,
        //   sort: "disabled",
        // },
      ],
      rows: transformedData,
    });
  }, [employeeHoursList]);

  const getEmployeeList = () => {
    AxiosInstance.get("auth/employees").then(async (res) => {
      if (res.data.status == 1) {
        setOptions(res.data.data);
      }
    });
  };

  useEffect(() => {
    getEmployeeList();
  }, []);

  useEffect(() => {
    getEmployeeHoursList();
  }, [username, dateRange]);

  const showTaskListModal = (tasks, title) => {
    setIsModalTaskList(tasks);
    setIsModalVisible(true);
  };

  const handleTaskListCancel = () => {
    setIsModalVisible(false);
    setIsModalTaskList(null);
    setSearchQuery("");
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredTasks = isModalTaskList?.filter(
    (task) =>
      task?.Project_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      task?.Task_key.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <div className="demo">
        <MDBCard className="page-content">
          <div className="create-project">
            <MDBCol size="md" className="col-example  titel-set">
              {" "}
              <>
                <div className="leave-set-btn2">
                  <MDBCol size="md" className="col-example">
                    <h3 className="tablHeader">Employee Hours List</h3>
                  </MDBCol>
                </div>
              </>
            </MDBCol>
          </div>
          <div className="row">
            <MDBCol size="md-3">
              <label className="start-list">Due Date</label>
              <PmsRangePicker
                className="search-rangepicker"
                allowClear={true}
                format="DD-MM-YYYY"
                onChange={(values) => {
                  if (values == null) {
                    setDateRange("");
                  } else {
                    setDateRange(
                      values.map((item) => {
                        return moment(item).format("MM/DD/YYYY");
                      })
                    );
                  }
                }}
              />
            </MDBCol>
            <MDBCol size="md-3">
              <label className="start-list">Employee</label>
              <Select
                placeholder="Employee Search"
                // mode="multiple"
                allowClear={true}
                showSearch
                className="select-dropdown leave-4"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(e) => {
                  setUsername(e);
                }}
              >
                {options.map((option) => (
                  <Option
                    value={option._id}
                  >{`${option.First_name}${option.Last_name}`}</Option>
                ))}
              </Select>
            </MDBCol>
          </div>

          {loading ? (
            <ReactLoader loading={loading} />
          ) : (
            <div className="scrool-set" style={{ marginTop: "20px" }}>
              <div className="sprint-tbl-report">
                <MDBDataTableV5
                  hover
                  className="employee-hours-list-cus"
                  sortable={true}
                  entriesOptions={[10, 20, 25]}
                  entries={10}
                  pagesAmount={4}
                  data={datatable}
                  //   searchTop
                  searchBottom={false}
                />
              </div>
            </div>
          )}
        </MDBCard>

        <Modal
          width={800}
          className="myssss"
          title={"Employee Task List"}
          visible={isModalVisible}
          onCancel={handleTaskListCancel}
          footer={null}
        >
          <div>
            <Input
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchChange}
              style={{ marginBottom: "10px", width: "200px" }}
            />
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Project Name</th>
                  <th>Task Key</th>
                  <th>Issue Type</th>
                  <th>Status</th>
                  <th>Task Duration</th>
                </tr>
              </thead>
              <tbody>
                {filteredTasks?.map((task, index) => (
                  <tr key={index}>
                    <td>{task?.Project_name}</td>
                    <td>{task?.Task_key}</td>
                    <td>{task?.Issue_type}</td>
                    <td>{task?.Status}</td>
                    <td>{task?.TaskRunningTime}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default EmployeeHoursList;
