import React, { useEffect, useState } from "react";
import { Button, Form, Radio, Modal, Menu, Dropdown, Input, Spin } from "antd";
import { MDBDataTableV5, MDBCard } from "mdbreact";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import AxiosInstance from "../../../service/AxiosInstance";
import moment from "moment";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { ExportOutlined } from "@ant-design/icons";
import ReactLoader from "../../../components/common/ReactLoader";
const values = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
const KraForm = () => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [datatable, setDatatable] = useState({});
  const [form] = Form.useForm();
  const [q2, setQ2] = useState(null);
  const [q3, setQ3] = useState(null);
  const [flag, Setflag] = useState(false);
  const [id, setId] = useState("");
  const [id2, setId2] = useState("");
  const [employees, Setemployees] = useState([]);
  const [ratingId, setRatingId] = useState("");
  const [loading, setLoading] = useState(false);
  const [exportData , setExportData] = useState([]);
  const [dataLoading,setDataLoading] = useState(false)

  const showModal = (item) => {
    setId(item?._id);
    setIsModalOpen(true);
  };
  const showModal2 = (item) => {
    setId2(item?._id);
    setIsModalOpen2(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
  useEffect(() => {
    setDatatable({
      columns: [
        {
          label: "Name",
          field: "name",
          // sort: 'disabled',
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Code",
          field: "Employee_code",
          // sort: 'disabled',
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Department",
          field: "department",
          // sort: 'asc',
          width: 200,
        },
        {
          label: "Position",
          field: "Position",
          // sort: 'asc',
          width: 200,
        },
        {
          label: "Team Leader Name",
          field: "Team_leader_name",
          // sort: 'asc',
          width: 200,
        },
        {
          label: "Own Score",
          field: "Own_Score",
          // sort: 'asc',
          width: 200,
        },
        {
          label: "TL/HOD Score",
          field: "TL_Score",
          // sort: 'asc',
          width: 200,
        },



        {
          label: "Action",
          field: "action",
          sort: "disabled",
          width: 100,
        },
      ],
      rows: employees,
    });
  }, [flag, employees]);

  const [select, setSelect] = useState({});
  const Rating = ({ value, id }) => {
    setSelect({})
    const formdata = new URLSearchParams();
    if (value == "TL/HOD") {
      formdata.append("value", value);
    }
    formdata.append("id", id);
    setLoading(true);
    AxiosInstance.post("auth/kra/rating", formdata).then((res) => {
      setLoading(false);
      if (res?.data?.data[res.data.data.length-1]?.Employee_rating) {
        setSelect(res?.data?.data[res.data.data.length-1]?.Employee_rating);
      } else {
        setSelect(res?.data?.data[res.data.data.length-1]?.Team_leader_rating);
      }
    });
  };

  useEffect(() => {
    if (ratingId) {
      Rating(ratingId);
    }
  }, [ratingId, values]);

  useEffect(() => {
    setDataLoading(true)
    const formdata = new URLSearchParams();
    // formdata.append("Q1", TLname);
    // formdata.append("Role", "React/Node");
    AxiosInstance.post("auth/kra/list").then(async (result) => {
      if (result.data.data) {
        setDataLoading(false)
        console.log(result.data.data , 145)
        let tempArr = []
        let filterArr = []
        let employeesdeta = await result.data.data.map((item) => {
          const currentMonth = new Date().getMonth() + 1;
          console.log(currentMonth, "div");
          let previousMonth = moment(item?.date).format("M")
          console.log(previousMonth, "divyaaaa");
          if (currentMonth == previousMonth) {
            let res = {
                date: moment(item?.date).format("DD-MM-YYYY"),
                name: `${item?.name?.First_name} ${item?.name?.Middle_name} ${item?.name?.Last_name}`,
                department: item?.name?.Role,
                Position: item?.name?.Position,
                Employee_code: item?.name?.Employee_code,
                Team_leader_name: `${item?.tl_name?.First_name} ${item?.tl_name?.Middle_name} ${item?.tl_name?.Last_name}`,
                Own_Score: item.employee_rating,
                TL_Score: item.tl_rating,
                action: actions(item),
                // (
                //   <Link
                //     style={{ fontWeight: "500" }}
                //     onClick={() => showModal(item)}
                //   >
  
                //   </Link>
                // ),
            }
            tempArr.push(res)
          }
        });
        setExportData(tempArr)
          Setemployees(tempArr);

      }
    });
  }, [flag]);
  const handleClick = (items, value) => {
    if (value == "TL/HOD") {
      showModal(items);
    } else {
      showModal2(items);
    }
  };
  const checkAction = () => {
    exportData.forEach(object => {
      delete object['action'];
    });
    let currentDate = moment(new Date()).format("DD-MM-YYYY")
    exportAll(exportData, `Employee KRA (${currentDate})`);
  };
  const exportAll = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);

    // delete ws["H1"];
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const data = new Blob([excelBuffer], { type: fileType });

    
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  // const actionMenu = (items) => {
  //   return (
  //     <>
  //       <Link
  //         style={{ fontWeight: "500" }}
  //         onClick={(e) => {
  //           let res = {
  //             id: items?.name?._id,
  //             value: "Employee",
  //           };
  //           setRatingId(res);
  //           Rating(res);
  //           handleClick(items, "Employee");
  //         }}
  //       >
  //         VIEW
  //       </Link>

  //       <Link
  //         style={{ fontWeight: "500" }}
  //         onClick={(e) => {
  //           let res = {
  //             id: items?.name?._id,
  //             value: "TL/HOD",
  //           };
  //           setRatingId(res);
  //           Rating(res);
  //           handleClick(items, "TL/HOD");
  //         }}
  //       >
  //         | TL/HOD
  //       </Link>
  //     </>
  //   );
  // };

  const actions = (items) => {
    return (
      <>
        {/* <Dropdown.Button overlay={actionMenu(items)}></Dropdown.Button> */}
        <Link
          style={{ fontWeight: "500", marginRight: "10px" }}
          onClick={(e) => {
            let res = {
              id: items?.name?._id,
              value: "Employee",
            };
            setRatingId(res);
            Rating(res);
            handleClick(items, "Employee");
          }}
        >
          VIEW
        </Link>
        |
        <Link
          style={{ marginLeft: "10px", fontWeight: "500" }}
          onClick={(e) => {
            let res = {
              id: items?.name?._id,
              value: "TL/HOD",
            };
            setRatingId(res);
            Rating(res);
            handleClick(items, "TL/HOD");
          }}
        >
          TL/HOD
        </Link>
      </>
    );
  };
  return (
    <>
      <div className="demo">
        <div className="page-container">
          <div className="mx-0 create-project">
            <MDBCol size="md" className="col-example emp-kra-btns">
              <h3 className="tablHeader">Employee KRA</h3>
              <button
                type="button"
                className="ant-btn export-btn pms-same-btn"
                onClick={() => checkAction()}
              >
                <ExportOutlined />
              </button>
            </MDBCol>

          </div>
          <MDBCol size="md" className="col-example float-right">
            <span className="btn-right right-emp-btn">
              <Modal
                title="Rate review by TL/HOD"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                className="tl-kra-modal"
              >
                <Spin spinning={loading}></Spin>
                <div>
                  <div className="questions">
                    <Form
                      form={form}
                      name="control-hooks"
                      layout="vertical"
                      autoComplete="off"
                    >
                      <h6 className="kra-modal-space">
                        {" "}
                        1.How well was His/her performance for all tasks in{" "}
                        {moment().subtract(1, "month").format("MMM")}{" "}
                        {moment().format("YYYY")} ?
                      </h6>
                      <Form.Item>
                        <Radio.Group
                          value={
                            select && Object.keys(select).length > 0
                              ? `${select?.Q1}`
                              : ""
                          }
                        >
                          {values.map((item, index) => {
                            return <Radio value={item}>{item}</Radio>;
                          })}
                        </Radio.Group>
                      </Form.Item>

                      <h6 className="kra-modal-space">
                        2.How much was He/She capable to meet the deadlines? ?
                      </h6>
                      <Form.Item>
                        <Radio.Group
                          value={
                            select && Object.keys(select).length > 0
                              ? `${select?.Q2}`
                              : ""
                          }
                        >
                          {values.map((item, index) => {
                            return <Radio value={item}>{item}</Radio>;
                          })}
                        </Radio.Group>
                      </Form.Item>

                      <h6 className="kra-modal-space">
                        3.Rate Him/her as a team player from scale of 1 to 10 based on your observation.
                      </h6>
                      <Form.Item>
                        <Radio.Group
                          value={
                            select && Object.keys(select).length > 0
                              ? `${select?.Q3}`
                              : ""
                          }
                        >
                          {values.map((item, index) => {
                            return <Radio value={item}>{item}</Radio>;
                          })}
                        </Radio.Group>
                      </Form.Item>



                      <h6 className="kra-modal-space">
                        4.Rate Him/Her on overall performance on a scale of 1 to 10.
                      </h6>
                      <Form.Item>
                        <Radio.Group
                          value={
                            select && Object.keys(select).length > 0
                              ? `${select?.Q4}`
                              : ""
                          }
                        >
                          {values.map((item, index) => {
                            return <Radio value={item}>{item}</Radio>;
                          })}
                        </Radio.Group>
                      </Form.Item>
                      <h6 className="kra-modal-space">
                        5.Rate His/Her behavior on a scale of 1 to 10.
                      </h6>
                      <Form.Item>
                        <Radio.Group
                          value={
                            select && Object.keys(select).length > 0
                              ? `${select?.Q5}`
                              : ""
                          }
                        >
                          {values.map((item, index) => {
                            return <Radio value={item}>{item}</Radio>;
                          })}
                        </Radio.Group>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </Modal>
            </span>
          </MDBCol>
          <MDBCol size="md" className="col-example float-right">
            <span className="btn-right right-emp-btn">
              <Modal
                title="Rate review by Employee"
                open={isModalOpen2}
                onOk={handleOk}
                onCancel={handleCancel2}
                className="tl-kra-modal"
              >
                <div>
                  <div className="questions">
                    <Form
                      form={form}
                      name="control-hooks"
                      layout="vertical"
                      autoComplete="off"
                    >
                      <h6 className="kra-modal-space">
                        {" "}
                        1.How well you performed your task in{" "}
                        {moment().subtract(1, "month").format("MMM")}{" "}
                        {moment().format("YYYY")} ?
                      </h6>
                      <Form.Item>
                        <Radio.Group
                          value={
                            select && Object.keys(select).length > 0
                              ? `${select?.Q1}`
                              : ""
                          }
                        >
                          {values.map((item, index) => {
                            return <Radio value={item}>{item}</Radio>;
                          })}
                        </Radio.Group>
                      </Form.Item>

                      <h6 className="kra-modal-space">
                        2.How capable were you to meet your deadlines?
                      </h6>
                      <Form.Item>
                        <Radio.Group
                          value={
                            select && Object.keys(select).length > 0
                              ? `${select?.Q2}`
                              : ""
                          }
                        >
                          {values.map((item, index) => {
                            return <Radio value={item}>{item}</Radio>;
                          })}
                        </Radio.Group>
                      </Form.Item>

                      <h6 className="kra-modal-space">
                        3.Rate yourself as a team player from 1 to 10
                      </h6>
                      <Form.Item>
                        <Radio.Group
                          value={
                            select && Object.keys(select).length > 0
                              ? `${select?.Q3}`
                              : ""
                          }
                        >
                          {values.map((item, index) => {
                            return <Radio value={item}>{item}</Radio>;
                          })}
                        </Radio.Group>
                      </Form.Item>

                      <h6 className="kra-modal-space">
                        4.Rate yourself in punctuality from 1 to 10?
                      </h6>
                      <Form.Item>
                        <Radio.Group
                          value={
                            select && Object.keys(select).length > 0
                              ? `${select?.Q4}`
                              : ""
                          }
                        >
                          {values.map((item, index) => {
                            return <Radio value={item}>{item}</Radio>;
                          })}
                        </Radio.Group>
                      </Form.Item>

                      <h6 className="kra-modal-space">
                        5.Rate yourself on overall performance from 1 to 10?
                      </h6>
                      <Form.Item>
                        <Radio.Group
                          value={
                            select && Object.keys(select).length > 0
                              ? `${select?.Q5}`
                              : ""
                          }
                        >
                          {values.map((item, index) => {
                            return <Radio value={item}>{item}</Radio>;
                          })}
                        </Radio.Group>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </Modal>
            </span>
          </MDBCol>
        </div>

        {
          dataLoading ? <ReactLoader loading={dataLoading} /> :
        
        <div className="tasklisttbl page-container">
          <MDBDataTableV5
            hover
            entriesOptions={[20, 25, 30]}
            entries={20}
            pagesAmount={4}
            data={datatable}
            searchTop
            searchBottom={false}
          />
        </div>
        }
      </div>
    </>
  );
};

export default KraForm;
