import { MDBCard, MDBCol } from "mdb-react-ui-kit";
import { MDBDataTableV5 } from "mdbreact";
import React, { useEffect, useState } from "react";
import AttendanceDeviceModal from "./AttendanceDeviceModal";
import ReactLoader from "../../../../components/common/ReactLoader";
import AxiosInstance from "../../../../service/AxiosInstance";
import { DeleteOutlined } from "@ant-design/icons";
import { Modal, Button, message } from "antd";
import Switch from "@mui/material/Switch";

export default function AttendanceDevice() {
  const [isOpen, setIsOpen] = useState(false);
  const [deviceId, setDeviceId] = useState();
  const [loading, setLoading] = useState(false);
  const [datatable, setDatatable] = useState({});
  const [deviceIdList, setDeviceIdList] = useState([]);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const changeStatus = (item) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("device_id", item?._id);

    AxiosInstance.post("/auth/active-deactive/deviceId", urlencoded)
      .then((res) => {
        if (res?.data?.status == 1) {
          const newStatus = item?.Status === "Active" ? "Deactive" : "Active";

          if (newStatus === "Active") {
            message.success("Device activated successfully");
          } else {
            message.success("Device deactivated successfully");
          }

          getAllDeviceID();
        } else {
          message.error("Device ID not found");
        }
      })
      .catch((error) => {
        console.error("Server error", error);
      });
  };

  const getAllDeviceID = () => {
    setLoading(true);
    AxiosInstance.get("/auth/deviceId/list").then((res) => {
      setLoading(false);
      let list1 = res.data.data.map((item, index) => {
        return {
          icon: index + 1,
          device_name: item?.device_name,
          device_id: item?.device_id,
          Location_id: item?.Location_id?.Location_name,
          Status: (
            <span
              style={{
                color: item?.Status === "Active" ? "green" : "red",
                fontWeight: "bold",
              }}
            >
              {item?.Status}
            </span>
          ),
          Action: (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Switch
                checked={item?.Status === "Active"}
                onClick={() => changeStatus(item)}
              />

              <Button
                danger
                className="dlt-btn"
                type="primary"
                onClick={() => {
                  setDeviceId(item?._id);
                  setIsOpenDeleteModal(true);
                }}
              >
                <DeleteOutlined />
              </Button>
            </div>
          ),
        };
      });
      setDeviceIdList(list1);
    });
  };

  useEffect(() => {
    setDatatable({
      columns: [
        {
          label: "No",
          field: "icon",
          sort: "disabled",
          width: 10,
        },
        {
          label: "Device Name",
          field: "device_name",
          width: 150,
          sort: "disabled",
        },
        {
          label: "Device ID",
          field: "device_id",
          width: 200,
          sort: "disabled",
        },
        {
          label: "Location",
          field: "Location_id",
          width: 200,
          sort: "disabled",
        },
        {
          label: "Status",
          field: "Status",
          sort: "disabled",
          width: 30,
        },
        {
          label: "Action",
          field: "Action",
          sort: "disabled",
          width: 30,
        },
      ],

      rows: deviceIdList,
    });
  }, [deviceIdList]);

  const deleteDevice = (id) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    AxiosInstance.post("/auth/delete/deviceId", urlencoded)
      .then((res) => {
        if (res?.data?.status == 1) {
          message.success("Device id deleted Successfully");
          setIsOpenDeleteModal(false);
          getAllDeviceID();
        } else {
          message.error("Device id not found");
        }
      })
      .catch((error) => {
        console.error("Server error", error);
      });
  };

  const handleCancel = (errorInfo) => {
    setIsOpenDeleteModal(false);
  };

  useEffect(() => {
    getAllDeviceID();
  }, []);

  const reloadList = () => {
    setIsOpen(false);
    getAllDeviceID();
  };

  return (
    <>
      <Modal
        title="Are you sure to delete this record?"
        centered
        open={isOpenDeleteModal}
        onOk={() => setIsOpenDeleteModal(false)}
        onCancel={handleCancel}
        width={380}
        footer={null}
        className="delete-modal"
      >
        <Button
          className="pms-same-btn"
          type="danger"
          onClick={() => deleteDevice(deviceId)}
          style={{
            marginRight: "7%",
          }}
        >
          Delete
        </Button>
        <Button onClick={handleCancel} className="pms-same-btn-cancel">
          Cancel
        </Button>
      </Modal>

      <div className="demo">
        <MDBCard className="page-content">
          <div className="create-project">
            <MDBCol size="md" className="col-example">
              <h3 className="tablHeader">
                Attendance Device
                <Button
                  type="primary"
                  onClick={() => {
                    setIsOpen(true);
                    setDeviceId("");
                  }}
                  style={{ float: "right" }}
                  className="pms-same-btn"
                >
                  Add
                </Button>
                {isOpen ? (
                  <AttendanceDeviceModal
                    deviceId={deviceId}
                    inSidebar={true}
                    btnStyl={true}
                    reloadList={reloadList}
                    setIsOpen={(a) => {
                      setIsOpen(a);
                    }}
                  />
                ) : null}
              </h3>
            </MDBCol>
          </div>

          {loading ? (
            <ReactLoader loading={loading} />
          ) : (
            <div className="scrool-set">
              <MDBDataTableV5
                hover
                entriesOptions={[10, 20, 25]}
                entries={10}
                pagesAmount={4}
                data={datatable}
                searchTop
                searchBottom={false}
              />
            </div>
          )}
        </MDBCard>
      </div>
    </>
  );
}
