import React, { useEffect, useState } from "react";
import { MDBDataTableV5, MDBCard, MDBRow } from "mdbreact";
import { MDBCol } from "mdb-react-ui-kit";
import axiosInstance from "../../../service/AxiosInstance";
import { Col, Select, Row, message } from "antd";
import moment from "moment";
import { AttendanceOption } from "../Options";
import PmsDatePicker from "../me/leaveModule/PmsDatePicker";
import { Menu, Dropdown } from "antd";
import { Button, Form, Modal } from "antd";
import AxiosInstance from "../../../service/AxiosInstance";
import { ToastContainer, toast } from "react-toastify";

export default function AttendanceReport() {
  const [flag, Setflag] = useState(false);
  const [employees, Setemployees] = useState([]);
  const [loading, setLoading] = useState("");
  const [date, setDate] = useState(moment());
  const [datatable, setDatatable] = React.useState({});
  const [attendanceOption, setAttendanceOption] = useState("ALL");
  // added  19-01-2023
  const [visible, setVisible] = useState(false);
  const [checkInTimeUserData, setCheckInTimeUserData] = useState("");
  const [finalCheckInValue, setFinalCheckInValue] = useState("08:30");

  const UserData = localStorage.getItem("userdata")
    ? localStorage.getItem("userdata")
    : undefined;
  let Position_Id = UserData ? JSON.parse(UserData)[0].Position_id : undefined;
  useEffect(() => {
    let showResult = employees.filter((e) => {
      if (attendanceOption == "ABSENT") {
        return e.faceinOut == "" || e.faceinOut == undefined;
      }
      if (attendanceOption == "PRESENT") {
        return e.faceinOut !== undefined && e.faceinOut !== "";
      }
      return true;
    });
    setDatatable({
      columns: [
        {
          label: "Name",
          field: "name",
          width: 150,
          sort: "disabled",
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Email",
          field: "email",
          sort: "disabled",
          width: 270,
        },
        {
          label: "Department",
          field: "department",
          sort: "asc",
          width: 200,
        },
        {
          label: "Position",
          field: "position",

          width: 200,
        },
        {
          label: "Check IN",
          field: "faceinOut",

          width: 100,
        },
      ],
      rows: showResult,
    });
  }, [employees, flag, attendanceOption]);
  function getFaceReports() {
    axiosInstance
      .get("auth/report/facelogins?date=" + date.format("YYYY-MM-DD"))
      .then(async (result) => {
        setLoading("");
        if (result.data.data) {
          let employeesdeta = await result.data.data.map((item) => {
            let row = item.faceLogs;
            let lastRow = null;
            let faceOut = "";
            let logs = [];
            if (item.faceLogs.length > 0) {
              row = item.faceLogs[0];

              logs.push(row.check_in_time.split(" ")[1]);
              if (item.faceLogs.length > 1) {
                lastRow = item.faceLogs[item.faceLogs.length - 1];
                faceOut = lastRow.check_in_time;
                logs.push(lastRow.check_in_time.split(" ")[1]);
              }
            }

            let faceinOut = row.check_in_time;
            // let faceinOut = row.check_in_time;
            // items.action = actions(items);

            return {
              name: `${item.emp.First_name} ${item.emp.Middle_name} ${item.emp.Last_name}  (${item.emp.Employee_code})`,
              // name: (
              //   <Link style={{fontWeight:'500'}}
              //     to={{ pathname: `/employeeDetail/${item._id}` }}
              //   >{`${item.First_name} ${item.Middle_name} ${item.Last_name}`}</Link>
              // ),

              email: item.emp.Email,

              position: item?.emp?.Position_id?.Position_name,
              department: item?.emp?.Role_id?.Department_name,
              mobileNo: item?.emp?.Mobile,
              faceinOut: logs?.length > 0 ? logs.join(" - ") : actions(item),
              logs: logs,
            };
          });
          Setemployees(employeesdeta);
        }
      });
  }

  useEffect(() => {
    setLoading("Generating report....");
    getFaceReports();
  }, [flag, date]);
  const handleChanges = (e) => {
    setDate(e);
  };

  // when the user open the check in modal then used...
  const handleActionOptions = (items, key) => {
    if (key === "checkIn") {
      // here is set up the checkin modal
      setVisible(true);
      setCheckInTimeUserData(items);
    }
  };

  // here is define action basis on who is absent today
  const actionMenu = (items, listAction) => (
    <Menu
      onClick={(e) => handleActionOptions(items, e.key)}
      items={listAction}
    />
  );
  const actions = (items) => {
    let listAction = [
      {
        key: "checkIn",
        label: "Check In",
        items: items,
      },
    ];

    return (
      <>
        <Dropdown.Button
          overlay={actionMenu(items, listAction)}
          // menu={actionMenu(items, listAction)}
        ></Dropdown.Button>
      </>
    );
  };

  // when the user cancel the check in modal then used...
  const handleCancelCheckInModal = () => {
    setVisible(false);
    // clear the temporary setted data..
    setCheckInTimeUserData("");
    setFinalCheckInValue("08:30");
  };

  // when the user submit the checkin modal then used...
  const onFinish = React.useCallback(() => {
    if (finalCheckInValue !== "" && checkInTimeUserData !== "") {
      const checkInUrlEncoded = new URLSearchParams();
      checkInUrlEncoded.append("Employee_id", checkInTimeUserData?.emp?._id);
      checkInUrlEncoded.append("check_in_time", finalCheckInValue);
      checkInUrlEncoded.append("date", new Date());
      checkInUrlEncoded.append(
        "Employee_code",
        checkInTimeUserData?.emp?.Employee_code
      );
      checkInUrlEncoded.append("present_first_half", true);
      checkInUrlEncoded.append("present_second_half", false);
      checkInUrlEncoded.append("present_count", 0.5);
      checkInUrlEncoded.append("status", checkInTimeUserData?.emp?.Status);

      // here pass data into backend
      AxiosInstance.post("/auth/add-attendance-face-report", checkInUrlEncoded)
        .then(async (res) => {
          if (res?.data?.status === 1) {
            setTimeout(() => {
              // toast.success("Time added Successfully");
              message.success("Time added Successfully");
            }, 2000);
            handleCancelCheckInModal();
            getFaceReports();
          }
        })
        .catch((e) => {
          console.log("error", e);
        });
    }
  }, [finalCheckInValue, checkInTimeUserData]);

  // when the user change the checkin time then used...
  const handleGetCheckInTime = (e) => {
    const { value } = e.target;
    setFinalCheckInValue(value);
  };

  return (
    <>
      <div className="demo">
        <MDBCard className="page-content">
          <div>
            <div className="create-project">
              <MDBCol size="md" className="col-example">
                <h3 className="tablHeader">Face Attendance</h3>
              </MDBCol>
            </div>
          </div>
          <div className="tasklisttbl">
            <MDBRow className="">
              <MDBCol size="md-3">
                <label className="text-font-weight-600">Date</label>

                <PmsDatePicker
                  // searchStyle={{paddingLeft:'10px', width:'60%'}}
                  onChange={(d) => handleChanges(d)}
                />
              </MDBCol>
              <MDBCol size="md-2">
                <label className="text-font-weight-600">Show</label>
                <Select
                  // style={{ width: '100%' }}
                  className="select-dropdown box-width"
                  value={attendanceOption}
                  onChange={(e) => setAttendanceOption(e)}
                  options={AttendanceOption}
                />
              </MDBCol>

              <MDBCol size="md-3">{loading}</MDBCol>
            </MDBRow>
            <hr />
            <MDBDataTableV5
              hover
              entriesOptions={[20, 25, 30]}
              entries={20}
              pagesAmount={4}
              data={datatable}
              searchTop
              searchBottom={false}
            />
          </div>
          <Modal
            title="Check In Modal"
            centered
            // visible={visible}
            open={visible}
            // onOk={() => setVisible(false)}
            onCancel={handleCancelCheckInModal}
            width={550}
            // height={640}
            className="leave-form modal-back modal-title close-btn"
            footer={null}
          >
            {/* <MDBTabsContent>
            <MDBTabsPane show={justifyActive === 'tab1'}> */}
            <Form
              // form={form}
              name="control-hooks"
              layout="vertical"
              // name="basic"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
              className="left-margin"
            >
              <div className="d-flex-attendance gap-20">
                <div
                  className={`rel-input-details ${
                    finalCheckInValue === "08:30" ? "active-radio-content" : ""
                  }`}
                >
                  <div className="attendance-radio-details">
                    <label htmlFor="checkInTime">08:30</label>
                  </div>
                  <div className="input-radio-attend">
                    <input
                      type="radio"
                      className="radio-inner"
                      name="checkInTime"
                      value="08:30"
                      checked={finalCheckInValue === "08:30"}
                      onChange={handleGetCheckInTime}
                    />
                  </div>
                </div>
                <div
                  className={`rel-input-details ${
                    finalCheckInValue === "08:45" ? "active-radio-content" : ""
                  }`}
                >
                  <div className="attendance-radio-details">
                    <label htmlFor="checkInTime">08:45</label>
                  </div>
                  <div className="input-radio-attend">
                    <input
                      type="radio"
                      className="radio-inner"
                      name="checkInTime"
                      value="08:45"
                      checked={finalCheckInValue === "08:45"}
                      onChange={handleGetCheckInTime}
                    />
                  </div>
                </div>
                <div
                  className={`rel-input-details ${
                    finalCheckInValue === "09:00" ? "active-radio-content" : ""
                  }`}
                >
                  <div className="attendance-radio-details">
                    <label htmlFor="checkInTime">09:00</label>
                  </div>
                  <div className="input-radio-attend">
                    <input
                      type="radio"
                      className="radio-inner"
                      name="checkInTime"
                      value="09:00"
                      checked={finalCheckInValue === "09:00"}
                      onChange={handleGetCheckInTime}
                    />
                  </div>
                </div>
                <div
                  className={`rel-input-details ${
                    finalCheckInValue === "09:15" ? "active-radio-content" : ""
                  }`}
                >
                  <div className="attendance-radio-details">
                    <label htmlFor="checkInTime">09:15</label>
                  </div>
                  <div className="input-radio-attend">
                    <input
                      type="radio"
                      className="radio-inner"
                      name="checkInTime"
                      value="09:15"
                      checked={finalCheckInValue === "09:15"}
                      onChange={handleGetCheckInTime}
                    />
                  </div>
                </div>
                <div
                  className={`rel-input-details ${
                    finalCheckInValue === "09:30" ? "active-radio-content" : ""
                  }`}
                >
                  <div className="attendance-radio-details">
                    <label htmlFor="checkInTime">09:30</label>
                  </div>
                  <div className="input-radio-attend">
                    <input
                      type="radio"
                      className="radio-inner"
                      name="checkInTime"
                      value="09:30"
                      checked={finalCheckInValue === "09:30"}
                      onChange={handleGetCheckInTime}
                    />
                  </div>
                </div>
                <div
                  className={`rel-input-details ${
                    finalCheckInValue === "09:45" ? "active-radio-content" : ""
                  }`}
                >
                  <div className="attendance-radio-details">
                    <label htmlFor="checkInTime">09:45</label>
                  </div>
                  <div className="input-radio-attend">
                    <input
                      type="radio"
                      className="radio-inner"
                      name="checkInTime"
                      value="09:45"
                      checked={finalCheckInValue === "09:45"}
                      onChange={handleGetCheckInTime}
                    />
                  </div>
                </div>
                <div
                  className={`rel-input-details ${
                    finalCheckInValue === "10:00" ? "active-radio-content" : ""
                  }`}
                >
                  <div className="attendance-radio-details">
                    <label htmlFor="checkInTime">10:00</label>
                  </div>
                  <div className="input-radio-attend">
                    <input
                      type="radio"
                      className="radio-inner"
                      name="checkInTime"
                      value="10:00"
                      checked={finalCheckInValue === "10:00"}
                      onChange={handleGetCheckInTime}
                    />
                  </div>
                </div>
                <div
                  className={`rel-input-details ${
                    finalCheckInValue === "10:15" ? "active-radio-content" : ""
                  }`}
                >
                  <div className="attendance-radio-details">
                    <label htmlFor="checkInTime">10:15</label>
                  </div>
                  <div className="input-radio-attend">
                    <input
                      type="radio"
                      className="radio-inner"
                      name="checkInTime"
                      value="10:15"
                      checked={finalCheckInValue === "10:15"}
                      onChange={handleGetCheckInTime}
                    />
                  </div>
                </div>
                <div
                  className={`rel-input-details ${
                    finalCheckInValue === "10:30" ? "active-radio-content" : ""
                  }`}
                >
                  <div className="attendance-radio-details">
                    <label htmlFor="checkInTime">10:30</label>
                  </div>
                  <div className="input-radio-attend">
                    <input
                      type="radio"
                      className="radio-inner"
                      name="checkInTime"
                      value="10:30"
                      checked={finalCheckInValue === "10:30"}
                      onChange={handleGetCheckInTime}
                    />
                  </div>
                </div>
              </div>

              <Form.Item>
                <div className="justify-btn-end">
                  <Button
                    className="pms-same-btn mb-0"
                    htmlType="submit"
                    style={{ marginRight: "10px" }}
                  >
                    Submit
                  </Button>
                  <Button
                    onClick={handleCancelCheckInModal}
                    className="pms-same-btn-cancel"
                  >
                    Cancel
                  </Button>
                  {/* </div> */}
                </div>
              </Form.Item>
            </Form>
          </Modal>
        </MDBCard>
        {/* <ToastContainer /> */}
      </div>
    </>
  );
}

//
