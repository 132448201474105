import React, { useState } from "react";
import EmployeeTab from "./EmployeeTab";
import OtherDetailsTab from "./OtherDetailsTab";
import List from "../me/leaveModule/List";
import { useParams } from "react-router";
const OtherDetails = ({ OtherDetail }) => {
  // console.log(OtherDetail, leaveTab, "OtherDetail555");
  const params = useParams();
  return (
    // <div className="demo">
    <div>
      <div className="cardmargine">
        {!OtherDetail && <EmployeeTab />}
        <OtherDetailsTab OtherDetail="OtherDetail" />
        {/* <List id={params.id} /> */}
      </div>
    </div>
  );
};

export default OtherDetails;
