import React, { useEffect, useState } from "react";
import { MDBDataTableV5, MDBCard } from "mdbreact";
import { MDBCol } from "mdb-react-ui-kit";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import axiosInstance from "../../../service/AxiosInstance";
import ReactLoader from "../../../components/common/ReactLoader";
import { Link } from "react-router-dom";
import { LiaGreaterThanSolid } from "react-icons/lia";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function EmployeeTracker() {
  const [departmentList, setDepartmentList] = useState([]);
  const [clusterList, setClusterList] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [flag, Setflag] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [employeesTimer, setEmployeesTimer] = useState([]);
  const [employeesAbsent, setEmployeesAbsent] = useState([]);
  const [datatable, setDatatable] = React.useState({});
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getDepartmentList = () => {
    axiosInstance.get("auth/employee/departmentcount", {}).then(async (res) => {
      setDepartmentList(res.data.data);
      let employeesdeta = await res.data.data.map((item) => item.employees.map((item) => {
        return {
          name: `${item.First_name} ${item.Middle_name} ${item.Last_name}`,
          department: item.Role,
          _id: item._id,
        };
      }));
      setEmployees(employeesdeta);
      if (res.data.data.length > 0) {
        setFilterData(res.data.data[0]);
      }
    });
  };

  const getClusterTeamList = async () => {
    try {
      const res = await axiosInstance.get("auth/getTeamMembers", {});
      if (res.data.data) {
        setClusterList(res.data.data);

        let clusterTeam = await res.data.data.map((item) => item.members.map((item) => {
          return {
            name: `${item.memberId.First_name} ${item.memberId.Middle_name} ${item.memberId.Last_name}`,
            department: item.memberId.Role,
            _id: item.memberId._id,
          };
        }));
        setEmployees(clusterTeam);
        if (res.data.data.length > 0) {
          setFilterData(res.data.data[0]);
        }
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const UserData = localStorage.getItem("userdata")
    ? localStorage.getItem("userdata")
    : undefined;
  let Position_Id = UserData ? JSON.parse(UserData)[0].Position_id : undefined;

  useEffect(() => {
    setLoading(true);

    axiosInstance
      .get("auth/task/get-running-task-timer")
      .then(async (result) => {
        if (result.data.data) {
          setEmployeesTimer(result.data.data);
        }
      });

    axiosInstance.get("auth/employees").then(async (result) => {
      if (result.data.data) {
        setLoading(false);
        if (value == 0) {

          let employeesdeta = await filterData.employees.map((item) => {
            return {
              name: `${item.First_name} ${item.Middle_name} ${item.Last_name}`,
              email: item.Email,
              position: item.Position,
              department: item.Role,
              mobileNo: item.Mobile,
              action: <Link className="employee-tracker-view"
                to={`/task/${item?.runningTaskIds?.[0]}`}
                target="_blank" >
                {item?.runningTaskIds?.[0] ? "View" : ""}</Link>,
              _id: item._id,
            };
          });
          setEmployees(employeesdeta);
        } else {
          let clusterData = await filterData.members.map((item) => {
            return {
              name: `${item.memberId.First_name} ${item.memberId.Middle_name} ${item.memberId.Last_name}`,
              email: item.memberId.Email,
              position: item.memberId.Position,
              department: item.memberId.Role,
              mobileNo: item.memberId.Mobile,
              action: <Link className="employee-tracker-view"
                to={`/task/${item?.memberId?.runningTaskIds?.[0]}`}
                target="_blank" >
                {item?.memberId?.runningTaskIds?.[0] ? "View" : ""}</Link>,
              _id: item?.memberId?._id,
            };
          });
          setEmployees(clusterData);
        }
      }
    });

    axiosInstance.get("auth/leave/todays").then(async (result) => {
      if (result.data.data) {
        setEmployeesAbsent(result.data.data);
      }
    });
  }, [flag, filterData]);


  useEffect(() => {
    if (value == 0) {
      getDepartmentList()
    } else {
      getClusterTeamList();
    }
  }, [value]);
  const columns=[

    {
      label: "Employee Name",
      field: "name",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Name",
      },
    },
    {
      label: "PROJECT NAME",
      field: "projectname",
      width: 270,
    },
    {
      label: "TASK ID",
      field: "taskid",
      width: 270,
    },
    {
      label: "ACTION",
      field: "action",
      width: 270,
    },
  ]

  useEffect(() => {
    var employeeList = employees.map((obj) => {
      let employeeTrack = employeesTimer.filter((emp) => {
        return emp.Employee_id == obj._id;
      });
      let absent = employeesAbsent.filter((ele) => {
        return ele.Employee_ID._id == obj._id;
      });

      obj.taskid = absent[0]
        ? "ABS"
        : employeeTrack.length
          ? employeeTrack[0]?.
            Task_id?.Task_key : "-";
      obj.projectname = absent[0]
        ? "ABS"
        : employeeTrack.length
          ? employeeTrack[0]?.Task_id?.Project_name
          : "-";

      return obj;
    });

    setDatatable({
      columns: columns,
      rows: employeeList,
    });
    if (value === 1) {
      columns.splice(1, 0, {
        label: "DEPARTMENT NAME",
        field: "department",
        width: 270,
      });
    }
  }, [flag, employees, employeesTimer, employeesAbsent]);

  return (
    <>
      <div className="demo">
        <MDBCard className="page-content">
          <div>
            <div className="create-project employee-table">
              <MDBCol size="md" className="col-example">
                <h3 className="tablHeader">Employee Tracker</h3>
              </MDBCol>
            </div>
          </div>

          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="main-tabs-bacic">
                <Tab className="cus-tab-name" label="Department Wise" {...a11yProps(0)} />
                <Tab className="cus-tab-name" label="Cluster Wise" {...a11yProps(1)} />
                {value == 0 ?
                  <Box className="right-top-box-data">
                    <Box className="total-memeber-count">
                      <h4 className="text-tab-right">Total Memeber</h4>
                      {filterData && (<p>{filterData.countEmployee}</p>)}
                    </Box>
                    <Box className="total-memeber-count">
                      <h4 className="text-tab-right">Tracker On</h4>
                      {filterData && (<p>{filterData.runningTaskCount}</p>)}
                    </Box>
                    <Box className="total-memeber-count">
                      <h4 className="text-tab-right">Tracker Off</h4>
                      {filterData && (<p>{filterData.remainingEmployeeCount}</p>)}
                    </Box>
                  </Box> :
                  <Box className="right-top-box-data">
                    <Box className="total-memeber-count">
                      <h4 className="text-tab-right">Total Memeber</h4>
                      {filterData && (<p>{filterData.memberCount}</p>)}
                    </Box>
                    <Box className="total-memeber-count">
                      <h4 className="text-tab-right">Tracker On</h4>
                      {filterData && (<p>{filterData.runningTasksCount}</p>)}
                    </Box>
                    <Box className="total-memeber-count">
                      <h4 className="text-tab-right">Tracker Off</h4>
                      {filterData && (<p>{filterData.remainingMemberCount}</p>)}
                    </Box>
                  </Box>}
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0} className="custom-tab-panel">
              <Box className="main-tabs">

                <Box className="left-tab-main">
                  <h4 className="text-left-tab">
                    Department List</h4>
                  <Box className="employee-main-table">
                    {departmentList.map((department, index) => (
                      <Box key={index} className="employee-profile-count"
                        style={{ backgroundColor: filterData == department ? ' rgba(220, 220, 220, 0.808)' : '' }}
                        onClick={() =>

                          setFilterData(department)}>
                        <h4 className="empl-memeber" >{department?.Department_name}</h4>
                        <Box className="depar-empl-icone">
                          <h4 className="empl-memeber-count-emp">{department?.countEmployee}</h4>
                          <LiaGreaterThanSolid />
                        </Box>
                      </Box>
                    ))}
                  </Box>

                </Box>
                <Box className="right-tab-main">
                  <Box className="main-table-data">
                    {loading ? (
                      <ReactLoader loading={loading} />
                    ) : (
                      <div className="tasklisttbl emp-tracl-tbl">
                        <MDBDataTableV5
                          className="tasklisttblss data"
                          hover
                          entriesOptions={[10, 20, 40, 50]}
                          entries={10}
                          pagesAmount={10}
                          data={datatable}
                          searchTop
                          searchBottom={false}
                        />
                      </div>
                    )}
                  </Box>
                </Box>
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1} className="custom-tab-panel">
              <Box className="main-tabs">
                <Box className="left-tab-main cus-left-tab">
                  <h4 className="text-left-tab">
                    Cluster Team List</h4>
                  <Box className="employee-main-table">
                    {clusterList.map((cluster, index) => (
                      <Box key={index} className="employee-profile-count"
                        style={{ backgroundColor: filterData == cluster ? ' rgba(220, 220, 220, 0.808)' : '' }}
                        onClick={() => setFilterData(cluster)}>
                        <h4 className="empl-memeber" >{cluster?.cluster_Name}</h4>
                        <Box className="depar-empl-icone">
                          <h4 className="empl-memeber-count-emp">{cluster?.memberCount}</h4>
                          <LiaGreaterThanSolid />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Box className="right-tab-main">
                  <Box className="main-table-data">
                    {loading ? (
                      <ReactLoader loading={loading} />
                    ) : (
                      <div className="tasklisttbl emp-tracl-tbl">
                        <MDBDataTableV5
                          className="tasklisttblss data"
                          hover
                          entriesOptions={[10, 20, 40, 50]}
                          entries={10}
                          pagesAmount={10}
                          data={datatable}
                          searchTop
                          searchBottom={false}
                        />
                      </div>
                    )}
                  </Box>
                </Box>
              </Box>
            </CustomTabPanel>

          </Box>
        </MDBCard>
      </div>
    </>
  );
}