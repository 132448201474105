import { FETCH_PROJECTS_SUCCESS } from "./ActionsType";

const fetchProjectSuccess = (projects) => {
  return {
    type: FETCH_PROJECTS_SUCCESS,
    payload: projects,
  };
};

export default fetchProjectSuccess;
