import { useState } from "react";
import ContactBar from "./ContactBar";
import { Link } from "react-router-dom";
import { MDBCard } from "mdbreact";
import { MDBCol } from "mdb-react-ui-kit";
import { Png } from "../../../assets/png";
import { JPG } from "../../../assets/jpg";
function LeavePolicy() {
  const [loading, setLoading] = useState(false);
  return (
    <>
      <div className="demo">
        <ContactBar />
        <MDBCard className="page-content">
          <div className="page-wrapper-main">
            <h1 className="page-title-main">HR Handbook 2024</h1>
            <h1 className="page-title-main">
              Welcome to Virtual Height IT Services Pvt Ltd (VHITS)
            </h1>
            <h3 className="page-subtitle-main">Your Journey Begins Here!</h3>
            <p className="page-para">
              On behalf of the entire VHITS team, we extend a warm welcome to
              you! We are thrilled to have you on board as a valuable member of
              our growing family
            </p>
            <p className="page-para">
              As a director, We want to express our enthusiasm for the
              contributions you will undoubtedly bring to our organization. Your
              skills and talents are a tremendous asset, and we look forward to
              achieving new heights together.
            </p>
            <p className="page-para">
              To guide you through your journey with us, we've prepared an HR
              Handbook that outlines our company's policies, values, and the
              resources available to support your success. Please take the time
              to familiarize yourself with this valuable resource.
            </p>
            <p className="page-para">
              Should you have any questions or need assistance along the way,
              our HR team is here to help. We believe that your experience at
              VHITS will be not only professionally rewarding but also
              personally fulfilling
            </p>
            <p className="page-para">
              Welcome aboard! We're excited to see the positive impact you'll
              make within our team.
            </p>
            <h5 className="page-hightlight-text">Best regards,</h5>

            <div className="card-row">
              <div className="card-col">
                <div className="card-box">
                  <img src={JPG.CEO} className="card-img" />
                  <div className="card-content-box">
                    <h5 className="card-text">Mr. Himanshu Jaiswal (CEO)</h5>
                  </div>
                </div>
              </div>
              <div className="card-col">
                <div className="card-box">
                  <img src={Png.MD} className="card-img" />
                  <div className="card-content-box">
                    <h5 className="card-text">Mrs. Anuradha Jaiswal (MD)</h5>
                  </div>
                </div>
              </div>
              <div className="card-col">
                <div className="card-box">
                  <img src={Png.CTO} className="card-img" />
                  <div className="card-content-box">
                    <h5 className="card-text">
                      Mr. Hitendrasinh Chauhan (CTO)
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="hr-main-section">
              <h2 className="hr-main-text">A Warm Welcome from the HR Team.</h2>
              <h2 className="hr-main-text">
                We are thrilled to have you as part of our dynamic team
              </h2>
              <p className="page-para">
                This HR Handbook is your guide to understanding our company's
                policies, procedures, and guidelines. It reflects our commitment
                to a positive work environment and your professional growth. As
                you embark on this journey with us, feel free to reach out to
                the HR team for any clarification or assistance. We look forward
                to a successful and collaborative association. This handbook
                pertains to all professional and support staff employees of{" "}
                <span className="highlight-span">
                  VIRTUAL HEIGHT IT SERVICES PVT. LTD
                </span>
              </p>
              <p className="page-para">
                While meticulous consideration has been given to the plans,
                policies, procedures, and guidelines outlined herein, VHITS.
                expressly reserves the right to alter, suspend, revoke,
                terminate, or modify any or all of the aforementioned plans,
                policies, procedures, and guidelines, either in whole or in
                part, at its discretion and at any time, with or without prior
                notice.
              </p>
              <p className="page-para">
                Apart from this handbook, specific departments, units, offices,
                or divisions within the company may periodically issue and
                revise guidelines, rules, and manuals, subject to approval by
                the Office of Human Resources.Employees within those domains are
                obligated to comprehend and adhere to such guidelines, rules,
                and manuals applicable to their respective areas.
              </p>

              <div className="card-row">
                <div className="card-col">
                  <div className="card-box">
                    <img src={Png.CPO} className="card-img" />
                    <div className="card-content-box">
                      <h5 className="card-text">Mrs. Shivani Jaiswal (CPO)</h5>
                    </div>
                  </div>
                </div>

                <div className="card-col">
                  <div className="card-box">
                    <img src={Png.HRExec} className="card-img" />
                    <div className="card-content-box">
                      <h5 className="card-text">
                        Ms. Simran Gupta (Asst. HR Manager)
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="policy-main-section">
              <h3 className="page-hightlight-text-main">
                FUNDAMENTALS OF SHARED RESPONSIBILITIES
              </h3>

              <div className="page-content-section">
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Employee Responsibilities and HR Support
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      Each employee, whether in a leadership or peer role, bears
                      distinct responsibilities in interpersonal dealings. HR
                      managers and staff offer professional support without
                      replacing responsible managers, emphasizing active
                      contribution to HR management quality. HR acts as a
                      business partner, advising, proposing best practices, and
                      fostering a co-responsible partnership for all HR matters.
                    </li>
                  </ul>
                </div>
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Partnership for Efficiency
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      The partnership between employees and HR is pivotal for
                      efficient people management.
                    </li>
                    <li className="page-li">
                      HR staff credibility relies on professional contributions
                      and excellent communication skills, addressing delicate
                      matters with appropriateness.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="policy-main-section">
              <h3 className="page-hightlight-text-main">
                EMPLOYEE COMMUNICATION GUIDELINES
              </h3>

              <div className="page-content-section">
                <div className="page-content-main">
                  <h4 className="page-content-title-text">Respect and Trust</h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      Respect and trust are prerequisites, prohibiting
                      intolerance, harassment, or discrimination.
                    </li>
                    <li className="page-li">
                      Appropriate communication skills are essential, ensuring a
                      respectful workplace at all levels.
                    </li>
                  </ul>
                </div>
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Transparency and Honesty
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      Transparent and honest communication is crucial for
                      efficient dialogue and continuous improvement.
                    </li>
                    <li className="page-li">
                      Open communication fosters competence-sharing and
                      creativity, particularly in a flat organization structure.
                    </li>
                  </ul>
                </div>
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Inclusive Communication
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      Communication involves not just informing but also
                      listening and engaging in dialogue.
                    </li>
                    <li className="page-li">
                      Cooperation and helping others are integral for evaluating
                      potential candidates for promotion.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="policy-main-section">
              <h3 className="page-hightlight-text-main">
                JOINING{" "}
                <span className="highlight-span">
                  VIRTUAL HEIGHT IT SERVICES PVT. LTD
                </span>
                .
              </h3>

              <div className="page-content-section">
                <div className="page-content-main">
                  <h4 className="page-content-title-text">Hiring Policy</h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      Company success depends on attracting employees committed
                      to long-term relationships and continuous improvement.
                    </li>
                    <li className="page-li">
                      Hiring focuses on personal attitudes, professional skills,
                      and alignment with company values.
                    </li>
                  </ul>
                </div>
                <div className="page-content-main">
                  <h4 className="page-content-title-text">Cultural Fit</h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      Attention is given to aligning candidate values with the
                      company culture.
                    </li>
                  </ul>
                </div>
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Non-Discriminatory Employment
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      Employment decisions are based on skills and adherence to
                      principles; no consideration for origin, nationality,
                      religion, race, gender, or age.
                    </li>
                    <li className="page-li">
                      Open-mindedness towards new ideas is expected from all
                      employees
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="policy-main-section">
              <h3 className="page-hightlight-text-main">
                EMPLOYMENT AT{" "}
                <span className="highlight-span">
                  VIRTUAL HEIGHT IT SERVICES PVT. LTD
                </span>
                .
              </h3>

              <div className="page-content-section">
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Commitment to Principles
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      Company commitment to principles and international
                      conventions regarding employee rights.
                    </li>
                    <li className="page-li">
                      Non-compliance with the handbook leads to termination
                    </li>
                  </ul>
                </div>
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Safe Working Environment
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      Providing a safe working environment following high
                      standards.
                    </li>
                    <li className="page-li">
                      Strict prohibition of discrimination and harassment, with
                      explicit rules for prevention.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="policy-main-section">
              <h3 className="page-hightlight-text-main">
                PROBATIONARY PERIOD -1 MONTH PROBATION
              </h3>

              <div className="page-content-section">
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Initial Probationary Period
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      The First Month of employment for all new VIRTUAL HEIGHT
                      IT SERVICES PVT. LTD. employees, exempt and non-exempt,
                      constitute an initial probationary period at VIRTUAL
                      HEIGHT IT SERVICES PVT. LTD
                    </li>
                    <li className="page-li">
                      Employee performance and adherence to company policies
                      during this period are closely monitored
                    </li>
                  </ul>
                </div>
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Performance Monitoring
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      During the probationary period, the new hire's immediate
                      supervisor conducts regular performance meetings.
                    </li>
                    <li className="page-li">
                      Performance concerns are communicated in writing to the
                      employee.
                    </li>
                  </ul>
                </div>
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Termination during Probation
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      <span className="highlight-span">
                        VIRTUAL HEIGHT IT SERVICES PVT. LTD
                      </span>
                      . reserves the right to terminate employees at any time
                      during the probationary period.
                    </li>
                  </ul>
                </div>
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Resignation during Probation
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      Employees resigning during the probationary period
                      generally must provide 1 week' notice to their supervisor
                    </li>
                    <li className="page-li">
                      The company, at its discretion, may accept or request a
                      longer notice period, particularly for technical staff
                      tied to project deadlines.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="policy-main-section">
              <h3 className="page-hightlight-text-main">
                ATTENDANCE GUIDELINES
              </h3>

              <div className="page-content-section">
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Expectation of Timely and Regular Attendance
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      Timely and regular attendance is a performance expectation
                      for all{" "}
                      <span className="highlight-span">
                        VIRTUAL HEIGHT IT SERVICES PVT. LTD
                      </span>
                      . employees.
                    </li>
                    <li className="page-li">
                      The designated working hours for employees shall be from
                      10:00 AM to 7:15 PM, with a 30-minute buffer time
                      incorporated. Strict adherence to punctuality is required,
                      and any employee arriving after 10:30 AM will be deemed to
                      have taken half day leave for the day. In the event an
                      employee arrives precisely at 10:30 AM, departure is
                      mandated at 7:45 PM. Lunch break is scheduled from 1:15 PM
                      to 2:00 PM. On a strict basis 8:30 working hours should be
                      completed on a daily basis.
                    </li>
                    <li className="page-li">
                      Adherence to workplace schedules is crucial for adequate
                      staffing, positive employee morale, and meeting
                      productivity standards.
                    </li>
                  </ul>
                </div>
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Notification Procedures
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      Employees must follow departmental notification procedures
                      for being late, absent, or requesting planned time away
                      from work.
                    </li>
                    <li className="page-li">
                      Requests should be made in advance to the supervisor or
                      designee, following departmental procedures for early
                      arrivals or departures.
                    </li>
                  </ul>
                </div>
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Absence Notification
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      Upon notification, the employee must inform the HR if an
                      absence is due to a documented/approved leave of absence.
                    </li>
                    <li className="page-li">
                      This ensures appropriate tracking of leave utilization and
                      absenteeism.
                    </li>
                  </ul>
                </div>
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Abandonment of Position
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      Failure to call in and report to work for three
                      consecutively scheduled work days will be viewed as
                      abandonment of the position.
                    </li>
                    <li className="page-li">
                      The supervisor should consult with Human Resources in such
                      situations.
                    </li>
                  </ul>
                </div>
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Holiday-Related Absences
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      If an employee calls out before, on, or after a holiday,
                      the HR manager will review the circumstances.
                    </li>
                    <li className="page-li">
                      Based on the review, the manager may decide whether to
                      count the incident as a regular occurrence or issue a
                      Written Warning for holiday-related call outs. Progression
                      to the next disciplinary level applies if the employee is
                      already on discipline.
                    </li>
                  </ul>
                </div>
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Disciplinary Steps:
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      One Occurrence equals to:
                      <ul className="page-ul inner-ul">
                        <li className="page-li"> 1 Absent</li>
                        <li className="page-li"> 2 Tardiest</li>
                        <li className="page-li">2 Missed Clocks</li>
                      </ul>
                    </li>
                    <li className="page-li">
                      2 Occurrences: Step 1: Verbal Warning
                    </li>
                    <li className="page-li">
                      3 Occurrences: Step 2: Written Warning
                    </li>
                    <li className="page-li">
                      4 Occurrences: Step 3: Final Written Warning
                    </li>
                    <li className="page-li">
                      5 Occurrences: Step 4: Termination
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="policy-main-section">
              <h3 className="page-hightlight-text-main">LEAVE POLICY</h3>

              <div className="page-content-section">
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    General Leave Provisions
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      Leave is granted to employees with the intention of
                      providing rest, facilitating health recuperation, and
                      fulfilling social obligations to maintain a healthy and
                      efficient staff for the company
                    </li>
                    <li className="page-li">
                      Employees have the option to take up to 5 max paid leaves
                      in a single month.
                    </li>
                    <li className="page-li">
                      In the event an employee opts for a 7-day leave, only 5
                      days will be remunerated, while the remaining 2 days will
                      be unpaid.
                    </li>
                    <li className="page-li">
                      This policy aims to foster a balance between employee
                      health and the operational needs of the company.
                    </li>
                    <li className="page-li">
                      In the event that the total number of working days in a
                      given month falls below 20, employees are required to
                      attend work on Saturdays to fulfill the monthly working
                      day requirement.
                    </li>
                  </ul>
                </div>
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Discretionary Nature of Leave
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      Leave is not a matter of right; sanctioning of leave is at
                      Management discretion based on exigencies of business or
                      the seriousness of the case.
                    </li>
                    <li className="page-li">
                      The leave year spans from 1st January to 31st December.
                    </li>
                    <li className="page-li">
                      Eligible leave is credited to employees on the 1st of
                      January every year.
                    </li>
                    <li className="page-li">
                      The different types of leaves under this policy are
                      Privilege Leave (PL), Maternity Leave (ML), and Loss of
                      Pay (LOP).
                    </li>
                  </ul>
                </div>
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Applicability of Leave Policy
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      The Leave policy is applicable to all permanent staff of
                      the company as well as contract workers.
                    </li>
                  </ul>
                </div>
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Leave Credit for Mid-Month Joining
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      Employees joining service between 16th and the end of the
                      month are not entitled to leave credit for that month.
                    </li>
                    <li className="page-li">
                      Employees have a total 12 leaves, on a prorata basis of 1
                      per month.
                    </li>
                  </ul>
                </div>
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Maternity Leave (ML)
                  </h4>
                  <p className="page-inner-sub-title-text">
                    Eligibility and Entitlement:
                  </p>
                  <ul className="page-ul">
                    <li className="page-li">
                      All married female staff are eligible.
                    </li>
                    <li className="page-li">
                      84 days of Maternity Leave are allowed for females
                      delivering a child.
                    </li>
                    <li className="page-li">
                      The leave can be adjusted before and after delivery,
                      totaling 84 days.
                    </li>
                    <li className="page-li">
                      A certificate from the gynecologist, stating the expected
                      date of delivery, must be submitted.
                    </li>
                    <li className="page-li">
                      National/declared/festival/weekly off days are counted as
                      part of leave.
                    </li>
                    <li className="page-li">
                      Extended leave due to complications falls under Loss of
                      Pay (LOP)
                    </li>
                  </ul>
                </div>
                <div className="page-content-main">
                  <h4 className="page-content-title-text">Loss Of Pay (LOP)</h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      All married female staff are eligible.
                    </li>
                    <li className="page-li">
                      LOP can be applied when no other leave is available.
                    </li>
                    <li className="page-li">
                      No pay or allowance is provided during LOP.
                    </li>
                    <li className="page-li">
                      A maximum of 6 days of LOP can be availed with management
                      approval.
                    </li>
                    <li className="page-li">
                      Failure to report to duty after sanctioned LOP is deemed
                      voluntary service abandonment.
                    </li>
                    <li className="page-li">
                      LOP can be implicated on disciplinary grounds with regard
                      to attendance. LOP days will not be counted for EL
                      eligibility.
                    </li>
                    <li className="page-li">
                      LOP days will not be counted for EL eligibility.
                    </li>
                  </ul>
                </div>
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Leave Application Procedure
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      Employees apply for leave via the PMS.
                    </li>
                    <li className="page-li">
                      Advance notification or submission through PMS/email is
                      required for planned leave.
                    </li>
                    <li className="page-li">
                      Full-day leave is quantified at 8 hours and 30 minutes,
                      while half-day leave is assessed as 4.5 hours.
                    </li>
                    <li className="page-li">
                      Planned leave requires 6 days' notice for 2 to 4 days, 15
                      days' notice for 4 to 7 days, and 30 days' notice for
                      leaves exceeding 8 days.
                    </li>
                    <li className="page-li">
                      Medical certification is mandatory for sickness beyond 2
                      consecutive days.
                    </li>
                    <li className="page-li">
                      Leave approval or disapproval is contingent on the
                      prevailing work scenario and assigned tasks.
                    </li>
                    <li className="page-li">
                      Once PLs are consumed by the employee , the leave will
                      only be approved after the proper completion of the task
                      and tasks.
                    </li>
                  </ul>
                </div>

                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Cancellation Of Leave
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      The department head can cancel sanctioned leave based on
                      situational or operational needs.
                    </li>
                    <li className="page-li">
                      If an employee proceeds to avail the canceled leave, those
                      days will be treated as absence from duty.
                    </li>
                  </ul>
                </div>
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Extension Of Leave
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      Prior approval is necessary for leave extension.
                    </li>
                    <li className="page-li">
                      Employees must apply for leave extensions well in advance.
                    </li>
                    <li className="page-li">
                      Unsanctioned overstay will be treated as absence from duty
                      if no information is provided to the Department HR
                      Department.
                    </li>
                  </ul>
                </div>

                <div className="page-content-main">
                  <h4 className="page-content-title-text">Absence From Duty</h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      Definition of Absence from Duty:
                    </li>
                    <li className="page-li">
                      When an employee takes leave from duty without prior
                      approval or proper intimation under certain unavoidable
                      circumstances, those day/days will be treated as absence
                      from duty.
                    </li>
                    <li className="page-li">
                      The days of absence will be treated under Loss of Pay with
                      double deduction.
                    </li>
                  </ul>
                </div>

                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Reporting and Explanation:
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      The employee must report to the department head upon
                      rejoining duty from absence.
                    </li>
                    <li className="page-li">
                      The employee is required to provide valid reasons for
                      absence in writing before resuming work.
                    </li>
                  </ul>
                </div>

                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Continuous Absence Beyond 7 Days:
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      If an employee is absent from duty continuously for more
                      than 7 days (including any
                      National/Festival/Declared/weekly off days in-between), an
                      official correspondence from the HR department will be
                      sent and it may result in termination of the employment.
                    </li>
                    <li className="page-li">
                      The correspondence will request the employee to report to
                      duty and provide an explanation for the absence.
                    </li>
                  </ul>
                </div>
                <div className="page-content-main">
                  <h4 className="page-content-title-text">
                    Non-Response and Service Withdrawal:
                  </h4>
                  <ul className="page-ul">
                    <li className="page-li">
                      If there is no response from the employee within the
                      stipulated time mentioned in the official correspondence,
                      it will be assumed that the employee has voluntarily
                      withdrawn their service from the company.The withdrawal
                      will be recorded accordingly
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="policy-main-section">
              <h3 className="page-hightlight-text-main">INCREMENT POLICY</h3>
              <div className="page-content-section">
                <div className="page-content-main">
                  <ul className="page-ul">
                    <li className="page-li">
                      The increment cycle will align with the terms negotiated
                      with the employee during the onboarding process. For those
                      with an annual salary below 6 lakhs, the increment ratio
                      may range from 0% to 50%, contingent upon performance.
                      Employees earning an annual salary equal to or exceeding 6
                      lakhs may be entitled to an increment ranging from 0% to
                      25%. Increment depends on performance, stability and
                      inflation rate. Employees who commence their tenure
                      between the 16th and 30th of the month will undergo their
                      increment cycle starting from the subsequent month.
                    </li>
                    <li className="page-li">
                      Salary discussions must be kept confidential in accordance
                      with company rules. Your cooperation in maintaining this
                      confidentiality is appreciated. Company may take any
                      disciplinary action in violation of this rule.
                    </li>
                    <li className="page-li">
                      Employees are requested to notify HR via email for any
                      increment inquiries. Upon receiving the email, the HR
                      department will promptly address the request. Increment
                      decisions are solely based on individual performance, as
                      evaluated by the reporting manager. The increment review
                      is conducted periodically, considering the employee's
                      performance over the tenure. Your commitment and
                      contributions play a pivotal role in determining the
                      outcome. We appreciate your understanding and look forward
                      to fostering a rewarding and performance-driven work
                      environment.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="policy-main-section">
              <h3 className="page-hightlight-text-main">
                USAGE OF MOBILE PHONE
              </h3>
              <div className="page-content-section">
                <div className="page-content-main">
                  <p className="page-para">
                    The usage of mobile devices within the office premises
                    during working hours is strictly prohibited. Employees are
                    required to obtain prior permission from the HR department
                    before taking any calls. In case of emergencies, employees
                    are instructed to provide the following contact numbers to
                    their family members:
                  </p>
                  <ul className="page-ul">
                    <li className="page-li">
                      <h3 className="highlight-lable">
                        Himanshu Jaiswal (CEO):
                        <span className="page-para">
                          9510987700 / 9376093930
                        </span>
                      </h3>
                    </li>
                    <li className="page-li">
                      <h3 className="highlight-lable">
                        Hitendrasinh Chauhan (CTO):
                        <span className="page-para">9601198035</span>
                      </h3>
                    </li>
                    <li className="page-li">
                      <h3 className="highlight-lable">
                        Shivani Jaiswal (Chief People Officer):
                        <span className="page-para">9727418343</span>
                      </h3>
                    </li>
                    <li className="page-li">
                      <h3 className="highlight-lable">
                        Kalpesh Darji (ACE Head):
                        <span className="page-para">9327287700</span>
                      </h3>
                    </li>
                    <li className="page-li">
                      <h3 className="highlight-lable">
                        Satyam Kudesia (General Manager Indore):
                        <span className="page-para"></span>
                      </h3>
                    </li>
                    <li className="page-li">
                      <h3 className="highlight-lable">
                        Simran Choudhary (HR Manager):
                        <span className="page-para">
                          9586877700 / 9724847700
                        </span>
                      </h3>
                    </li>
                    <li className="page-li">
                      <h3 className="highlight-lable">
                        Simran Gupta (HR Executive):
                        <span className="page-para">8511257700</span>
                      </h3>
                    </li>
                    <li className="page-li">
                      <h3 className="highlight-lable">
                        Landline Number:
                        <span className="page-para">
                          07948487700 / 07948447700
                        </span>
                      </h3>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="policy-main-section">
              <h3 className="page-hightlight-text-main">
                EMPLOYEE ENGAGEMENT POLICY
              </h3>
              <div className="page-content-section">
                <div className="page-content-main">
                  <p className="page-para">Diverse engaging events:</p>
                  <p className="page-para">
                    ports Day, Food Fest, Diwali, Hackathon, Activity
                    Competitions, Custom Days and Religious celebrations.
                  </p>
                  <ul className="page-ul">
                    <li className="page-li">
                      Employee Engagement Policy emphasizes:
                      <ul className="page-ul inner-ul">
                        <li className="page-li">
                          Positive workplace through open communication.
                        </li>
                        <li className="page-li">
                          Appreciation of feedback and work-life balance.
                        </li>
                        <li className="page-li">
                          Crucial recognition of achievements and provision of
                          growth opportunities.
                        </li>
                      </ul>
                    </li>
                    <li className="page-li">
                      Regular team-building activities and check-ins foster a
                      supportive atmosphere.
                    </li>
                    <li className="page-li">
                      Commitment to ensuring everyone feels valued, motivated,
                      and part of a thriving team.
                    </li>

                    <li className="page-li">
                      Aim to create a happy and productive work experience.
                    </li>
                    <li className="page-li">
                      Customized sessions for employee growth based on their
                      fields and profiles.
                    </li>
                    <li className="page-li">
                      Focus on enhancing professional development within the
                      workforce.
                    </li>
                  </ul>

                  <p className="page-para">
                    It's compulsory for every employee to participate in the
                    activities with enthusiasm and encouragement. Employee
                    engagement in such activities will be noted and considered
                    as a decisive factor for increased productivity and
                    increment process.
                  </p>
                </div>
              </div>
            </div>

            <div className="policy-main-section">
              <h3 className="page-hightlight-text-main">REFERRAL POLICY</h3>
              <div className="page-content-section">
                <div className="page-content-main">
                  <ul className="page-ul">
                    <li className="page-li">
                      Objective:
                      <ul className="page-ul inner-ul">
                        <li className="page-li">
                          Our Employee Referral Policy is designed to encourage
                          and reward employees for referring qualified
                          candidates, fostering a collaborative and
                          growth-oriented workforce.
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="page-ul">
                    <li className="page-li">
                      Referral Eligibility:
                      <ul className="page-ul inner-ul">
                        <li className="page-li">
                          All full-time employees are eligible to participate in
                          the referral program.
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="page-ul">
                    <li className="page-li">
                      Referral Process:
                      <ul className="page-ul inner-ul">
                        <li className="page-li">
                          Employees can submit referrals through the designated
                          referral form by the HR department.
                        </li>
                        <li className="page-li">
                          Referrals should include the candidate's resume and
                          relevant information.
                        </li>
                        <li className="page-li">
                          {" "}
                          HR will review referrals for eligibility and
                          qualifications.
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="page-ul">
                    <li className="page-li">
                      FormLink:-
                      <ul className="page-ul inner-ul">
                        <li className="page-li">
                          <a
                            className="page-link"
                            href="https://docs.google.com/forms/d/e/1FAIpQLSf0ytGwrbZddn2-_3wdhveo8A40q6L
                    97AUjOEAMjPFQeOMLhg/viewform?usp=sf_link"
                          >
                            https://docs.google.com/forms/d/e/1FAIpQLSf0ytGwrbZddn2-_3wdhveo8A40q6L
                            97AUjOEAMjPFQeOMLhg/viewform?usp=sf_link
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="page-ul">
                    <li className="page-li">
                      Rewards:
                      <ul className="page-ul inner-ul">
                        <li className="page-li">
                          Successful referrals leading to a hire will be
                          rewarded after the new employee completes a
                          probationary period.
                        </li>
                        <li className="page-li">
                          Rewards may include cash incentives defined time to
                          time by the HR department.
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="page-ul">
                    <li className="page-li">
                      Confidentiality:
                      <ul className="page-ul inner-ul">
                        <li className="page-li">
                          Referral information will be treated confidentially.
                        </li>
                        <li className="page-li">
                          Employees are encouraged to obtain the candidate's
                          consent before making a referral.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="policy-main-section">
              <h3 className="page-hightlight-text-main">
                EXIT FORMALITY POLICY
              </h3>
              <div className="page-content-section">
                <p className="page-para">
                  You resign when you voluntarily inform HR that you will stop
                  working for our company. We also consider you to resign if you
                  don’t come to work for 7 consecutive days without notice.
                </p>
                <p className="page-para">
                  Discuss resignation with the HR team before applying is
                  compulsory.
                </p>
                <p className="page-para">
                  The stipulated notice period for employees shall be 45 days.
                  In the event that an employee avails leave during the notice
                  period, an extension equivalent to the duration of the leave
                  taken shall be applied to the notice period. The company
                  reserves the right to adjust and reduce the notice period as
                  deemed necessary in accordance with prevailing work
                  conditions.
                </p>
                <div className="page-content-main">
                  <ul className="page-ul">
                    <li className="page-li">
                      Resignation Processing:
                      <ul className="page-ul inner-ul">
                        <li className="page-li">
                          After submitting a resignation, management takes one
                          week to respond and address raised concerns.
                        </li>
                      </ul>
                    </li>
                    <li className="page-li">
                      Leave Calculation:
                      <ul className="page-ul inner-ul">
                        <li className="page-li">
                          Remaining leave is calculated on a prorata basis,
                          considering one holiday per month and adjusted
                          accordingly.
                        </li>
                      </ul>
                    </li>
                    <li className="page-li">
                      Last Day Procedures:
                      <ul className="page-ul inner-ul">
                        <li className="page-li">
                          On the last day of the notice period, employees need
                          to submit credentials to the technical and HR teams.
                        </li>
                      </ul>
                    </li>
                    <li className="page-li">
                      Handover Process:
                      <ul className="page-ul inner-ul">
                        <li className="page-li">
                          Remaining handover, including documents provided to
                          the company, is given to the existing employee within
                          45 days after your notice period gets completed.
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <p className="page-para">
                    This exit formality policy ensures a clear and
                    straightforward process for resigning employees, promoting
                    effective communication and a smooth transition.
                  </p>
                </div>
              </div>
            </div>

            <div className="policy-main-section">
              <h3 className="page-hightlight-text-main">
                IMPORTANT HIERARCHY DETAILS
              </h3>
              <div className="page-content-section">
                <ul className="page-ul">
                  <li className="page-li">
                    <h3 className="highlight-lable">
                      Anuradha Jaiswal - MD (Managing Director)
                    </h3>
                    <p className="page-important-text">
                      1. Email: md@virtualheight.com
                    </p>
                    <p className="page-important-text">
                      2. Phone No.: 9574530302
                    </p>
                  </li>
                  <li className="page-li">
                    <h3 className="highlight-lable">
                      Himanshu Jaiswal - CEO (Chief Executive Officer)
                    </h3>
                    <p className="page-important-text">
                      1. Email: info@virtualheight.com
                    </p>
                    <p className="page-important-text">
                      2. Phone No.: 9510987700
                    </p>
                  </li>
                  <li className="page-li">
                    <h3 className="highlight-lable">
                      Hiten Chauhan - CTO (Chief Technical Officer)
                    </h3>
                    <p className="page-important-text">
                      1. Email: hiten@virtualheight.com
                    </p>
                    <p className="page-important-text">
                      2. Phone No.: 9601198035
                    </p>
                  </li>
                  <li className="page-li">
                    <h3 className="highlight-lable">
                      Shivani Jaiswal - CPO (Chief People Officer)
                    </h3>
                    <p className="page-important-text">
                      1. Email: cpo@virtualheight.com
                    </p>
                    <p className="page-important-text">
                      2. Phone No.: 9727418343
                    </p>
                  </li>
                  <li className="page-li">
                    <h3 className="highlight-lable">
                      Kiran Vadaria - VP (Sales)
                    </h3>
                    <p className="page-important-text">
                      1. Email: kiran@virtualheight.com
                    </p>
                    <p className="page-important-text">
                      2. Phone No.: 7574887700
                    </p>
                  </li>
                  <li className="page-li">
                    <h3 className="highlight-lable">
                      Hardik Trivedi - VP (Strategies & Operations)
                    </h3>
                    <p className="page-important-text">
                      1. Email: hardik.trivedi@virtualheight.com
                    </p>
                    <p className="page-important-text">
                      2. Phone No.: 8733867700
                    </p>
                  </li>
                  <li className="page-li">
                    <h3 className="highlight-lable">
                      Krishna Prajapati - VP (Operations)
                    </h3>
                    <p className="page-important-text">
                      1. Email: krishna@virtualheight.com
                    </p>
                    <p className="page-important-text">
                      2. Phone No.: 9737700326
                    </p>
                  </li>
                  <li className="page-li">
                    <h3 className="highlight-lable">
                      Yatri Chauhan - VP (Accounts)
                    </h3>
                    <p className="page-important-text">
                      1. Email: yatrichauhan.vhits@gmail.com
                    </p>
                    <p className="page-important-text">
                      2. Phone No.: 9687278722
                    </p>
                  </li>
                  <li className="page-li">
                    <h3 className="highlight-lable">
                      Satyam Kudesia - General Manager - Indore
                    </h3>
                    <p className="page-important-text">
                      1. Email: indore@virtualheight.com
                    </p>
                    <p className="page-important-text">
                      2. Phone No.: 7415077727
                    </p>
                  </li>
                  <li className="page-li">
                    <h3 className="highlight-lable">
                      SSimran Choudhary - HR Manager
                    </h3>
                    <p className="page-important-text">
                      1. Email: hr@virtualheight.com/ simran@virtualheight.com
                    </p>
                    <p className="page-important-text">
                      2. Phone No.: 9586877700
                    </p>
                  </li>
                  <li className="page-li">
                    <h3 className="highlight-lable">
                      Simran Gupta - HR Executive
                    </h3>
                    <p className="page-important-text">
                      1. Email: simrangupta.vhits@gmail.com
                    </p>
                    <p className="page-important-text">
                      2. Phone No.: 8511257700
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div className="policy-main-section">
              <h3 className="page-hightlight-text-main">TRACKER POLICY</h3>
              <div className="page-content-section">
                <p className="page-para">
                  All employees are mandated to utilize the Tracker for precise
                  timekeeping.
                </p>
                <p className="page-para">
                  The calculation of working hours shall be exclusively based on
                  the recorded data in the Tracker system.
                </p>
                <p className="page-para">
                  A full day is defined as 8 hours and 30 minutes, while a half
                  day is quantified as 4.5 hours within the Tracker system.
                </p>
                <p className="page-para">
                  Any inquiries or concerns regarding the utilization of the Top
                  Tracker should be directed to the HR department.
                </p>
              </div>
            </div>

            <div className="policy-main-section">
              <h3 className="page-hightlight-text-main">
                INTEGRATED IT POLICY
              </h3>
              <div className="page-content-section">
                <h4 className="page-content-title-text">
                  1. Purpose and Overview:
                </h4>
                <ul className="page-ul">
                  <li className="page-li">
                    Protect{" "}
                    <span className="highlight-span">
                      VIRTUAL HEIGHT IT SERVICES PVT. LTD
                    </span>
                    ., employees, customers, and partners from harm due to the
                    misuse of IT systems and data, encompassing deliberate and
                    inadvertent actions
                  </li>
                  <li className="page-li">
                    Potential damages include malware infection, legal and
                    financial penalties for data leakage, and lost productivity
                    from network downtime.
                  </li>
                </ul>
              </div>
              <div className="page-content-section">
                <h4 className="page-content-title-text">
                  2. Responsibility and Adherence:
                </h4>
                <ul className="page-ul">
                  <li className="page-li">
                    Every employee is responsible for IT systems and data
                    security.
                  </li>
                  <li className="page-li">
                    Adherence to policy guidelines is mandatory for all
                    employees.
                  </li>
                  <li className="page-li">
                    Employees unclear about the policy's impact on their role
                    should consult their manager or IT security officer
                  </li>
                </ul>
              </div>
              <div className="page-content-section">
                <h4 className="page-content-title-text">
                  3. Information Transfer:
                </h4>
                <ul className="page-ul">
                  <li className="page-li">
                    Users must not send, upload, or transfer confidential
                    information to non-
                    <span className="highlight-span">
                      VIRTUAL HEIGHT IT SERVICES PVT. LTD
                    </span>
                    . systems unless explicitly authorized in the performance of
                    their duties.
                  </li>
                </ul>
              </div>
              <div className="page-content-section">
                <h4 className="page-content-title-text">
                  4. Malware Prevention:
                </h4>
                <ul className="page-ul">
                  <li className="page-li">
                    Users must guard against the risk of malware and report any
                    actual or suspected infections immediately.
                  </li>
                </ul>
              </div>
            </div>

            <div className="policy-main-section">
              <h3 className="page-hightlight-text-main">VOTING POLICY</h3>
              <p className="page-para">
                On election day, one can take 3 hrs off to vote. You can take a
                paid half-day off if you need to travel a short distance to
                vote. If your trip lasts more days, please use your Paid Leaves
                or you need to adjust with a working Saturday.
              </p>
            </div>
            <div className="policy-main-section">
              <h3 className="page-hightlight-text-main">EMAIL POLICY</h3>
              <div className="page-content-section">
                <h4 className="page-content-title-text">1. Ethical Use:</h4>
                <ul className="page-ul">
                  <li className="page-li">
                    All email use must align with ethical conduct, safety,
                    compliance with laws, and proper business practices.
                  </li>
                </ul>
              </div>
              <div className="page-content-section">
                <h4 className="page-content-title-text">
                  2. Business-Related Use:
                </h4>
                <ul className="page-ul">
                  <li className="page-li">
                    <span className="highlight-span">
                      VIRTUAL HEIGHT IT SERVICES PVT. LTD
                    </span>
                    . Email accounts should primarily be used for business
                    purposes.
                  </li>
                  <li className="page-li">
                    Limited personal communication is allowed, but
                    non-business-related commercial uses are prohibited.
                  </li>
                </ul>
              </div>
              <div className="page-content-section">
                <h4 className="page-content-title-text">3. Data Security:</h4>
                <ul className="page-ul">
                  <li className="page-li">
                    All data within email messages or attachments must adhere to
                    the Data Protection Standard defined by the IT
                    administration department.
                  </li>
                </ul>
              </div>
              <div className="page-content-section">
                <h4 className="page-content-title-text">
                  4. Offensive Content:
                </h4>
                <ul className="page-ul">
                  <li className="page-li">
                    The email system must not be used for creating or
                    distributing disruptive or offensive messages.
                  </li>
                  <li className="page-li">
                    Employees receiving such emails should report the matter to
                    their supervisor immediately
                  </li>
                </ul>
              </div>
              <div className="page-content-section">
                <h4 className="page-content-title-text">
                  5. Forwarding Restrictions:
                </h4>
                <ul className="page-ul">
                  <li className="page-li">
                    Users are prohibited from automatically forwarding emails to
                    non-approved third-party systems, and forwarded messages
                    must not contain confidential information.
                  </li>
                </ul>
              </div>
              <div className="page-content-section">
                <h4 className="page-content-title-text">
                  6. Third-Party Systems:
                </h4>
                <ul className="page-ul">
                  <li className="page-li">
                    Non-approved third-party email systems and storage servers
                    must not be used for business transactions, documentation,
                    or email retention.
                  </li>
                </ul>
              </div>
              <div className="page-content-section">
                <h4 className="page-content-title-text">
                  7. Personal Email Usage:
                </h4>
                <ul className="page-ul">
                  <li className="page-li">
                    Reasonable personal email usage is acceptable, with
                    non-work-related emails saved in a separate folder.
                  </li>
                </ul>
              </div>
            </div>

            <div className="policy-main-section">
              <h3 className="page-hightlight-text-main">
                Cyber security and digital devices
              </h3>
              <div className="page-content-section">
                <p className="page-para">
                  This section deals with all things digital at work. We want to
                  set some guidelines for using computers, phones, our internet
                  connection and social media to ensure security and protect our
                  assets.
                </p>
                <h4 className="page-content-title-text">Internet usage</h4>
                <p className="page-para">
                  Our corporate internet connection is primarily for business.
                  But, you can occasionally use our connection for personal
                  purposes as long as they don’t interfere with your job
                  responsibilities. Also, we expect you to temporarily halt
                  personal activities that slow down our internet connection
                  (e.g. uploading photos) if you’re asked to.
                </p>
                <p className="page-para">
                  You must not use our internet connection to:
                </p>
                <ul className="page-ul">
                  <li className="page-li">
                    Download or upload obscene, offensive or illegal material.
                  </li>
                  <li className="page-li">
                    Send confidential information to unauthorized recipients.
                    Invade another person’s privacy and gain access to sensitive
                    information.
                  </li>
                  <li className="page-li">
                    Download or upload pirated movies, music, material or
                    software.
                  </li>
                  <li className="page-li">
                    Visit potentially dangerous websites that can compromise our
                    network and computers’ safety.
                  </li>
                  <li className="page-li">
                    Perform unauthorized or illegal actions, like hacking, fraud
                    or buying/selling illegal goods
                  </li>
                </ul>
              </div>
            </div>

            <div className="policy-main-section">
              <h3 className="page-hightlight-text-main">
                EMPLOYEE TRAINING AND DEVELOPMENT
              </h3>
              <div className="page-content-section">
                <p className="page-para">Employee training and development</p>
                <h4 className="page-content-title-text">Internet usage</h4>
                <p className="page-para">
                  We owe our success to our employees. To show our gratitude, we
                  will invest in our employees' professional development. We
                  want employees to feel confident about improving their
                  efficiency and productivity. We also want to help our
                  employees achieve personal growth and success.
                </p>
                <p className="page-para">
                  We offer these training opportunities:
                </p>
                <ul className="page-ul">
                  <li className="page-li">
                    Formal training sessions (individual or corporate.)
                  </li>
                  <li className="page-li">Employee coaching and mentoring.</li>
                  <li className="page-li">Seats at industry conferences.</li>
                  <li className="page-li">On-the-job training.</li>
                  <li className="page-li">Job rotation.</li>
                </ul>
                <p className="page-para">
                  Development is a collective process. Team members and managers
                  should regularly discuss learning needs and opportunities. And
                  it’s HR’s responsibility to facilitate any development
                  activities and processes
                </p>
              </div>
            </div>

            <div className="policy-main-section">
              <h3 className="page-hightlight-text-main">
                PROGRESSIVE DISCIPLINE
              </h3>
              <div className="page-content-section">
                <p className="page-para">
                  Here we outline steps we will take to address employee
                  misconduct. We want to give employees a chance to correct
                  their behavior when possible and assist them in doing so. We
                  also want to ensure that we thoroughly investigate and handle
                  serious offenses. Our progressive discipline process has six
                  steps of increasing severity. These steps are:
                </p>
                <ul className="page-ul">
                  <li className="page-li">Verbal warning</li>
                  <li className="page-li">Informal meeting with supervisor</li>
                  <li className="page-li">Formal reprimand</li>
                  <li className="page-li">Formal disciplinary meeting</li>
                  <li className="page-li">Penalties</li>
                  <li className="page-li">Termination</li>
                </ul>
                <p className="page-para">
                  Different offenses correspond to different steps in our
                  disciplinary process.
                </p>
                <p className="page-para">
                  For example, minor, one-time offenses (e.g. breach of our
                  dress code policy) will trigger Step 1.
                </p>
                <p className="page-para">
                  More severe violations (e.g. sexual harassment) will trigger
                  step 5.
                </p>
                <p className="page-para">
                  If you manage employees, inform them when you launch a
                  progressive discipline process. Pointing out a performance
                  issue is not necessarily a verbal warning and may be part of
                  your regular feedback.
                </p>
                <p className="page-para">
                  If you judge that progressive discipline is appropriate, let
                  your team member know and ask HR to help you explain our full
                  procedure. Managers may skip or repeat steps at their
                  discretion.
                </p>
                <p className="page-para">
                  Our company may treat circumstances differently from that
                  described in this policy. But, we are always obliged to act
                  fairly and lawfully and document every stage of our
                  progressive discipline process.
                </p>
                <p className="page-para">
                  Keep in mind that our company isn’t obliged to follow the
                  steps of our progressive discipline process. As you are
                  employed “at-will”, we may terminate you directly without
                  launching a progressive discipline process. For serious
                  offenses (e.g. sexual harassment), we may terminate you
                  without warning.
                </p>
              </div>
            </div>

            <div className="policy-main-section">
              <h3 className="page-hightlight-text-main">
                LAPTOP & DESKTOP USAGE POLICY
              </h3>
              <div className="page-content-section">
                <h4 className="page-content-title-text">
                  1. Ownership and Use:
                </h4>
                <ul className="page-ul">
                  <li className="page-li">
                    Company Laptops and desktop are employer-purchased and
                    designated for business use and is Property of VIRTUAL
                    HEIGHT IT SERVICES PVT. LTD.
                  </li>
                  <li className="page-li">
                    Employees must adhere to specific actions:
                  </li>
                </ul>
              </div>
              <div className="page-content-section">
                <h4 className="page-content-title-text">
                  2. Software Installation:
                </h4>
                <ul className="page-ul">
                  <li className="page-li">
                    Employees shall not install personal software on the company
                    laptop.
                  </li>
                </ul>
              </div>
              <div className="page-content-section">
                <h4 className="page-content-title-text">
                  3. Personal Use Restrictions:
                </h4>
                <ul className="page-ul">
                  <li className="page-li">
                    Employees shall not use the company laptop for personal
                    activities (emails, IMs, web browsing, etc.).
                  </li>
                </ul>
              </div>
              <div className="page-content-section">
                <h4 className="page-content-title-text">
                  4. Loss or Theft Reporting:
                </h4>
                <ul className="page-ul">
                  <li className="page-li">
                    Immediate reporting of loss or theft to IT or management is
                    mandatory.
                  </li>
                </ul>
              </div>
              <div className="page-content-section">
                <h4 className="page-content-title-text">
                  5. Security Measures:
                </h4>
                <ul className="page-ul">
                  <li className="page-li">
                    Employee responsibilities for the physical and digital
                    security of the laptop include:
                    <ul className="page-ul">
                      <li className="page-li">
                        Locking the laptop and desktop securely when not in use.
                      </li>
                      <li className="page-li">
                        Changing passwords as required by the employer.
                      </li>
                      <li className="page-li">
                        Ensuring the functionality of provided Anti-virus,
                        Firewall, or Encryption software.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="page-content-section">
                <h4 className="page-content-title-text">
                  6. Termination Protocol:
                </h4>
                <ul className="page-ul">
                  <li className="page-li">
                    Upon termination, all company property, including Company
                    Laptops, must be returned to the employer
                  </li>
                </ul>
              </div>
            </div>

            <div className="policy-main-section">
              <h3 className="page-hightlight-text-main">DESK POLICY</h3>
              <div className="page-content-section">
                <h4 className="page-content-title-text">
                  1. Secure Work Area:
                </h4>
                <ul className="page-ul">
                  <li className="page-li">
                    Employees must ensure the security of sensitive/confidential
                    information in hardcopy or electronic form in their work
                    area.
                  </li>
                  <li className="page-li">
                    End-of-day and extended absence protocols apply.
                  </li>
                </ul>
              </div>
              <div className="page-content-section">
                <h4 className="page-content-title-text">
                  2. Workstation Security:
                </h4>
                <ul className="page-ul">
                  <li className="page-li">
                    Computer workstations must be locked when unoccupied and
                    shut down at the end of the workday.
                  </li>
                </ul>
              </div>
              <div className="page-content-section">
                <h4 className="page-content-title-text">
                  3. Restricted Information Handling:
                </h4>
                <ul className="page-ul">
                  <li className="page-li">
                    Removal and locking of any Restricted or Sensitive
                    information from the desk during unoccupancy or end-of-day.
                  </li>
                  <li className="page-li">
                    File cabinets containing such information must be kept
                    closed and locked when not in use.
                  </li>
                </ul>
              </div>
              <div className="page-content-section">
                <h4 className="page-content-title-text">
                  4. Password Protection:
                </h4>
                <ul className="page-ul">
                  <li className="page-li">
                    Immediate reporting of loss or theft to IT or management is
                    mandatory.
                  </li>
                </ul>
              </div>
              <div className="page-content-section">
                <h4 className="page-content-title-text">
                  5. Document Disposal:
                </h4>
                <ul className="page-ul">
                  <li className="page-li">
                    Shredding of Restricted and/or Sensitive documents in
                    official shredder bins or placing them in locked
                    confidential disposal bins.
                  </li>
                </ul>
              </div>
            </div>

            <div className="policy-main-section">
              <h3 className="page-hightlight-text-main">Employee expenses</h3>
              <div className="page-content-section">
                <p className="page-para">
                  There are some expenses that we will pay directly on your
                  behalf (e.g. hotel rooms for work-related travel.) But, we ask
                  you to keep track and report on those reimbursable expenses
                  that you pay yourself. We reimburse employee expenses that are
                  related to Business travel, Upon approval outings with
                  business partners or colleagues. Not all travel expenses are
                  reimbursable. For example, we will pay for your transportation
                  to an airport for work-related travel, but not to a museum for
                  a personal visit.
                </p>
                <p className="page-para">
                  Before traveling for business, contact HR to clarify which
                  expenses are reimbursable within your particular trip. Please
                  keep receipts for all reimbursable expenses. You can submit
                  them to your manager within 15 days after the date of each
                  expense. If your manager approves your expenses, you will
                  receive your reimbursement within five pay periods.
                </p>
              </div>
            </div>

            <div className="policy-main-section">
              <h3 className="page-hightlight-text-main">
                Performance management
              </h3>
              <h4 className="page-content-title-text">
                We have built our performance management practices to:
              </h4>
              <div className="page-content-section">
                <ul className="page-ul">
                  <li className="page-li">
                    Ensure you understand your job responsibilities and have
                    specific goals to meet.
                  </li>
                  <li className="page-li">
                    Provide you with actionable and timely feedback on your
                    work.
                  </li>
                  <li className="page-li">
                    Invest in development opportunities that help you grow
                    professionally.
                  </li>
                  <li className="page-li">
                    Recognize and reward your work in financial or non-financial
                    ways (e.g. employee awards.)
                  </li>
                  <p className="page-para">
                    To meet these objectives, we have:
                  </p>
                  <li className="page-li">
                    Established quarterly performance reviews. During these
                    reviews, your manager will fill out your performance
                    evaluation report and arrange a meeting with you to discuss
                    it. Through these discussions, managers aim to recognize
                    employees who are good at their jobs, identify areas of
                    improvement and talk about career moves. Pay increases or
                    bonuses are not guaranteed. But, we encourage managers to
                    recommend rewards for their team members when they deserve
                    them. There won’t be any forced ranking or other comparison
                    between employees, as our goal is to help all employees
                    improve and develop their careers.
                  </li>
                  <li className="page-li">
                    Instructed all managers to meet with their team members once
                    per week to provide feedback and talk about their work and
                    motivations. This way, you can receive feedback in a timely
                    manner and avoid surprises during your quarterly performance
                    review.
                  </li>
                </ul>
              </div>
            </div>

            <div className="policy-main-section">
              <h4 className="page-content-title-text">
                How we expect managers to lead employees.
              </h4>
              <p className="page-para">
                If you manage a team, you are responsible for your team members’
                performance. To conduct effective regular meetings and
                performance evaluations, we expect you to:
              </p>
              <div className="page-content-section">
                <ul className="page-ul">
                  <li className="page-li">
                    Set clear objectives. Your team members should know what you
                    expect of them. When you first hire someone to your team,
                    ensure they understand their job duties. Set specific goals
                    for each team member (and team-wide if applicable.) Revisit
                    those goals during quarterly performance reviews.
                  </li>
                  <li className="page-li">
                    Provide useful feedback. During scheduled meetings with your
                    team members, give them both guidance and praise, as
                    appropriate. Be fair and specific to help them understand
                    and implement your feedback.
                  </li>
                  <li className="page-li">
                    Keep your team members involved. There should be two-way
                    communication between you and your team. Make your
                    expectations clear, but always take your team members’
                    motivations and aspirations into account. Discuss training
                    and development opportunities that may interest your team
                    members.
                  </li>
                  <li className="page-li">
                    Keep logs with important incidents about each one of your
                    team members. These logs help you evaluate your team, but
                    may also prove useful when rewarding, promoting or
                    terminating your team members.
                  </li>
                </ul>
              </div>
            </div>

            <div className="policy-main-section">
              <h4 className="page-content-title-text">
                Employee relationships
              </h4>
              <p className="page-para">
                We want to ensure that relationships between employees are
                appropriate and harmonious. We outline our guidelines and we ask
                you to always behave professionally.
              </p>
              <div className="page-content-section">
                <h4 className="page-content-title-text">Fraternization</h4>
                <ul className="page-ul">
                  <li className="page-li">
                    Fraternization refers to dating or being friends with your
                    colleagues. In this policy, “dating” equals consensual
                    romantic relationships and sexual relations. Non-consensual
                    relationships constitute sexual violence and we prohibit
                    them explicitly.
                  </li>
                </ul>
              </div>
              <div className="page-content-section">
                <h4 className="page-content-title-text">Dating colleagues</h4>
                <ul className="page-ul">
                  <li className="page-li">
                    If you start dating a colleague, we expect you to maintain
                    professionalism and keep personal discussions outside of our
                    workplace. You are also obliged to respect your colleagues
                    who date each other. We won’t tolerate sexual jokes,
                    malicious gossip and improper comments. If you witness this
                    kind of behavior, please report it to HR.
                  </li>
                </ul>
              </div>
              <div className="page-content-section">
                <h4 className="page-content-title-text">Dating managers</h4>
                <ul className="page-ul">
                  <li className="page-li">
                    To avoid accusations of favoritism, abuse of authority and
                    sexual harassment, supervisors must not date their direct
                    reports. This restriction extends to every manager above an
                    employee. Also, if you act as a hiring manager, you aren’t
                    allowed to hire your partner to your team. You can refer
                    them for employment to other teams or departments where you
                    don’t have any managerial or hiring authority.
                  </li>
                </ul>
              </div>
              <div className="page-content-section">
                <h4 className="page-content-title-text">Friendships at work</h4>
                <ul className="page-ul">
                  <li className="page-li">
                    Employees who work together may naturally form friendships
                    either in or outside of the workplace. We encourage this
                    relationship between peers, as it can help you communicate
                    and collaborate. But, we expect you to focus on your work
                    and keep personal disputes outside of our workplace.
                  </li>
                </ul>
              </div>
            </div>

            <div className="policy-main-section">
              <h4 className="page-content-title-text">Social media</h4>
              <p className="page-para">
                We want to provide practical advice to prevent careless use of
                social media in our workplace.
              </p>
              <p className="page-para">
                We address two types of social media uses: using personal social
                media at work and representing our company through social media.
              </p>
              <p className="page-para">
                <span className="highlight-span">
                  Using personal social media at work
                </span>
                You are permitted to access your personal accounts at work
                during lunch time. But, we expect you to act responsibly,
                according to our policies and ensure that you stay productive.
              </p>
              <p className="page-para">Specifically, we ask you to:</p>
              <ul className="page-ul">
                <li className="page-li">
                  Discipline yourself. Avoid getting sidetracked by your social
                  platforms.
                </li>
                <li className="page-li">
                  Ensure others know that your personal account or statements
                  don’t represent our company. For example, use a disclaimer
                  such as “opinions are my own.”
                </li>
                <li className="page-li">
                  Avoid sharing intellectual property (e.g trademarks) or
                  confidential information. Ask your manager or PR first before
                  you share company news that’s not officially announced.
                </li>
                <li className="page-li">
                  Avoid any defamatory, offensive or derogatory content. You may
                  violate our company’s anti-harassment policy if you direct
                  such content towards colleagues, clients or partners.
                </li>
              </ul>
            </div>

            <div className="policy-main-section">
              <h4 className="page-content-title-text">
                Representing our company through social media
              </h4>
              <p className="page-para">
                If you handle our social media accounts or speak on our
                company’s behalf, we expect you to protect our company’s image
                and reputation. Specifically, you should:
              </p>
              <ul className="page-ul">
                <li className="page-li">Be respectful, polite and patient.</li>
                <li className="page-li">
                  Avoid speaking on matters outside your field of expertise when
                  possible.
                </li>
                <li className="page-li">
                  Follow our confidentiality and data protection policies and
                  observe laws governing copyrights, trademarks, plagiarism and
                  fair use.
                </li>
                <li className="page-li">
                  Coordinate with our PR/Marketing department when you’re about
                  to share any major-impact content.
                </li>
                <li className="page-li">
                  Avoid deleting or ignoring comments for no reason.
                </li>
                <li className="page-li">
                  Correct or remove any misleading or false content as quickly
                  as possible.
                </li>
              </ul>
            </div>

            <div className="policy-main-section">
              <h4 className="page-content-title-text">
                Harassment and violence
              </h4>
              <p className="page-para">
                To build a happy and productive workplace, we need everyone to
                treat others well and help them feel safe. Each of us should do
                our part to prevent harassment and workplace violence.
              </p>
              <h4 className="page-content-title-text">Workplace harassment</h4>
              <p className="page-para">
                Harassment is a broad term and may include seemingly harmless
                actions, like gossip. We can’t create an exhaustive list, but
                here are some instances that we consider harassment:
              </p>
              <ul className="page-ul">
                <li className="page-li">
                  Sabotaging someone’s work on purpose.
                </li>
                <li className="page-li">
                  Engaging in frequent or unwanted advances of any nature.
                </li>
                <li className="page-li">
                  Commenting derogatorily on a person’s ethnic heritage or
                  religious beliefs.
                </li>
                <li className="page-li">
                  Starting or spreading rumors about a person’s personal life.
                </li>
                <li className="page-li">
                  Ridiculing someone in front of others or singling them out to
                  perform tasks unrelated to their job (e.g. bringing water)
                  against their will.
                </li>
              </ul>
            </div>

            <div className="policy-main-section">
              <h4 className="page-content-title-text">Sexual harassment</h4>
              <p className="page-para">
                Sexual harassment is illegal and we will seriously investigate
                relevant reports. If an employee is found guilty of sexual
                harassment, they will be terminated.
              </p>
              <p className="page-para">
                If you’re being harassed, whether by a colleague, customer or
                vendor, you can choose to talk to any of these people:
              </p>
              <ul className="page-ul">
                <li className="page-li">
                  Offenders. If you suspect that an offender doesn’t realize
                  they are guilty of harassment, you could talk to them directly
                  in an effort to resolve the harassment. This tactic is
                  appropriate for cases of minor harassment (e.g. inappropriate
                  jokes between colleagues.) Avoid using this approach with
                  customers or stakeholders.
                </li>
                <li className="page-li">
                  Your manager. If customers, stakeholders or team members are
                  involved in your claim, you may reach out to your manager.
                  Your manager will assess your situation and may contact HR if
                  appropriate
                </li>
                <li className="page-li">
                  HR. Feel free to reach out to HR in any case of harassment no
                  matter how minor it may seem. For your safety, contact HR as
                  soon as possible in cases of serious harassment (e.g. sexual
                  advances) or if your manager is involved in your claim.
                  Anything you disclose will remain confidential
                </li>
              </ul>
            </div>

            <div className="policy-main-section">
              <h4 className="page-content-title-text">Workplace violence</h4>
              <p className="page-para">
                Violence in our workplace is a serious form of harassment. It
                includes physical and sexual assault, destruction of property,
                threats to harm a person or property and verbal and
                psychological abuse. We want to avoid those incidents
                altogether, but we also want to be ready to respond if needed.
                For this reason, we ask you to:
              </p>
              <ul className="page-ul">
                <li className="page-li">
                  Report to HR if you suspect or know that someone is being
                  violent. Your report will be confidential and we will
                  investigate the situation with discretion.
                </li>
                <li className="page-li">
                  Call our building’s security if you witness incidents of
                  severe physical violence (e.g. ones that involve a lethal
                  weapon.) For your safety, avoid getting involved. We will
                  treat employees who verbally threaten others as high risk and
                  they will receive an appropriate penalty. If HR finds that an
                  employee commits an act of violence, we will terminate that
                  employee and possibly press criminal charges. Employees who
                  damage property deliberately will be solely responsible for
                  paying for it.
                </li>
              </ul>
            </div>

            <div className="policy-main-section">
              <h4 className="page-content-title-text">Supporting victims</h4>
              <p className="page-para">
                To support victims of workplace violence, we may provide victims
                with our lawyer’s services to help them file lawsuits.
              </p>
            </div>

            <div className="policy-main-section">
              <h4 className="page-content-title-text">Get help early on</h4>
              <p className="page-para">
                Seek help from others early on to mitigate conflicts. For
                example:
              </p>
              <ul className="page-ul">
                <li className="page-li">
                  If you experience conflicts with a colleague, ask your manager
                  for advice before tensions escalate. If these conflicts
                  persist, ask HR whether you could attend conflict resolution
                  seminars with your colleague.
                </li>
                <li className="page-li">
                  If you are experiencing personal or work troubles, ask for
                  help from a [mental health professional.] Check with your
                  insurance provider to determine whether they cover any mental
                  health services or ask HR for information on our Employee
                  Assistance Program (EAP). Your discussions will remain
                  confidential.
                </li>
                <li className="page-li">
                  Our workplace is founded on mutual respect and we won’t allow
                  anyone to compromise this foundation.
                </li>
              </ul>
            </div>

            <div className="policy-main-section">
              <h4 className="page-content-title-text">Get help early on</h4>
              <p className="page-para">
                Seek help from others early on to mitigate conflicts. For
                example:
              </p>
              <ul className="page-ul">
                <li className="page-li">
                  If you experience conflicts with a colleague, ask your manager
                  for advice before tensions escalate. If these conflicts
                  persist, ask HR whether you could attend conflict resolution
                  seminars with your colleague.
                </li>
                <li className="page-li">
                  If you are experiencing personal or work troubles, ask for
                  help from a [mental health professional.] Check with your
                  insurance provider to determine whether they cover any mental
                  health services or ask HR for information on our Employee
                  Assistance Program (EAP). Your discussions will remain
                  confidential.
                </li>
                <li className="page-li">
                  Our workplace is founded on mutual respect and we won’t allow
                  anyone to compromise this foundation.
                </li>
              </ul>
            </div>

            <div className="policy-main-section">
              <h4 className="page-content-title-text">Workplace policies</h4>
              <p className="page-para">
                This section describes policies that apply to everyone at our
                company: employees, contractors, volunteers, vendors and
                stakeholders alike. These policies help us build a productive,
                lawful and pleasant workplace.
              </p>
              <h4 className="page-content-title-text">
                Confidentiality and data protection
              </h4>
              <p className="page-para">
                We want to ensure that private information about clients,
                employees, partners and our company is well-protected.
              </p>
              <p className="page-para">
                Examples of confidential information are:
              </p>
              <ul className="page-ul">
                <li className="page-li">Employee records</li>
                <li className="page-li">Unpublished financial information</li>
                <li className="page-li">Data of customers/partners/vendors</li>
                <li className="page-li">
                  Customer lists (existing and prospective)
                </li>
                <li className="page-li">
                  Unpublished goals, forecasts and initiatives marked as
                  confidential
                </li>
              </ul>
              <p className="page-para">
                As part of our hiring process, we may ask you to sign
                non-compete and non-disclosure agreements (NDAs.) We are also
                committed to:
              </p>
              <ul className="page-ul">
                <li className="page-li">
                  Restrict and monitor access to sensitive data.
                </li>
                <li className="page-li">
                  Develop transparent data collection procedures.
                </li>
                <li className="page-li">
                  Train employees in online privacy and security measures.
                </li>
                <li className="page-li">
                  Build secure networks to protect online data from
                  cyberattacks.
                </li>
                <li className="page-li">
                  Establish data protection practices (e.g. secure locks, data
                  encryption, frequent backups, access authorization.)
                </li>
              </ul>
              <p className="page-para">
                We also expect you to act responsibly when handling confidential
                information.
              </p>
              <p className="highlight-lable">You must:</p>
              <ul className="page-ul">
                <li className="page-li">
                  Lock or secure confidential information at all times.
                </li>
                <li className="page-li">
                  Shred confidential documents when they’re no longer needed.
                </li>
                <li className="page-li">
                  Make sure you view confidential information on secure devices
                  only.
                </li>
                <li className="page-li">
                  Only disclose information to other employees when it’s
                  necessary and authorized. Keep confidential documents inside
                  our company’s premises unless it’s absolutely necessary to
                  move them.
                </li>
                <p className="highlight-lable">You must not:</p>
                <li className="page-li">
                  Use confidential information for your personal benefit or
                  profit.
                </li>
                <li className="page-li">
                  Disclose confidential information to anyone outside of our
                  company
                </li>
                <li className="page-li">
                  Replicate confidential documents and files and store them on
                  insecure devices. This policy is important for our company’s
                  legality and reputation. We will terminate any employee who
                  breaches our confidentiality guidelines for personal profit.
                  We may also discipline any unintentional breach of this policy
                  depending on its frequency and seriousness. We will terminate
                  employees who repeatedly disregard this policy, even when they
                  do so unintentionally.
                </li>
              </ul>
            </div>

            <div className="policy-main-section">
              <h4 className="highlight-lable">Dear Valued Team</h4>
              <p className="page-para">
                On behalf of the HR team at VHITS, we extend our gratitude for
                taking the time to review our HR Handbook. Your commitment to
                familiarizing yourself with our policies and guidelines is
                greatly appreciated.
              </p>
              <p className="page-para">
                The HR Handbook serves as a comprehensive guide to the various
                aspects of our company's culture, values, and operational
                procedures. We believe that by going through this resource,
                you've not only gained insights into our organization but have
                also taken a crucial step toward a successful and fulfilling
                journey with us.
              </p>
              <p className="page-para">
                As you continue your exciting tenure with VHITS, we encourage
                you to actively apply the knowledge acquired from the handbook
                in your daily tasks and interactions. Should you have any
                questions, require clarification, or seek further assistance,
                our HR team is here to support you every step of the way
              </p>
              <p className="page-para">
                Thank you once again for your diligence and commitment to
                understanding our company's ethos. We're thrilled to have you as
                part of our team, and we look forward to witnessing your
                positive contributions.
              </p>
              <p className="page-para">
                Wishing you continued success and a rewarding experience at
                VHITS.
              </p>
              <br />
              <h5 className="page-hightlight-text">Best regards,</h5>
              <h2 className="page-end-text">HR Department</h2>
            </div>
          </div>
        </MDBCard>
      </div>
    </>
  );
}
export default LeavePolicy;
