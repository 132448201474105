import React, { useEffect, useState } from "react";
import { MDBDataTableV5 } from "mdbreact";
import MeBar from "../MeBar";
import AxiosInstance from "../../../../service/AxiosInstance";
import moment from "moment";
import { Button, Col, DatePicker, Form, Row, Select, Space, Table } from "antd";
import Link from "antd/lib/typography/Link";
import { object } from "prop-types";
import ReactLoader from "../../../../components/common/ReactLoader";

import { useParams } from "react-router-dom";
import EmployeeTab from "../../employee_master/EmployeeTab";
import OtherDetailsTab from "../../employee_master/OtherDetailsTab";

const MyDailyLogs = (props) => {
  const [form] = Form.useForm();
  const [dailyLogs, setDailyLogs] = useState([]);
  const [datatable, setDatatable] = useState({});
  const [orignalData, setOrignalData] = useState([]);
  const [loading, setLoading] = useState(false);

  let currMonth = moment(new Date()).month() + 1;
  let currYear = moment(new Date()).year();

  //Here redereing same componenets from employee other details tab so Getting user id from params
  const params = useParams();
  const userPramasid = params?.id;

  const onFinish = (values) => {
    dailyLogsList(values?.month, values?.year);
  };

  const dailyLogsList = (m, y) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("month", m);
    urlencoded.append("year", y);
    userPramasid && urlencoded.append("id", userPramasid);
    setLoading(true);
    AxiosInstance.post("auth/working-employee-report", urlencoded).then(
      async (res) => {
        setLoading(false);
        setOrignalData(res.data.data);
        let list1 = [];
        list1 = res.data.data.map((item, index) => {
          var duration = moment.duration(
            moment(item?.Check_Out).diff(moment(item?.Check_In))
          );
          var seconds = duration.asSeconds();
          let minutes = Math.floor(seconds) % 3600;
          let durationTime =
            Math.floor(seconds / 3600) +
            " hours " +
            Math.floor(minutes / 60) +
            " mins " +
            Math.floor(seconds % 60) +
            " seconds ";
          return {
            ...item,
            key: item._id,
            Date: moment(item?.Check_In).format("DD-MM-YYYY"),
            project_name: item.Project_id.Project_name,
            Task: (
              <Link
                style={{ fontWeight: "500" }}
                to={`/task/${item?.Task_id?._id}`}
              >
                {item?.Task_id?.Task_key}
              </Link>
            ),
            shortSummary: item?.Task_id?.Short_summary.substring(0, 30),
            Starttime: `${moment(item?.Check_In).format("hh:mm:ss")}`,
            Endtime: `${moment(item?.Check_Out).format("hh:mm:ss")}`,
            totaltime: durationTime,
          };
        });
        setDailyLogs(list1);
      }
    );
  };
  useEffect(() => {
    dailyLogsList(currMonth < 10 ? `0${currMonth}` : currMonth, currYear);
  }, []);

  const columns = [
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
    },
    {
      title: "Project Name",
      dataIndex: "project_name",
      key: "project_name",
    },
    {
      title: "Task",
      dataIndex: "Task",
      key: "Task",
    },
    {
      title: "Short Description",
      dataIndex: "shortSummary",
      key: "shortSummary",
    },
    {
      title: "Start Time",
      dataIndex: "Starttime",
      key: "Starttime",
    },
    {
      title: "End Time",
      dataIndex: "Endtime",
      key: "Endtime",
    },
    {
      title: "Total Time",
      dataIndex: "totaltime",
      key: "totaltime",
    },
  ];

  return (
    <div className="event-main event-main2 team-leave-view">
      {/* {!userPramasid ? (
        <MeBar />
      ) : (
        <>
          <EmployeeTab />
          <OtherDetailsTab />
        </>
      )} */}
       {!userPramasid || !props?.OtherDetail ? (
          <MeBar />
        ) : (
          <>
            {!props?.OtherDetail && (
              <>
                <EmployeeTab />
                <OtherDetailsTab />
              </>
            )}
          </>
        )}
      <div className="page-content">
        <div className="attendance-head daily-log-head">
          <p className="tablHeader">My Daily Logs</p>
          <Space direction="horizontal">
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
            >
              <Row>
                <Col style={{ paddingRight: "12px" }}>
                  <Form.Item
                    // label="Select month"
                    name="month"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Month",
                      },
                    ]}
                    initialValue={currMonth < 10 ? `0${currMonth}` : currMonth}
                  >
                    <Select showSearch="true" placeholder="Select month">
                      <Select.Option value="01">January</Select.Option>
                      <Select.Option value="02">February</Select.Option>
                      <Select.Option value="03">March</Select.Option>
                      <Select.Option value="04">April</Select.Option>
                      <Select.Option value="05">May</Select.Option>
                      <Select.Option value="06">June</Select.Option>
                      <Select.Option value="07">July</Select.Option>
                      <Select.Option value="08">August</Select.Option>
                      <Select.Option value="09">September</Select.Option>
                      <Select.Option value="10">October</Select.Option>
                      <Select.Option value="11">November</Select.Option>
                      <Select.Option value="12">December</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col style={{ paddingRight: "12px" }}>
                  <Form.Item
                    // label="Select Year"
                    name="year"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Year",
                      },
                    ]}
                    initialValue={currYear ? currYear : ""}
                  >
                    <Select showSearch="true" placeholder="Select year">
                      {/* <Select.Option value="2023">2023</Select.Option> */}
                      <Select.Option value="2024">2024</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col>
                  <Form.Item>
                    <Button htmlType="submit" className="pms-same-btn">
                      Refresh
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Space>
        </div>
        {loading ? (
          <ReactLoader loading={loading} />
        ) : (
          <div className="tasklisttbl">
            <Table
              dataSource={dailyLogs}
              columns={columns}
              pagination={{ pageSize: 30 }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MyDailyLogs;
