import React, { useState, useEffect } from "react";
import { Select, Spin, Table } from "antd";
import moment from "moment";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionMyDailyReport } from "../../../redux/actions/TaskActions";
import AxiosInstance from "../../../service/AxiosInstance";

const ProjectTeamLeave = () => {
  const MyDailyTaskReport = useSelector(
    (state) => state.Task?.MyDailyTaskReport
  );
  const [myReport, setMyReport] = useState(MyDailyTaskReport);
  const [myTask, setMyTask] = useState([]);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  let PID = params.id;
  const dispatch = useDispatch();
  const columns = [
    {
      title: "Employee Name",
      dataIndex: "Name",
    },
    {
      title: "Date",
      dataIndex: "Date",
    },
    {
      title: "Department",
      dataIndex: "Department",
    },
  ];
  const getDefaultReport = () => {
    setLoading(true);
    const formData = new URLSearchParams();
    formData.append("id", JSON.stringify(PID));
    AxiosInstance.post("auth/project-member-leave", formData).then((res) => {
      setLoading(false);
      dispatch(actionMyDailyReport(res.data.data[0]));
      setMyReport(res.data.data[0]);
    });
  };
  const leaveDates = (items) => {
    const dateArray = items.Leave_Date_to_Date.split(",");
    const newStartDate = new Date(dateArray[0]);
    const newEndDate = new Date(dateArray[1]);
    const halfStart = items.Leave_half_from;
    const halfTo = items.Leave_half_to;

    let appliedDate = "";
    let days = "";
    if (
      moment(newStartDate, "DD/MM/YYYY").format("MMM DD, YYYY") ==
      moment(newEndDate, "DD/MM/YYYY").format("MMM DD, YYYY") &&
      halfStart == "First Half" &&
      halfTo == "Second Half"
    ) {
      appliedDate = moment(newStartDate, "DD/MM/YYYY").format("MMM DD, YYYY");
      days = "1 Day";
    } else {
      appliedDate =
        moment(newStartDate, "DD/MM/YYYY").format("MMM DD, YYYY") +
        " - " +
        moment(newEndDate, "DD/MM/YYYY").format("MMM DD, YYYY");
      if (
        moment(newStartDate, "DD/MM/YYYY").format("MMM DD, YYYY") ==
        moment(newEndDate, "DD/MM/YYYY").format("MMM DD, YYYY")
      ) {
        appliedDate = moment(newStartDate, "DD/MM/YYYY").format("MMM DD, YYYY");
        if (halfStart == "Second Half") {
          days = "0.5 Day (Second Half)";
        } else {
          days = "0.5 Day (First Half)";
        }
      } else {
        let daysLeft =
          moment(newEndDate, "DD/MM/YYYY").diff(
            moment(newStartDate, "DD/MM/YYYY"),
            "days"
          ) + 1;
        if (halfStart == "First Half" && halfTo == "Second Half") {
          days = daysLeft + " Days";
        } else {
          if (halfStart == "Second Half") {
            daysLeft -= 0.5;
          }
          if (halfTo == "First Half") {
            daysLeft -= 0.5;
          }
          days = daysLeft + " Days (" + halfStart + " - " + halfTo + ")";
        }
      }
    }

    return (
      <>
        {appliedDate} <span className="info">{days}</span>{" "}
      </>
    );
  };
  const GetMyReport = () => {
    let data = [];
    myReport?.map((row) => {
      if(row.Status=="Approved" || row.Status=="Conditional Approved"  ){
        let detail = {
          Name: `${row.Employee_ID.First_name} ${row.Employee_ID.Last_name}`,
          Department: row.Employee_ID.Role,
          Date: leaveDates(row),
          Day: row.Position
        };
        data.push(detail);
        setMyTask(data);
      }
    });
  };
  useEffect(() => {
    GetMyReport();
  }, [myReport]);

  useEffect(() => {
    getDefaultReport();
  }, []);
  return (
    <>
      {" "}
      <div className="my-task-dropdown">
      </div>
      <Spin spinning={loading}></Spin>
      <Table className="team-table" columns={columns} dataSource={myTask} />
    </>
  )
}

export default ProjectTeamLeave