import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Spin, Table, Tabs, Tooltip } from "antd";
import AxiosInstance from "../../../service/AxiosInstance";
import SelectEmpployee from "../../../components/common/SelectEmpployee";
import moment from "moment";
import Mention from "react-textarea-mention";
import mentionStyle from "./mentionStyle";
import mentionsInputStyles from "./mentionsInputStyles";
import { async } from "@firebase/util";
import { SendOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const TaskComments = ({ User, TaskId, Type, changeTask }) => {
  const [form] = Form.useForm();
  const [comments, setComments] = useState([]);
  const [count2, setCount2] = useState(false);
  const [historyList, setHistoryList] = useState([]);
  const [hoursLogsList, setHoursLogsList] = useState([]);
  const [empId, setEmpId] = useState([]);
  const [empData, setEmpData] = useState([]);
  const [mentionList, setMentionList] = useState("");
  const [loading, setLoading] = useState(false);

  const imageUrl = "https://bucket-pms.s3.ap-south-1.amazonaws.com/s3-upload/";
  async function fetchUserList(username) {
    let employee = [];
    await AxiosInstance.get(`auth/employees`).then(async (res) => {
      employee = res.data.data.map((item) => ({
        display: `${item.First_name} ${item.Last_name}`,
        id: "&",
        name: `${item.First_name} ${item.Last_name}`,
        username: `${item.First_name}${item.Last_name}`,
      }));
    });
    setMentionList(employee);
  }
  useEffect(() => {
    fetchUserList();
  }, []);
  const ProjectId = "6328ae383a38dc0bf6db7cea";
  const callApi = async () => {
    const formData = new URLSearchParams();
    formData.append("Task_id", TaskId);
    AxiosInstance.post("auth/tasks/comments", formData).then(async (res) => {
      if (res.data.status === 1) {
        setComments(res.data.data);
      }
    });
    AxiosInstance.get(`auth/history/${TaskId}`).then(async (res) => {
      if (res.data.status == 1) {
        setHistoryList(res.data.data[0].History);
        res.data.data[0].History.map((ele) => {
          if (ele?.User_id !== undefined) {
            setEmpId(ele?.User_id);
          }
        });
        // setEmpId(res.data.data[0].History[0].User_id);
      }
    });
    AxiosInstance.get(`auth/tasks/tasktimer/${TaskId}`).then(async (res) => {
      if (res.data.status == 1) {
        setHoursLogsList(res.data.data);
      }
    });
    // AxiosInstance.get(`auth/project/alltasktimer/${ProjectId}`).then(
    //   async (res) => {
    //     if (res.data.status == 1) {
    //       setTotalTime(res.data.data);
    //     }
    //   }
    // );
  };
  // console.log(totalTime, "71");
  useEffect(() => {
    //console.log("re rendering the component")
    callApi();
  }, [count2, changeTask]);

  useEffect(() => {
    empProfile();
  }, []);

  const empProfile = async () => {
    AxiosInstance.get("auth/employees").then(async (res) => {
      setEmpData(res?.data?.data);
      res?.data?.data?.filter((element) => {
        if (element?._id == empId) {
          // let EmployeeData = {
          //   Profile_pic: element.Profile_pic,
          // }
          setEmpData(element);
          // empData.push(EmployeeData);
        }
      });
    });
  };

  // console.log(empId, 40)
  // console.log(empData, "harsh111")

  const onFinish = async (values) => {
    setLoading(true);
    form.resetFields();
    if (values.comments.trim()) {
      // const User = JSON.parse(localStorage.getItem('userdata'));
      const formData = new URLSearchParams();
      formData.append("User_id", User[0]._id);
      formData.append("First_name", User[0].First_name);
      formData.append("Last_name", User[0].Last_name);
      formData.append("Task_id", TaskId);
      formData.append("Type", Type);

      if (Type == "Project") {
        formData.append("Project_id", TaskId);
      }
      formData.append("Comment", values.comments.replace(/[&()[\]{}]/g, ""));

      await AxiosInstance.post("auth/tasks/comment/add", formData)
        .then((res) => {
          console.log("{{{{{", res);
          // here is setup for the latest comment basis
          if (res.data.status === 1) {
            const formData = new URLSearchParams();
            formData.append("Task_id", TaskId);
            AxiosInstance.post("auth/tasks/comments", formData).then(
              async (res) => {
                if (res.data.status === 1) {
                  setComments(res.data.data);
                }
              }
            );
          }
          let formData1 = new URLSearchParams();
          // console.log(res.data.data[0].at(-1).User_id, 11111);
          formData1.append("User_id", res.data.data[0].at(-1).User_id);
          formData1.append("Task_id", res.data.data[0].at(-1).Task_id);
          formData1.append(
            "Updated_by",
            res.data.data[0].at(-1).First_name +
              " " +
              res.data.data[0].at(-1).Last_name
          );
          formData1.append("Comments", res.data.data[0].at(-1).Comment);
          AxiosInstance.post("auth/history/add", formData1).then((res) => {
            if (res.data.status == 1) {
              callApi();
            }
          });
          setCount2(!count2);
          // here through call the api
        })
        .catch((err) => console.log(err, "err"));
      await setLoading(false);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      this.sendMessage();
    }
  };
  // const handleInputChange = (event, newValue, newPlainTextValue, mentions) => {
  //   console.log(event, newValue, newPlainTextValue, mentions, 140);
  //   // setInputValue(e.target.value);
  // };
  const handleChange = (content) => {
    console.log(content);
  };

  function WriteComment({ content }) {
    return (
      <>
        <Form
          className="max-w"
          form={form}
          name="comments"
          // labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="add-cmt">
            <img
              src={
                User[0]._id && User[0].Profile_pic
                  ? imageUrl + User[0].Profile_pic
                  : "https://bootdey.com/img/Content/avatar/avatar1.png"
              }
              alt="profile"
              style={{
                width: "35px",
                height: "35px",
                objectFit: "contain",
                borderRadius: "50px",
                marginRight: "10px",
              }}
            />
            <div className="task-cmt-textarea" style={{ flex: "1" }}>
              <Form.Item
                name="comments"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && e.altKey) {
                    console.log("keys pressed");
                    form.submit();
                  }
                }}
              >
                <Mention
                  onChange={handleChange}
                  field="username"
                  data={mentionList}
                />
              </Form.Item>
              <button>
                <SendOutlined />
              </button>

              <div className="tip-text">
                <Spin spinning={loading}> </Spin>
                <p>
                  Pro-tip :{" "}
                  <span>
                    1. Press <b>@</b> to mention
                  </span>
                </p>
                <p style={{ marginLeft: "43px" }}>
                  <span>
                    2. Press <b>Left Alt + Enter </b> to comment for Windows
                  </span>
                </p>
                <p style={{ marginLeft: "43px" }}>
                  <span>
                    3. Press <b>Option + Enter </b> to comment for Mac
                  </span>
                </p>
              </div>
            </div>
          </div>
        </Form>
      </>
    );
  }

  // function Text({ content }) {
  //   console.log(content,"contentKRISHNA");
  //   const words = content.split(" ");
  //   const URL_REGEX =
  //    /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gm;
  //   return (
  //     <p>
  //       {words.map((word) => {
  //         return word.match(URL_REGEX) ? (

  //           <>
  //             {" "}<a href={word}  target="_blank">
  //               {" "}
  //               {word}{" "}
  //             </a>{" "}
  //           </>
  //         ) : (
  //          " " + word + " "
  //         );
  //       })}
  //     </p>
  //   );
  // }
  function Text({ content }) {
    const words = content.split(/(\s+)/); // split on whitespace and keep the whitespace
    const URL_REGEX =
      /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gm;
    return (
      <p>
        {words.map((word) => {
          if (word.match(URL_REGEX)) {
            // If the word is a URL, add a space before it
            return (
              <>
                {/* {"\u00A0"}{/ Unicode character for a non-breaking space /} */}
                <a href={word} target="_blank">
                  {word}
                </a>{" "}
              </>
            );
          } else if (word.trim()) {
            // If the word is not a URL and is not only whitespace, add a space before and after it
            return "" + word + "";
          } else {
            // If the word is only whitespace, return it as-is
            return word;
          }
        })}
      </p>
    );
  }

  function CommentList({ comments }) {
    return (
      <>
        {comments &&
          comments.map((item, number) => {
            let time = 0;
            let current = Date.now();
            let before = new Date(item.Create_at).getTime();

            //console.log('x> y',before>current);
            let difrence = current - before;
            const days = Math.floor(difrence / (24 * 60 * 60 * 1000));
            const hours = Math.floor(difrence / (60 * 60 * 1000));
            const minutes = Math.floor(difrence / (60 * 1000));
            const sec = Math.floor(difrence / 1000);
            if (days > 0) {
              time = `${days} days ago`;
            } else if (hours > 0) {
              time = `${hours} hours ago`;
            } else if (minutes === 0 || minutes === 1) {
              time = `Just now`;
            } else {
              time = `${minutes} minutes ago`;
            }
            return (
              <div className="add-cmt" style={{ marginTop: "15px" }}>
                <img
                  src={
                    item.User_id
                      ? imageUrl + item.User_id.Profile_pic
                      : "https://bootdey.com/img/Content/avatar/avatar1.png"
                  }
                  alt="profile"
                  style={{
                    width: "35px",
                    height: "35px",
                    objectFit: "contain",
                    borderRadius: "50px",
                    marginRight: "10px",
                  }}
                />
                <div style={{ flex: "1" }}>
                  <div className="user-name-detils user-name-detils-cmt">
                    <p>
                      {" "}
                      {item.First_name} {item.Last_name}
                    </p>
                    <p> {`${time}`}</p>

                    <p className="label-comment">Comment</p>
                  </div>
                  <div className="user-cmt-detail user-name-detils-cmt">
                    {" "}
                    <>
                      <Text content={item.Comment} />
                    </>
                  </div>
                </div>
              </div>
            );
          })}
      </>
    );
  }

  let PrevstatusBg;
  let CurrentstatusBg;

  function HistoryList({ history }) {
    // console.log("maulik347");
    const Currentcolorstatus = (color) => {
      CurrentstatusBg = "shadow-0";
      if (color == "IN PROGRESS") {
        CurrentstatusBg = "danger-info";
      } else if (color == "DEVELOPMENT DONE") {
        CurrentstatusBg = "danger-info";
      } else if (color == "REJECTED") {
        CurrentstatusBg = "shadow-0";
      } else if (color == "ON HOLD") {
        CurrentstatusBg = "shadow-0";
      } else if (color == "READY FOR QA") {
        CurrentstatusBg = "danger-info";
      } else if (color == "REOPEN") {
        CurrentstatusBg = "shadow-0";
      } else if (color == "READY FOR UAT") {
        CurrentstatusBg = "success-info";
      } else if (color == "CLOSE") {
        CurrentstatusBg = "success-info";
      }
    };

    const Prevcolorstatus = (color) => {
      PrevstatusBg = "shadow-0";
      if (color == "IN PROGRESS") {
        PrevstatusBg = "danger-info";
      } else if (color == "DEVELOPMENT DONE") {
        PrevstatusBg = "danger-info";
      } else if (color == "REJECTED") {
        PrevstatusBg = "shadow-0";
      } else if (color == "ON HOLD") {
        PrevstatusBg = "shadow-0";
      } else if (color == "READY FOR QA") {
        PrevstatusBg = "danger-info";
      } else if (color == "REOPEN") {
        PrevstatusBg = "shadow-0";
      } else if (color == "READY FOR UAT") {
        PrevstatusBg = "success-info";
      } else if (color == "CLOSE") {
        PrevstatusBg = "success-info";
      }
    };
    return (
      <>
        {history &&
          history.map((item, number) => {
            // console.log(item , 279)
            // let time = 0;
            // let current = Date.now();
            // let before = new Date(item.Time).getTime();

            // //console.log('x> y',before>current);
            // let difrence = current - before;
            // const days = Math.floor(difrence / (24 * 60 * 60 * 1000));
            // const hours = Math.floor(difrence / (60 * 60 * 1000));
            // const minutes = Math.floor(difrence / (60 * 1000));
            // const sec = Math.floor(difrence / 1000);
            // if (days > 0) {
            //   time = `${days} days ago`;
            // } else if (hours > 0) {
            //   time = `${hours} hours ago`;
            // } else if (minutes === 0 || minutes === 1) {
            //   time = `Just now`;
            // } else {
            //   time = `${minutes} minutes ago`;
            // }

            Currentcolorstatus(item.Current_Status);
            Prevcolorstatus(item.Previous_Status);

            console.log(empData);
            return (
              <div className="add-cmt" style={{ marginTop: "15px" }}>
                <img
                  src={
                    item.User_id && item.User_id.Profile_pic
                      ? imageUrl + item.User_id.Profile_pic
                      : "https://bootdey.com/img/Content/avatar/avatar1.png"
                  }
                  alt="profile"
                  style={{
                    width: "35px",
                    height: "35px",
                    objectFit: "contain",
                    borderRadius: "50px",
                    marginRight: "10px",
                  }}
                />
                <div style={{ flex: "1" }}>
                  <div className="user-name-detils">
                    <p>
                      <b>
                        {!item.Updated_by
                          ? item.User_id.First_name +
                            " " +
                            item.User_id.Last_name
                          : item.Updated_by}
                      </b>
                    </p>
                    <p>
                      {item.Task_create
                        ? `Task Create On ${moment(item.Task_create).format(
                            "DD MMM YYYY"
                          )} At ${moment(item.Task_create).format("hh:mm A")}`
                        : item.Previous_Status
                        ? `Changed the Status ${moment(item.Time).format(
                            "DD MMM YYYY"
                          )} At ${moment(item.Time).format("hh:mm A")}`
                        : item.Current_Assignees
                        ? `Changed the Assignee On ${moment(item.Time).format(
                            "DD MMM YYYY"
                          )} At ${moment(item.Time).format("hh:mm A")}`
                        : item.Comments
                        ? `Added a Comment On ${moment(item.Time).format(
                            "DD MMM YYYY"
                          )} At ${moment(item.Time).format("hh:mm A")}`
                        : item.Current_Assign_date
                        ? `Changed the Assigned Date On ${moment(
                            item.Time
                          ).format("DD MMM YYYY")} At ${moment(
                            item.Time
                          ).format("hh:mm A")}`
                        : item.Current_Due_date
                        ? `Changed the Due Date On ${moment(item.Time).format(
                            "DD MMM YYYY"
                          )} At ${moment(item.Time).format("hh:mm A")}`
                        : item?.Current_Priority
                        ? `Changed the Priority On ${moment(item.Time).format(
                            "DD MMM YYYY"
                          )} At ${moment(item.Time).format("hh:mm A")}`
                        : item?.Current_Short_summary
                        ? `Changed the Short summary On ${moment(
                            item.Time
                          ).format("DD MMM YYYY")} At ${moment(
                            item.Time
                          ).format("hh:mm A")}`
                        : item?.Current_Task_duration
                        ? `Changed the Task Duration On ${moment(
                            item.Time
                          ).format("DD MMM YYYY")} At ${moment(
                            item.Time
                          ).format("hh:mm A")}`
                        : item?.Current_Description
                        ? `Changed the Task Description On ${moment(
                            item.Time
                          ).format("DD MMM YYYY")} At ${moment(
                            item.Time
                          ).format("hh:mm A")}`
                        : ""}
                    </p>
                    <p className="label-comment">Comment</p>
                  </div>
                  <div className="user-cmt-detail">
                    <p style={{ margin: "0" }}>
                      <>
                        <div
                          className={
                            item?.Current_Description
                              ? "task-description"
                              : "status-data"
                          }
                        >
                          {item.Current_Assign_date ? (
                            <>
                              {moment(item.Privious_Assign_date).format(
                                "DD MMM YYYY"
                              ) ||
                                moment(item.Previous_Assign_date).format(
                                  "DD MMM YYYY"
                                )}{" "}
                              &nbsp;{" "}
                              <i className="fas fa-long-arrow-alt-right taskarrowmini"></i>{" "}
                              &nbsp;{" "}
                              <div>
                                {moment(item.Current_Assign_date).format(
                                  "DD MMM YYYY"
                                )}
                              </div>
                            </>
                          ) : item.Current_Due_date ? (
                            <>
                              {moment(item.Previous_Due_date).format(
                                "DD MMM YYYY"
                              )}{" "}
                              &nbsp;{" "}
                              <i className="fas fa-long-arrow-alt-right taskarrowmini"></i>{" "}
                              &nbsp;{" "}
                              <div>
                                {moment(item.Current_Due_date).format(
                                  "DD MMM YYYY"
                                )}
                              </div>
                            </>
                          ) : item.Comments ? (
                            item.Comments
                          ) : item.Previous_Assignees ? (
                            <>
                              {item.Previous_Assignees ||
                                item.Privious_Assignees}{" "}
                              &nbsp;{" "}
                              <i className="fas fa-long-arrow-alt-right taskarrowmini"></i>{" "}
                              &nbsp; <div>{item.Current_Assignees}</div>
                            </>
                          ) : item.Current_Status ? (
                            <>
                              <div className={PrevstatusBg}>
                                {item.Previous_Status}
                              </div>{" "}
                              &nbsp;
                              <i className="fas fa-long-arrow-alt-right taskarrowmini"></i>{" "}
                              &nbsp;
                              <div className={CurrentstatusBg}>
                                {item.Current_Status}
                              </div>
                            </>
                          ) : item?.Current_Short_summary ? (
                            <>
                              <div className={PrevstatusBg}>
                                <Tooltip title={item?.Previous_Short_summary}>
                                  {`${item?.Previous_Short_summary?.slice(
                                    0,
                                    30
                                  )}...`}
                                </Tooltip>
                              </div>{" "}
                              &nbsp; &nbsp;
                              <i className="fas fa-long-arrow-alt-right taskarrowmini"></i>{" "}
                              &nbsp; &nbsp;
                              <div className={CurrentstatusBg}>
                                <Tooltip title={item?.Current_Short_summary}>
                                  {`${item?.Current_Short_summary?.slice(
                                    0,
                                    30
                                  )}...`}
                                </Tooltip>
                              </div>
                            </>
                          ) : item?.Current_Priority ? (
                            <>
                              <div className={PrevstatusBg}>
                                {item.Previous_Priority}
                              </div>{" "}
                              &nbsp;
                              <i className="fas fa-long-arrow-alt-right taskarrowmini"></i>{" "}
                              &nbsp;
                              <div className={CurrentstatusBg}>
                                {item.Current_Priority}
                              </div>
                            </>
                          ) : item?.Current_Task_duration ? (
                            <>
                              <div className={PrevstatusBg}>
                                {item.Previous_Task_duration}
                              </div>
                              hr &nbsp;
                              <i className="fas fa-long-arrow-alt-right taskarrowmini"></i>{" "}
                              &nbsp;
                              <div className={CurrentstatusBg}>
                                {item.Current_Task_duration}
                              </div>
                              hr
                            </>
                          ) : item?.Current_Description ? (
                            <>
                              <p className="prev-next-title">
                                Previous Description:
                              </p>
                              <Tooltip
                                title={
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.Previous_Description,
                                    }}
                                  ></div>
                                }
                              >
                                <div
                                  className={"task-description-scroll"}
                                  dangerouslySetInnerHTML={{
                                    __html: item.Previous_Description,
                                  }}
                                ></div>
                              </Tooltip>
                              {/* <i class="fas fa-long-arrow-alt-right taskarrowmini"></i>{" "} */}
                              <p className="prev-next-title">
                                Current Description:
                              </p>
                              <Tooltip
                                title={
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.Current_Description,
                                    }}
                                  ></div>
                                }
                              >
                                <div
                                  className={"task-description-scroll"}
                                  dangerouslySetInnerHTML={{
                                    __html: item.Current_Description,
                                  }}
                                ></div>
                              </Tooltip>
                            </>
                          ) : (
                            <div className={PrevstatusBg}>
                              {item.Previous_Status
                                ? item.Previous_Status
                                : "OPEN"}
                            </div>
                          )}
                        </div>
                      </>
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
      </>
    );
  }
  function HoursLogsList({ hoursLogsList }) {
    const columns = [
      {
        title: "Date",
        dataIndex: "Check_In",
        key: "Check_In",
        render: (Check_In) => {
          return moment(Check_In).format("DD/MM/yyy");
        },
      },
      {
        title: "Employee name",
        dataIndex: "Employee_id",
        key: "Employee_id",
        render: (Employee_id) => {
          return Employee_id?.First_name + " " + Employee_id?.Last_name;
        },
      },

      {
        title: "Timing",

        key: "Check_Out",
        render: (record) => {
          return (
            moment(record.Check_In).format(" hh:mm:ss A") +
            " - " +
            (record?.Check_Out
              ? moment(record?.Check_Out).format(" hh:mm:ss A")
              : "")
          );
        },
      },
      {
        title: "Duration",

        key: "duration",
        render: (record) => {
          var duration = moment.duration(
            moment(record?.Check_Out).diff(moment(record.Check_In))
          );
          var seconds = duration.asSeconds();

          let minutes = Math.floor(seconds) % 3600;
          return (
            Math.floor(seconds / 3600) +
            " hours " +
            Math.floor(minutes / 60) +
            " mins " +
            Math.floor(seconds % 60) +
            " seconds "
          );
        },
      },
    ];
    return (
      <>
        <Table
          // className="task_table"
          columns={columns}
          dataSource={hoursLogsList}
        />
      </>
    );
  }

  return (
    <>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Comments" key="1">
          <div id="scrollContainer" className="all-task">
            <WriteComment />
            <CommentList comments={comments} />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="History" key="2">
          <HistoryList history={historyList} />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Hours Logs" key="3">
          <HoursLogsList hoursLogsList={hoursLogsList} />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default TaskComments;
