import React, { useState, useEffect } from "react";
import { Col, message, Row, Select, Button, Modal, Spin, Input } from "antd";
import moment from "moment";
import DashboardBar from "../../../components/common/DashboardBar";
import AxiosInstance from "../../../service/AxiosInstance";
import { Link } from "react-router-dom";
import { TablePagination } from "@mui/material";

function TaskDashboard() {
  const [taskDashboard, setTaskDashboard] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isModalTodayTaskHours, setIsModalTodayTaskHours] = useState(false);
  const [TodayTaskHoursTask, setTodayTaskHoursTask] = useState(null);
  const [isModalAssignedTasksDev, setIsModalAssignedTasksDev] = useState(false);
  const [assignedTasksDev, setAssignedTasksDev] = useState(null);
  const [isModalAllProject, setIsModalAllProject] = useState(false);
  const [allProjectData, setallProjectData] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const TaskAllocatedHours = taskDashboard?.TaskAllocatedHours || 0;
  const TodayTaskHours = taskDashboard?.TodayTaskHours || 0;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 30));
    setPage(0);
  };

  const getTaskDashboardCount = () => {
    setLoading(true);
    AxiosInstance.get("/auth/task/dashboard-count")
      .then((res) => {
        setLoading(false);
        if (res?.data?.status === 1) {
          setTaskDashboard(res.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    getTaskDashboardCount();
  }, []);

  const showModal = (task) => {
    setSelectedTask(task);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedTask(null);
    setPage(0);
    setSearchQuery("");
  };

  const showTodayTaskHoursModal = (task, title) => {
    groupByEmployee(task);
    setModalTitle(title);
    setIsModalTodayTaskHours(true);
  };

  const handleTodayTaskHoursCancel = () => {
    setIsModalTodayTaskHours(false);
    setTodayTaskHoursTask(null);
    setPage(0);
    setSearchQuery("");
  };

  const showAssignedTasksDevModal = (task, title) => {
    setAssignedTasksDev(task);
    setModalTitle(title);
    setIsModalAssignedTasksDev(true);
  };

  const handleAssignedTasksDevCancel = () => {
    setIsModalAssignedTasksDev(false);
    setAssignedTasksDev(null);
    setPage(0);
    setSearchQuery("");
  };
  const showAllProjectModal = (task, title) => {
    setallProjectData(task);
    setModalTitle(title);
    setIsModalAllProject(true);
  };

  const handleAllProjectCancel = () => {
    setIsModalAllProject(false);
    setallProjectData(null);
    setPage(0);
    setSearchQuery("");
  };

  const groupByEmployee = (tasks) => {
    const grouped = tasks.reduce((acc, task) => {
      const employeeId = task.Employee_id._id;
      let employeeGroup = acc.find(
        (group) => group.employee._id === employeeId
      );
      if (!employeeGroup) {
        employeeGroup = {
          employee: task.Employee_id,
          tasks: [],
        };
        acc.push(employeeGroup);
      }
      employeeGroup.tasks.push(task);
      return acc;
    }, []);

    setTodayTaskHoursTask(grouped);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredTasks = TodayTaskHoursTask?.filter((entry) => {
    const fullName =
      `${entry?.employee?.First_name} ${entry?.employee?.Last_name}`.toLowerCase();
    const lowerCaseQuery = searchQuery.toLowerCase();
    return (
      fullName.includes(lowerCaseQuery) ||
      entry?.tasks.some(
        (task) =>
          task?.Project_name?.toLowerCase().includes(lowerCaseQuery) ||
          task?.Task_key?.toLowerCase().includes(lowerCaseQuery)
      )
    );
  });

  const filteredAssignedTasksDev = assignedTasksDev?.filter((entry) => {
    const fullName = `${entry?.First_name} ${entry?.Last_name}`.toLowerCase();
    const role = entry?.Role?.toLowerCase();
    const lowerCaseQuery = searchQuery.toLowerCase();
    return fullName.includes(lowerCaseQuery) || role.includes(lowerCaseQuery);
  });

  const filteredAllProjectData = allProjectData?.filter((entry) => {
    const projectName = entry?.Project_name?.toLowerCase();
    const status = entry?.Status?.toLowerCase();
    const lowerCaseQuery = searchQuery.toLowerCase();
    return (
      projectName.includes(lowerCaseQuery) || status.includes(lowerCaseQuery)
    );
  });

  return (
    <>
      <div className="event-main">
        <DashboardBar />
        <div className="page-content">
          <Row className="mx-0 mydashboard-main">
            <Col className="col" xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="second-main-modal">
                <div className="pm-flex-drop">
                  <div className="left-head-title" style={{ margin: "0" }}>
                    Task Dashboard
                  </div>
                </div>
                <div className="custom-demo-profile-my">
                  <div class="flex-count-details custom-flex-count-details">
                    <div class="flex-count-inner">
                      <div class="count-cols count-cols-first">
                        <div class="count-card">
                          <div class="count-card-body">
                            <div className="count-title-loader-box">
                              <h5 class="count-card-title">Total Developers</h5>
                              {loading && <Spin />}
                            </div>

                            <p
                              class="count-card-amount"
                              onClick={() =>
                                showAssignedTasksDevModal(
                                  taskDashboard?.employeeData,
                                  "Total Developers Details"
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {taskDashboard?.TotalDeveloper}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="count-cols count-cols-first">
                        <div class="count-card">
                          <div class="count-card-body">
                            <div className="count-title-loader-box">
                              <h5 class="count-card-title">
                                Allocated Task To Developers
                              </h5>
                              {loading && <Spin />}
                            </div>
                            <p
                              class="count-card-amount"
                              onClick={() =>
                                showAssignedTasksDevModal(
                                  taskDashboard?.TotalTodayTaskEmployees,
                                  "Allocated Task To Developer Details"
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {taskDashboard?.TotalAssignedTasks}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="count-cols count-cols-first">
                        <div class="count-card">
                          <div class="count-card-body">
                            <div className="count-title-loader-box">
                              <h5 class="count-card-title">
                                Vacant Developers
                              </h5>
                              {loading && <Spin />}
                            </div>
                            <p
                              class="count-card-amount"
                              onClick={() =>
                                showAssignedTasksDevModal(
                                  taskDashboard?.remainingDeveloperData,
                                  "Vacant Developers Details"
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {taskDashboard?.TotalRemainingDeveloper}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="count-cols count-cols-first">
                        <div class="count-card">
                          <div class="count-card-body">
                            <div className="count-title-loader-box">
                              <h5 class="count-card-title">
                                Task Allocated Hours
                              </h5>
                              {loading && <Spin />}
                            </div>
                            <p
                              class="count-card-amount"
                              onClick={() =>
                                showTodayTaskHoursModal(
                                  taskDashboard?.taskData,
                                  "Today Task Hours Details"
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {`${TodayTaskHours}/${TaskAllocatedHours}`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Total Today Task */}
                  <div class="flex-count-details custom-flex-count-details">
                    <div class="flex-count-inner">
                      <div className="custom-story-task">
                        <h3>Total Today Task</h3>
                        <p
                          class="count-card-stories-and-tasks"
                          onClick={() =>
                            showTodayTaskHoursModal(
                              taskDashboard?.taskData,
                              "Total Today Task Details"
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {taskDashboard?.TotalTodayTaskCount}
                        </p>
                      </div>
                      <div class="flex-count-details custom-flex-count-details ">
                        <div class="flex-count-inner">
                          <div class="count-cols-custom">
                            <div class="count-card">
                              <div class="count-card-body">
                                <div className="count-title-loader-box">
                                  <h5 class="count-card-title">Draft</h5>
                                  {loading && <Spin />}
                                </div>
                                <p
                                  class="count-card-amount"
                                  onClick={() => {
                                    const draftTasks =
                                      taskDashboard?.taskData?.filter(
                                        (task) => task?.Status == "DRAFT"
                                      );
                                    showTodayTaskHoursModal(
                                      draftTasks,
                                      "Draft Task List"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {taskDashboard?.TodayTaskDraftCount}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="count-cols-custom">
                            <div class="count-card">
                              <div class="count-card-body">
                                <div className="count-title-loader-box">
                                  <h5 class="count-card-title">Open</h5>
                                  {loading && <Spin />}
                                </div>
                                <p
                                  class="count-card-amount"
                                  onClick={() => {
                                    const OpenTasks =
                                      taskDashboard?.taskData?.filter(
                                        (task) => task?.Status == "OPEN"
                                      );
                                    showTodayTaskHoursModal(
                                      OpenTasks,
                                      "Open Task List"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {taskDashboard?.TodayTaskOpenCount}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="count-cols-custom">
                            <div class="count-card">
                              <div class="count-card-body">
                                <div className="count-title-loader-box">
                                  <h5 class="count-card-title">InProgress</h5>
                                  {loading && <Spin />}
                                </div>
                                <p
                                  class="count-card-amount"
                                  onClick={() => {
                                    const InProgressTasks =
                                      taskDashboard?.taskData?.filter(
                                        (task) => task?.Status == "IN PROGRESS"
                                      );
                                    showTodayTaskHoursModal(
                                      InProgressTasks,
                                      "InProgress Task List"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {taskDashboard?.TodayTaskInProgressCount}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="count-cols-custom">
                            <div class="count-card">
                              <div class="count-card-body">
                                <div className="count-title-loader-box">
                                  <h5 class="count-card-title"> Dev Done</h5>
                                  {loading && <Spin />}
                                </div>
                                <p
                                  class="count-card-amount"
                                  onClick={() => {
                                    const DevDoneTasks =
                                      taskDashboard?.taskData?.filter(
                                        (task) =>
                                          task?.Status == "DEVELOPMENT DONE"
                                      );
                                    showTodayTaskHoursModal(
                                      DevDoneTasks,
                                      "Development Done Task List"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {taskDashboard?.TodayTaskDevDoneCount}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="count-cols-custom">
                            <div class="count-card">
                              <div class="count-card-body">
                                <div className="count-title-loader-box">
                                  <h5 class="count-card-title">Rejected</h5>
                                  {loading && <Spin />}
                                </div>
                                <p
                                  class="count-card-amount"
                                  onClick={() => {
                                    const RejectedTasks =
                                      taskDashboard?.taskData?.filter(
                                        (task) => task?.Status == "REJECTED"
                                      );
                                    showTodayTaskHoursModal(
                                      RejectedTasks,
                                      "Rejected Task List"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {taskDashboard?.TodayTaskRejectedCount}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="count-cols-custom">
                            <div class="count-card">
                              <div class="count-card-body">
                                <div className="count-title-loader-box">
                                  <h5 class="count-card-title">On Hold</h5>
                                  {loading && <Spin />}
                                </div>
                                <p
                                  class="count-card-amount"
                                  onClick={() => {
                                    const OnHoldTasks =
                                      taskDashboard?.taskData?.filter(
                                        (task) => task?.Status == "ON HOLD"
                                      );
                                    showTodayTaskHoursModal(
                                      OnHoldTasks,
                                      "On Hold Task List"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {taskDashboard?.TodayTaskOnHoldCount}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="count-cols-custom">
                            <div class="count-card">
                              <div class="count-card-body">
                                <div className="count-title-loader-box">
                                  <h5 class="count-card-title">RFQA</h5>
                                  {loading && <Spin />}
                                </div>
                                <p
                                  class="count-card-amount"
                                  onClick={() => {
                                    const RFQATasks =
                                      taskDashboard?.taskData?.filter(
                                        (task) => task?.Status == "READY FOR QA"
                                      );
                                    showTodayTaskHoursModal(
                                      RFQATasks,
                                      "Ready For QA Task List"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {taskDashboard?.TodayTaskReadyForQACount}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="count-cols-custom">
                            <div class="count-card">
                              <div class="count-card-body">
                                <div className="count-title-loader-box">
                                  <h5 class="count-card-title">Reopen</h5>
                                  {loading && <Spin />}
                                </div>
                                <p
                                  class="count-card-amount"
                                  onClick={() => {
                                    const ReopenTasks =
                                      taskDashboard?.taskData?.filter(
                                        (task) => task?.Status == "REOPEN"
                                      );
                                    showTodayTaskHoursModal(
                                      ReopenTasks,
                                      "Reopen Task List"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {taskDashboard?.TodayTaskReoprnCount}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="count-cols-custom">
                            <div class="count-card">
                              <div class="count-card-body">
                                <div className="count-title-loader-box">
                                  <h5 class="count-card-title">UAT</h5>
                                  {loading && <Spin />}
                                </div>
                                <p
                                  class="count-card-amount"
                                  onClick={() => {
                                    const UATTasks =
                                      taskDashboard?.taskData?.filter(
                                        (task) =>
                                          task?.Status == "READY FOR UAT"
                                      );
                                    showTodayTaskHoursModal(
                                      UATTasks,
                                      "Ready For UAT Task List"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {taskDashboard?.TodayTaskReadyForUATCount}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="count-cols-custom">
                            <div class="count-card">
                              <div class="count-card-body">
                                <div className="count-title-loader-box">
                                  <h5 class="count-card-title">Closed</h5>
                                  {loading && <Spin />}
                                </div>
                                <p
                                  class="count-card-amount"
                                  onClick={() => {
                                    const ClosedTasks =
                                      taskDashboard?.taskData?.filter(
                                        (task) => task?.Status == "CLOSE"
                                      );
                                    showTodayTaskHoursModal(
                                      ClosedTasks,
                                      "Closed Task List"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {taskDashboard?.TodayTaskClosedCount}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ant-row cutom-task-dashboard">
                  <div className="custom-demo-profile-my">
                    <div className="ant-col ant-col-24">
                      {/* Total project data */}

                      <div className="ant-col ant-col-24">
                        <div className="second-row-task-main">
                          <div className="custom-story-task">
                            <h3>Total Project</h3>
                            <p
                              class="count-card-stories-and-tasks"
                              onClick={() =>
                                showAllProjectModal(
                                  taskDashboard?.projectData,
                                  "Total Project List"
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {taskDashboard?.TotalProject}
                            </p>
                          </div>
                          <div class="flex-count-details custom-flex-count-details ">
                            <div class="flex-count-inner">
                              <div class="count-cols-custom-second">
                                <div class="count-card">
                                  <div class="count-card-body">
                                    <div className="count-title-loader-box">
                                      <h5 class="count-card-title">Open</h5>
                                      {loading && <Spin />}
                                    </div>
                                    <p
                                      class="count-card-amount"
                                      onClick={() => {
                                        const OpenProject =
                                          taskDashboard?.projectData?.filter(
                                            (task) => task?.Status == "Open"
                                          );
                                        showAllProjectModal(
                                          OpenProject,
                                          "Open Project List"
                                        );
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {taskDashboard?.projectOpenCount}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="count-cols-custom-second">
                                <div class="count-card">
                                  <div class="count-card-body">
                                    <div className="count-title-loader-box">
                                      <h5 class="count-card-title">
                                        InProgress
                                      </h5>
                                      {loading && <Spin />}
                                    </div>
                                    <p
                                      class="count-card-amount"
                                      onClick={() => {
                                        const InProgressProject =
                                          taskDashboard?.projectData?.filter(
                                            (task) =>
                                              task?.Status == "InProgress"
                                          );
                                        showAllProjectModal(
                                          InProgressProject,
                                          "In Progress Project List"
                                        );
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {taskDashboard?.projectInProgressCount}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="count-cols-custom-second">
                                <div class="count-card">
                                  <div class="count-card-body">
                                    <div className="count-title-loader-box">
                                      <h5 class="count-card-title">Hold</h5>
                                      {loading && <Spin />}
                                    </div>
                                    <p
                                      class="count-card-amount"
                                      onClick={() => {
                                        const HoldProject =
                                          taskDashboard?.projectData?.filter(
                                            (task) => task?.Status == "Hold"
                                          );
                                        showAllProjectModal(
                                          HoldProject,
                                          "Hold Project List"
                                        );
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {taskDashboard?.projectHoldCount}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="count-cols-custom-second">
                                <div class="count-card">
                                  <div class="count-card-body">
                                    <div className="count-title-loader-box">
                                      <h5 class="count-card-title">
                                        Client Review
                                      </h5>
                                      {loading && <Spin />}
                                    </div>
                                    <p
                                      class="count-card-amount"
                                      onClick={() => {
                                        const ClientReviewProject =
                                          taskDashboard?.projectData?.filter(
                                            (task) =>
                                              task?.Status == "InClientReview"
                                          );
                                        showAllProjectModal(
                                          ClientReviewProject,
                                          "Client Review Project List"
                                        );
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {
                                        taskDashboard?.projectInClientReviewCount
                                      }
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* total stories data */}

                      <div className="custom-story-task">
                        <h3>Total Stories</h3>
                        <p
                          class="count-card-stories-and-tasks"
                          onClick={() =>
                            showTodayTaskHoursModal(
                              taskDashboard?.TotalStoriesData,
                              "Total Story Details"
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {taskDashboard?.TotalStoryCount}
                        </p>
                      </div>
                      <div class="flex-count-details custom-flex-count-details ">
                        <div class="flex-count-inner">
                          <div class="count-cols-custom">
                            <div class="count-card">
                              <div class="count-card-body">
                                <div className="count-title-loader-box">
                                  <h5 class="count-card-title">Draft</h5>
                                  {loading && <Spin />}
                                </div>
                                <p
                                  class="count-card-amount"
                                  onClick={() => {
                                    const DraftStory =
                                      taskDashboard?.TotalStoriesData?.filter(
                                        (task) => task?.Status == "DRAFT"
                                      );
                                    showTodayTaskHoursModal(
                                      DraftStory,
                                      "Draft Story List"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {taskDashboard?.storyDraftCount}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="count-cols-custom">
                            <div class="count-card">
                              <div class="count-card-body">
                                <div className="count-title-loader-box">
                                  <h5 class="count-card-title">Open</h5>
                                  {loading && <Spin />}
                                </div>
                                <p
                                  class="count-card-amount"
                                  onClick={() => {
                                    const OpenStory =
                                      taskDashboard?.TotalStoriesData?.filter(
                                        (task) => task?.Status == "OPEN"
                                      );
                                    showTodayTaskHoursModal(
                                      OpenStory,
                                      "Open Story List"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {taskDashboard?.storyOpenCount}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="count-cols-custom">
                            <div class="count-card">
                              <div class="count-card-body">
                                <div className="count-title-loader-box">
                                  <h5 class="count-card-title">InProgress</h5>
                                  {loading && <Spin />}
                                </div>
                                <p
                                  class="count-card-amount"
                                  onClick={() => {
                                    const InProgressStory =
                                      taskDashboard?.TotalStoriesData?.filter(
                                        (task) => task?.Status == "IN PROGRESS"
                                      );
                                    showTodayTaskHoursModal(
                                      InProgressStory,
                                      "In Progress Story List"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {taskDashboard?.storyInProgressCount}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="count-cols-custom">
                            <div class="count-card">
                              <div class="count-card-body">
                                <div className="count-title-loader-box">
                                  <h5 class="count-card-title"> Dev Done</h5>
                                  {loading && <Spin />}
                                </div>
                                <p
                                  class="count-card-amount"
                                  onClick={() => {
                                    const DevelopmentDoneStory =
                                      taskDashboard?.TotalStoriesData?.filter(
                                        (task) =>
                                          task?.Status == "DEVELOPMENT DONE"
                                      );
                                    showTodayTaskHoursModal(
                                      DevelopmentDoneStory,
                                      "Development Done Story List"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {taskDashboard?.storyDevDoneCount}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="count-cols-custom">
                            <div class="count-card">
                              <div class="count-card-body">
                                <div className="count-title-loader-box">
                                  <h5 class="count-card-title">Rejected</h5>
                                  {loading && <Spin />}
                                </div>
                                <p
                                  class="count-card-amount"
                                  onClick={() => {
                                    const RejectedStory =
                                      taskDashboard?.TotalStoriesData?.filter(
                                        (task) => task?.Status == "REJECTED"
                                      );
                                    showTodayTaskHoursModal(
                                      RejectedStory,
                                      "Rejected Story List"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {taskDashboard?.storyRejectedCount}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="count-cols-custom">
                            <div class="count-card">
                              <div class="count-card-body">
                                <div className="count-title-loader-box">
                                  <h5 class="count-card-title">On Hold</h5>
                                  {loading && <Spin />}
                                </div>
                                <p
                                  class="count-card-amount"
                                  onClick={() => {
                                    const OnHoldStory =
                                      taskDashboard?.TotalStoriesData?.filter(
                                        (task) => task?.Status == "ON HOLD"
                                      );
                                    showTodayTaskHoursModal(
                                      OnHoldStory,
                                      "On Hold Story List"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {taskDashboard?.storyOnHoldCount}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="count-cols-custom">
                            <div class="count-card">
                              <div class="count-card-body">
                                <div className="count-title-loader-box">
                                  <h5 class="count-card-title">RFQA</h5>
                                  {loading && <Spin />}
                                </div>
                                <p
                                  class="count-card-amount"
                                  onClick={() => {
                                    const RFQAStory =
                                      taskDashboard?.TotalStoriesData?.filter(
                                        (task) => task?.Status == "READY FOR QA"
                                      );
                                    showTodayTaskHoursModal(
                                      RFQAStory,
                                      "Ready For QA Story List"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {taskDashboard?.storyReadyForQACount}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="count-cols-custom">
                            <div class="count-card">
                              <div class="count-card-body">
                                <div className="count-title-loader-box">
                                  <h5 class="count-card-title">Reopen</h5>
                                  {loading && <Spin />}
                                </div>
                                <p
                                  class="count-card-amount"
                                  onClick={() => {
                                    const ReopenStory =
                                      taskDashboard?.TotalStoriesData?.filter(
                                        (task) => task?.Status == "REOPEN"
                                      );
                                    showTodayTaskHoursModal(
                                      ReopenStory,
                                      "Reopen Story List"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {taskDashboard?.storyReoprnCount}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="count-cols-custom">
                            <div class="count-card">
                              <div class="count-card-body">
                                <div className="count-title-loader-box">
                                  <h5 class="count-card-title">UAT</h5>
                                  {loading && <Spin />}
                                </div>
                                <p
                                  class="count-card-amount"
                                  onClick={() => {
                                    const UATStory =
                                      taskDashboard?.TotalStoriesData?.filter(
                                        (task) =>
                                          task?.Status == "READY FOR UAT"
                                      );
                                    showTodayTaskHoursModal(
                                      UATStory,
                                      "Ready For UAT Story List"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {taskDashboard?.storyReadyForUATCount}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="count-cols-custom">
                            <div class="count-card">
                              <div class="count-card-body">
                                <div className="count-title-loader-box">
                                  <h5 class="count-card-title">Closed</h5>
                                  {loading && <Spin />}
                                </div>
                                <p
                                  class="count-card-amount"
                                  onClick={() => {
                                    const ClosedStory =
                                      taskDashboard?.TotalStoriesData?.filter(
                                        (task) => task?.Status == "CLOSE"
                                      );
                                    showTodayTaskHoursModal(
                                      ClosedStory,
                                      "Closed Story List"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {taskDashboard?.storyClosedCount}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* total tasks data */}
                    <div className="ant-col ant-col-24">
                      <div className="second-row-task-main">
                        <div className="custom-story-task">
                          <h3>Total Tasks</h3>
                          <p
                            class="count-card-stories-and-tasks"
                            onClick={() =>
                              showTodayTaskHoursModal(
                                taskDashboard?.TotalTasksData,
                                "Total Task Details"
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {taskDashboard?.TotalTasksCount}
                          </p>
                        </div>
                        <div class="flex-count-details custom-flex-count-details ">
                          <div class="flex-count-inner">
                            <div class="count-cols-custom">
                              <div class="count-card">
                                <div class="count-card-body">
                                  <div className="count-title-loader-box">
                                    <h5 class="count-card-title">Draft</h5>
                                    {loading && <Spin />}
                                  </div>
                                  <p
                                    class="count-card-amount"
                                    onClick={() => {
                                      const draftTasks =
                                        taskDashboard?.TotalTasksData?.filter(
                                          (task) => task?.Status == "DRAFT"
                                        );
                                      showTodayTaskHoursModal(
                                        draftTasks,
                                        "Draft Task List"
                                      );
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {taskDashboard?.taskDraftCount}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="count-cols-custom">
                              <div class="count-card">
                                <div class="count-card-body">
                                  <div className="count-title-loader-box">
                                    <h5 class="count-card-title">Open</h5>
                                    {loading && <Spin />}
                                  </div>
                                  <p
                                    class="count-card-amount"
                                    onClick={() => {
                                      const OpenTasks =
                                        taskDashboard?.TotalTasksData?.filter(
                                          (task) => task?.Status == "OPEN"
                                        );
                                      showTodayTaskHoursModal(
                                        OpenTasks,
                                        "Open Task List"
                                      );
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {taskDashboard?.taskOpenCount}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="count-cols-custom">
                              <div class="count-card">
                                <div class="count-card-body">
                                  <div className="count-title-loader-box">
                                    <h5 class="count-card-title">InProgress</h5>
                                    {loading && <Spin />}
                                  </div>
                                  <p
                                    class="count-card-amount"
                                    onClick={() => {
                                      const InProgressTasks =
                                        taskDashboard?.TotalTasksData?.filter(
                                          (task) =>
                                            task?.Status == "IN PROGRESS"
                                        );
                                      showTodayTaskHoursModal(
                                        InProgressTasks,
                                        "InProgress Task List"
                                      );
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {taskDashboard?.taskInProgressCount}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="count-cols-custom">
                              <div class="count-card">
                                <div class="count-card-body">
                                  <div className="count-title-loader-box">
                                    <h5 class="count-card-title"> Dev Done</h5>
                                    {loading && <Spin />}
                                  </div>
                                  <p
                                    class="count-card-amount"
                                    onClick={() => {
                                      const DevDoneTasks =
                                        taskDashboard?.TotalTasksData?.filter(
                                          (task) =>
                                            task?.Status == "DEVELOPMENT DONE"
                                        );
                                      showTodayTaskHoursModal(
                                        DevDoneTasks,
                                        "Development Done Task List"
                                      );
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {taskDashboard?.taskDevDoneCount}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="count-cols-custom">
                              <div class="count-card">
                                <div class="count-card-body">
                                  <div className="count-title-loader-box">
                                    <h5 class="count-card-title">Rejected</h5>
                                    {loading && <Spin />}
                                  </div>
                                  <p
                                    class="count-card-amount"
                                    onClick={() => {
                                      const RejectedTasks =
                                        taskDashboard?.TotalTasksData?.filter(
                                          (task) => task?.Status == "REJECTED"
                                        );
                                      showTodayTaskHoursModal(
                                        RejectedTasks,
                                        "Rejected Task List"
                                      );
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {taskDashboard?.taskRejectedCount}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="count-cols-custom">
                              <div class="count-card">
                                <div class="count-card-body">
                                  <div className="count-title-loader-box">
                                    <h5 class="count-card-title">On Hold</h5>
                                    {loading && <Spin />}
                                  </div>
                                  <p
                                    class="count-card-amount"
                                    onClick={() => {
                                      const OnHoldTasks =
                                        taskDashboard?.TotalTasksData?.filter(
                                          (task) => task?.Status == "ON HOLD"
                                        );
                                      showTodayTaskHoursModal(
                                        OnHoldTasks,
                                        "On Hold Task List"
                                      );
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {taskDashboard?.taskOnHoldCount}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="count-cols-custom">
                              <div class="count-card">
                                <div class="count-card-body">
                                  <div className="count-title-loader-box">
                                    <h5 class="count-card-title">RFQA</h5>
                                    {loading && <Spin />}
                                  </div>
                                  <p
                                    class="count-card-amount"
                                    onClick={() => {
                                      const RFQATasks =
                                        taskDashboard?.TotalTasksData?.filter(
                                          (task) =>
                                            task?.Status == "READY FOR QA"
                                        );
                                      showTodayTaskHoursModal(
                                        RFQATasks,
                                        "Ready For QA Task List"
                                      );
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {taskDashboard?.taskReadyForQACount}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="count-cols-custom">
                              <div class="count-card">
                                <div class="count-card-body">
                                  <div className="count-title-loader-box">
                                    <h5 class="count-card-title">Reopen</h5>
                                    {loading && <Spin />}
                                  </div>
                                  <p
                                    class="count-card-amount"
                                    onClick={() => {
                                      const ReopenTasks =
                                        taskDashboard?.TotalTasksData?.filter(
                                          (task) => task?.Status == "REOPEN"
                                        );
                                      showTodayTaskHoursModal(
                                        ReopenTasks,
                                        "Reopen Task List"
                                      );
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {taskDashboard?.taskReoprnCount}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="count-cols-custom">
                              <div class="count-card">
                                <div class="count-card-body">
                                  <div className="count-title-loader-box">
                                    <h5 class="count-card-title">UAT</h5>
                                    {loading && <Spin />}
                                  </div>
                                  <p
                                    class="count-card-amount"
                                    onClick={() => {
                                      const UATTasks =
                                        taskDashboard?.TotalTasksData?.filter(
                                          (task) =>
                                            task?.Status == "READY FOR UAT"
                                        );
                                      showTodayTaskHoursModal(
                                        UATTasks,
                                        "Ready For UAT Task List"
                                      );
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {taskDashboard?.taskReadyForUATCount}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="count-cols-custom">
                              <div class="count-card">
                                <div class="count-card-body">
                                  <div className="count-title-loader-box">
                                    <h5 class="count-card-title">Closed</h5>
                                    {loading && <Spin />}
                                  </div>
                                  <p
                                    class="count-card-amount"
                                    onClick={() => {
                                      const ClosedTasks =
                                        taskDashboard?.TotalTasksData?.filter(
                                          (task) => task?.Status == "CLOSE"
                                        );
                                      showTodayTaskHoursModal(
                                        ClosedTasks,
                                        "Closed Task List"
                                      );
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {taskDashboard?.taskClosedCount}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* total due task data */}
                <div className="custom-demo-profile-my cutom-task-dashboard">
                  <div class="flex-count-details custom-flex-count-details">
                    <div class="flex-count-inner">
                      <div class="count-cols count-cols-first">
                        <div class="count-card">
                          <div class="count-card-body">
                            <div className="count-title-loader-box">
                              <h5 class="count-card-title">Total Due Task</h5>
                              {loading && <Spin />}
                            </div>
                            <p
                              class="count-card-amount"
                              onClick={() =>
                                showModal(taskDashboard?.DueTaskData)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {taskDashboard?.TotalDueTaskCount}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Next Day Allocated Task data */}
                <div className="custom-demo-profile-my cutom-task-dashboard">
                  <div className="custom-story-task">
                    <h3>Next Day Allocated Task</h3>
                  </div>
                  <div class="flex-count-details custom-flex-count-details">
                    <div class="flex-count-inner">
                      <div class="count-cols count-cols-first">
                        <div class="count-card">
                          <div class="count-card-body">
                            <div className="count-title-loader-box">
                              <h5 class="count-card-title">Total Developers</h5>
                              {loading && <Spin />}
                            </div>

                            <p
                              class="count-card-amount"
                              onClick={() =>
                                showAssignedTasksDevModal(
                                  taskDashboard?.NextDayPresentEmplData,
                                  "Total Developers Details"
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {taskDashboard?.NextDayPresentEmployeeCount}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="count-cols count-cols-first">
                        <div class="count-card">
                          <div class="count-card-body">
                            <div className="count-title-loader-box">
                              <h5 class="count-card-title">
                                Allocated Task To Developers
                              </h5>
                              {loading && <Spin />}
                            </div>
                            <p
                              class="count-card-amount"
                              onClick={() =>
                                showAssignedTasksDevModal(
                                  taskDashboard?.NextDayTaskEmployeesData,
                                  "Allocated Task To Developer Details"
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {taskDashboard?.NextDayTaskCount}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="count-cols count-cols-first">
                        <div class="count-card">
                          <div class="count-card-body">
                            <div className="count-title-loader-box">
                              <h5 class="count-card-title">
                                Vacant Developers
                              </h5>
                              {loading && <Spin />}
                            </div>
                            <p
                              class="count-card-amount"
                              onClick={() =>
                                showAssignedTasksDevModal(
                                  taskDashboard?.NextDayRemainingDeveloperData,
                                  "Vacant Developers Details"
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {taskDashboard?.NextDayRemainingDeveloper}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="count-cols count-cols-first">
                        <div class="count-card">
                          <div class="count-card-body">
                            <div className="count-title-loader-box">
                              <h5 class="count-card-title">
                                Task Allocated Hours
                              </h5>
                              {loading && <Spin />}
                            </div>
                            <p
                              class="count-card-amount"
                              onClick={() =>
                                showTodayTaskHoursModal(
                                  taskDashboard?.NextDayTasksData,
                                  "Next Day Task Hours Details"
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {`${taskDashboard?.TotalNextDayTaskHours}/${taskDashboard?.NextDayTaskAllocatedHours}`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Modal for Due Task Data */}
                <Modal
                  width={800}
                  className="myssss"
                  title="Due Task Details"
                  visible={isModalVisible}
                  onCancel={handleCancel}
                  footer={null}
                >
                  <div>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Developers Name</th>
                          <th>Project Name</th>
                          <th>Task Key</th>
                          <th>Due Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedTask
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          ?.map((task, index) => (
                            <tr key={index}>
                              <td>{`${task?.Employee?.First_name} ${task?.Employee?.Last_name}`}</td>
                              <td>{task?.Project?.Project_name}</td>
                              <td>{task?.Task_key}</td>
                              <td>
                                {moment(task?.Due_date).format("DD-MM-YYYY")}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <TablePagination
                      className="total-task-details-pag"
                      component="div"
                      count={selectedTask?.length}
                      page={page}
                      searchTop
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      rowsPerPageOptions={[30, 60, 90]}
                    />
                  </div>
                </Modal>

                {/* Modal for Total Developer Data */}
                {/* Modal for Allocated Task To Developers Data  and  Assigned Task To Developers Data*/}
                <Modal
                  width={800}
                  className="myssss"
                  title={modalTitle}
                  visible={isModalAssignedTasksDev}
                  onCancel={handleAssignedTasksDevCancel}
                  footer={null}
                >
                  <div>
                    <Input
                      placeholder="Search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      style={{ marginBottom: "10px", width: "200px" }}
                    />
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Developers Name</th>
                          <th>Department</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredAssignedTasksDev
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          ?.map((task, index) => (
                            <tr key={index}>
                              <td>{`${task?.First_name} ${task?.Last_name}`}</td>
                              <td>{task?.Role}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <TablePagination
                      className="total-task-details-pag"
                      component="div"
                      count={filteredAssignedTasksDev?.length}
                      page={page}
                      searchTop
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      rowsPerPageOptions={[30, 60, 90]}
                    />
                  </div>
                </Modal>

                {/* Modal for Today Task Hours Data */}
                {/* Modal for Today Total Task Data */}
                {/* Modal for Total Storys Data */}
                {/* Modal for Total Tasks Data */}
                <Modal
                  width={800}
                  className="myssss"
                  title={modalTitle}
                  visible={isModalTodayTaskHours}
                  onCancel={handleTodayTaskHoursCancel}
                  footer={null}
                >
                  <div>
                    <Input
                      placeholder="Search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      style={{ marginBottom: "10px", width: "200px" }}
                    />
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Developers Name</th>
                          <th>Project Name</th>
                          <th>Task Key</th>
                          <th>Task Duration</th>
                          <th>Assigned Date</th>
                          <th>Due Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredTasks
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          ?.map((entry, index) => (
                            <React.Fragment key={index}>
                              {entry.tasks.map((task, taskIndex) => (
                                <tr key={taskIndex}>
                                  {taskIndex === 0 && (
                                    <td rowSpan={entry?.tasks.length}>
                                      {`${entry?.employee?.First_name} ${entry?.employee?.Last_name}`}
                                    </td>
                                  )}
                                  <td>{task?.Project_name}</td>
                                  <td>
                                    <Link
                                      to={`/task/${task?._id}`}
                                      target="_blank"
                                    >
                                      {task?.Task_key}
                                    </Link>
                                  </td>
                                  <td>{task?.Task_duration}</td>
                                  <td>
                                    {task?.Assigned_date === "-"
                                      ? "-"
                                      : moment(task?.Assigned_date).format(
                                          "DD-MM-YYYY"
                                        )}
                                  </td>
                                  <td>
                                    {task?.Due_date === "-"
                                      ? "-"
                                      : moment(task?.Due_date).format(
                                          "DD-MM-YYYY"
                                        )}
                                  </td>
                                </tr>
                              ))}
                            </React.Fragment>
                          ))}
                      </tbody>
                    </table>
                    <TablePagination
                      className="total-task-details-pag"
                      component="div"
                      count={filteredTasks?.length}
                      page={page}
                      searchTop
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      rowsPerPageOptions={[30, 60, 90]}
                    />
                  </div>
                </Modal>

                {/* Modal for Total Project Data */}
                <Modal
                  width={800}
                  className="myssss"
                  title={modalTitle}
                  visible={isModalAllProject}
                  onCancel={handleAllProjectCancel}
                  footer={null}
                >
                  <div>
                    <Input
                      placeholder="Search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      style={{ marginBottom: "10px", width: "200px" }}
                    />
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Project Name</th>
                          <th>Status</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Created On</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredAllProjectData
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          ?.map((task, index) => (
                            <tr key={index}>
                              <td>{task?.Project_name}</td>
                              <td>{task?.Status}</td>
                              <td>
                                {task?.Project_start_date == "-"
                                  ? "-"
                                  : moment(task?.Project_start_date).format(
                                      "DD-MM-YYYY"
                                    )}
                              </td>
                              <td>
                                {task?.Project_due_date
                                  ? moment(task?.Project_due_date).format(
                                      "DD-MM-YYYY"
                                    )
                                  : "-"}
                              </td>
                              <td>
                                {task?.createdAt
                                  ? moment(task?.createdAt).format("DD-MM-YYYY")
                                  : "-"}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <TablePagination
                      className="total-task-details-pag"
                      component="div"
                      count={filteredAllProjectData?.length}
                      page={page}
                      searchTop
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      rowsPerPageOptions={[30, 60, 90]}
                    />
                  </div>
                </Modal>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default TaskDashboard;
