import React, { useState, useEffect } from "react";
import { Col, Row, Select } from "antd";
import DashboardBar from "../../../../components/common/DashboardBar";
import MilestoneDue from "./MilestoneDue";
import SprintDue from "./SprintDue";
import MyProject from "./MyProject";
import EmpLeave from "./EmpLeave";
import StoryDue from "./StoryDue";
import ProTaskUpdate from "./ProTaskUpdate";
import PmProjectList from "./PmProjectList";
import moment from "moment";
import AxiosInstance from "../../../../service/AxiosInstance";
import { MyprojectStatusBadge } from "../../../../components/common/Coomon";
import DueCount from "./DueCount";

//main component
const PmDashboard = (props) => {
  const [milestoneData, setMilestoneData] = useState([]);
  const [projectIds, setProjectIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState("");
  const [sortForDues, setSortForDues] = useState("All");
  const [dataLength, setDataLength] = useState(0);
  const [milestoneDataLength, setMilestoneDataLength] = useState(0);
  const [sprintDueDataLength, setSprintDueDataLength] = useState(0);
  const [allLengthData, setAllLengthData] = useState([]);
  const { Option } = Select;
  const GetMyProject = () => {
    setLoading(true);
    AxiosInstance.get("/auth/details-project").then((res) => {
      setLoading(false);
      let sortedList = res.data.data.sort(
        (a, b) => moment(a.Project_due_date) - moment(b.Project_due_date)
      );
      let list1 = sortedList.map((item, index) => {
        return {
          id: item._id,
          icon: index + 1,
          project_name: item?.Project_name,
          status: MyprojectStatusBadge(item?.Status),
          Tech_stack: item?.Tech_stack,
          dueDate:
            item?.Project_due_date == "-"
              ? "-"
              : moment(item?.Project_due_date).format("DD/MM/YYYY"),
          Date:
            item?.Project_start_date == "-"
              ? ""
              : `${moment(item?.Project_start_date).format(
                  "DD-MMM"
                )} - ${moment(item?.Project_due_date).format("DD-MMM")}`,
          Border_left: item?.Project_due_date ? item?.Project_due_date : "",
        };
      });
      setProjectIds(list1.map((item) => item.id));
      // all due's count basis
      GetAllDuesCount(list1.map((item) => item.id));
      setMilestoneData(list1);
    });
  };
  const GetAllDuesCount = (newProjectIds) => {
    const formData = new URLSearchParams();
    formData.append("id", JSON.stringify(newProjectIds));
    formData.append("type", sortForDues);

    AxiosInstance.post("/auth/dues-count", formData).then((res) => {
      let response = res?.data?.data;
      setCount(response);
      // setdata(response)
    });
  };

  useEffect(() => {
    GetMyProject();
    // GetAllDuesCount();
  }, [sortForDues]);
  useEffect(() => {
    if (milestoneDataLength && dataLength && sprintDueDataLength) {
      const temArray = [];
      temArray.push(milestoneDataLength);
      temArray.push(sprintDueDataLength);
      temArray.push(dataLength);
      setAllLengthData(temArray);
    }
  }, [milestoneDataLength, dataLength, sprintDueDataLength]);

  return (
    <>
      <div className="event-main">
        <DashboardBar />
        <div className="page-content">
          <Row className="mx-0 mydashboard-main">
            <Col className="col" xs={24} sm={24} md={24} lg={24} xl={24}>
              <div>
                <div className="pm-flex-drop">
                  <div className="left-head-title" style={{ margin: "0" }}>
                    PM / TPM Dashboard
                  </div>
                  <div className="flex-project-activity">
                    <>
                      <div className="project-title-name ">
                        <h6>Select Type :</h6>
                      </div>
                      <div className="milestone-dropdown">
                        <Select
                          defaultValue={sortForDues}
                          className="select-dropdown leave-4"
                          onChange={(e) => {
                            setSortForDues(e);
                          }}
                        >
                          <Option value="All">All</Option>
                          <Option value="Dues">Dues</Option>
                        </Select>
                      </div>
                    </>
                    <></>
                  </div>
                </div>
                <div className="pm-dash-main-cards">
                  <div className="pm-dash-left">
                    <DueCount count={count} allLengthData={allLengthData} />
                    <div className="pm-dashboard-details">
                      <MyProject
                        loading={loading}
                        milestoneData={milestoneData}
                      />
                      <MilestoneDue
                        loading={loading}
                        projectIds={projectIds}
                        setLoading={setLoading}
                        sortForDues={sortForDues}
                        milestoneDataLength={milestoneDataLength}
                        setMilestoneDataLength={setMilestoneDataLength}
                      />
                      <SprintDue
                        loading={loading}
                        projectIds={projectIds}
                        setLoading={setLoading}
                        sortForDues={sortForDues}
                        setSprintDueDataLength={setSprintDueDataLength}
                      />
                      {/* <ProjectGraph /> */}
                      <StoryDue
                        loading={loading}
                        projectIds={projectIds}
                        setLoading={setLoading}
                        sortForDues={sortForDues}
                        setDataLength={setDataLength}
                        dataLength={dataLength}
                      />
                      <EmpLeave
                        loading={loading}
                        projectIds={projectIds}
                        setLoading={setLoading}
                      />
                    </div>
                    {/* <PmProjectList /> */}
                  </div>
                  <div className="pm-dash-right">
                    <ProTaskUpdate
                      loading={loading}
                      projectIds={projectIds}
                      setLoading={setLoading}
                      sortForDues={sortForDues}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default PmDashboard;
