import archive from "../assets/images/archive.svg";
import unarchive from "../assets/images/unarchive.svg";
import arrow_right_btn from "../assets/images/next-my-btn-arrow.svg";
const Svg = {
  archive,
  unarchive,
  arrow_right_btn,
};

export { Svg };
