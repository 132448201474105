import logo2 from "../assets/images/logo2.png";
// import logo3 from '../assets/images/logo3.png';
import SuggestionIcon from "../assets/images/suggestion.png";
import HolidayIcon from "../assets/images/HolidaysCalendar.png";
import HRExec from "../assets/images/HR_Handbook/HRExec.png";
import CTO from "../assets/images/HR_Handbook/CTO.png";
import MD from "../assets/images/HR_Handbook/MD.png";
import CPO from "../assets/images/HR_Handbook/CPO.png";
import filter from "../assets/images/filter-my.png";
import status from "../assets/images/status.png";
import task from "../assets/images/task.png";
import issue from "../assets/images/issue.png";
import alarm from "../assets/images/alarm.png";

import filterIcon from "../assets/images/filter.png";
import search from "../assets/images/search-my.png";
import crossIcon from "../assets/images/close.png";
import ArrowLeft from "../assets/images/arrow-left.png";
import Trash from "../assets/images/trash.png";
import Share from "../assets/images/share.png";
import Archive from "../assets/images/archive.png";
import exportIcon from "../assets/images/exportIconFile.png";
import refresh_icon from '../assets/images/refresh-icon.png'

const Png = {
  refresh_icon,
  Archive,
  Share,
  Trash,
  ArrowLeft,
  logo2,
  SuggestionIcon,
  HolidayIcon,
  HRExec,
  CTO,
  exportIcon,
  MD,
  CPO,
  filter,
  status,
  task,
  issue,
  alarm,

  filterIcon,
  search,
  crossIcon,
};

export { Png };
