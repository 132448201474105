import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import AxiosInstance from "../../../service/AxiosInstance";

import { MDBDataTableV5, MDBCard } from "mdbreact";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import moment from "moment";
import PmsRangePicker from "../me/leaveModule/RangePicker";
import { Spin } from "antd";
import ReactLoader from "../../../components/common/ReactLoader";

const TaskTimerReport = () => {
  const [takstimerList, settakstimerList] = useState([]);
  const [datatable, setDatatable] = useState({});
  const [result, setResult] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [loading, setLoading] = useState(false);

  const getTaskTimerList = () => {
    setLoading(true);
    AxiosInstance.get(`auth/tasks/tasktimer/report`).then((res) => {
      setResult(res.data.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getTaskTimerList();
  }, []);

  const getTaskTimmerReport = () => {
    let filterData = result;
    if (dateRange.length > 1) {
      filterData = filterData.filter((item, index) => {
        let checkInDate = moment(item?.Check_In).format("DD-MM-YYYY");
        let checkOutDate = moment(item?.Check_Out).format("DD-MM-YYYY");
        return checkInDate >= dateRange[0] && checkOutDate <= dateRange[1];
      });
    }

    let list1 = filterData.map((item, index) => {
      return {
        icon: index + 1,
        Emplopyee_name:
          item?.Employee_id?.First_name + " " + item?.Employee_id?.Last_name,
        Project_name: item?.Project_id?.Project_name,
        Short_summary: item?.Task_id?.Short_summary,
        Check_In: item?.Check_In
          ? moment(item?.Check_In).format("DD/MM/YYYY hh:mm:ss A")
          : "",
        Check_Out: item?.Check_Out
          ? moment(item?.Check_Out).format("DD/MM/YYYY hh:mm:ss A")
          : "",
        Status: item?.Status,
      };
    });

    settakstimerList(list1);
  };
  useEffect(() => {
    getTaskTimmerReport();
  }, [result, dateRange]);

  useEffect(() => {
    setDatatable({
      columns: [
        {
          label: "No",
          field: "icon",
          sort: "disabled",
          width: 10,
        },
        {
          label: "Employee Name",
          field: "Emplopyee_name",
          width: 150,
          sort: "disabled",
        },

        {
          label: "Project Name",
          field: "Project_name",
          width: 150,
          sort: "disabled",
        },
        {
          label: "Task Short Summry",
          field: "Short_summary",
          width: 150,
          sort: "disabled",
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Description",
          },
        },

        {
          label: "Check In",
          field: "Check_In",
          width: 270,
          sort: "disabled",
        },
        {
          label: "Check Out",
          field: "Check_Out",
          width: 270,
          sort: "disabled",
        },

        {
          label: "Status",
          field: "Status",
          width: 200,
          sort: "disabled",
        },
      ],
      rows: takstimerList,
    });
  }, [takstimerList]);

  const handleRangePickerChange = (value, dateString) => {
    if (value === null) {
      setDateRange("");
      getTaskTimmerReport();
    } else {
      setDateRange(dateString);
    }
  };

  return (
    <>
      <div className="demo">
        <MDBCard className="page-content">
          <div className="create-project">
            <MDBCol
              // style={{ marginLeft: '7px' }}
              size="md"
              className="col-example  titel-set"
            >
              {" "}
              <>
                <div className="leave-set-btn2">
                  <MDBCol size="md" className="col-example">
                    <h3 className="tablHeader">Task Timer Report</h3>
                  </MDBCol>
                </div>
              </>
            </MDBCol>

            {!loading && <MDBCol>
              <PmsRangePicker
                className="tasktimer-range-picker"
                format="DD-MM-YYYY"
                onChange={handleRangePickerChange}
              />
            </MDBCol>}
          </div>

          <div className="scrool-set">
            {loading ? (
              <ReactLoader loading={loading} />
            ) : (
              <MDBDataTableV5
                hover
                entriesOptions={[10, 20, 25]}
                entries={10}
                pagesAmount={4}
                data={datatable}
                searchTop
                searchBottom={false}
              />
            )}
          </div>
        </MDBCard>
      </div>
    </>
  );
};

export default TaskTimerReport;
