import React, { useState } from 'react'
import { Card, Drawer, Timeline, Avatar, Comment, Tooltip, Badge } from 'antd'
// import { LeaveDates } from '../../../components/common/Coomon'
import moment from 'moment'

const ManualAttendanceLogs = (props) => {
  const { leave, visible, logs } = props
   const color = leave.status == 'Approved' ? 'green' : 'gray'

  return (
    <>
      <Drawer
        title="Attendance logs"
        placement="right"
        onClose={props.onClose} 
        visible={visible}
      >
       <Badge.Ribbon text={leave.status} color={color}>
          <Card
            size="small"
            title={
                leave?.employee_id?.First_name +
              ' ' +
              leave?.employee_id?.Last_name
            }
            style={{ width: 300 }}
          >
            {/* {<LeaveDates items={leave} />} */}
            {/* <p>
              Unpaid{' '}
              <span className="info">
                Requested on{' '}
                {moment(leave?.Create_at).format('MMM DD, YYYY HH:mm a')}
              </span>
            </p> */}
            <p>{leave.status_comment}</p>
            {/* <p>Card content</p> */}
           </Card>
        </Badge.Ribbon> 
        <Timeline>
          {logs.map((log) => (
            <Timeline.Item>
              <Comment
                author={
                  <a>
                    {log.Created_by?.First_name} {log.Created_by?.Last_name}
                  </a>
                }
                avatar={
                  <Avatar
                    src="https://joeschmoe.io/api/v1/random"
                    alt= {log.Created_by?.First_name +' '+log.Created_by?.Last_name}
                  />
                }
                content={
                  <p>
                    <b>{log.Action_Log}</b>
                    <br />
                    {log.Comment}
                  </p>
                }
                datetime={
                  <Tooltip
                    title={moment(log.Create_at).format('YYYY-MM-DD HH:mm:ss')}
                  >
                    <span>{moment(log.Create_at).fromNow()}</span>
                  </Tooltip>
                }
              />
            </Timeline.Item>
          ))}
        </Timeline>
      </Drawer>
    </>
  )
}
export default ManualAttendanceLogs
