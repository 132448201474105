import React, { useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Row,
  Select,
  TimePicker,
  message,
} from "antd";
import AxiosInstance from "../../../service/AxiosInstance";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { useEffect } from "react";
import TextArea from "antd/lib/input/TextArea";
import { useLocation } from "react-router-dom";

const ManualRequestAttendance = (props) => {
  const location = useLocation();
  const [isRequestOpen, setIsRequestOpen] = useState(false);
  const [attendanceDate, setAttendanceDate] = useState();
  const [attendanceCheckoutTime, setAttendanceCheckOutTime] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();
  const [selectReason, setSelectReason] = useState();
  const [checkTime, setCheckTime] = useState();
  //add new
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const [leaveDates, setLeaveDates] = useState();

  const { Option } = Select;

  const { items, attendanceList } = props;
  const isDisableAttendanceAction = localStorage.getItem(
    "isDisableAttendanceAction"
  );
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const [getManualAttendance, setRequestManualAttendance] = useState([]);
  //add new
  const [emergencyLeaveReason, setEmergencyLeaveReason] = useState([]);
  const [secondHalfApproveOpen, setSecondHalfApproveOpen] = useState(false);
  const [secondHalfLeaveReqReason, setSecondHalfLeaveReqReason] =
    useState(false);

  const handleSecondHalfOpen = () => setSecondHalfApproveOpen(true);
  const handleSecondHalfClose = () => setSecondHalfApproveOpen(false);
  //new
  // const onEmergencyFinish = (value) => {
  //   var date2 = new Date();
  //   let fomatedDate = moment(date2).format("MMM DD YYYY hh:mm a");
  //   // const startDate = leaveDates[0].format("YYYY-MM-DD");
  //   // const endDate = leaveDates[1].format("YYYY-MM-DD");

  //   // const startDateboth = leaveDates?.[0]
  //   // const endDateboth = leaveDates?.[1]
  //   // const data =[startDateboth,endDateboth]

  //   const Urlencoded = new URLSearchParams();
  //   Urlencoded.append("Email", userData?.[0]?.Email);
  //   const datelist = [props.date, props.date];
  //   Urlencoded.append("Leave_Date_to_Date", datelist);
  //   Urlencoded.append("StartDate", props.date);
  //   Urlencoded.append("EndDate", props.date);
  //   Urlencoded.append("Create_at", fomatedDate);
  //   Urlencoded.append("Leave_type", "Exceptional Leave"); //change on 02/07/2024
  //   Urlencoded.append("Status", "Pending");
  //   Urlencoded.append("Leave_half_from", "First Half");
  //   Urlencoded.append("Leave_half_to", "Second Half");

  //   Urlencoded.append("Employee_ID", userData?.[0]?._id);
  //   Urlencoded.append("Reason", emergencyLeaveReason);
  //   AxiosInstance.post("auth/leave/add", Urlencoded)
  //     .then((res) => {
  //       if(res?.data?.status == 400){
  //         toast.error(res?.data?.message);
  //         message.error(res?.data?.message);
  //       }else{
  //         toast.success("Request created successfully");
  //         message.success("Request created successfully");
  //       }
  //       setEmergencyLeaveReason("")
  //       setIsSubmitting(false);
  //     })
  //     .catch((error) => {
  //       setEmergencyLeaveReason("")
  //       toast.error(error?.response?.data)
  //       message.error(error?.response?.data)
  //       setIsSubmitting(false);
  //     });
  //   setIsSecondModalOpen(false);
  // };
  //add new 11-01-2024

  // new 28-02-2024
  const onEmergencyFinish = (value) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("date", props.date);
    urlencoded.append("type", "Exceptional");
    urlencoded.append("time", moment());
    urlencoded.append("reason", emergencyLeaveReason);
    urlencoded.append("Employee_id", userData?.[0]?._id);
    urlencoded.append("reqType", "Exceptional");

    AxiosInstance.post("auth/addManualAttendanceRequest", urlencoded)
      .then((res) => {
        if (res?.data?.status == 400) {
          // toast.error(res?.data?.message);
          message.error(res?.data?.message);
        } else {
          // toast.success("Request created successfully");
          message.success("Request created successfully");
        }
        setEmergencyLeaveReason("");
        setIsSubmitting(false);
      })
      .catch((error) => {
        setEmergencyLeaveReason("");
        // toast.error(error?.response?.data);
        message.error(error?.response?.data);
        setIsSubmitting(false);
      });
    setIsSecondModalOpen(false);
  };

  const fullname = userData[0].First_name + " " + userData[0].Last_name;

  const handleClick = (items, key) => {
    if (key == "request") {
      setIsRequestOpen(true);
      setAttendanceDate(items?.date);
      form.setFieldValue("type", "checkOut");
    }
    if (key == "second half Approve") {
      setSecondHalfApproveOpen(true);
      setAttendanceDate(items?.date);
      setAttendanceCheckOutTime(items?.checkOut);
    }
  };
  const emergencyhandleClick = (items, key) => {
    if (key == "request emergency") {
      setIsSecondModalOpen(true);
    }
  };
  // const handleSecondModalOpen = () => {
  //   setIsSecondModalOpen(true);
  // };

  // const handleSecondModalCancel = () => {
  //   setIsSecondModalOpen(false);
  // };
  const calculateBusinessDays = (start, end) => {
    const startDate = moment(start);
    const endDate = moment(end);

    let currentDate = moment(startDate);
    let businessDays = 0;

    while (currentDate.isSameOrBefore(endDate)) {
      const dayOfWeek = currentDate.day();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        // Check if the current day is not Sunday (0) or Saturday (6)
        businessDays++;
      }
      currentDate.add(1, "days");
    }

    return businessDays;
  };

  const actionMenu = (items, listAction) => {
    let lastIssue = [];
    let show = false;

    const idealCheckInTime = moment("10:30 AM ", "hh:mm A");
    const idealCheckOutTime = moment("07:15 PM", "hh:mm A");
    lastIssue = attendanceList.filter((attendance, index) => {
      const checkInTimeConvert = moment(attendance.checkIn).format("hh:mm A");
      const checkOutTimeConvert = moment(attendance.checkOut).format("hh:mm A");
      const checkInTime = moment(checkInTimeConvert, "hh:mm A");
      const checkOutTime = moment(checkOutTimeConvert, "hh:mm A");

      if (attendance.checkIn == "" && attendance.checkIn == "") {
        return attendance;
      }

      if (checkInTime && checkOutTime) {
        if (
          checkInTime.isAfter(idealCheckInTime) ||
          checkOutTime.isBefore(idealCheckOutTime)
        ) {
          return attendance;
        }
      }

      if (
        (attendance.checkIn == "" && attendance.checkIn != "") ||
        (attendance.checkIn != "" && attendance.checkIn == "")
      ) {
        return attendance;
      }
      //New 03-01-2024
      return attendance;
      //end
      let show = true;
      if (lastIssue?.length > 0) {
        lastIssue?.map((lastIssue) => {
          if (listAction[0].items.date == lastIssue.date) {
            if (lastIssue.weekOff == true) {
              return (show = true);
            }

            if (lastIssue.isRequested == true) {
              return (show = true);
            }

            return (show = false);
          }

          // REGION END NEW BY DURGESH
        });
      }
      //end
    });

    //based on checkIn and CheckOut value enable and disable button of request for change time.
    //New 03-01-2024

    if (lastIssue?.length > 0) {
      lastIssue?.map((lastIssue) => {
        if (listAction[0].items.date == lastIssue.date) {
          if (lastIssue.checkIn == "" || lastIssue.checkOut == "") {
            show = false;
          }
          if (lastIssue.checkOut && lastIssue.checkIn) {
            show = true;
          }
          if (lastIssue.checkIn && lastIssue.checkOut == "") {
            show = false;
          }
          if (lastIssue.checkOut && lastIssue.checkIn == "") {
            show = false;
          }

          if (lastIssue.weekOff == true) {
            show = true;
          }
          // New End.
        }

        const businessDaysDiff = calculateBusinessDays(
          moment(lastIssue.date).format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD")
        );

        if (businessDaysDiff > 4) {
          show = true;
        } else {
          show = false;
        }
      });
    }

    // console.log(
    //   "items?.present_second_half",
    //   items?.present_first_half && items?.present_second_half
    // );
    // let show = false;
    // if (items?.present_first_half && items?.present_second_half) {
    //   show = true;
    // }
    let modifiedAction = [];
    if (items?.present_first_half && items?.checkOut) {
      modifiedAction = listAction?.filter((item) => item?.key !== "request"); //for second half
    } else if (
      items?.checkIn &&
      items?.present_first_half &&
      !items?.checkOut &&
      items?.present_second_half
    ) {
      modifiedAction = listAction?.filter((item) => item?.key == "request");
    }

    return (
      <>
        <Menu
          items={modifiedAction}
          onClick={(e) => handleClick(items, e.key)}
          disabled={show}
        />
      </>
    );
  };

  let listAction = [
    {
      key: "request",
      label: "Request for change time",
      items: items,
    },
    {
      key: "second half Approve",
      label: "Request for Second Half",
      items: items,
    },
  ];

  const EmergencylistAction = (items, emergency) => {
    let lastIssue = [];
    const idealCheckInTime = moment("10:30 AM ", "hh:mm A");
    const idealCheckOutTime = moment("07:15 PM", "hh:mm A");
    lastIssue = attendanceList.filter((attendance, index) => {
      const checkInTimeConvert = moment(attendance.checkIn).format("hh:mm A");
      const checkOutTimeConvert = moment(attendance.checkOut).format("hh:mm A");
      const checkInTime = moment(checkInTimeConvert, "hh:mm A");
      const checkOutTime = moment(checkOutTimeConvert, "hh:mm A");

      if (attendance.checkIn == "" && attendance.checkIn == "") {
        return attendance;
      }

      if (checkInTime && checkOutTime) {
        if (
          checkInTime.isAfter(idealCheckInTime) ||
          checkOutTime.isBefore(idealCheckOutTime)
        ) {
          return attendance;
        }
      }

      if (
        (attendance.checkIn == "" && attendance.checkIn != "") ||
        (attendance.checkIn != "" && attendance.checkIn == "")
      ) {
        return attendance;
      }
      //New 03-01-2024
      return attendance;
      //end
      let show = true;
      if (lastIssue?.length > 0) {
        lastIssue?.map((lastIssue) => {
          if (listAction[0].items.date == lastIssue.date) {
            if (lastIssue.weekOff == true) {
              return (show = true);
            }

            if (lastIssue.isRequested == true) {
              return (show = true);
            }

            return (show = false);
          }

          // REGION END NEW BY DURGESH
        });
      }
      //end
    });

    //based on checkIn and CheckOut value enable and disable button of request for change time.
    //New 03-01-2024

    let show;
    if (lastIssue?.length > 0) {
      lastIssue?.map((lastIssue) => {
        if (listAction[0].items.date == lastIssue.date) {
          //New
          if (lastIssue.checkIn == "" || lastIssue.checkOut == "") {
            show = false;
          }
          if (lastIssue.checkIn && lastIssue.checkOut == "") {
            show = false;
          }
          if (lastIssue.checkOut && lastIssue.checkIn == "") {
            show = false;
          }
          if (lastIssue.checkOut && lastIssue.checkIn) {
            show = true;
          }
          if (lastIssue.weekOff == true) {
            show = true;
          }
          // New End.
        }

        const businessDaysDiff = calculateBusinessDays(
          moment(lastIssue.date).format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD")
        );

        if (businessDaysDiff > 4) {
          show = true;
        }
      });
    }
    return (
      <>
        <Menu
          items={emergency}
          onClick={(e) => emergencyhandleClick(items, e.key)}
          disabled={show}
        />
      </>
    );
  };

  let emergency = [
    {
      key: "request emergency",
      label: "Exceptional Leave",
      items: items,
    },
  ];
  const handleCancel = (errorInfo) => {
    form.resetFields();
    setIsRequestOpen(false);
  };
  const emergencyhandleCancel = (errorInfo) => {
    form.resetFields();
    setIsSecondModalOpen(false);
    setEmergencyLeaveReason("");
    setLeaveDates("");
  };

  useEffect(() => {
    if (attendanceDate && checkTime) {
      getManualAttendanceDataList();
    }
  }, [attendanceDate, checkTime]);

  useEffect(() => {
    if (getManualAttendance.length) {
      let newFilterData = getManualAttendance.filter((items) => {
        let modiFyDate = items.date.split("T")[0];

        return modiFyDate === attendanceDate && items.type === checkTime;
      });
      if (newFilterData.length) {
        setIsSubmitting(true);
      } else {
        setIsSubmitting(false);
      }
    }
  }, [getManualAttendance]);

  function getManualAttendanceDataList() {
    let getManulAttendance = {
      date: attendanceDate,
      employee_id: userData?.[0]?._id,
    };
    try {
      AxiosInstance.get(`auth/getAllMaunualAttendanceRequestDate`, {
        params: { getManulAttendance },
      }).then((res) => {
        setRequestManualAttendance(res.data.data);
      });
    } catch (error) {
      console.log(error);
    }
  }

  const onFinish = (values) => {
    setIsSubmitting(true);

    let dateselected = values?.date;
    let selectedTime = values?.time;
    selectedTime.date(dateselected.date());
    selectedTime.month(dateselected.month());
    selectedTime.year(dateselected.year());

    const urlencoded = new URLSearchParams();
    urlencoded.append("date", moment(values?.date).format("YYYY-MM-DD"));
    urlencoded.append("type", values?.type);
    urlencoded.append("time", selectedTime);
    urlencoded.append("reason", selectReason);
    urlencoded.append("Employee_id", props?.employee_id);
    urlencoded.append("reqType", "checkOut");

    const URlencoded = new URLSearchParams();
    URlencoded.append("date", moment(values?.date).format("YYYY-MM-DD"));
    URlencoded.append("Employee_ID", userData?.[0]?.employee_id);

    if (selectReason === "Emergency Leave") {
      const Urlencoded = new URLSearchParams();
      Urlencoded.append("Email", userData?.[0]?.Email);
      Urlencoded.append("Leave_Date_to_Date", items.date);
      Urlencoded.append("StartDate", items.date);
      Urlencoded.append("EndDate", items.date);
      Urlencoded.append("Create_at", items.date);
      Urlencoded.append("Leave_type", selectReason);
      Urlencoded.append("Status", "Pending");
      Urlencoded.append("Employee_ID", userData?.[0]?.employee_id);
      Urlencoded.append("Emergency_Reson", emergencyLeaveReason);
      AxiosInstance.post("auth/leave/add", Urlencoded)
        .then((res) => {
          setIsRequestOpen(false);
          // toast.success("Request created successfully");
          message.success("Request created successfully");

          setIsSubmitting(false);
        })
        .catch((error) => {
          console.error("Error creating request:", error);

          setIsSubmitting(false);
        });
    }
    AxiosInstance.post("auth/addManualAttendanceRequest", urlencoded)
      .then((res) => {
        getManualAttendanceDataList();

        setIsRequestOpen(false);
        form.resetFields();
        // localStorage.setItem("isDisableAttendanceAction" , true)
        // toast.success("Request created successfully");
        message.success("Request created successfully");
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.error("Error creating request:", error);
        // toast.error(error.response.data);
        message.error(error.response.data);
        setIsSubmitting(false);
        setIsRequestOpen(false);
      });
  };

  let data = moment(items?.date).format("MMMM Do YYYY, h:mm:ss a");

  // useEffect(() => {
  //   form.setFieldsValue({
  //     date:
  //       attendanceDate &&
  //       moment(moment(attendanceDate).format("DD/MM/YYYY"), "DD/MM/YYYY"),
  //   });
  // }, [isRequestOpen]);
  // useEffect(() => {
  //   form.setFieldsValue({
  //     date:
  //       attendanceDate &&
  //       moment(moment(attendanceDate).format("DD/MM/YYYY"), "DD/MM/YYYY"),
  //   });
  // }, [isSecondModalOpen]);

  //New 19-02-2024
  const handleSubmitSeconfHalfApprovRequest = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("date", moment(attendanceDate).format("YYYY-MM-DD"));
    urlencoded.append("type", "checkOut");
    urlencoded.append("time", attendanceCheckoutTime);
    urlencoded.append("reason", secondHalfLeaveReqReason);
    urlencoded.append("Employee_id", props?.employee_id);
    urlencoded.append("reqType", "secondHalf");
    AxiosInstance.post("auth/addManualAttendanceRequest", urlencoded)
      .then((res) => {
        getManualAttendanceDataList();
        handleSecondHalfClose();
        form.resetFields();
        // toast.success("Request created successfully");
        message.success("Request created successfully");
        localStorage.setItem("isDisableAttendanceAction", true);
      })
      .catch((error) => {
        // toast.error(error.response.data);
        message.error(error.response.data);
        console.error("Error creating request:", error);
        handleSecondHalfClose();
      });
  };

  return (
    <>
      {!items.leave &&
      !items.present_first_half &&
      !items.present_second_half &&
      !items?.checkIn &&
      !items?.checkOut &&
      !items?.weekOff &&
      location?.pathname == "/myattendance" ? (
        <Dropdown.Button
          overlay={EmergencylistAction(items, emergency)}
          // menu={EmergencylistAction(items, emergency)}
        ></Dropdown.Button>
      ) : (
        ((items?.checkIn &&
          items.present_first_half &&
          items.present_second_half &&
          !items?.checkOut) ||
          (items?.checkIn &&
            items.present_first_half &&
            !items.present_second_half)) &&
        location?.pathname == "/myattendance" &&
        !items?.weekOff &&
        !items?.leave && (
          <Dropdown.Button
            overlay={actionMenu(items, listAction)}
            // menu={actionMenu(items, listAction)}
          ></Dropdown.Button>
        )
      )}
      <Modal
        title="Manual Attendance Request"
        centered
        // visible={isRequestOpen}
        open={isRequestOpen}
        //onOk={() => setIsOpenDeleteModal(false)}
        onCancel={handleCancel}
        width={410}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          // name="control-hooks"
          onFinish={onFinish}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="Date"
                name="date"
                rules={[
                  {
                    required: true,
                    message: "Please Select Date",
                  },
                ]}
              >
                <DatePicker format={"DD/MM/YYYY"} disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24} style={{ paddingRight: "12px" }}>
              <Form.Item
                label="Type"
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Please Select type",
                  },
                ]}
              >
                <Select
                  onChange={(e) => {
                    setCheckTime(e);
                  }}
                  defaultValue={"checkOut"}
                >
                  <Select.Option value="checkOut">Check Out</Select.Option>
                  {/* {location?.pathname == "/report/attendance-summary" ? (
                    <Select.Option value="checkOut">Check Out</Select.Option>
                  ) : (
                    <>
                      <Select.Option value="checkIn">Check In</Select.Option>
                      <Select.Option value="checkOut">Check Out</Select.Option>
                    </>
                  )} */}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24} style={{ paddingRight: "12px" }}>
              <Form.Item
                label="Time"
                name="time"
                rules={[
                  {
                    required: true,
                    message: "Please Select time",
                  },
                ]}
              >
                <TimePicker />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24} style={{ paddingRight: "12px" }}>
              <Form.Item
                label="Reason"
                name="reason"
                rules={[
                  {
                    required: true,
                    message: "Please Select Reason",
                  },
                ]}
              >
                <Select
                  onChange={(e) => {
                    setSelectReason(e);
                  }}
                >
                  <Option value="technical issue">Technical Issue</Option>
                  <Option value="Health Issue">Health Issue</Option>
                  <Option value="Vehicle Issue">Vehicle Issue</Option>
                  <Option value="Cricket Tournament">Cricket Tournament</Option>
                  <Option value="Client Meeting">Client Meeting</Option>
                  <Option value="Joining Formalities">
                    Joining Formalities
                  </Option>
                  <Option value="Early IN/OUT">Early IN/OUT</Option>
                  {/* <Option value="WFH">WFH</Option> */}
                  {/* <Option value="Emergency Leave">Emergency leave</Option> */}
                </Select>
                {/* <Input.TextArea rows={5} maxLength={500} /> */}
              </Form.Item>
              {/* {selectReason === "Emergency Leave" && (
                <Form.Item
                  label="Emergency Leave Reason"
                  name="emergencyLeaveReason"
                  rules={[
                    {
                      required: true,
                      message: "Please provide Emergency Leave reason",
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={5}
                    maxLength={500}
                    value={emergencyLeaveReason}
                    onChange={(e) => setEmergencyLeaveReason(e.target.value)}
                  />
                </Form.Item>
              )} */}
            </Col>
          </Row>

          <Form.Item>
            <div style={{ textAlign: "end", marginRight: "10px" }}>
              <Button
                className="pms-same-btn"
                type="primary"
                htmlType="submit"
                // onClick={chackDescription}
                style={{ marginRight: "10px" }}
                disabled={isSubmitting}
              >
                Submit
              </Button>
              <Button onClick={handleCancel} className="pms-same-btn-cancel">
                Cancel
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <div>
        <Modal
          title="Exceptional Leave Form"
          centered
          // visible={isSecondModalOpen}
          open={isSecondModalOpen}
          onCancel={emergencyhandleCancel}
          width={410}
          footer={null}
        >
          <Form form={form} onFinish={onEmergencyFinish}>
            <div className="input-box-main">
              <label>Employee Name</label>
              <Input value={fullname} style={{ marginTop: "2px" }} />
            </div>

            <div className="input-box-main">
              <label>Exceptional Leave Reason</label>
              <div
                className="text-area leave-rows"
                name="reason"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Reason!",
                  },
                ]}
              >
                <TextArea
                  rows={3}
                  value={emergencyLeaveReason}
                  onChange={(e) => setEmergencyLeaveReason(e.target.value)}
                />
              </div>
            </div>

            <Form.Item>
              <div style={{ textAlign: "end" }}>
                <Button
                  className="pms-same-btn"
                  type="primary"
                  htmlType="submit"
                  // onClick={chackDescription}
                  style={{ marginRight: "15px" }}
                >
                  Submit
                </Button>
                <Button
                  onClick={emergencyhandleCancel}
                  className="pms-same-btn-cancel"
                >
                  Cancel
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      </div>
      <div>
        <Modal
          title="Second Half Approve"
          centered
          // visible={secondHalfApproveOpen}
          open={secondHalfApproveOpen}
          onCancel={handleSecondHalfClose}
          width={410}
          footer={null}
        >
          <Form form={form} onFinish={handleSubmitSeconfHalfApprovRequest}>
            <div className="input-box-main">
              <label>Reason</label>
              <div
                className="text-area leave-rows"
                name="reason"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Reason!",
                  },
                ]}
              >
                <TextArea
                  rows={3}
                  onChange={(e) => setSecondHalfLeaveReqReason(e.target.value)}
                />
              </div>
            </div>

            <Form.Item>
              <div style={{ textAlign: "end" }}>
                <Button
                  className="pms-same-btn"
                  type="primary"
                  htmlType="submit"
                  // onClick={chackDescription}
                  style={{ marginRight: "15px" }}
                >
                  Submit
                </Button>
                <Button
                  onClick={handleSecondHalfClose}
                  className="pms-same-btn-cancel"
                >
                  Cancel
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default ManualRequestAttendance;
