import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { MDBNavbarItem, MDBNavbarLink, MDBNavbarNav } from "mdb-react-ui-kit";
import { Link, useParams } from "react-router-dom";
const EmployeeTab = (props) => {
  const params = useParams();
  return (
    <>
      <div className="main-basic-details">
        <MDBNavbarNav className="page-menu-nav custom-page-menu-nav">
          <MDBNavbarItem>
            <Link to={`/employeeDetail/${params?.id}`} active>
              {" "}
              {/* <MDBNavbarLink active aria-current="page" href="#"> */}
              <h4>
                <strong>Basic Details</strong>
              </h4>
              {/* </MDBNavbarLink> */}
            </Link>
          </MDBNavbarItem>
        </MDBNavbarNav>
      </div>
    </>
  );
};

export default EmployeeTab;
