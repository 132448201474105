import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Upload,
  Typography,
  message,
} from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import PmsDatePicker from "../me/leaveModule/PmsDatePicker";
import { DeleteFilled } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";
import Instance from "../../../service/AxiosInstance";
import moment from "moment";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Box, Button as Buttonss, IconButton } from "@mui/material";

import { MDBIcon } from "mdb-react-ui-kit";
import { useLocation } from "react-router-dom";
import { Png } from "../../../assets/png";

let date = new Date();
// main component

//getting loggedin user data

let userData = JSON.parse(localStorage.getItem("userdata"));
let PID = localStorage.getItem("PID");

const repoter_name = userData?.[0]?.First_name + " " + userData?.[0].Last_name;

//getting project id

const Add = ({
  setFlage,
  setFlage1,
  btnStyl,
  projectDetail,
  list,
  inSidebar,
  setTaskAddIsShow,
  taskList,
  updateList,
}) => {
  const params = useParams();
  const location = useLocation();

  //getting perticular project tasks list
  const [tasksList, setTasksList] = useState([]);

  // useEffect(() => {
  const GetprojectTaskList = () => {
    Instance.get(`auth/projecttasks/list/${projectDetail._id}`).then((res) => {
      setTasksList(res.data.data);
    });
  };
  // }, [])
  useEffect(() => {
    GetprojectTaskList();
  }, []);

  const toasterMessageFunction = (data) => {
    toast.success(data, {
      toastId: "taskCreated",
    });
  };
  const fileHandler = (e) => {
    Object.values(e).map((file) => {
      setFile((prev) => [...prev, file]);
      setUrl((prev) => [...prev, file.name]);
    });
  };

  const [form] = Form.useForm();
  const [url, setUrl] = useState([]);
  const [file, setFile] = useState([]);

  const [projectList, setProjectList] = useState();
  const [count, setCount] = useState(1);
  const [visible, setVisible] = useState(true);
  const [textEditer, setTextEditer] = useState("");
  const [editorHeight, setEditorHeight] = useState(300); 
  const [stepsEditer, setStepsEditer] = useState("");
  const [stepsError, setStepsError] = useState("");
  const [sprintdata, setSprintdata] = useState([]);
  const [ProjectIDeOnChange, setProjectIDeOnChange] = useState("");
  const [reporterList, setReporterList] = useState([]);
  const [assigneesList, setAssigneesList] = useState([]);
  const [selectedMember, setSelectedMember] = useState("");
  const [selectedMemberId, setSelectedMemberId] = useState("");
  const [isueType, setIsueType] = useState();
  const [loading, setLoading] = useState(false);
  const [bugIssue, setBugIssue] = useState(false);
  const [descriptionError, setDescriptionError] = useState("");
  // const [url, setUrl] = useState('');
  const [urlArray, setUrlArray] = useState([]);
  // let urlArray = [];
  let filterUrl;
  // console.log("assigneesList", assigneesList);
  // console.log("sprintdata", sprintdata);
  // const [urlArray, setUrlArray] = useState([])
  let config = {
    // extraPlugins: [ 'autogrow' ],
    // autoGrow_minHeight: 200,
    // autoGrow_maxHeight: 600,
    // autoGrow_bottomSpace: 50,
    // height: 200, // Initial height
    // removePlugins: [ 'resize' ],
    toolbar: [
      // Customize toolbar as per your requirement
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'undo',
      'redo'
  ],
  height: editorHeight,
    toolbarGroups: [
      // { name: "document", groups: ["mode", "document", "doctools"] },

      { name: "forms", groups: ["forms"] },
      { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
      { name: "links", groups: ["link"] },
      {
        name: "paragraph",
        groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
      },
      { name: "styles", groups: ["Styles", "Format", "Font", "FontSize"] },
      { name: "clipboard", groups: ["clipboard", "undo"] },
      { name: "others", groups: ["others"] },
      { name: "about", groups: ["about"] },
      {
        name: "editing",
        groups: ["find", "selection", "spellchecker", "editing"],
      },
    ],
    removeButtons:
      "Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo",
    fontSize_sizes: "16/16px;24/24px;48/48px;",
    font_names:
      "Arial/Arial, Helvetica, sans-serif;" +
      "Times New Roman/Times New Roman, Times, serif;" +
      "Verdana",
    allowedContent: true,
    scayt_autoStartup: true,
  };




  // const [descriptionAlert, setDescriptionAlert] = useState('');

  // function chackDescription() {
  //   if (textEditer === '') setDescriptionAlert('Please Enter Description');
  //   if (textEditer !== '') setDescriptionAlert('');
  // }

  const getSprintData = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("Project_id", params?.id);
    Instance.post("sprint/getAllsprint", urlencoded).then((res) => {
      console.log(res, 161);
      setSprintdata(res.data.data);
    });
  };

  useEffect(() => {
    getSprintData();
  }, [params?.id]);

  useEffect(() => {
    // if(projectDetail){
    Instance.get("auth/projects").then((res) => {
      setProjectList(res.data.data);
    });
    // }
  }, []);

  useEffect(() => {
    if (projectDetail?.Project_member) {
      // let projectMember = projectDetail?.Project_member
      //   ? projectDetail?.Project_member.split(',')
      //   : '';
      //Employee
      Instance.get(`auth/projectemployeelist/${projectDetail._id}`).then(
        async (res) => {
          if (res?.data?.status == 1) {
            const filteredDataArray = res.data.data.filter(
              (item) => item !== null
            );
            let EmID = filteredDataArray.map((member) => {
              // let EmID1 = res.data.data.filter(
              //   (item) => item.First_name + ' ' + item.Last_name == member
              // );
              return {
                label:
                  member?.First_name +
                  " " +
                  member?.Last_name +
                  " (" +
                  member?.Role +
                  ")",
                value: member?.First_name + " " + member?.Last_name,
              };
            });
            setAssigneesList(EmID);
            setSelectedMember(EmID);
          }
        }
      );
      //let projectMan = projectDetail?.Project_manage?.split(',');
      //let projectMan = projectDetail?.Project_member?.split(',');
      //setReporterList(userData[0].First_name);
    }
  }, [projectDetail]);

  // project data List
  useEffect(() => {
    let projectData = projectList?.filter(
      (item) => item._id == ProjectIDeOnChange
    );
    if (ProjectIDeOnChange) {
      // let projectMember = projectData[0]?.Project_member.split(',');
      //Employee
      Instance.get(`auth/projectemployeelist/${ProjectIDeOnChange}`).then(
        async (res) => {
          if (res.data.status == 1) {
            let EmID = res.data.data.map((member) => {
              // let EmID1 = res.data.data.filter(
              //   (item) => item.First_name + ' ' + item.Last_name == member
              // );
              return {
                label:
                  member?.First_name +
                  " " +
                  member?.Last_name +
                  " (" +
                  member?.Role +
                  ")",
                value: member?.First_name + " " + member?.Last_name,
              };
            });
            setAssigneesList(EmID);
            setSelectedMember(EmID);
          }
        }
      );
      // setAssigneesList(projectMember);
      let projectMan = projectData[0]?.Project_manager?.split(",");
      setReporterList(projectMan);
    }
  }, [ProjectIDeOnChange]);

  // useEffect(() => {
  //   let projectData = projectList?.filter(
  //     (item) => item._id == ProjectIDeOnChange
  //   );
  //   if (ProjectIDeOnChange) {
  //     let projectMember = projectData[0]?.Project_member.split(',');

  //     //Employee
  //     Instance.get(`auth/manageremployees/61af44fb8fbced36f9e324a1`).then(async (res) => {
  //       if (res.data.status == 1) {
  //         let EmID =
  //           projectMember &&
  //           projectMember.map((member) => {
  //             let EmID1 = res.data.data.filter(
  //               (item) => item.First_name + ' ' + item.Last_name == member
  //             );
  //             return {
  //               label:
  //                 EmID1[0]?.First_name +
  //                 ' ' +
  //                 EmID1[0]?.Last_name +
  //                 ' (' +
  //                 EmID1[0]?.Role +
  //                 ')',
  //               value: EmID1[0]?.First_name + ' ' + EmID1[0]?.Last_name,
  //               // value:  EmID1[0]?._id,
  //             };
  //           });
  //         setAssigneesList(EmID);
  //         setSelectedMember(EmID);
  //       }
  //     });
  //     // setAssigneesList(projectMember);
  //     let projectMan = projectData[0]?.Project_manager.split(',');
  //     setReporterList(projectMan);
  //   }
  // }, [ProjectIDeOnChange]);
  // employee list
  useEffect(() => {
    if (projectDetail) {
      Instance.get(`auth/projectemployeelist/${projectDetail._id}`).then(
        async (res) => {
          if (res?.data?.status == 1) {
            const filteredDataArray = res?.data?.data?.filter(
              (item) => item !== null
            );
            let EmID = await filteredDataArray.filter(
              (item) => item.First_name + " " + item.Last_name == selectedMember
            );
            setSelectedMemberId(EmID[0]?._id);
          }
        }
      );
    } else if (ProjectIDeOnChange) {
      Instance.get(`auth/projectemployeelist/${ProjectIDeOnChange}`).then(
        async (res) => {
          if (res.data.status == 1) {
            let EmID = await res.data.data.filter(
              (item) => item.First_name + " " + item.Last_name == selectedMember
            );
            setSelectedMemberId(EmID[0]?._id);
          }
        }
      );
    }
  }, [selectedMember]);

  // upload file props
  const props = {
    className: "upload-list-inline",
    listType: "picture",
    maxCount: "50",
    multiple: "multiple",
    openFileDialogOnClick: "false",
    action: "image",
  };

  //#region all uploaded file delete fun
  const allFileDelete = () => {
    // if (urlArray[0]) {
    //   urlArray?.map((item) => {
    //     const formData = new URLSearchParams();
    //     formData.append('image', item.url);
    //     Instance.post('auth/tasks/deleteImage', formData).then((result) => {
    //       //   // changeflag.setflag(!changeflag.flag);
    //     });
    //   });
    // }
  };
  //#endregion
  useEffect(() => {
    // if (setFlage1) {
    //   setFlage1((pre) => pre + 1);
    // }
    if (setFlage) {
      setFlage((pre) => pre + 1);
    }
  }, [visible]);

  const fetchAssigneesList = () => {
    if (projectDetail?.Project_member) {
      Instance.get(`auth/projectemployeelist/${projectDetail._id}`).then(
        async (res) => {
          if (res?.data?.status == 1) {
            const filteredDataArray = res.data.data.filter(
              (item) => item !== null
            );
            let EmID = filteredDataArray.map((member) => {
              return {
                label: `${member?.First_name} ${member?.Last_name} (${member?.Role})`,
                value: `${member?.First_name} ${member?.Last_name}`,
              };
            });
            setAssigneesList(EmID);
          }
        }
      );
    }
  };

  // const fetchSprintList = () => {
  //   if (projectDetail) {
  //     Instance.post("sprint/getAllsprint", { Project_id: projectDetail._id })
  //       .then((res) => {
  //         setSprintdata(res.data.data);
  //       })
  //       .catch((error) => {
  //         console.log("Error fetching sprint list:", error);
  //       });
  //   }
  // };

  // const fetchLinkIssueList = () => {
  //   if (projectDetail) {
  //     Instance.get(`auth/projecttasks/list/${projectDetail._id}`)
  //       .then((res) => {
  //         setLinkIssueList(res.data.data);
  //       })
  //       .catch((error) => {
  //         console.log("Error fetching link issue list:", error);
  //       });
  //   }
  // };

  const handleRefreshSprint = () => {
    getSprintData();
  };

  const handleRefreshTasks = () => {
    GetprojectTaskList();
  };

  const handleRefreshClick = () => {
    fetchAssigneesList();
  };

  //submit
  const onFinish = async (values) => {
    if (!textEditer.trim()) {
      // message.error("Description is required.");
      if (!stepsEditer.trim() && isueType === "Bug") {
        setStepsError("Steps to reproduce is required");
        setDescriptionError("Description is required");
        return;
      }
      setDescriptionError("Description is required");
      return;
    }
    if (!stepsEditer.trim() && isueType === "Bug") {
      // message.error("Steps is required.");
      setStepsError("Steps to reproduce is required");
      return;
    }
    let Project_Id = projectList.filter(
      (item, index) => values.projectShortName === item._id
    );
    let taskKey = `${
      projectDetail
        ? projectDetail?.Project_short_name
        : Project_Id[0]?.Project_short_name
    }`;
    let sptId;
    let sptName;
    if (values.sprint == projectDetail.Sprint_id) {
      sptName = values.sprint;
      sptId = projectDetail.Sprint_id;
    } else {
      sptName = JSON.parse(values.sprint)?.Name;
      sptId = JSON.parse(values.sprint)?._id;
    }
    if (values) {
      const formData = new FormData();
      formData.append("Issue_type", values.IssueType);
      if (stepsEditer?.length > 0) {
        formData.append("Steps_to_reproduce", stepsEditer);
      }
      if (sptId) formData.append("Sprint_id", sptId ? sptId : "-");
      formData.append("Short_summary", values.shortSummary);
      formData.append("Description", textEditer);
      formData.append("Reporter", values.reporter ? values.reporter : "-");
      // formData.append(
      //   'Upload_file',
      //   urlArray[0] ? JSON.stringify(urlArray) : ''
      // );

      formData.append(
        "Assigned_date",
        values.assignedDate ? values.assignedDate : "-"
      );
      let fileData = file ? [...file] : "";
      if (fileData.length) {
        fileData.map((ele) => {
          formData.append("image", ele);
        });
      }

      formData.append("Due_date", values.dueDate ? values.dueDate : "-");
      // formData.append('Assignees', selectedMemberId);
      formData.append("Assignees", values?.assignees ? values?.assignees : "");
      formData.append("Priority", values.priority);
      // formData.append('Upload_file',data.length>1?JSON.stringify(data):'')

      if (projectDetail) {
        formData.append("Project_name", projectDetail?.Project_name);
        formData.append("Project_id", projectDetail._id);
      } else {
        formData.append("Project_name", Project_Id[0]?.Project_name);
        formData.append("Project_id", values.projectName);
      }
      formData.append("Employee_id", selectedMemberId);
      formData.append("Task_key", taskKey);
      formData.append("Task_duration", values.taskDuration ? values.taskDuration: "-");

      formData.append("Linkissue_Taskkey", values.linkissue);
      setLoading(true);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      await Instance.post("auth/tasks/add", formData, config)
        .then((res) => {
          setLoading(false);
          if (res.data.status == 1) {
            form.resetFields();
            setTextEditer("");
            setIsueType("");

            // setTextEditer('');
            // setFlage1((pre) => pre + 1);
            toasterMessageFunction(res.data.message);
            // if(res.data.message === "Task add successfully."){
            // }
            setCount((pre) => pre + 1);
            setTaskAddIsShow(false);
            if (location.pathname === `/project/tasks/${params?.id}`) {
              updateList(true);
            }
            // setflag(pre=>pre+1);
          }
          setUrlArray([]);
          setVisible(false);
        })
        .catch((err) => {
          setLoading(false);
          // toast.error("Please try again.");
          message.error("Please try again.");
        });
    }
  };
  useEffect(() => {
    if (textEditer?.length > 0) {
      setDescriptionError("");
    }
  }, [textEditer]);
  useEffect(() => {
    if (stepsEditer?.length > 0) {
      setStepsError("");
    }
  }, [stepsEditer]);
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    // setTextEditer('');
  };
  const handleIsueType = (value) => {
    setIsueType(value);
  };
  useEffect(() => {
    if (isueType === "Bug") {
      setBugIssue(true);
    } else {
      setBugIssue(false);
    }
  }, [isueType]);

  const handleCancel = (errorInfo) => {
    allFileDelete();
    form.resetFields();
    setTextEditer("");
    setStepsEditer("");
    setVisible(false);
    setIsueType("");
    setProjectIDeOnChange("");
    setUrlArray([]);
    setTaskAddIsShow(false);
    // setFlage1((pre) => pre + 1);

    // setProjectIDeOnChange('')
    // urlArray = [];
  };

  const onDescriptionChange = (evt) => {
    setTextEditer(evt.editor.getData());
    console.log(evt, "evt");
    console.log(evt.editor.getData(), "evt.editor.getData()");
  };
  const deleteFilled = (rowIndex) => {
    setUrl((oldState) => oldState.filter((item, index) => index != rowIndex));
  };
  return (
    <>
      {/* {btnStyl ? (<> */}
      {/* {alert('hello')} */}
      {/* <Button className="btn-add-link tablHeader" onClick={() => setVisible(true)}>
          <MDBIcon fas icon="plus" />
          <span style={{  paddingLeft: '15px', fontWeight: '600' }}>
            
          </span> */}
      {/* </Button> */}
      {/* </>) : null} */}
      {/* (
        <Button style={ { margin: "5px 9px 0px 10px"}} type="primary" onClick={() => setVisible(true)}>
          
        </Button>
      ) */}

      <Modal
        title="Create Task"
        centered
        // visible={visible}
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={handleCancel}
        maskClosable={false}
        width={800}
        height={640}
        bodyStyle={{ padding: "20px 15px" }}
        footer={null}
      >
        <Form
          form={form}
          name="control-hooks"
          layout="vertical"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row>
            {projectDetail?.Project_name ? (
              <Col span={24} style={{ paddingRight: "10px" }}>
                <Form.Item
                  label="Project Name"
                  name="projectName"
                  // initialValue={localStorage.getItem("ProjectName")}
                  initialValue={projectDetail?.Project_name}
                  rules={[
                    {
                      required: true,
                      message: "Please Select Project Name",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            ) : (
              <Col span={24} style={{ paddingRight: "10px" }}>
                <Form.Item
                  label="Project Name"
                  name="projectName"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Project Name",
                      whitespace: true,
                    },
                  ]}
                >
                  <Select
                    onChange={(e) => setProjectIDeOnChange(e)}
                    showSearch="true"
                    optionFilterProp="children"
                    // filterOption={(input, option) =>
                    //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                  >
                    {projectList?.map((item, index) => {
                      return (
                        <Select.Option value={item._id}>
                          {item.Project_name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row>
            <Col span={12} style={{ paddingRight: "10px" }}>
              <Form.Item
                label="Issue Type"
                name="IssueType"
                rules={[
                  {
                    required: true,
                    message: "Please Select Issue Type!",
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  // onChange={(e) => {
                  //   setIsueType(e);
                  // }}
                  onChange={(e) => handleIsueType(e)}
                >
                  <Select.Option value="Bug">Bug</Select.Option>
                  <Select.Option value="Task">Task</Select.Option>
                  <Select.Option value="Story">Story</Select.Option>
                  <Select.Option value="Suggestion">Suggestion</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} style={{ paddingRight: "12px" }}>
              <Form.Item
                label="Short Summary"
                name="shortSummary"
                rules={[
                  {
                    required: true,
                    message: "Please Select Short Summary",
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          {bugIssue ? (
            <Row gutter={16}>
              <Col
                span={24}
                style={{ paddingRight: "10px", marginTop: "10px" }}
              >
                <Form.Item
                  label="Steps To Reproduce"
                  name="stepsToReproduce"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Steps To Reproduce!",
                      whitespace: true,
                    },
                  ]}
                >
                  <div style={{ marginBottom: "5px" }}>
                    <CKEditor
                      className="cknew-editor steps-editor"
                      editor={ClassicEditor}
                      // activeClass="p10"
                      config={config}
                      content={stepsEditer}
                      events={{
                        change: onDescriptionChange,
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setStepsEditer(data);
                        if (data == "") {
                          form.setFields([
                            {
                              name: "stepsToReproduce",
                              errors: ["Please Enter Steps To Reproduce!"],
                            },
                          ]);
                        } else {
                          form.setFieldsValue({ stepsToReproduce: data });
                        }
                      }}
                    />
                    {/* {stepsError && (
                  // <div style={{ color: "#ff4d4f" }}>{stepsError}</div>
                  <div className="descriptionError">{stepsError}</div>
                )} */}
                  </div>
                </Form.Item>
              </Col>
            </Row>
          ) : (
            ""
          )}
          <Row gutter={16}>
            <Col span={24} style={{ paddingRight: "10px", marginTop: "10px" }}>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Description!",
                    whitespace: true,
                  },
                ]}
              >
                {/* <div className="my-class"></div> */}
                <CKEditor
                  className="cknew-editor"
                  editor={ClassicEditor}
                  // name= "description"
                  // activeClass="p10"
                  config={config}
                  content={textEditer}
                  events={{
                    change: onDescriptionChange,
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setTextEditer(data);
                    if (data == "") {
                      form.setFields([
                        {
                          name: "description",
                          errors: ["Please enter description"],
                        },
                      ]);
                    } else {
                      form.setFieldsValue({ description: data });
                    }
                  }}
                />
              </Form.Item>
            </Col> 
          </Row>

          <Row>
            <Col span={10}>
              <Form.Item
                style={{ marginTop: "5px" }}
                label="Upload File"
                name="profile"
                rules={[
                  {
                    required: false,
                    message: "Please upload a file.",
                  },
                  {
                    validator: async (rule, value) => {
                      if (Array.isArray(value)) {
                        const allowedFormats = [
                          ".jpg",
                          ".jpeg",
                          ".png",
                          ".pdf",
                          ".doc",
                          ".docx",
                          ".xlsx",
                          ".csv",
                        ];
                        for (let file of value) {
                          const fileExtension = file.name.substr(
                            file.name.lastIndexOf(".")
                          );
                          if (!allowedFormats.includes(fileExtension)) {
                            return Promise.reject(
                              `Only ${allowedFormats.join(
                                ", "
                              )} files are allowed.`
                            );
                          }
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Buttonss
                  variant="contained"
                  component="label"
                  className="bttn-clr pms-same-btn pms-same-btn-upload-new"
                >
                  <input
                    accept="files/*"
                    multiple
                    hidden
                    type="file"
                    onChange={(e) => {
                      // setFile(e.target.files);
                      fileHandler(e.target.files);
                    }}
                  />
                  Upload File
                </Buttonss>
              </Form.Item>
              {url.map((row, index) => {
                return (
                  <>
                    <Box className="upload-file-show">
                      <Typography variant="p">{row}</Typography>
                      <DeleteFilled onClick={() => deleteFilled(index)} />
                    </Box>
                  </>
                );
              })}
            </Col>
            <Col span={10}>
              <Form.Item
                label="Priority"
                name="priority"
                initialValue={"MEDIUM"}
                rules={[
                  {
                    required: true,
                    message: "Please Select Priority!",
                    whitespace: true,
                  },
                ]}
              >
                <Select>
                  <Select.Option value="HIGH">HIGH</Select.Option>
                  <Select.Option value="MEDIUM">MEDIUM</Select.Option>
                  <Select.Option value="LOW">LOW</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={10}>
              <div className="assigness-button">
                <Form.Item
                  style={{ marginTop: "-5px" }}
                  label="Assignees"
                  name="assignees"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Assignees!",
                      whitespace: true,
                    },
                  ]}
                >
                 
                  {/* </Col> */}

                  {/* <Select onChange={(e) => setSelectedMember(e)}>
                  {assigneesList?.map((item, index) => {
                    return <Select.Option value={item}>{item}</Select.Option>;
                  })}
                </Select> */}
                  {/* <Select
                  onChange={(e) => setSelectedMember(e)}
                  options={assigneesList}
                /> */}

                  <Select
                    disabled={!inSidebar && (ProjectIDeOnChange ? false : true)}
                    onChange={(e) => setSelectedMember(e)}
                    showSearch="true"
                    optionFilterProp="children"
                  >
                    {assigneesList?.map((item, index) => {
                      return (
                        <Select.Option value={item.value}>
                          {item.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <div className="custom-button-assignees">
                    <Button
                      className="refresh-btn"
                      onClick={handleRefreshClick}
                    >
                      <img src={Png.refresh_icon} alt="" />
                    </Button>
                  </div>
              </div>
            </Col>

            <Col span={10}>
              <Form.Item
                label="Reporter"
                name="reporter"
                initialValue={localStorage.getItem("userName")}
                // initialValue={

                //   projectDetail?.Project_manager
                //     ? projectDetail?.Project_manager
                //     : reporterList[0]
                // }

                rules={[
                  {
                    required: true,
                    message: "Please Select Reporter!",
                    whitespace: true,
                  },
                ]}
              >
                <Input disabled />
                {/* <Form.Item label="Reporter" name="reporter" initialValue={reporterList[0]}> */}
                {/* <Select
                  disabled={!inSidebar && (ProjectIDeOnChange ? false : true)}
                >
                  {reporterList?.map((item, index) => {
                    return <Select.Option value={item}>{item}</Select.Option>;
                  })}
                </Select> */}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Form.Item
                style={{ marginTop: "-5px" }}
                label="Assigned Date"
                name="assignedDate"
                // initialValue={moment(`${(date.getDate()+'-'+(date.getMonth()+1)+'-'+date.getFullYear())}`, 'DD-MM-YYYY')}
                // rules={[
                //   {
                //     required: true,
                //     message: 'Please Select Assigned Date!',
                //   },
                // ]}
              >
                <PmsDatePicker
                  format={"DD/MM/YYYY"}
                  // defaultValue={moment(`${(date.getDate()+'-'+date.getMonth()+'-'+date.getFullYear())}`, 'YYYY-MM-DD')}
                  disabledDate={(d) => !d || d.isBefore(Date.now() - 86400000)}
                />
              </Form.Item>
            </Col>
            <>
              <Col span={10}>
                <Form.Item label="Due Date" name="dueDate">
                  <PmsDatePicker
                    format={"DD/MM/YYYY"}
                    disabledDate={(d) => d < moment(Date.now() - 86400000)}
                  />
                </Form.Item>
              </Col>
            </>
            <Col span={10}>
              <Form.Item
                label="Task Duration ( in hour, 30min = 0.5 )"
                name="taskDuration"
                rules={[
              {
                required: isueType === 'Task' || isueType === 'Story',
                message: 'Please Enter Task Duration',
              },
            ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>

            {/* link issue */}

            <Col span={10}>
              <Form.Item
                // style={{ marginTop: '-5px' }}
                label="Link issue"
                name="linkissue"
                rules={[
                  {
                    // required: true,
                    message: "Please Select Link issue",
                    whitespace: true,
                  },
                ]}
              >
                
                <Select
                  // disabled={!inSidebar && (ProjectIDeOnChange ? false : true)}
                  // onChange={(e) => setSelectedMember(e)}
                  showSearch="true"
                  // optionFilterProp="children"
                >
                  {tasksList?.map((item, index) => {
                    return (
                      <Select.Option value={item.Task_key}>
                        {`${item.Task_key} - ${item.Short_summary}`}
                      </Select.Option>
                    );
                  })}
                
                </Select>
                {/* <Col span={2}>
                  <Button
                    type="primary"
                    icon={<ReloadOutlined />}
                    onClick={handleRefreshTasks}
                    style={{ marginTop: "30px" }}
                  />
                </Col> */}
              </Form.Item>
              <div className="custom-button-assignees">
                  <Button className="refresh-btn" onClick={handleRefreshTasks}>
                    <img src={Png.refresh_icon} alt="" />
                  </Button>
                </div>
            </Col>
            <Col span={10}>
              <Form.Item
                // style={{ marginTop: '-5px' }}
                label="Sprint"
                name="sprint"
                // rules={[
                //   {
                //     // required: true,
                //     message: 'Please Select Sprint',
                //     whitespace: true,
                //   },
                // ]}
              >
                
                <Select showSearch="true">
                  {sprintdata?.map((item, index) => {
                    return (
                      <Select.Option
                        value={JSON.stringify({
                          _id: item._id,
                          Name: item.Name,
                        })}
                      >
                        {item.Name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <div className="custom-button-assignees">
                  <Button className="refresh-btn" onClick={handleRefreshSprint}>
                    <img src={Png.refresh_icon} alt="" />
                  </Button>
                </div>
            </Col>
          </Row>
          <Form.Item>
            <div style={{ textAlign: "end", marginTop: "10px" }}>
              <Button
                // className="btn"
                type="primary"
                htmlType="submit"
                loading={loading}
                style={{ marginRight: "10px" }}
                className="pms-same-btn"
              >
                Submit
              </Button>
              <Button className="pms-same-btn-cancel" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Add;
