import React, { useEffect, useState } from "react";
import { MDBCard, MDBCol } from "mdb-react-ui-kit";
import { MDBDataTableV5 } from "mdbreact";
import { Modal, Button, message } from "antd";
import ReactLoader from "../../../../components/common/ReactLoader";
import AxiosInstance from "../../../../service/AxiosInstance";
import { toast } from "react-toastify";

function MailSetting() {
    const [loading, setLoading] = useState(false);
    const [datatable, setDatatable] = useState({});
    const [mailSettingList, setMailSettingList] = useState([]);

    const getMailSetting = () => {
        setLoading(true);
        AxiosInstance.get("/auth/get/mail/setting").then((res) => {
            setLoading(false);
            const list1 = Object.entries(res.data.data?.[0]).map(([key, value], index) => {
                return {
                    id: index + 1,
                    apiName: key,
                    status: value
                }
            })
            setMailSettingList(list1);
        })
            .catch((error) => {
                setLoading(false);
                console.error("Error fetching mail settings:", error);
            });
    };

    const onFinish = (status, payload) => {
        const data = {
            [payload?.apiName]: status
        }

        AxiosInstance.post("/auth/add/mail/setting", data, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((res) => {
                if (res?.data?.status == 1) {
                    setMailSettingList((prev) =>
                        prev?.map((item) => {
                            if (payload?.id === item?.id) {
                                return { ...item, status }
                            }
                            return item
                        })
                    )
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || "Failed to add device");
            });
    };

    useEffect(() => {
        setDatatable({
            columns: [
                {
                    label: "No",
                    field: "id",
                    sort: "disabled",
                    width: 10,
                },
                {
                    label: "Check Box",
                    field: "status",
                    width: 200,
                    sort: "disabled",
                },
                {
                    label: "Mail Setting",
                    field: "apiName",
                    width: 150,
                    sort: "disabled",
                },
            ],

            rows: mailSettingList.map((item) => ({

                ...item,
                status: <input type="checkbox" checked={item.status} onChange={(e) => {
                    console.log(e, item.apiName, "sadasd")
                    onFinish(e.target.checked, item)
                }} />
            })),
        });
    }, [mailSettingList]);

    useEffect(() => {
        getMailSetting();
    }, []);


    return (
        <>
            <div className="demo">
                <MDBCard className="page-content">
                    <div className="create-project">
                        <MDBCol size="md" className="col-example">
                            <h3 className="tablHeader">
                                Configuration
                            </h3>
                        </MDBCol>
                    </div>

                    {loading ? (
                        <ReactLoader loading={loading} />
                    ) : (
                        <div className="mail-data-table">
                            <MDBDataTableV5
                                hover
                                entriesOptions={[10, 20, 25]}
                                entries={10}
                                pagesAmount={4}
                                data={datatable}
                                searchTop
                                searchBottom={false}
                            />
                        </div>
                    )}
                </MDBCard>
            </div>
        </>
    )
}

export default MailSetting
