import React from "react";
import LoadingOverlay from "react-loading-overlay";
import { Loading } from "./Loader";

const ReactLoader = (props) => {
  return (
    <LoadingOverlay
      styles={{
        background: "#fff",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
      active={props.loading}
      spinner={<Loading />}
      // styles={{
      //   overlay: (base) => ({
      //     ...base,
      //     background: "#fff",
      //     width: "100%",
      //     display: "flex",
      //     justifyContent: "center",
      //   }),
      // }}
    />
  );
};

export default ReactLoader;
