import React from "react";

export default function EstimatedAndConsumeTime({
  count,
  estimatedTime,
  consumedTime,
}) {
  return (
    <div className="flex-count-details-data custom-flex-count-details">
      <div className="flex-count-inner estimated-consume-timer-data">
        <div className="count-cols-data">
          <div className="count-card estimated-consume-count-card">
            <div className="count-card-body">
              <h5 className="count-card-title">Estimated Hours</h5>
              <p className="count-card-amount">
                {estimatedTime ? estimatedTime : 0}
              </p>
            </div>
          </div>
        </div>
        <div className="count-cols-data">
          <div className="count-card estimated-consume-count-card">
            <div className="count-card-body">
              <h5 className="count-card-title">Consumed Hours</h5>
              <p className="count-card-amount">
                {consumedTime ? consumedTime : 0}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
