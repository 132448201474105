import React, { useEffect, useState } from "react";
import { MDBDataTableV5, MDBCard } from "mdbreact";
import { MDBCol } from "mdb-react-ui-kit";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { LiaGreaterThanSolid } from "react-icons/lia";
import axiosInstance from "../../../service/AxiosInstance";
import ReactLoader from "../../../components/common/ReactLoader";
import { useDispatch, useSelector } from "react-redux";
import fetchProjectsSuccess from "../../../redux/actions/ProjectDataActions";
import moment from "moment";
import { Link, useHistory, useLocation } from "react-router-dom";
import { blue } from "@mui/material/colors";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
const { Option } = Select;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function StoryTracker() {
  const [value, setValue] = useState(0);
  const [clusterList, setClusterList] = useState([]);
  const location = useLocation();
  console.log(location, 57);

  let userData = JSON.parse(localStorage.getItem("userdata")) || {};
  console.log(userData, userData[0]?._id, 588);
  const [filterData, setFilterData] = useState({
    all: [],
    upcoming: [],
    overdue: [],
  });
  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "PROJECT NAME",
        field: "Project_name",
        width: 270,
      },
      {
        label: "Story ID",
        field: "Task_key",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Name",
        },
      },
      {
        label: "Start Date",
        field: "startdate",
        width: 270,
      },
      {
        label: "End Date",
        field: "enddate",
        width: 270,
      },
      {
        label: "Overdue Days",
        field: "overduedays",
        width: 270,
      },
    ],
    rows: [],
  });
  const [loading, setLoading] = useState(false);
  const [projectData, setProjectData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const dispatch = useDispatch();
  const history = useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
    setDatatable((prevState) => ({
      ...prevState,
      rows: filterData[event.target.value],
    }));
  };

  // const getClusterTeamList = async () => {
  //   setLoading(true);
  //   try {
  //     const res = await axiosInstance.get(`auth/getTeamMembers-story/${userData[0]?._id}`);
  //     if (res.data.data) {
  //       setClusterList(res.data.data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  //   setLoading(false);
  // };

  const getClusterTeamList = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `auth/getTeamMembers-story/${userData[0]?._id}`
      );
      if (res.data.status === 1) {
        setClusterList(res.data.data);
      } else if (
        res.data.status === 0 &&
        res.data.message === "You are not in any cluster team"
      ) {
      } else {
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   getClusterTeamList();
  // }, []);

  const getClusterTeamListAll = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get("auth/getTeamMembers");
      if (res.data.data) {
        setClusterList(res.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   getClusterTeamListAll();
  // }, []);

  useEffect(() => {
    if (location.pathname === "/story-tracker") {
      getClusterTeamListAll();
    } else if (location.pathname === "/my-dashboard") {
      getClusterTeamList();
    }
  }, [location.pathname]);

  const getAllProjectData = () => {
    axiosInstance.get("auth/projects/Default?status=").then((res) => {
      if (res?.data?.data) {
        console.log(res.data.data, "getAllProjectData");
        dispatch(fetchProjectsSuccess(res?.data?.data));
        setProjectData();
      }
    });
  };
  useEffect(() => {
    getAllProjectData();
  }, []);

  const handleCellClick = (record) => {
    window.open(`/#/project/tasks/${record}`, "_blank");
  };
  const handleChangeClick = (taskId, projectId) => {
    window.open(`/#/project/task/${taskId}/${projectId}`, "_blank");
  };

  const modifiedRows = filterData[selectedFilter].map((row) => {
    const cellStyle = row.upcoming
      ? { color: "green" }
      : row.overdue
      ? { color: "red" }
      : {};

    return {
      ...row,
      Project_name: (
        <span
          onClick={() => handleCellClick(row.Project_id)}
          className="pointer-blue"
        >
          {row.Project_name}
        </span>
      ),
      Project_name_text: row.Project_name,
      Task_key: (
        <span
          onClick={() => handleChangeClick(row.Task_id, row.Project_id)}
          className="pointer-blue"
        >
          {row.Task_key}
        </span>
      ),
      Task_key_text: row.Task_key,
      overduedays: <span style={cellStyle}>{row.overduedays}</span>,
    };
  });

  const modifiedDatatable = {
    ...datatable,
    rows: modifiedRows,
    columns: [...datatable.columns],
  };

  const getAllProjectFilterData = (id) => {
    axiosInstance.post(`auth/get-cluster-project-list/${id}`).then((res) => {
      if (res?.data?.data) {
        if (res.data.data.length > 0) {
          let data = res.data.data
            .map((data) => {
              return data.tasks.map((task) => {
                const currentDate = moment();
                // const endDate = task.Due_date ? moment(task.Due_date) : null;
                // const assignedDate = task.Assigned_date
                //   ? moment(task.Assigned_date)
                //   : null;
                const endDate = task.Due_date
                  ? moment(task.Due_date).startOf("day")
                  : null;
                const assignedDate = task.Assigned_date
                  ? moment(task.Assigned_date).startOf("day")
                  : null;
                // const overdueDays =
                //   endDate && task.Status !== "CLOSE"
                //     ? Math.max(currentDate.diff(endDate, "days"), 0)
                //     : "-";
                const overdueDays =
                  endDate && endDate.isValid()
                    ? Math.max(currentDate.diff(endDate, "days"), 0)
                    : "-";
                const upcomingDays =
                  assignedDate &&
                  assignedDate.isValid() &&
                  assignedDate.isAfter(currentDate)
                    ? assignedDate.diff(currentDate, "days") + 1
                    : 0;
                const upcoming = upcomingDays > 0;
                return {
                  Project_name: data.project?.Project_name || "-",
                  Project_id: data.project?._id,

                  Task_key: task.Task_key || "-",
                  Task_id: task._id,
                  startdate:
                    assignedDate && assignedDate.isValid()
                      ? assignedDate.format("DD/MM/YYYY")
                      : "-",
                  enddate:
                    endDate && endDate.isValid()
                      ? endDate.format("DD/MM/YYYY")
                      : "-",
                  overduedays: upcoming ? upcomingDays : overdueDays,
                  upcomingDays: upcoming ? upcomingDays : "-",
                  upcoming,
                  // overdue:
                  // !upcoming && task.Status !== "CLOSE" && overdueDays > 0,
                  overdue: !upcoming && overdueDays !== "-" && overdueDays > 0,
                };
              });
            })
            .flat();

          const upcomingData = data.filter((task) => task.upcoming);
          const overdueData = data.filter((task) => task.overdue);

          setFilterData({
            all: data,
            upcoming: upcomingData,
            overdue: overdueData,
          });
          setDatatable((prevState) => ({
            ...prevState,
            rows: data.filter((task) => task[selectedFilter]),
          }));
        } else {
          setFilterData({ all: [], upcoming: [], overdue: [] });
        }
      } else {
        setFilterData({ all: [], upcoming: [], overdue: [] });
      }
    });
  };

  useEffect(() => {
    console.log("filterData", filterData);
    setDatatable((prevState) => ({
      ...prevState,
      rows: filterData[selectedFilter],
    }));
  }, [filterData]);

  return (
    <>
      <div className="demo">
        <MDBCard className="page-content">
          <div>
            <div className="create-project employee-table">
              <MDBCol size="md" className="col-example">
                <h2 className="tablHeader">Story Tracker</h2>
              </MDBCol>
            </div>
          </div>
          {location.pathname === "/story-tracker" ? (
            <div className="page-height-story">
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderColor: "divider" }}></Box>
                <CustomTabPanel
                  value={value}
                  index={0}
                  className="custom-tab-panel"
                >
                  <Box className="main-tabs">
                    <Box className="left-tab-main">
                      <h2 className="text-left-tab">Cluster Team List</h2>
                      <Box className="employee-main-table">
                        {loading ? (
                          <ReactLoader loading={loading} />
                        ) : (
                          clusterList.map((cluster, index) => (
                            <Box
                              key={index}
                              className="employee-profile-count"
                              onClick={() =>
                                getAllProjectFilterData(
                                  cluster.tpm_employee_id._id
                                )
                              }
                            >
                              <h4 className="empl-memeber">
                                {cluster?.cluster_Name}
                              </h4>
                              <Box className="depar-empl-icone">
                                <h4 className="empl-memeber-count-emp">
                                  {cluster?.memberCount}
                                </h4>
                                <LiaGreaterThanSolid />
                              </Box>
                            </Box>
                          ))
                        )}
                      </Box>
                    </Box>
                    <Box className="right-tab-main custom-right-tab-main">
                      <Box className="main-table-data">
                        <div className="tasklisttbl emp-tracl-tbl">
                          <div className="backlog-sprint-task-status custom-sprint-task-status">
                            <Select
                              className="custom-select"
                              labelId="filter-label"
                              id="filter"
                              value={selectedFilter}
                              onChange={handleFilterChange}
                            >
                              <MenuItem
                                value="all"
                                style={{ fontSize: "12px" }}
                              >
                                All
                              </MenuItem>
                              <MenuItem
                                value="upcoming"
                                style={{ fontSize: "12px" }}
                              >
                                Upcoming
                              </MenuItem>
                              <MenuItem
                                value="overdue"
                                style={{ fontSize: "12px" }}
                              >
                                Overdue
                              </MenuItem>
                            </Select>
                          </div>
                          <MDBDataTableV5
                            className="tasklisttblss data"
                            hover
                            entriesOptions={[10, 20, 40, 50]}
                            entries={10}
                            pagesAmount={10}
                            data={modifiedDatatable}
                            searchTop
                            searchBottom={false}
                          />
                        </div>
                      </Box>
                    </Box>
                  </Box>
                </CustomTabPanel>
              </Box>
            </div>
          ) : (
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderColor: "divider" }}></Box>
              <CustomTabPanel
                value={value}
                index={0}
                className="custom-tab-panel"
              >
                <Box className="main-tabs">
                  <Box className="left-tab-main">
                    <h2 className="text-left-tab">Cluster Team List</h2>
                    <Box className="employee-main-table">
                      {loading ? (
                        <ReactLoader loading={loading} />
                      ) : (
                        clusterList.map((cluster, index) => (
                          <Box
                            key={index}
                            className="employee-profile-count"
                            onClick={() =>
                              getAllProjectFilterData(
                                cluster.tpm_employee_id._id
                              )
                            }
                          >
                            <h4 className="empl-memeber">
                              {cluster?.cluster_Name}
                            </h4>
                            <Box className="depar-empl-icone">
                              <h4 className="empl-memeber-count-emp">
                                {cluster?.memberCount}
                              </h4>
                              <LiaGreaterThanSolid />
                            </Box>
                          </Box>
                        ))
                      )}
                    </Box>
                  </Box>
                  <Box className="right-tab-main custom-right-tab-main">
                    <Box className="main-table-data">
                      <div className="tasklisttbl emp-tracl-tbl">
                        <div className="backlog-sprint-task-status custom-sprint-task-status">
                          <Select
                            className="custom-select"
                            labelId="filter-label"
                            id="filter"
                            value={selectedFilter}
                            onChange={handleFilterChange}
                          >
                            <MenuItem value="all" style={{ fontSize: "12px" }}>
                              All
                            </MenuItem>
                            <MenuItem
                              value="upcoming"
                              style={{ fontSize: "12px" }}
                            >
                              Upcoming
                            </MenuItem>
                            <MenuItem
                              value="overdue"
                              style={{ fontSize: "12px" }}
                            >
                              Overdue
                            </MenuItem>
                          </Select>
                        </div>
                        <MDBDataTableV5
                          className="tasklisttblss data"
                          hover
                          entriesOptions={[10, 20, 40, 50]}
                          entries={10}
                          pagesAmount={10}
                          data={modifiedDatatable}
                          searchTop
                          searchBottom={false}
                        />
                      </div>
                    </Box>
                  </Box>
                </Box>
              </CustomTabPanel>
            </Box>
          )}
        </MDBCard>
      </div>
    </>
  );
}
