import { MDBDataTableV5, MDBCard } from "mdbreact";
import { MDBRow, MDBCol, MDBIcon, MDBBadge } from "mdb-react-ui-kit";
import React, { useState, useEffect } from "react";
import AxiosInstance from "../../../service/AxiosInstance";
import moment from "moment/moment";
import DateDurationDatePicker from "../DateDurationDatePicker";
import { Button, DatePicker } from "antd";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { ExportOutlined } from "@ant-design/icons";
import { Modal, Form, Input, Row, Col, Select } from "antd";
import PmsRangePicker from "../me/leaveModule/RangePicker";

const EmployeePresentList = () => {
  const [employeePresentData, setEmplopyeePresentData] = useState([]);
  const [datatable, setDatatable] = useState({});
  const [DateRange, setDateRange] = useState([]);

  const [visible, setVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [employeeDataById, setemployeeDataById] = useState([]);
  const [dataId, setDataId] = useState("");
  const [attendanceStatus, setAttendanceStatus] = useState("");
  const [attendanceStatusDate, setAttendanceStatusDate] = useState("");
  const [form] = Form.useForm();

  const handleCancel = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setVisible(false);
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const GetEmployeePresentList = () => {
    AxiosInstance.get("getEmployeePresentList").then(async (res) => {
      let list2 = res.data.data;

      if (DateRange?.length > 0 && DateRange[0] !== "" && DateRange[1] !== "") {
        list2 = list2.filter((item, index) => {
          item.Date = moment(item.Date).format("DD/MM/YYYY");
          return item.Date >= DateRange[0] && item.Date <= DateRange[1];
        });
      }

      let list1 = list2.map((item, index) => {
        return {
          icon: index + 1,
          Date: item?.Date.includes("/")
            ? item?.Date
            : moment(item?.Date).format("DD/MM/YYYY"),
          Name: item ? item.Name : "-",
          Code: item ? item?.Code : "-",
          Working_days: item?.Working_days,
          Full_leave: item?.Full_leave,
          Half_leave: item?.Half_leave,
          Missed_punch: item?.Missed_punch,
          WFM: item?.WFM,
          Carry_forward: item?.Carry_forward,
          Total_days: item?.Total_days,
          Leave_status: item?.Attendence_Status,
          Attendence_Status_Date: moment(item?.Attendence_Status_Date).format(
            "YYYY-MM-DD"
          ),
          Allow: item ? item.Allow : "-",
          Grand_Total: item ? item.Grand_Total : "-",
          Action: (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <MDBIcon
                fas
                icon="edit"
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => {
                  setDataId(item?._id);
                  setVisible(true);
                }}
              />{" "}
            </div>
          ),
        };
      });
      setEmplopyeePresentData(list1);
    });
  };

  console.log("Data id is", dataId);
  console.log("employee data by id is", employeeDataById);

  const getEmployeePresentDataById = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", dataId);
    AxiosInstance.post("getEmployeePresentDetailsById", urlencoded).then(
      (res) => {
        setemployeeDataById(res.data.data);
      }
    );
  };

  useEffect(() => {
    getEmployeePresentDataById();
  }, [dataId]);

  useEffect(() => {
    form.setFieldsValue({
      update_name: employeeDataById?.[0]?.Name,
      code: employeeDataById?.[0]?.Code,
      working_days: employeeDataById?.[0]?.Working_days,
      full_leaves: employeeDataById?.[0]?.Full_leave,
      half_leaves: employeeDataById?.[0]?.Half_leave,
      missed_punch: employeeDataById?.[0]?.Missed_punch,
      WFM: employeeDataById?.[0]?.WFM,
      carray_forward: employeeDataById?.[0]?.Carry_forward,
      total_days: employeeDataById?.[0]?.Total_days,
      allow: employeeDataById?.[0]?.Allow,
      grand_total: employeeDataById?.[0]?.Grand_Total,

      //attendance_status: employeeDataById?.[0]?.Attendence_Status,
      // attendanceStatusDate:
      // employeeDataById?.[0]?.Attendence_Status_Date &&
      //   moment(
      //     moment(employeeDataById?.[0]?.Attendence_Status_Date).format('DD/MM/YYYY'),
      //     'DD/MM/YYYY',
      //   ),
    });
  }, [dataId, employeeDataById]);

  useEffect(() => {
    GetEmployeePresentList();
  }, [DateRange]);

  const onFinish = (values) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", dataId);
    urlencoded.append("Name", values?.update_name);
    urlencoded.append("Code", values?.code);
    urlencoded.append("Working_days", values?.working_days);
    urlencoded.append("Full_leave", values?.full_leaves);
    urlencoded.append("Half_leave", values?.half_leaves);
    urlencoded.append("Missed_punch", values?.missed_punch);
    urlencoded.append("WFM", values?.WFM);
    urlencoded.append("Carry_forward", values?.carray_forward);
    urlencoded.append("Total_days", values?.total_days);
    urlencoded.append("Allow", values?.allow);
    urlencoded.append("Grand_Total", values?.grand_total);
    //urlencoded.append('Attendence_Status', values?.attendance_status)
    //urlencoded.append('Attendence_Status_Date', values?.attendanceStatusDate)

    AxiosInstance.post("updateEmployeePresentDetailsById", urlencoded).then(
      (res) => {
        console.log(res.data.data);
        setVisible(false);
        GetEmployeePresentList();
      }
    );
  };
  useEffect(() => {
    setDatatable({
      columns: [
        {
          label: "No",
          field: "icon",
          sort: "disabled",
          width: 10,
        },
        {
          label: "Date",
          field: "Date",
          width: 20,
          sort: "disabled",
        },

        {
          label: "Name",
          field: "Name",
          width: 100,
          sort: "disabled",
        },
        {
          label: "Code",
          field: "Code",
          width: 10,
          sort: "disabled",
        },

        {
          label: "Working Days",
          field: "Working_days",
          width: 10,
          sort: "disabled",
        },
        {
          label: "Full Leave",
          field: "Full_leave",
          width: 10,
          sort: "disabled",
        },
        {
          label: "Half Leave",
          field: "Half_leave",
          width: 10,
          sort: "disabled",
        },

        {
          label: "Missed Punch",
          field: "Missed_punch",
          width: 10,
          sort: "disabled",
        },
        {
          label: "WFM",
          field: "WFM",
          width: 10,
          sort: "disabled",
        },
        {
          label: "Carry Forward",
          field: "Carry_forward",
          sort: "disabled",
          width: 10,
        },

        {
          label: "Total Days",
          field: "Total_days",
          sort: "disabled",
          width: 10,
        },

        {
          label: "Allow",
          field: "Allow",
          sort: "disabled",
          width: 10,
        },
        {
          label: "Grand Total",
          field: "Grand_Total",
          sort: "disabled",
          width: 10,
        },
        {
          label: "Status",
          field: "Leave_status",
          sort: "disabled",
          width: 10,
        },
        {
          label: "Attendence Status Date",
          field: "Attendence_Status_Date",
          sort: "disabled",
          width: 10,
        },
        {
          label: "Action",
          field: "Action",
          sort: "disabled",
          width: 100,
        },
      ],
      rows: employeePresentData,
    });
  }, [employeePresentData]);
  const checkAction = () => {
    exportAll(employeePresentData, "Employee Data");
  };
  const exportAll = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    delete ws["H1"];
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <>
      <div className="add-emp-pre-date ">
        <PmsRangePicker
          allowClear={true}
          onChange={(values) => {
            if (values == null) {
              setDateRange("");
            } else {
              setDateRange(
                values.map((item) => {
                  return moment(item).format("DD/MM/YYYY");
                })
              );
            }
          }}
        />
      </div>
      <div>
        <div className="emp-preset-table">
          <MDBDataTableV5
            hover
            entriesOptions={[10, 20, 25]}
            entries={10}
            pagesAmount={4}
            data={datatable}
            searchTop
            searchBottom={false}
          />
        </div>{" "}
      </div>{" "}
      {/* Edit Modal */}
      <Button
        type="button"
        className="ant-btn ant-btn-primary export-btn pms-same-btn"
        onClick={() => checkAction()}
      >
        <ExportOutlined />
      </Button>
      <Modal
        title="Edit Details"
        centered
        // visible={visible}
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={handleCancel}
        width={800}
        height={640}
        bodyStyle={{ padding: "20px 0px" }}
        footer={null}
        maskClosable={false}
      >
        {employeeDataById ? (
          <Form
            form={form}
            name="control-hooks"
            layout="vertical"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            //onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row style={{ padding: "0px 20px" }}>
              <Col span={12} style={{ padding: "5px 10px" }}>
                <div style={{ marginBottom: "5px" }}>
                  Name<span style={{ color: "red" }}>*</span>
                </div>
                <Form.Item name="update_name">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} style={{ padding: "5px 10px" }}>
                <div style={{ marginBottom: "5px" }}>
                  Code<span style={{ color: "red" }}>*</span>
                </div>
                <Form.Item name="code">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} style={{ padding: "5px 10px" }}>
                <div style={{ marginBottom: "5px" }}>
                  Working Days<span style={{ color: "red" }}>*</span>
                </div>
                <Form.Item name="working_days">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} style={{ padding: "5px 10px" }}>
                <div style={{ marginBottom: "5px" }}>
                  Full leave<span style={{ color: "red" }}>*</span>
                </div>
                <Form.Item name="full_leaves">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} style={{ padding: "5px 10px" }}>
                <div style={{ marginBottom: "5px" }}>
                  Half leave<span style={{ color: "red" }}>*</span>
                </div>
                <Form.Item name="half_leaves">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} style={{ padding: "5px 10px" }}>
                <div style={{ marginBottom: "5px" }}>
                  Missed punch<span style={{ color: "red" }}>*</span>
                </div>
                <Form.Item name="missed_punch">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} style={{ padding: "5px 10px" }}>
                <div style={{ marginBottom: "5px" }}>
                  WFM<span style={{ color: "red" }}>*</span>
                </div>
                <Form.Item name="WFM">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} style={{ padding: "5px 10px" }}>
                <div style={{ marginBottom: "5px" }}>
                  Carry Forward<span style={{ color: "red" }}>*</span>
                </div>
                <Form.Item name="carray_forward">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} style={{ padding: "5px 10px" }}>
                <div style={{ marginBottom: "5px" }}>
                  Total days<span style={{ color: "red" }}>*</span>
                </div>
                <Form.Item name="total_days">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} style={{ padding: "5px 10px" }}>
                <div style={{ marginBottom: "5px" }}>
                  Allow<span style={{ color: "red" }}>*</span>
                </div>
                <Form.Item name="allow">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} style={{ padding: "5px 10px" }}>
                <div style={{ marginBottom: "5px" }}>
                  Grant total<span style={{ color: "red" }}>*</span>
                </div>
                <Form.Item name="grand_total">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            {/* <Row style={{ padding: "0px 39px" }}>
              <Col span={24} style={{ paddingRight: "12px" }}>
                <Form.Item
                  label="Attendance Status"
                  name="attendance_status"
                  rules={[
                    {
                      required: true,
                      message: "Please Select status",
                      whitespace: true,
                    },
                  ]}
                >
                  <Select
                    onChange={(e) => {
                      setAttendanceStatus(e);
                    }}
                  >
                    <Select.Option value="Pending">Pending</Select.Option>
                    <Select.Option value="Approved">Approved</Select.Option>
                    <Select.Option value="Issue">Issue</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row> */}

            {/* <Row style={{ padding: '0px 39px' }}>
              <Col span={24} style={{ padding: '5px 10px' }}>
                <Form.Item label="Attendance status date" name="attendanceStatusDate">
                  <DatePicker
                  onChange={(date)=> {setAttendanceStatusDate(date._d)}}
                    format={'DD/MM/YYYY'}

                  />
                </Form.Item>
              </Col>
            </Row> */}

            <Form.Item>
              <div
                style={{
                  textAlign: "end",
                  marginRight: "32px",
                  marginTop: "15px",
                }}
              >
                <Button
                  className="pms-same-btn"
                  type="primary"
                  htmlType="submit"
                  // onClick={chackDescription}
                  style={{ marginRight: "10px" }}
                >
                  Submit
                </Button>
                <Button className="pms-same-btn-cancel" onClick={handleCancel}>
                  Cancel
                </Button>
              </div>
            </Form.Item>
          </Form>
        ) : null}
      </Modal>
    </>
  );
};

export default EmployeePresentList;
