import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { MDBNavbarItem, MDBNavbarLink, MDBNavbarNav } from "mdb-react-ui-kit";
import { Link, useParams, useLocation } from "react-router-dom";
import MyAttendance from "../attendance/MyAttendance";
import MyDailyLogs from "../me/myDailyLogs/MyDailyLogs";
import OtherDetails from "./OtherDetails";
import List from "../me/leaveModule/List";
const OtherDetailsTab = ({ OtherDetail }) => {
  const params = useParams();
  const location = useLocation();
  const [tabData, setTabData] = useState("");
  console.log(tabData, "setTabData");
  return (
    <>
      <MDBNavbarNav className="employee-new-tab">
        <MDBNavbarItem>
          {/* <Link to={!OtherDetail && `/employeeOtherDetail/${params?.id}`}> */}
          <Link>
            {" "}
            <MDBNavbarLink
              className={
                tabData==""?"active":""
                // OtherDetail
                //   ? "active"
                //   : location.pathname ===
                //       `/employeeOtherDetail/${params?.id}` && "active"
              }
              aria-current="page"
              href="#"
              onClick={() => 
                setTabData("")}
            >
              My Leave
            </MDBNavbarLink>
          </Link>
        </MDBNavbarItem>
        <MDBNavbarItem>
          {/* <Link to={!OtherDetail && `/employeeAttendance/${params?.id}`}> */}
          <Link>
            {" "}
            <MDBNavbarLink
              aria-current="page"
              href="#"
              className={
                tabData=="My attendance"?"active":""
                // location.pathname === `/employeeAttendance/${params?.id}` &&
                // "active"
              }
              onClick={() => 
                
                setTabData("My attendance")
              }
            >
              My attendance
            </MDBNavbarLink>
          </Link>
        </MDBNavbarItem>
        <MDBNavbarItem>
          {/* <Link to={`/employeeDailyLogs/${params?.id}`}> */}
          <Link>
            {" "}
            <MDBNavbarLink
              aria-current="page"
              href="#"
              className={
                 tabData=="MyDailyLogs"?"active":''
                // location.pathname === `/employeeDailyLogs/${params?.id}` &&
                // "active"
              }
              onClick={() => setTabData("MyDailyLogs")}
            >
              My Daily Logs
            </MDBNavbarLink>
          </Link>
        </MDBNavbarItem>
      </MDBNavbarNav>
      {tabData==""&& <List id={params?.id} />}
      {tabData=="My attendance"&& <MyAttendance OtherDetail="OtherDetail"/>}
      {tabData=="MyDailyLogs"&& <MyDailyLogs OtherDetail="OtherDetail"/>}

    </>
  );
};

export default OtherDetailsTab;
