import React, { useEffect, useState } from "react";

import "antd/dist/antd.css";
import { useHistory, Link } from "react-router-dom";
import SelectEmpployee from "../../../components/common/SelectEmpployee";
import moment from "moment";
import { MDBNavbarItem, MDBNavbarLink, MDBNavbarNav } from "mdb-react-ui-kit";
import Permission from "../Permission";
import AxiosInstance from "../../../service/AxiosInstance";
import { useSelector } from "react-redux";

const MeBar = (props) => {
  const permssionsState = useSelector((state) => state.Permissions.Permissions);
  const [rolePermissions, setRolepermissions] = useState(permssionsState);

  let userData = JSON.parse(localStorage.getItem("userdata"));

  return (
    <>
      <MDBNavbarNav className="page-menu-nav">
        <MDBNavbarItem>
          <Link to="/me" active>
            {" "}
            <MDBNavbarLink active aria-current="page" href="#">
              My Leaves
            </MDBNavbarLink>
          </Link>
        </MDBNavbarItem>
        <MDBNavbarItem>
          <Link to="/myattendance" active>
            {" "}
            <MDBNavbarLink active aria-current="page" href="#">
              Attendance
            </MDBNavbarLink>
          </Link>
        </MDBNavbarItem>
        <MDBNavbarItem>
          <Link to="/mydailylogs" active>
            {" "}
            <MDBNavbarLink active aria-current="page" href="#">
              My Daily Logs
            </MDBNavbarLink>
          </Link>
        </MDBNavbarItem>
        {/* <MDBNavbarItem>
          <Link to="/overtime">
            {" "}
            <MDBNavbarLink>Overtime</MDBNavbarLink>
          </Link>
        </MDBNavbarItem>{" "} */}
        <MDBNavbarItem>
          {rolePermissions?.Me?.Team_Leave == "1" ? (
            <Link to="/teamleave" active>
              {" "}
              <MDBNavbarLink active aria-current="page" href="#">
                Team Leave
              </MDBNavbarLink>
            </Link>
          ) : (
            ""
          )}
        </MDBNavbarItem>
      </MDBNavbarNav>
    </>
  );
};

export default MeBar;
