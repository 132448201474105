
import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useHistory, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import ReactLoader from "../../components/common/ReactLoader";

import jwtDecode from "jwt-decode";
import { useSelector } from "react-redux";
import { Routes } from "../../routes/routes";

const loading = () => <ReactLoader />;

const DefaultLayout = () => {
  const history = useHistory();
  const token = localStorage.getItem("token");
  const [checkInCheckOut, setCheckInCheckOut] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const permssionsState = useSelector((state) => state?.Permissions?.Permissions);

  

  useEffect(() => {
    const isValidToken = (accessToken) => {
      if (!accessToken) return false;
      const decodedToken = jwtDecode(accessToken);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp > currentTime;
    };

    const interval = setInterval(() => {
      if (!isValidToken(token)) {
        clearInterval(interval);
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        history.push("/login");
      }
    }, 600000); 

    return () => clearInterval(interval);
  }, [token, history]);

  const handleModeChange = () => {
    setDarkMode(!darkMode);
    if (darkMode) {
      document.body.classList.add("darkmode-modal");
    } else {
      document.body.classList.remove("darkmode-modal");
    }
  };

  const hasPermission = (route) => {
    if(route?.objectkey==undefined){
      return true
    }
    return permssionsState[route?.objectkey]?.[route?.permissionKey] === "1";
  };

  return (
    <div>
      {token ? (
        <div className={`flexible-content ${darkMode ? 'darkmode' : ''}`}>
          <Sidebar />
          <main id="content">
            <Header
              checkInCheckOut={checkInCheckOut}
              updateMode={handleModeChange}
            />
            <React.Suspense fallback={loading()}>
              <Switch>
                {Routes?.map((route, idx) => {
                  return hasPermission(route) ? route.component && (
                    <Route
                      key={idx}
                      path={route?.path}
                      exact={route?.exact}
                      name={route?.name}
                      render={(props) => (
                        <route.component
                          {...props}
                          setCheckInCheckOut={setCheckInCheckOut}
                        />
                      )}
                    />
                  ) : null;
                })}
                <Redirect from='/' to="/dashboard" />
              </Switch>
            </React.Suspense>
          </main>
          <Footer />
        </div>
      ) : (
        <Redirect to="/login" />
      )}
    </div>
  );
};

export default DefaultLayout;
