import React, { useEffect, useState } from "react";
import AxiosInstance from "../../../../service/AxiosInstance";
import { Png } from "../../../../assets/png";
import {
  IconButton,
  Box,
  Typography,
  TableContainer,
  Paper,
  Table,
  Menu,
  MenuItem,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  LinearProgress,
  TextField,
  InputAdornment,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BugReportIcon from "@mui/icons-material/BugReport";
import PersonIcon from "@mui/icons-material/Person";
import BlockIcon from "@mui/icons-material/Block";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Link } from "react-router-dom";
import PmsRangePicker from "../../me/leaveModule/RangePicker";
import SearchIcon from "@mui/icons-material/Search";

import "react-toastify/dist/ReactToastify.css";

import { CgCalendarDates } from "react-icons/cg";
import { MDBCard, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import { Select } from "antd";
const { Option } = Select;

const MilestoneList2 = () => {
  const [openProject, setOpenProject] = useState(null);
  const [milestoneList, setMilestoneList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [groupBy, setGroupBy] = useState("Project");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState([]);
  const [searchQuery, setSearchQuery] = useState([]);

  const handleChangePage = (evemt, newPage) => {

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {

    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };
  const handleGroupBy = (groupBy) => {
    console.log(groupBy);
    setGroupBy(groupBy);
    handleMenuClose();
  };

  function formatDate(dateString, isDueDate) {
    if (dateString) {
      const date = new Date(dateString);
      const currentDate = new Date();
      const diffInDays = Math.floor(
        (date - currentDate) / (1000 * 60 * 60 * 24)
      );

      if (isDueDate) {
        if (diffInDays < 0) {
          return (
            <span style={{ color: "red" }}>
              {`${date.getDate().toString().padStart(2, "0")}-${(
                date.getMonth() + 1
              )
                .toString()
                .padStart(2, "0")}-${date.getFullYear()} (${Math.abs(
                diffInDays
              )} days ago)`}
            </span>
          );
        } else {
          return (
            <span style={{ color: "green" }}>
              {`${date.getDate().toString().padStart(2, "0")}-${(
                date.getMonth() + 1
              )
                .toString()
                .padStart(
                  2,
                  "0"
                )}-${date.getFullYear()} (${diffInDays} days to go)`}
            </span>
          );
        }
      } else {
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      }
    } else {
      return "-";
    }
  }

  const getMilestoneList = async () => {
    setLoading(true);
    try {
      const res = await AxiosInstance.get("milestone/newReport");
      setMilestoneList(res?.data?.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getMilestoneList();
  }, []);

  useEffect(() => {
    console.log(startDate, endDate, status);
  }, [startDate, endDate, status]);

  const onChange = (value) => {
    if (!value || !value[0] || !value[1]) {
      setStartDate(null);
      setEndDate(null);
    } else {
      setStartDate(value[0]);
      setEndDate(value[1]);
    }
  };

  const filteredMilestoneList = milestoneList.filter((item) => {
    const itemStartDate = new Date(item?.milestones?.[0]?.milestone?.StartDate);
    const itemEndDate = new Date(item?.milestones?.[0]?.milestone?.DueDate);

    const startDateMatch =
      !startDate || areDatesEqual(itemStartDate, startDate);
    const endDateMatch = !endDate || areDatesEqual(itemEndDate, endDate);
    const statusMatch =
      status.length === 0 ||
      status.includes(item?.milestones?.[0]?.milestone?.Status);

    const searchQueryMatch = !searchQuery || matchesSearchQuery(item);

    return startDateMatch && endDateMatch && statusMatch && searchQueryMatch;
  });

  function areDatesEqual(date1, date2) {
    return (
      date1.toISOString().slice(0, 10) === date2.toISOString().slice(0, 10)
    );
  }

  function matchesSearchQuery(item) {
    const searchRegex = new RegExp(searchQuery, "i");
    const projectName = item?.project?.Project_name || "";
    const milestoneName = item?.milestones?.[0]?.milestone?.Name || "";
    const pmNAME = item?.project?.Project_manager || "";
    const chName = item?.project?.Technical_project_manager || "";

    return (
      searchRegex.test(projectName) ||
      searchRegex.test(milestoneName) ||
      searchRegex.test(pmNAME) ||
      searchRegex.test(chName)
    );
  }

  const handleSearch = (e) => {
    setSearchQuery(e?.target?.value);
  };

  return (
    <div className="demo">
      <MDBCard className="page-content">
        <div className="create-project">
          <MDBCol size="md" className="col-example titel-set">
            <>
              <div className="leave-set-btn2">
                <h3 className="tablHeader">Milestone Report</h3>
              </div>
            </>
          </MDBCol>
        </div>

        <hr />

        <Box className="bottom_main">
          <Box className="bottom_header">
            <Box
              className={`bottom_left ${isHovered ? "show-icons" : ""}`}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Box className="group_by">
                <Typography onClick={handleMenuOpen}>
                  Group by {groupBy}
                  <ArrowDropDownIcon />
                </Typography>
                <Menu
                  anchorEl={menuAnchor}
                  open={Boolean(menuAnchor)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={() => handleGroupBy("Project")}>
                    <MDBIcon icon="briefcase" className="project_icon" />
                    Project
                  </MenuItem>
                  <MenuItem onClick={() => handleGroupBy("None")}>
                    <BlockIcon className="none_icon"> </BlockIcon>
                    <Link to="/milestonesList">None</Link>
                  </MenuItem>
                </Menu>
              </Box>

              <Box className="right-side-my">
                <Box className="milestone_search_box">
                  <TextField
                    type="text"
                    className="cus-milestone_search_box"
                    placeholder="Search"
                    onChange={(e) => handleSearch(e)}
                    value={searchQuery}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <div className="row cus-row">
                  <MDBCol size="md-6 cus-main-md">
                    <label className="start-list">Due Date</label>
                    <PmsRangePicker
                      className="search-rangepicker"
                      format="DD-MM-YYYY"
                      onChange={onChange}
                    />
                  </MDBCol>
                  <MDBCol size="md-6 cus-main-md">
                    <label className="start-list">Status</label>
                    <Select
                      placeholder="Status"
                      mode="multiple"
                      className="select-dropdown cus-select-dropdown leave-4"
                      onChange={(e) => {
                        console.log(e);
                        setStatus(e);
                      }}
                    >
                      <Option value="Pending">Pending</Option>
                      <Option value="In Progress">In Progress</Option>
                      <Option value="In Client Review">In Client Review</Option>
                      <Option value="On Hold">On Hold</Option>
                      <Option value="Completed">Completed</Option>
                    </Select>
                  </MDBCol>
                </div>
              </Box>
            </Box>
            <Box className="tableContainer">
              <TableContainer component={Paper} className="cus-table-container">
                <Table aria-label="collapsible table" className="table">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Milestone</TableCell>
                      <TableCell>
                        <PersonIcon className="person-icon"></PersonIcon>
                        PM
                      </TableCell>
                      <TableCell>
                        <PersonIcon className="person-icon"></PersonIcon>
                        CH
                      </TableCell>
                      <TableCell>
                        {" "}
                        <img
                          className="status_icon"
                          src={Png.status}
                          alt="Status"
                        />
                        Status
                      </TableCell>
                      <TableCell>
                        {" "}
                        <CgCalendarDates className="person-icon"></CgCalendarDates>
                        Start Date
                      </TableCell>
                      <TableCell>
                        {" "}
                        <CgCalendarDates className="person-icon"></CgCalendarDates>
                        End Date
                      </TableCell>
                      <TableCell>
                        <img className="filter_icon" src={Png.task}></img>
                        Tasks
                      </TableCell>
                      <TableCell>
                        {" "}
                        <BugReportIcon className="filter_icon" />
                        Issues
                      </TableCell>
                      <TableCell>
                        <img className="filter_icon" src={Png.task}></img>
                        Sprints
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredMilestoneList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((row, index) => (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell className="table-data-button-main">
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                className="my-button"
                                onClick={() =>
                                  setOpenProject(
                                    openProject === row?.project?.Project_name
                                      ? null
                                      : row?.project?.Project_name
                                  )
                                }
                              >
                                {openProject === row?.project?.Project_name ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell
                              className="project_name"
                              sx={{ fontWeight: "bold", fontSize: "10%" }}
                            >
                              {" "}
                              <MDBIcon
                                icon="briefcase"
                                className="status_icon"
                              />
                              {row?.project?.Project_name}
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          {openProject === row?.project?.Project_name &&
                            row?.milestones?.map((milestone, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                  {milestone?.milestone?.Name}
                                </TableCell>
                                <TableCell>
                                  {row?.project?.Project_manager?.replace(
                                    /\([^)]*\)/,
                                    ""
                                  )}
                                </TableCell>
                                <TableCell>
                                  {row?.project?.Technical_project_manager?.replace(
                                    /\([^)]*\)/,
                                    ""
                                  )}
                                </TableCell>

                                <TableCell>
                                  {milestone?.milestone?.Status ===
                                  "In Progress" ? (
                                    <Box
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        backgroundColor: "#385CDC",
                                        color: "white",
                                        display: "inline-block",
                                        padding: "14.5px 14.5px",
                                      }}
                                    >
                                      {milestone?.milestone?.Status}
                                    </Box>
                                  ) : milestone?.milestone?.Status ===
                                    "Completed" ? (
                                    <Box
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        backgroundColor: "#375E14",
                                        color: "white",
                                        display: "inline-block",
                                        padding: "15px 15px",
                                      }}
                                    >
                                      {milestone?.milestone?.Status}
                                    </Box>
                                  ) : milestone?.milestone?.Status ===
                                    "Active" ? (
                                    <Box
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        backgroundColor: "#74CB80",
                                        color: "white",
                                        display: "inline-block",
                                        padding: "17px 17px",
                                      }}
                                    >
                                      {milestone?.milestone?.Status}
                                    </Box>
                                  ) : milestone?.milestone?.Status ===
                                    "DeActive" ? (
                                    <Box
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        backgroundColor: "grey",
                                        color: "white",
                                        display: "inline-block",
                                        padding: "23px 23px",
                                      }}
                                    >
                                      {milestone?.milestone?.Status}
                                    </Box>
                                  ) : milestone?.milestone?.Status ===
                                    "Client Not Responding" ? (
                                    <Box
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        backgroundColor: "#C5A070",
                                        color: "white",
                                        display: "inline-block",
                                        padding: "16px",
                                      }}
                                    >
                                      {milestone?.milestone?.Status}
                                    </Box>
                                  ) : milestone?.milestone?.Status ===
                                    "On Hold" ? (
                                    <Box
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        backgroundColor: "#385CDC",
                                        color: "white",
                                        display: "inline-block",
                                        padding: "23px 23px",
                                      }}
                                    >
                                      {milestone?.milestone?.Status}
                                    </Box>
                                  ) : milestone?.Status ===
                                    "In Client Review" ? (
                                    <Box
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        backgroundColor: "#F9EEA6",
                                        color: "white",
                                        display: "inline-block",
                                        padding: "16px",
                                      }}
                                    >
                                      {milestone?.milestone?.Status}
                                    </Box>
                                  ) : milestone?.milestone?.Status ===
                                    "Pending" ? (
                                    <Box
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        backgroundColor: "#08AEEA",
                                        color: "white",
                                        display: "inline-block",
                                        padding: "18px 18px",
                                        margin: "0%",
                                      }}
                                    >
                                      {milestone?.milestone?.Status}
                                    </Box>
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                                <TableCell>
                                  {formatDate(
                                    milestone?.milestone?.StartDate,
                                    false
                                  )}
                                </TableCell>
                                <TableCell>
                                  {formatDate(
                                    milestone?.milestone?.DueDate,
                                    true
                                  )}
                                </TableCell>
                                <TableCell>
                                  <div className="project_table_progress_bar">
                                    <span>
                                      {milestone?.milestone?.totalTaskCompleted}
                                    </span>
                                    <Box className="my-progress">
                                      {milestone?.milestone?.totalTask > 0 ? (
                                        <LinearProgress
                                          className="progress_bar"
                                          value={
                                            (milestone?.milestone
                                              ?.totalTaskCompleted /
                                              milestone?.milestone?.totalTask) *
                                            100
                                          }
                                          color="success"
                                          variant="determinate"
                                        />
                                      ) : (
                                        <LinearProgress
                                          className="progress_bar"
                                          value={0}
                                          color="success"
                                          variant="determinate"
                                        />
                                      )}
                                      <span className="progress-value">
                                        {milestone?.milestone?.totalTask > 0
                                          ? `${Math.round(
                                              (milestone?.milestone
                                                ?.totalTaskCompleted /
                                                milestone?.milestone
                                                  ?.totalTask) *
                                                100
                                            )}%`
                                          : "0%"}
                                      </span>
                                    </Box>
                                    <span>
                                      {milestone?.milestone?.totalTask}
                                    </span>
                                  </div>
                                </TableCell>

                                <TableCell>
                                  <div className="project_table_progress_bar">
                                    <span>
                                      {milestone?.milestone?.totalBugCompleted}
                                    </span>
                                    <Box className="my-progress">
                                      {milestone?.milestone?.totalBug > 0 ? (
                                        <LinearProgress
                                          className="progress_bar"
                                          value={
                                            (milestone?.milestone
                                              ?.totalBugCompleted /
                                              milestone?.milestone?.totalBug) *
                                            100
                                          }
                                          color="success"
                                          variant="determinate"
                                        />
                                      ) : (
                                        <LinearProgress
                                          className="progress_bar"
                                          value={0}
                                          color="success"
                                          variant="determinate"
                                        />
                                      )}
                                      <span className="progress-value">
                                        {milestone?.milestone?.totalBug > 0
                                          ? `${Math.round(
                                              (milestone?.milestone
                                                ?.totalBugCompleted /
                                                milestone?.milestone
                                                  ?.totalBug) *
                                                100
                                            )}%`
                                          : "0%"}
                                      </span>
                                    </Box>
                                    <span>
                                      {milestone?.milestone?.totalBug}
                                    </span>
                                  </div>
                                </TableCell>

                                <TableCell>
                                  <div className="project_table_progress_bar">
                                    <span>
                                      {
                                        milestone?.milestone
                                          ?.totalSprintCompleted
                                      }
                                    </span>
                                    <Box className="my-progress">
                                      {milestone?.milestone?.totalSprint > 0 ? (
                                        <LinearProgress
                                          className="progress_bar"
                                          value={
                                            (milestone?.milestone
                                              ?.totalSprintCompleted /
                                              milestone?.milestone
                                                ?.totalSprint) *
                                            100
                                          }
                                          color="success"
                                          variant="determinate"
                                        />
                                      ) : (
                                        <LinearProgress
                                          className="progress_bar"
                                          value={0}
                                          color="success"
                                          variant="determinate"
                                        />
                                      )}
                                      <span className="progress-value">
                                        {milestone?.milestone?.totalSprint > 0
                                          ? `${Math.round(
                                              (milestone?.milestone
                                                ?.totalSprintCompleted /
                                                milestone?.milestone
                                                  ?.totalSprint) *
                                                100
                                            )}%`
                                          : "0%"}
                                      </span>
                                    </Box>
                                    <span>
                                      {milestone?.milestone?.totalSprint}
                                    </span>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                        </React.Fragment>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className="pagination"
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={milestoneList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </Box>
        </Box>
      </MDBCard>
    </div>
  );
};

export default MilestoneList2;
