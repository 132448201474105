import React, { useEffect, useState } from "react";
import { Button, Col, DatePicker, Form, Row, Select, Space, Table } from "antd";

import MeBar from "../me/MeBar";

import AttendanceDetails from "./AttendanceDetails";
import { ExportOutlined } from "@ant-design/icons";
import "jspdf-autotable";
import jsPDF from "jspdf";
import moment from "moment";
import { useParams } from "react-router-dom";
import EmployeeTab from "../employee_master/EmployeeTab";
import OtherDetailsTab from "../employee_master/OtherDetailsTab";
import AxiosInstance from "../../../service/AxiosInstance";
import { ToastContainer, toast } from "react-toastify";

const MyAttendance = ({ OtherDetail }) => {
  const [form] = Form.useForm();
  const [year, setYear] = useState(moment(new Date()).year());
  const [month, setMonth] = useState(moment(new Date()).month() + 1);
  const [employees, Setemployees] = useState([]);

  let userData = JSON.parse(localStorage.getItem("userdata"));
  const userId = userData[0]?._id;

  //Here redereing same componenets from employee other details tab so Getting user id from params
  const params = useParams();
  const userPramasid = params?.id;

  const onFinish = (values) => {
    setMonth(values?.month);
    setYear(values?.year);

    const urlencoded = new URLSearchParams();
    urlencoded.append("month", values?.month);
  };
  const handledownloadSalarySlip = (userId) => {
    // alert(userId)
  };
  const downloadSlarySlip = () => {
    AxiosInstance.post(
      `/auth/emaplyee/download-salary-slip?month=${month}&&year=${year}`
    ).then((res) => {
      toast.success(res.data.message);
    });
  };
  return (
    <>
      <div className="event-main event-main2">
        {/* {!userPramasid ? (
          <MeBar />
        ) : (
          <>
          
            <EmployeeTab />
            <OtherDetailsTab />
          
          </>
        )} */}
        {!userPramasid || !OtherDetail ? (
          <MeBar />
        ) : (
          <>
            {!OtherDetail && (
              <>
                <EmployeeTab />
                <OtherDetailsTab />
              </>
            )}
          </>
        )}
        <div className="page-content">
          <div className="attendance-head attendance-year ">
            <p className="tablHeader">My Attendance</p>
            <div className="attendance-year">
              <span className="btn-right right-emp-btn attendance-btn"></span>
              <p>For The Period</p>
              <Space direction="horizontal">
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <Row>
                    <Col style={{ paddingRight: "12px" }}>
                      <Form.Item
                        // label="Select month"
                        name="month"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Month",
                          },
                        ]}
                        initialValue={month < 10 ? `0${month}` : month}
                      >
                        <Select showSearch="true" placeholder="Select month">
                          <Select.Option value="01">January</Select.Option>
                          <Select.Option value="02">February</Select.Option>
                          <Select.Option value="03">March</Select.Option>
                          <Select.Option value="04">April</Select.Option>
                          <Select.Option value="05">May</Select.Option>
                          <Select.Option value="06">June</Select.Option>
                          <Select.Option value="07">July</Select.Option>
                          <Select.Option value="08">August</Select.Option>
                          <Select.Option value="09">September</Select.Option>
                          <Select.Option value="10">October</Select.Option>
                          <Select.Option value="11">November</Select.Option>
                          <Select.Option value="12">December</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col style={{ paddingRight: "12px" }}>
                      <Form.Item
                        // label="Select Year"
                        name="year"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Year",
                          },
                        ]}
                        initialValue={year ? year : ""}
                      >
                        <Select showSearch="true" placeholder="Select year">
                          {/* <Select.Option value="2023">2023</Select.Option> */}
                          <Select.Option value="2024">2024</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col style={{ paddingRight: "12px" }}>
                      <Form.Item>
                        <div className="pms-same-btn-box">
                          <Button className="pms-same-btn" htmlType="submit">
                            Refresh
                          </Button>
                          <Button
                            className="pms-same-btn"
                            onClick={downloadSlarySlip}
                          >
                            Get Salary Slip
                          </Button>
                        </div>
                      </Form.Item>
                    </Col>

                    {/* <Col style={{ paddingRight: "12px" }}>
                      <Form.Item>
                        <Button className="pms-same-btn" onClick={()=>handledownloadSalarySlip(userPramasid ? userPramasid : userId)}>
                          Download Salary Slip
                        </Button>
                      </Form.Item>
                    </Col> */}
                  </Row>
                </Form>
              </Space>
            </div>
          </div>
          <hr />
          <AttendanceDetails
            year={year}
            month={month}
            employee_id={userPramasid ? userPramasid : userId}
            Setemployees={Setemployees}
          />
        </div>
      </div>
    </>
  );
};

export default MyAttendance;
