import { combineReducers } from "redux";
import AttendanceReducer from "./AttendanceReducer";
import PermssionsReducer from "./PermssionsReducer";
import TaskReducer from "./TaskReducer";
import StatusReducer from "./ChangeTaskReducer";
import ProjectDataReducer from "./ProjectDataReducer";

export default combineReducers({
  Permissions: PermssionsReducer,
  Task: TaskReducer,
  Attendance: AttendanceReducer,
  StatusReducer: StatusReducer,
  ProjectDataReducer: ProjectDataReducer,
});
