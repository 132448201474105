import React, { useEffect, useState } from "react";

import { MDBCard, MDBIcon } from "mdbreact";
import { MDBCol } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";

import {
  Box,
  Menu,
  MenuItem,
  Typography,
  TablePagination,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  InputAdornment,
  TextField,
} from "@mui/material";

import BlockIcon from "@mui/icons-material/Block";
import PersonIcon from "@mui/icons-material/Person";
import RefreshIcon from "@mui/icons-material/Refresh";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import PmsRangePicker from "../../me/leaveModule/RangePicker";
import SearchIcon from "@mui/icons-material/Search";
import BugReportIcon from "@mui/icons-material/BugReport";

import { Png } from "../../../../assets/png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import AxiosInstance from "../../../../service/AxiosInstance";
import LinearProgress from "@mui/material/LinearProgress";

import { CgCalendarDates } from "react-icons/cg";

import "antd/dist/antd.css";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { Select } from "antd";
import moment from "moment";
import StoryReportSkeletonFile from "./StoryReportSkeletonFile";
const { Option } = Select;

const StoryList = () => {
  const [milestoneList, setMilestoneList] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState([]);
  const [searchQuery, setSearchQuery] = useState([]);

  const [menuAnchor, setMenuAnchor] = useState(null);

  const [isHovered, setIsHovered] = useState(false);

  const [groupBy, setGroupBy] = useState("None");
  const [dateTesting, setDateTesting] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleGroupBy = (groupBy) => {
    console.log(groupBy);
    setGroupBy(groupBy);
    handleMenuClose();
  };

  const getMilestoneList = async () => {
    try {
      const res = await AxiosInstance.get("auth/story-report1");
      console.log(res, "111111");
      setMilestoneList(res?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getMilestoneList();
  }, []);

  useEffect(() => {
    console.log(startDate, endDate, status);
  }, [startDate, endDate, status]);

  console.log(startDate, endDate, "5555");
  const onChange = (value) => {
    if (!value || !value[0] || !value[1]) {
      setStartDate(null);
      setEndDate(null);
    } else {
      setStartDate(value[0]);
      setEndDate(value[1]);
      const startDateString = moment(value[0]).format("DD/MM/YYYY").toString();
      const endDateString = moment(value[1]).format("DD/MM/YYYY").toString();
      setDateTesting(startDateString);
      setEndDateFilter(endDateString);
    }
  };

  const filteredMilestoneList = milestoneList.filter((item) => {
    const itemStartDate = item?.stories?.[0]?.Assigned_date2
      ? moment(item?.stories?.[0]?.Assigned_date2).toDate()
      : null;
    const itemEndDate = item?.stories?.[0]?.Due_date2
      ? moment(item?.stories?.[0]?.Due_date2).toDate()
      : null;

    const startDateMatch =
      !startDate || !itemStartDate || startDate <= itemStartDate;
    const endDateMatch = !endDate || !itemEndDate || itemEndDate <= endDate;
    const statusMatch =
      status.length === 0 || status.includes(item?.stories?.[0]?.Status);
    const searchQueryMatch = !searchQuery || matchesSearchQuery(item);

    return startDateMatch && endDateMatch && statusMatch && searchQueryMatch;
  });

  function matchesSearchQuery(item) {
    const escapedSearchQuery = String(searchQuery).replace(
      /[.*+?^${}()|[\]\\]/g,
      "\\$&"
    );
    const searchRegex = new RegExp(escapedSearchQuery, "i");
    const projectName = item?.project?.Project_name || "";
    const storyName = item?.stories?.[0]?.Short_summary || "";
    const Task_keyName = item?.stories?.[0]?.Task_key || "";

    const pmNAME = item?.project?.Project_manager || "";
    console.log(pmNAME, "pmname");
    const chName = item?.project?.Technical_project_manager || "";

    return (
      searchRegex.test(projectName) ||
      searchRegex.test(storyName) ||
      searchRegex.test(pmNAME) ||
      searchRegex.test(chName) ||
      searchRegex.test(Task_keyName)
    );
  }

  const handleSearch = (e) => {
    console.log(e?.target?.value, "line145");
    setSearchQuery(e?.target?.value);
  };

  function formatDate(dateString, isDueDate, status) {
    if (dateString) {
      const date = new Date(dateString);
      const currentDate = new Date();
      const diffInDays = Math.floor(
        (date - currentDate) / (1000 * 60 * 60 * 24)
      );

      if (isDueDate) {
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        const formattedDate = `${day}-${month}-${year}`;

        if (status === "CLOSE") {
          return <span style={{ color: "black" }}>{formattedDate}</span>;
        } else {
          if (diffInDays < 0) {
            return (
              <div>
                <span style={{ color: "red" }}>{formattedDate}</span>
                <br />
                <span style={{ color: "red" }}>
                  ({Math.abs(diffInDays)} days ago)
                </span>
              </div>
            );
          } else {
            return (
              <div>
                <span style={{ color: "green" }}>{formattedDate}</span>
                <br />
                <span style={{ color: "green" }}>
                  ({diffInDays} days to go)
                </span>
              </div>
            );
          }
        }
      } else {
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      }
    } else {
      return "-";
    }
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 50));
    setPage(0);
  };

  const handleRefresh = () => {
    setMilestoneList([]);
    getMilestoneList();
    toast.success("Page Refreshed!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        background: "green",
        color: "white",
      },
    });
  };

  return (
    <>
      <div className="demo">
        <MDBCard className="page-content">
          <div className="create-project">
            <MDBCol size="md" className="col-example titel-set">
              <>
                <div className="leave-set-btn2">
                  <MDBCol size="md" className="col-example">
                    <h3 className="tablHeader">Story Report</h3>
                  </MDBCol>
                </div>
              </>
            </MDBCol>
          </div>
          <Box
            className={`bottom_left ${isHovered ? "show-icons" : ""}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <Box className="main-group-icon">
              <Box className="group_by">
                <Typography onClick={handleMenuOpen}>
                  Group by {groupBy}
                  <ArrowDropDownIcon />
                </Typography>
                <Menu
                  anchorEl={menuAnchor}
                  open={Boolean(menuAnchor)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={() => handleGroupBy("Project")}>
                    <MDBIcon icon="briefcase" className="project_icon" />
                    {/* Project */}
                    <Link to="/storyList2">Project</Link>
                  </MenuItem>
                  <MenuItem onClick={() => handleGroupBy("None")}>
                    <BlockIcon className="none_icon"> </BlockIcon>
                    None
                  </MenuItem>
                </Menu>
              </Box>

              <Box className="main-image-icon">
                {isHovered && (
                  <React.Fragment>
                    <Box className="star_icon_box">
                      <StarOutlineIcon />
                    </Box>
                    <Box className="ref_box_icon">
                      <Box className="ref_box_icon" onClick={handleRefresh}>
                        <RefreshIcon />
                      </Box>
                    </Box>
                  </React.Fragment>
                )}
              </Box>
            </Box>

            <Box className="right-side-my custom-right-my-side">
              <Box className="milestone_search_box">
                <TextField
                  className="cus-milestone_search_box"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => handleSearch(e)}
                  value={searchQuery}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <div className=" cus-row custom-row">
                {/* <MDBCol size="md-6 cus-main-md"> */}
                <div className="due_date">
                  <label className="start-list">Due Date</label>
                  <PmsRangePicker
                    className="search-rangepicker"
                    format="DD-MM-YYYY"
                    onChange={onChange}
                  />
                </div>
                {/* </MDBCol> */}
                {/* <MDBCol size="md-6 cus-main-md"> */}
                <div className="status_drop">
                  <label className="start-list">Status</label>
                  <Select
                    placeholder="Status"
                    mode="multiple"
                    className="select-dropdown cus-select-dropdown leave-4"
                    onChange={(e) => {
                      console.log(e, "788788788");
                      setStatus(e);
                    }}
                  >
                    <Option value="DRAFT">DRAFT</Option>
                    <Option value="OPEN">OPEN</Option>
                    <Option value="IN PROGRESS">IN PROGRESS</Option>
                    <Option value="DEVELOPMENT DONE">DEVELOPMENT DONE</Option>
                    <Option value="REJECTED">REJECTED</Option>
                    <Option value="ON HOLD">ON HOLD</Option>
                    <Option value="READY FOR QA">READY FOR QA</Option>
                    <Option value="REOPEN">REOPEN</Option>
                    <Option value="READY FOR UAT">READY FOR UAT</Option>
                    <Option value="CLOSE">CLOSE</Option>
                  </Select>
                </div>
                {/* </MDBCol> */}
              </div>
            </Box>
          </Box>

          <hr />
          <Box className="bottom_main">
            <Box className="bottom_header1">
              <Box className="main">
                {console.log(startDate, endDate, "349")}
                {filteredMilestoneList?.length > 0 ||
                dateTesting?.length > 0 ||
                endDateFilter?.length > 0 ||
                searchQuery?.length > 0 ||
                status?.length > 0 ? (
                  <Box className="right1">
                    <Paper sx={{ width: "100%", overflow: "hidden" }}>
                      <TableContainer
                        className="cus-container1"
                        component={Paper}
                        // sx={{ maxHeight: 300 }}
                      >
                        <Table
                          className="cus-table"
                          stickyHeader
                          aria-label="sticky table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>Story</TableCell>
                              <TableCell className="dp-text-image">
                                <MDBIcon
                                  icon="briefcase"
                                  className="status_icon"
                                />
                                Project
                              </TableCell>
                              <TableCell>
                                <img
                                  className="status_icon"
                                  src={Png.status}
                                  alt="Status"
                                />
                                Status
                              </TableCell>
                              <TableCell>
                                <PersonIcon className="person-icon"></PersonIcon>
                                PM
                              </TableCell>
                              <TableCell>
                                <PersonIcon className="person-icon"></PersonIcon>
                                CH
                              </TableCell>
                              <TableCell>
                                <CgCalendarDates className="person-icon"></CgCalendarDates>
                                Start Date
                              </TableCell>
                              <TableCell>
                                <CgCalendarDates className="person-icon"></CgCalendarDates>
                                End Date
                              </TableCell>

                              <TableCell>
                                <img
                                  className="filter_icon"
                                  src={Png.task}
                                ></img>
                                Tasks
                              </TableCell>

                              <TableCell>
                                {" "}
                                <BugReportIcon className="filter_icon" />
                                Issues
                              </TableCell>

                              <TableCell>
                                <img
                                  className="status_icon"
                                  src={Png.status}
                                  alt="Status"
                                />
                                DRAFT
                              </TableCell>
                              <TableCell>
                                <img
                                  className="status_icon"
                                  src={Png.status}
                                  alt="Status"
                                />
                                OPEN
                              </TableCell>
                              <TableCell>
                                <img
                                  className="status_icon"
                                  src={Png.status}
                                  alt="Status"
                                />
                                IN PROGRESS
                              </TableCell>
                              <TableCell>
                                <img
                                  className="status_icon"
                                  src={Png.status}
                                  alt="Status"
                                />
                                DEV DONE
                              </TableCell>
                              <TableCell>
                                <img
                                  className="status_icon"
                                  src={Png.status}
                                  alt="Status"
                                />
                                REJECTED
                              </TableCell>
                              <TableCell>
                                <img
                                  className="status_icon"
                                  src={Png.status}
                                  alt="Status"
                                />
                                ON HOLD
                              </TableCell>
                              <TableCell>
                                <img
                                  className="status_icon"
                                  src={Png.status}
                                  alt="Status"
                                />
                                RFQA
                              </TableCell>
                              <TableCell>
                                <img
                                  className="status_icon"
                                  src={Png.status}
                                  alt="Status"
                                />
                                REOPEN
                              </TableCell>
                              <TableCell>
                                <img
                                  className="status_icon"
                                  src={Png.status}
                                  alt="Status"
                                />
                                RFUAT
                              </TableCell>
                              <TableCell>
                                <img
                                  className="status_icon"
                                  src={Png.status}
                                  alt="Status"
                                />
                                CLOSE
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody className="table_body">
                            {filteredMilestoneList
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((item, index) => (
                                <TableRow
                                  key={item?.stories?.[0]?.stories?._id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell>
                                    <Tooltip
                                      title={item?.stories?.[0]?.Short_summary}
                                    >
                                      {item?.stories?.[0]?.Short_summary &&
                                      item?.stories?.[0]?.Short_summary.length >
                                        30 ? (
                                        <div
                                          style={{
                                            display: "-webkit-box",
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {`${item?.stories?.[0]?.Task_key} - `}{" "}
                                          {item?.stories?.[0]?.Short_summary.slice(
                                            0,
                                            30
                                          )}
                                          {item?.stories?.[0]?.Short_summary.slice(
                                            30,
                                            30
                                          )}
                                          ...
                                        </div>
                                      ) : (
                                        item?.stories?.[0]?.Short_summary
                                      )}
                                    </Tooltip>
                                  </TableCell>

                                  <TableCell>
                                    {item?.project?.Project_name}
                                  </TableCell>
                                  <TableCell>
                                    {item?.stories?.[0]?.Status ===
                                    "IN PROGRESS" ? (
                                      <Box
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          backgroundColor: "#62AEEB",
                                          color: "white",
                                          display: "inline-block",
                                          padding: "18px 18px",
                                        }}
                                      >
                                        {item?.stories?.[0]?.Status}
                                      </Box>
                                    ) : item?.stories?.[0]?.Status ===
                                      "CLOSE" ? (
                                      <Box
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          backgroundColor: "#5F9030",
                                          color: "white",
                                          display: "inline-block",
                                          padding: "18px 18px",
                                        }}
                                      >
                                        {item?.stories?.[0]?.Status}
                                      </Box>
                                    ) : item?.stories?.[0]?.Status ===
                                      "READY FOR QA" ? (
                                      <Box
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          backgroundColor: "#62AEEB",
                                          color: "white",
                                          display: "inline-block",
                                          padding: "18px 18px",
                                        }}
                                      >
                                        {item?.stories?.[0]?.Status}
                                      </Box>
                                    ) : item?.stories?.[0]?.Status ===
                                      "Ready For QA" ? (
                                      <Box
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          backgroundColor: "#62AEEB",
                                          color: "white",
                                          display: "inline-block",
                                          padding: "18px 18px",
                                        }}
                                      >
                                        {item?.stories?.[0]?.Status}
                                      </Box>
                                    ) : item?.stories?.[0]?.Status ===
                                      "REOPEN" ? (
                                      <Box
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          backgroundColor: "#CCDEE9",
                                          color: "white",
                                          display: "inline-block",
                                          padding: " 18px 18px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {item?.stories?.[0]?.Status}
                                      </Box>
                                    ) : item?.stories?.[0]?.Status ===
                                      "OPEN" ? (
                                      <Box
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          backgroundColor: "#CCDEE9",
                                          color: "white",
                                          display: "inline-block",
                                          padding: " 18px 18px",
                                          margin: "0%",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {item?.stories?.[0]?.Status}
                                      </Box>
                                    ) : item?.stories?.[0]?.Status ===
                                      "DEVELOPMENT DONE" ? (
                                      <Box
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          backgroundColor: "#62AEEB",
                                          color: "white",
                                          display: "inline-block",
                                          padding: "18px 18px",
                                        }}
                                      >
                                        {item?.stories?.[0]?.Status}
                                      </Box>
                                    ) : item?.stories?.[0]?.Status ===
                                      "ON HOLD" ? (
                                      <Box
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          backgroundColor: "#1266F1",
                                          color: "white",
                                          display: "inline-block",
                                          padding: " 18px 18px",
                                        }}
                                      >
                                        {item?.stories?.[0]?.Status}
                                      </Box>
                                    ) : item?.stories?.[0]?.Status ===
                                      "REJECTED" ? (
                                      <Box
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          backgroundColor: "#1266F1",
                                          color: "white",
                                          display: "inline-block",
                                          padding: " 18px 18px",
                                        }}
                                      >
                                        {item?.stories?.[0]?.Status}
                                      </Box>
                                    ) : item?.stories?.[0]?.Status ===
                                      "READY FOR UAT" ? (
                                      <Box
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          backgroundColor: "#5F9030",
                                          color: "white",
                                          display: "inline-block",
                                          padding: " 18px 18px",
                                        }}
                                      >
                                        {item?.stories?.[0]?.Status}
                                      </Box>
                                    ) : item?.stories?.[0]?.Status ===
                                      "DRAFT" ? (
                                      <Box
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          backgroundColor: "#CCDEE9",
                                          color: "white",
                                          display: "inline-block",
                                          padding: " 18px 18px",
                                        }}
                                      >
                                        {item?.stories?.[0]?.Status}
                                      </Box>
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {item?.project?.Project_manager?.replace(
                                      /\([^)]*\)/,
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {item?.project?.Technical_project_manager?.replace(
                                      /\([^)]*\)/,
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {formatDate(
                                      item?.stories?.[0]?.Assigned_date2,
                                      false
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {formatDate(
                                      item?.stories?.[0]?.Due_date2,
                                      true,
                                      item?.stories?.[0]?.Status
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <div className="project_table_progress_bar">
                                      <span>
                                        {item?.stories?.[0]?.completedTasks}
                                      </span>
                                      <Box className="my-progress">
                                        {item?.stories?.[0]?.totalTasks > 0 ? (
                                          <LinearProgress
                                            className="progress_bar"
                                            value={
                                              (item?.stories?.[0]
                                                ?.completedTasks /
                                                item?.stories?.[0]
                                                  ?.totalTasks) *
                                              100
                                            }
                                            color="success"
                                            variant="determinate"
                                          />
                                        ) : (
                                          <LinearProgress
                                            className="progress_bar"
                                            value={0}
                                            color="success"
                                            variant="determinate"
                                          />
                                        )}
                                        <span className="progress-value">
                                          {item?.totalTasks > 0
                                            ? `${Math.round(
                                                (item?.stories?.[0]
                                                  ?.completedTasks /
                                                  item?.stories?.[0]
                                                    ?.totalTasks) *
                                                  100
                                              )}%`
                                            : "0%"}
                                        </span>
                                      </Box>
                                      <span>
                                        {item?.stories?.[0]?.totalTasks}
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className="project_table_progress_bar">
                                      <span>
                                        {item?.stories?.[0]?.completedBugs}
                                      </span>
                                      <Box className="my-progress">
                                        {item?.stories?.[0]?.totalBugs > 0 ? (
                                          <LinearProgress
                                            className="progress_bar"
                                            value={
                                              (item?.stories?.[0]
                                                ?.completedBugs /
                                                item?.stories?.[0]?.totalBugs) *
                                              100
                                            }
                                            color="success"
                                            variant="determinate"
                                          />
                                        ) : (
                                          <LinearProgress
                                            className="progress_bar"
                                            value={0}
                                            color="success"
                                            variant="determinate"
                                          />
                                        )}
                                        <span className="progress-value">
                                          {item?.stories?.[0]?.totalBugs > 0
                                            ? `${Math.round(
                                                (item?.stories?.[0]
                                                  ?.completedBugs /
                                                  item?.stories?.[0]
                                                    ?.totalBugs) *
                                                  100
                                              )}%`
                                            : "0%"}
                                        </span>
                                      </Box>
                                      <span>
                                        {item?.stories?.[0]?.totalBugs}
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    className="td-task"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <Typography className="task-main">
                                      Task{" "}
                                      {
                                        item?.stories?.[0]?.statusCounts?.DRAFT
                                          ?.task
                                      }
                                    </Typography>
                                    <Typography className="task-main">
                                      Bug{" "}
                                      {
                                        item?.stories?.[0]?.statusCounts?.DRAFT
                                          ?.bug
                                      }
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    className="td-task"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <Typography className="task-main">
                                      Task{" "}
                                      {
                                        item?.stories?.[0]?.statusCounts?.OPEN
                                          ?.task
                                      }
                                    </Typography>
                                    <Typography className="task-main">
                                      Bug{" "}
                                      {
                                        item?.stories?.[0]?.statusCounts?.OPEN
                                          ?.bug
                                      }
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    className="td-task"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <Typography className="task-main">
                                      Task{" "}
                                      {
                                        item?.stories?.[0]?.statusCounts?.[
                                          "IN PROGRESS"
                                        ]?.task
                                      }
                                    </Typography>
                                    <Typography className="task-main">
                                      Bug{" "}
                                      {
                                        item?.stories?.[0]?.statusCounts?.[
                                          "IN PROGRESS"
                                        ]?.bug
                                      }
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    className="td-task"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <Typography className="task-main">
                                      Task{" "}
                                      {
                                        item?.stories?.[0]?.statusCounts?.[
                                          "DEVELOPMENT DONE"
                                        ]?.task
                                      }
                                    </Typography>
                                    <Typography className="task-main">
                                      Bug{" "}
                                      {
                                        item?.stories?.[0]?.statusCounts?.[
                                          "DEVELOPMENT DONE"
                                        ]?.bug
                                      }
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    className="td-task"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <Typography className="task-main">
                                      Task{" "}
                                      {
                                        item?.stories?.[0]?.statusCounts
                                          ?.REJECTED?.task
                                      }
                                    </Typography>
                                    <Typography className="task-main">
                                      Bug{" "}
                                      {
                                        item?.stories?.[0]?.statusCounts
                                          ?.REJECTED?.bug
                                      }
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    className="td-task"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <Typography className="task-main">
                                      Task{" "}
                                      {
                                        item?.stories?.[0]?.statusCounts?.[
                                          "ON HOLD"
                                        ]?.task
                                      }
                                    </Typography>
                                    <Typography className="task-main">
                                      Bug{" "}
                                      {
                                        item?.stories?.[0]?.statusCounts?.[
                                          "ON HOLD"
                                        ]?.bug
                                      }
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    className="td-task"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <Typography className="task-main">
                                      Task{" "}
                                      {
                                        item?.stories?.[0]?.statusCounts?.[
                                          "Ready For QA"
                                        ]?.task
                                      }
                                    </Typography>
                                    <Typography className="task-main">
                                      Bug{" "}
                                      {
                                        item?.stories?.[0]?.statusCounts?.[
                                          "Ready For QA"
                                        ]?.bug
                                      }
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    className="td-task"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <Typography className="task-main">
                                      Task{" "}
                                      {
                                        item?.stories?.[0]?.statusCounts?.REOPEN
                                          ?.task
                                      }
                                    </Typography>
                                    <Typography className="task-main">
                                      Bug{" "}
                                      {
                                        item?.stories?.[0]?.statusCounts?.REOPEN
                                          ?.bug
                                      }
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    className="td-task"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <Typography className="task-main">
                                      Task{" "}
                                      {
                                        item?.stories?.[0]?.statusCounts?.[
                                          "READT FOR UAT"
                                        ]?.task
                                      }
                                    </Typography>
                                    <Typography className="task-main">
                                      Bug{" "}
                                      {
                                        item?.stories?.[0]?.statusCounts?.[
                                          "READT FOR UAT"
                                        ]?.bug
                                      }
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    className="td-task"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <Typography className="task-main">
                                      Task{" "}
                                      {
                                        item?.stories?.[0]?.statusCounts?.CLOSE
                                          ?.task
                                      }
                                    </Typography>
                                    <Typography className="task-main">
                                      Bug{" "}
                                      {
                                        item?.stories?.[0]?.statusCounts?.CLOSE
                                          ?.bug
                                      }
                                    </Typography>
                                  </TableCell>
                                  {console.log(
                                    "item?.statusCounts?.CLOSE?.bug"
                                  )}
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </Box>
                ) : (
                  <>
                    <StoryReportSkeletonFile
                      filteredMilestoneList={filteredMilestoneList}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      formatDate={formatDate}
                      milestoneList={milestoneList}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </>
                )}
                <TablePagination
                  className="pages"
                  rowsPerPageOptions={[50, 75, 100]}
                  component="div"
                  count={milestoneList?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            </Box>
          </Box>
        </MDBCard>
      </div>
    </>
  );
};

export default StoryList;
