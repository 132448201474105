import { Progress, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AxiosInstance from "../../../../service/AxiosInstance";
import { Select, Spin, Table } from "antd";
import { taskStatusBadge } from "../../../../components/common/Coomon";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Key } from "@mui/icons-material";

const StoryDue = ({
  loading,
  projectIds,
  setLoading,
  sortForDues,
  setDataLength,
  dataLength,
}) => {
  const [storyData, setStoryData] = useState([]);
  const [statusSort, setStatusSort] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const { Option } = Select;
  const projectData = useSelector(
    (state) => state?.ProjectDataReducer?.projects
  );

  const GetAllStoryData = () => {
    setLoading(true);

    const formData = new URLSearchParams();
    formData.append("type", sortForDues);
    formData.append("id", JSON.stringify(projectIds));

    AxiosInstance.post("/auth/story-due", formData).then((res) => {
      setLoading(false);
      let sortedList = res.data.data.sort(
        (a, b) => moment(a.DueDate2) - moment(b.DueDate2)
      );
      let data = [];
      sortedList.map((item, index) => {
        const objData = {
          Key: index,
          task_id: item._id,
          icon: index + 1,
          name: item?.Task_key,
          project_id: item?.Project_id,
          project_name: item?.Project_id?.Project_name,
          status: taskStatusBadge(item?.Status),
          filter_status: item?.Status,
          Milestone: item?.Project_name,
          Border_left: item?.Due_date2 ? item?.Due_date2 : "",
          Date: item?.Assigned_date2
            ? `${moment(item?.Assigned_date2).format("DD-MMM")} - ${moment(
                item?.Due_date2
              ).format("DD-MMM")}`
            : "",
        };
        data.push(objData);
        // setStoryData((e) => [...e, objData]);
      });
      const projectNotCompleted = [
        ...new Set(filteredData.map((item) => item.Project_name)),
      ];
      const filteredDeveloper = data.filter((dev) =>
        projectNotCompleted.includes(dev.Milestone)
      );
      // setStoryData(data);
      setStoryData(filteredDeveloper);
      setDataLength(filteredDeveloper.length);
    });
  };
  const getAllProjectData = () => {
    if (projectData) {
      const updatedData = projectData.filter(
        (item) => item?.Status !== "Completed" && item?.Archive === false
      );
      setFilteredData(updatedData);
    }
  };
  useEffect(() => {
    getAllProjectData();
  }, []);
  useEffect(() => {
    GetAllStoryData();
  }, [projectIds, sortForDues, filteredData]);
  return (
    <>
      <div className="dash-col-milestone">
        <div className="main-milestone">
          <div className="milstone-flex">
            <div className="milestone-card-head">
              <p>Story Due</p>
              <Spin spinning={loading} style={{ marginTop: 100 }}>
                {" "}
              </Spin>
            </div>
            <div className="milestone-dropdown">
              <Select
                defaultValue={""}
                className="select-dropdown leave-4"
                onChange={(e) => {
                  setStatusSort(e);
                }}
              >
                <Option value="">All</Option>
                <Option value="OPEN">OPEN</Option>
                <Option value="IN PROGRESS">IN PROGRESS</Option>
                <Option value="DEVELOPMENT DONE">DEVELOPMENT DONE</Option>
                <Option value="READY FOR QA">READY FOR QA</Option>
                <Option value="ON HOLD">ON HOLD</Option>
              </Select>
            </div>
          </div>
          <div className="milestone-card-body">
            {storyData.length > 0 ? (
              storyData &&
              (statusSort.length > 0
                ? storyData.filter((item) => item.filter_status === statusSort)
                : storyData
              ).map((item, number) => {
                return (
                  <Link
                    key={item.Key}
                    className="mile-body-content"
                    to={`/project/task/${item.task_id}/${item.project_id}`}
                    // to={`/project/tasks/${item?.project_id}`}
                    target="_blank"
                    style={{
                      borderLeft: moment(item?.Border_left).isBefore(
                        moment(),
                        "day"
                      )
                        ? "4px solid red"
                        : moment(item?.Border_left).diff(moment(), "day") <= 15
                        ? "4px solid yellow"
                        : "4px solid grey",
                    }}
                  >
                    <div className="mile-progress">
                      <Progress type="circle" percent={30} />
                    </div>
                    <div className="mail-right-detail">
                      <p className="mile-name">{item?.name}</p>
                      <p className="mile-pro-name">{item?.project_name}</p>
                      <p className="mile-pro-name">{item?.Milestone}</p>
                      <div className="mile-status-date">
                        <p className="mile-status">{item?.status}</p>
                        <p className="">
                          <i className="far fa-calendar-alt"></i> {item?.Date}
                        </p>
                      </div>
                    </div>
                  </Link>
                );
              })
            ) : (
              <p
                style={{
                  fontSize: "20px",
                  textAlign: "center",
                  marginTop: "90px",
                }}
              >
                No Story Due
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StoryDue;
