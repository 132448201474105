import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Row, Col, message } from "antd";
import AxiosInstance from "../../../../service/AxiosInstance";

function AddEscalationType({ setIsOpen, reloadList, escalationTypeId }) {
  const [form] = Form.useForm();
  const [escalationType, setEscalationType] = useState(true);
  const [escalationTypeName, setEscalationTypeNmae] = useState("");

  const handleCancel = (errorInfo) => {
    form.resetFields();
    setEscalationTypeNmae("");
    setEscalationType(false);
    setIsOpen(false);
  };

  const onFinish = (values) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("Escalation_Type", values?.Escalation_Type);
    if (escalationTypeId?._id) {
      urlencoded.append("id", escalationTypeId?._id);
    }
    AxiosInstance.post("/auth/add/escalation/type", urlencoded).then((res) => {
      if (res?.data?.status == 1) {
        message.success(res?.data?.message);
        setIsOpen(false);
        reloadList();
      } else {
        message.error(res?.data?.message);
      }
    });
  };
  useEffect(() => {
    form.setFieldsValue({
      Escalation_Type: escalationTypeId?.Escalation_Type,
    });
  }, []);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    // setTextEditer('');
  };

  return (
    <>
      <Modal
        title="Add Escalation Type"
        centered
        open={escalationType}
        onOk={() => setEscalationType(false)}
        onCancel={handleCancel}
        width={800}
        height={640}
        bodyStyle={{ padding: "20px 15px" }}
        footer={null}
        maskClosable={false}
      >
        <Form
          form={form}
          name="control-hooks"
          layout="escalation Type"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row>
            <Col span={12}>
              <div>
                Escalation Type<span style={{ color: "red" }}>*</span>
              </div>
              <Form.Item
                name="Escalation_Type"
                rules={[
                  { required: true, message: "Please Enter Vertical Name" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <div style={{ textAlign: "end", marginTop: "10px" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: "10px" }}
                className="pms-same-btn"
              >
                Submit
              </Button>
              <Button className="pms-same-btn-cancel" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default AddEscalationType;
