import React, { useEffect, useState } from "react";
import AxiosInstance from "../../../../service/AxiosInstance";
import moment from "moment";
import { Select, Spin, Table } from "antd";
import { projectStatusBadge } from "../../../../components/common/Coomon";
import { Status } from "../../Options";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import fetchProjectsSuccess from "../../../../redux/actions/ProjectDataActions";

const MilestoneDue = ({
  loading,
  projectIds,
  setLoading,
  sortForDues,
  setMilestoneDataLength,
  milestoneDataLength,
}) => {
  const [milestoneDataForProject, setMilestoneDataForProject] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [statusSort, setStatusSort] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const dispatch = useDispatch();
  const { Option } = Select;
  const projectData = useSelector(
    (state) => state?.ProjectDataReducer?.projects
  );
  const GetAllMileStone = () => {
    setLoading(true);
    const formData = new URLSearchParams();
    formData.append("type", sortForDues);
    formData.append("id", JSON.stringify(projectIds));
    AxiosInstance.post("/milestone/fifteenDaysDueMilestones", formData).then(
      (res) => {
        setLoading(false);

        let sortedList = res.data.data.sort(
          (a, b) => moment(a.DueDate) - moment(b.DueDate)
        );
        let data = [];
        sortedList.map((item, index) => {
          const objData = {
            key: index,
            icon: index + 1,
            name: item?.Name,
            project_id: item?.Project_id?._id,
            project_name: item?.Project_id?.Project_name,
            project_status: item?.Project_id?.Status,
            pmname: item?.Project_id?.Project_manager,
            tpmname: item?.Project_id?.Technical_project_manager,
            dueDate: item?.DueDate
              ? moment(item?.DueDate).format("DD/MM/YYYY")
              : "",
            Border_left: item?.DueDate ? item?.DueDate : "",
            status: projectStatusBadge(item?.Status),
            filter_status: item?.Status,
            Date: item?.StartDate
              ? `${moment(item?.StartDate).format("DD-MMM")} - ${moment(
                  item?.DueDate
                ).format("DD-MMM")}`
              : "",
          };
          data.push(objData);
          // setMilestoneDataForProject((e)=>[...e,objData]);
        });
        // const projectNotCompleted = filteredData.map((projectName, index) => (
        //   <div key={index}>{projectName}</div>
        // ));
        const projectNotCompleted = [
          ...new Set(filteredData.map((item) => item.Project_name)),
        ];
        const filteredDeveloper = data.filter((dev) =>
          projectNotCompleted.includes(dev.project_name)
        );

        setMilestoneDataLength(filteredDeveloper?.length);
        setMilestoneDataForProject(filteredDeveloper);
      }
    );
  };

  const getAllProjectData2 = () => {
    AxiosInstance.get("auth/projects/Default?status=").then((res) => {
      if (res?.data?.data) {
        dispatch(fetchProjectsSuccess(res?.data?.data));
      }
    });
  };
  useEffect(() => {
    getAllProjectData2();
  }, []);

  const getAllProjectData = () => {
    if (projectData) {
      const updatedData = projectData.filter(
        (item) => item?.Status !== "Completed" && item?.Archive === false
      );
      setFilteredData(updatedData);
    }
  };
  useEffect(() => {
    getAllProjectData();
  }, []);

  useEffect(() => {
    GetAllMileStone();
  }, [projectIds, sortForDues, filteredData]);

  return (
    <>
      <>
        <div className="dash-col-milestone">
          <div className="main-milestone">
            <div className="milstone-flex">
              <div className="milestone-card-head">
                <p>Milestone Due</p>
                <Spin spinning={loading} style={{ marginTop: 100 }}>
                  {" "}
                </Spin>
              </div>
              <div className="milestone-dropdown">
                <Select
                  defaultValue={""}
                  className="select-dropdown leave-4"
                  onChange={(e) => {
                    setStatusSort(e);
                  }}
                >
                  <Option value="">All</Option>
                  <Option value="Pending">Pending</Option>
                  <Option value="In Progress">In Progress</Option>
                  <Option value="In Client Review">In Client Review</Option>
                  <Option value="On Hold">On Hold</Option>
                  <Option value="Client Not Responding">
                    Client Not Responding
                  </Option>
                </Select>
              </div>
            </div>
            <div className="milestone-card-body">
              {milestoneDataForProject.length > 0 ? (
                milestoneDataForProject &&
                (statusSort.length > 0
                  ? milestoneDataForProject.filter(
                      (item) => item.filter_status === statusSort
                    )
                  : milestoneDataForProject
                ).map((item, number) => {
                  return (
                    <Link
                      key={item.key}
                      className="mile-body-content"
                      to={`/project/milestone/${item?.project_id}`}
                      target="_blank"
                      style={{
                        borderLeft: moment(item?.Border_left).isBefore(
                          moment(),
                          "day"
                        )
                          ? "4px solid red"
                          : moment(item?.Border_left).diff(moment(), "day") <=
                            15
                          ? "4px solid yellow"
                          : "4px solid grey",
                      }}
                    >
                      <div className="mile-date">
                        <p>
                          {item?.dueDate?.split("/")[0]}
                          <span>
                            {moment(item?.dueDate?.split("/")[1], "MM").format(
                              "MMM"
                            )}
                          </span>
                        </p>
                      </div>
                      <div className="mail-right-detail">
                        <p className="mile-name">{item?.name}</p>
                        <p className="mile-pro-name">
                          {item?.project_name} ({item?.project_status})
                        </p>
                        <div className="mile-status-date">
                          <p className="mile-status">{item.status}</p>
                          <p className="">
                            {item.Date ? (
                              <>
                                <i className="far fa-calendar-alt"></i>{" "}
                                {item.Date}
                              </>
                            ) : (
                              <>
                                Invalid date{" "}
                                <i className="far fa-calendar-alt"></i>
                              </>
                            )}
                          </p>
                        </div>
                      </div>
                    </Link>
                  );
                })
              ) : (
                <p
                  style={{
                    fontSize: "20px",
                    textAlign: "center",
                    marginTop: "90px",
                  }}
                >
                  No Milestone Due
                </p>
              )}
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default MilestoneDue;
