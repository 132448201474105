import React, { useState, useEffect } from "react";
import { Table } from "antd";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionMyDailyReport } from "../../../redux/actions/TaskActions";
import AxiosInstance from "../../../service/AxiosInstance";

const AllocatedResource = (props) => {
  const { refresh, setRefresh } = props;
  const MyDailyTaskReport = useSelector(
    (state) => state.Task?.MyDailyTaskReport
  );
  const params = useParams();
  const PID = params?.id;
  const [myReport, setMyReport] = useState(MyDailyTaskReport);
  const [myTask, setMyTask] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      width: 10,
    },
    {
      title: "Department Name",
      dataIndex: "Department_Name",
      width: 200,
    },
    {
      title: "Employee Name",
      dataIndex: "Employee_Name",
      width: 200,
    },
    {
      title: "From Date",
      dataIndex: "from_Date",
      render: (data) => {
        return <>{moment(data).format("DD-MM-YYYY")}</>;
      },
      width: 130,
    },
    {
      title: "To Date",
      dataIndex: "to_Date",
      render: (data) => {
        return <>{moment(data).format("DD-MM-YYYY")}</>;
      },
      width: 130,
    },
    {
      title: "Assign For",
      dataIndex: "assign_for",
      width: 150,
    },
    {
      title: "Time",
      dataIndex: "time",
      //  render: (data) => {
      //   return <>{moment(data).format("h:mm")}</>;
      // },
      width: 150,
    },
    {
      title: "Requested By",
      dataIndex: "Requested_by",
      width: 150,
    },
  ];
  const getDefaultReport = () => {
    const formData = new URLSearchParams();
    formData.append("Project_id", PID);
    AxiosInstance.post(`auth/resource/list`, formData).then((res) => {
      dispatch(actionMyDailyReport(res.data.data));
      // setRefresh(false);
      const taskdata = [];
      var i = 1;
      res.data.data.map((data, index) => {
        data.Assign.map((data1) => {
          var dataObj = {
            no: res?.data?.data?.length - index,
            Department_Name: data?.Department_id?.Department_name,
            Employee_Name: data1?.Employee_id?.First_name
              ? `${data1?.Employee_id?.First_name} ${data1?.Employee_id?.Middle_name} ${data1?.Employee_id?.Last_name}`
              : "-",
            Exp_level: data?.Exp_level,
            from_Date: data1?.assign_from,
            to_Date: data1?.assign_to,
            time:
              data1 && data1?.Part_time?.From_time && data1?.Part_time?.To_time
                ? `${moment(data1?.Part_time?.From_time, ["HH:mm"]).format(
                    "hh:mm A"
                  )} - ${moment(data1?.Part_time?.To_time, ["HH:mm"]).format(
                    "hh:mm A"
                  )}`
                : "-",
            assign_for: data1?.Full_time ? "Full Time" : "Part Time",
            Requested_by: data?.Requested_by?.First_name
              ? `${data?.Requested_by?.First_name} ${data?.Requested_by?.Middle_name} ${data?.Requested_by?.Last_name}`
              : "-",
          };
          taskdata.push(dataObj);
          i++;
        });
      });
      setMyTask(taskdata?.reverse());
    });
  };

  useEffect(() => {
    getDefaultReport();
  }, []);
  useEffect(() => {
    getDefaultReport();
  }, [refresh]);

  return (
    <>
      {" "}
      <div className="my-task-dropdown"></div>
      <Table className="team-table" columns={columns} dataSource={myTask} />
    </>
  );
};

export default AllocatedResource;
