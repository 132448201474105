import React from "react";
import { Skeleton } from "antd";
import { MDBIcon } from "mdbreact";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";

import PersonIcon from "@mui/icons-material/Person";

import { Png } from "../../../../assets/png";
import { CgCalendarDates } from "react-icons/cg";
import BugReportIcon from "@mui/icons-material/BugReport";

import "antd/dist/antd.css";
import "react-toastify/dist/ReactToastify.css";

const StoryReportSkeletonFile = () => {
  return (
    <div>
       <Box className="bottom_main">
            <Box className="bottom_header">
              <Box className="main">
      <Box className="right">
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer
            // className="cus-container"
            className="cus-container1"
            component={Paper}
            // sx={{ maxHeight: 300 }}
          >
            <Table className="cus-table" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Story</TableCell>
                  <TableCell className="dp-text-image">
                    <MDBIcon icon="briefcase" className="status_icon" />
                    Project
                  </TableCell>
                  <TableCell>
                    <img
                      className="status_icon"
                      src={Png.status}
                      alt="Status"
                    />
                    Status
                  </TableCell>
                  <TableCell>
                    <PersonIcon className="person-icon"></PersonIcon>
                    PM
                  </TableCell>
                  <TableCell>
                    <PersonIcon className="person-icon"></PersonIcon>
                    CH
                  </TableCell>
                  <TableCell>
                    <CgCalendarDates className="person-icon"></CgCalendarDates>
                    Start Date
                  </TableCell>
                  <TableCell>
                    <CgCalendarDates className="person-icon"></CgCalendarDates>
                    End Date
                  </TableCell>
                  <TableCell>
                                <img
                                  className="filter_icon"
                                  src={Png.task}
                                ></img>
                                Tasks
                              </TableCell>

                              <TableCell>
                                {" "}
                                <BugReportIcon className="filter_icon" />
                                Issues
                              </TableCell>
                  <TableCell>
                    <img
                      className="status_icon"
                      src={Png.status}
                      alt="Status"
                    />
                    DRAFT
                  </TableCell>
                  <TableCell>
                    <img
                      className="status_icon"
                      src={Png.status}
                      alt="Status"
                    />
                    OPEN
                  </TableCell>
                  <TableCell>
                    <img
                      className="status_icon"
                      src={Png.status}
                      alt="Status"
                    />
                    IN PROGRESS
                  </TableCell>
                  <TableCell>
                    <img
                      className="status_icon"
                      src={Png.status}
                      alt="Status"
                    />
                    DEV DONE
                  </TableCell>
                  <TableCell>
                    <img
                      className="status_icon"
                      src={Png.status}
                      alt="Status"
                    />
                    REJECTED
                  </TableCell>
                  <TableCell>
                    <img
                      className="status_icon"
                      src={Png.status}
                      alt="Status"
                    />
                    ON HOLD
                  </TableCell>
                  <TableCell>
                    <img
                      className="status_icon"
                      src={Png.status}
                      alt="Status"
                    />
                    RFQA
                  </TableCell>
                  <TableCell>
                    <img
                      className="status_icon"
                      src={Png.status}
                      alt="Status"
                    />
                    REOPEN
                  </TableCell>
                  <TableCell>
                    <img
                      className="status_icon"
                      src={Png.status}
                      alt="Status"
                    />
                    RFUAT
                  </TableCell>
                  <TableCell>
                    <img
                      className="status_icon"
                      src={Png.status}
                      alt="Status"
                    />
                    CLOSE
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table_body">
                {Array.from({ length: 10 }).map((_, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell>
                      {/* {item?.stories?.[0]?.Short_summary} */}
                      <Skeleton.Input
                        active="active"
                        size="small"
                        shape="default"
                        className="story_reportStorySkeleton"
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton.Input
                        active="active"
                        size="small"
                        shape="default"
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton.Input
                        active="active"
                        size="small"
                        shape="default"
                      />
                    </TableCell>

                    <TableCell>
                      <Skeleton.Input
                        active="active"
                        size="small"
                        shape="default"
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton.Input
                        active="active"
                        size="small"
                        shape="default"
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton.Input
                        active="active"
                        size="small"
                        shape="default"
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton.Input
                        active="active"
                        size="small"
                        shape="default"
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton.Input
                        active="active"
                        size="small"
                        shape="default"
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton.Input
                        active="active"
                        size="small"
                        shape="default"
                      />
                    </TableCell>

                    <TableCell className="td-task" style={{ fontSize: "12px" }}>
                      <Typography className="task-main">
                        <Skeleton.Input
                          active="active"
                          size="small"
                          shape="default"
                        />
                      </Typography>
                      <Typography className="task-main">
                        {/* <Skeleton.Input active="active" size="small" shape="default" /> */}
                      </Typography>
                    </TableCell>
                    <TableCell className="td-task" style={{ fontSize: "12px" }}>
                      <Typography className="task-main">
                        <Skeleton.Input
                          active="active"
                          size="small"
                          shape="default"
                        />
                      </Typography>
                      <Typography className="task-main">
                        {/* <Skeleton.Input active="active" size="small" shape="default" /> */}
                      </Typography>
                    </TableCell>

                    <TableCell className="td-task" style={{ fontSize: "12px" }}>
                      <Typography className="task-main">
                        <Skeleton.Input
                          active="active"
                          size="small"
                          shape="default"
                        />
                      </Typography>
                      <Typography className="task-main">
                        {/* Bug {item?.stories?.[0]?.statusCounts?.["IN PROGRESS"]?.bug} */}
                      </Typography>
                    </TableCell>

                    <TableCell className="td-task" style={{ fontSize: "12px" }}>
                      <Typography className="task-main">
                        <Skeleton.Input
                          active="active"
                          size="small"
                          shape="default"
                        />
                      </Typography>
                      <Typography className="task-main">
                        {/* Bug{" "}
                                    {
                                      item?.stories?.[0]?.statusCounts?.["DEVELOPMENT DONE"]
                                        ?.bug
                                    } */}
                      </Typography>
                    </TableCell>

                    <TableCell className="td-task" style={{ fontSize: "12px" }}>
                      <Typography className="task-main">
                        <Skeleton.Input
                          active="active"
                          size="small"
                          shape="default"
                        />
                      </Typography>
                      <Typography className="task-main">
                        {/* Bug {item?.stories?.[0]?.statusCounts?.REJECTED?.bug} */}
                      </Typography>
                    </TableCell>

                    <TableCell className="td-task" style={{ fontSize: "12px" }}>
                      <Typography className="task-main">
                        <Skeleton.Input
                          active="active"
                          size="small"
                          shape="default"
                        />{" "}
                      </Typography>
                      <Typography className="task-main">
                        {/* Bug {item?.stories?.[0]?.statusCounts?.["ON HOLD"]?.bug} */}
                      </Typography>
                    </TableCell>

                    <TableCell className="td-task" style={{ fontSize: "12px" }}>
                      <Typography className="task-main">
                        <Skeleton.Input
                          active="active"
                          size="small"
                          shape="default"
                        />
                      </Typography>
                      <Typography className="task-main">
                        {/* Bug{" "}
                                    {item?.stories?.[0]?.statusCounts?.["Ready For QA"]?.bug} */}
                      </Typography>
                    </TableCell>

                    <TableCell className="td-task" style={{ fontSize: "12px" }}>
                      <Typography className="task-main">
                        <Skeleton.Input
                          active="active"
                          size="small"
                          shape="default"
                        />{" "}
                      </Typography>
                      <Typography className="task-main">
                        {/* Bug {item?.stories?.[0]?.statusCounts?.REOPEN?.bug} */}
                      </Typography>
                    </TableCell>

                    <TableCell className="td-task" style={{ fontSize: "12px" }}>
                      <Typography className="task-main">
                        <Skeleton.Input
                          active="active"
                          size="small"
                          shape="default"
                        />{" "}
                      </Typography>
                      <Typography className="task-main">
                        {/* Bug {item?.stories?.[0]?.statusCounts?.["READY FOR UAT"]?.bug} */}
                      </Typography>
                    </TableCell>
                    <TableCell className="td-task" style={{ fontSize: "12px" }}>
                      <Typography className="task-main">
                        <Skeleton.Input
                          active="active"
                          size="small"
                          shape="default"
                        />{" "}
                      </Typography>
                      <Typography className="task-main">
                        {/* Bug {item?.stories?.[0]?.statusCounts?.CLOSE?.bug} */}
                      </Typography>
                    </TableCell>
                    {console.log("item?.statusCounts?.CLOSE?.bug")}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      </Box>
      </Box>
      </Box>
    </div>
  );
};

export default StoryReportSkeletonFile;
