import { DatePicker, Form } from "antd";
import { Checkbox, Col, Modal, Row, Collapse, Select } from "antd";

import { MDBCard, MDBCol } from "mdb-react-ui-kit";
import { MDBDataTableV5 } from "mdbreact";
import moment, { duration } from "moment";
import React from "react";
import { useState } from "react";
import { useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import AxiosInstance from "../../../service/AxiosInstance";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import SortingSelect from "../SortingSelect";
const { Panel } = Collapse;
const { Option } = Select;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProjectWorkingHours = () => {
  let userData = JSON.parse(localStorage.getItem("userdata"));
  const params = useParams();
  const [datatable, setDatatable] = useState({});
  const [employeetable, setEmployeetable] = useState({});
  const [projectEmployee, setProjectEmployees] = useState({});
  console.log(projectEmployee, "projectEmployee33");
  const [departmentDatatable, setDepartmentDatatable] = useState({});
  const [empId, setEmpId] = useState([]);
  const [ProjectWH, setProjectWH] = useState([]);
  console.log(ProjectWH, "ProjectWH");
  const [TotalHours, setTotalHours] = useState();
  const [valueData, setValueData] = React.useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modalData, setModalData] = useState({});

  console.log(employeetable, "66");

  const [clusterList, setClusterList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [selectedOption, setSelectedOption] = useState("Cluster");
  
  const [selectedDate, setSelectedDate] = useState(moment());
  const [newEmployeeData, setNewEmployeeData] = useState([]);
  console.log(newEmployeeData, "newEmployeeData");


  const handleDateChange = (date) => {
    if (!date) {
      const today = moment();
      setSelectedDate(today);
      ProjectDATA(today);
    } else {
      setSelectedDate(date);
      ProjectDATA(date);
    }
  };

  console.log(filterData,"filterData")

  useEffect(() => {
    getClusterTeamList();
    getDepartmentList();
  }, []);

  const getClusterTeamList = async () => {
    try {
      const res = await AxiosInstance.get("auth/getTeamMembers");
      if (res.data.data) {
        const clusters = res.data.data.map((cluster) => ({
          name: cluster.cluster_Name,
          id: cluster._id,
          employees: cluster.members.map((member) => ({
            name: `${member.memberId.First_name} ${member.memberId.Middle_name} ${member.memberId.Last_name}`,
            // department: member.memberId.Role,
            _id: member.memberId._id,
            // location: member.memberId.Location_id?.Location_name,
          })),
        }));
        setClusterList(clusters);
        setFilterData(clusters[0]);
        setNewEmployeeData(clusters.flatMap((item) => item.employees));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getDepartmentList = async () => {
    try {
      const res = await AxiosInstance.get("auth/employee/departmentcount");
      if (res.data.data) {
        const departments = res.data.data.map((dept) => ({
          name: dept.Department_name,
          id: dept._id,
          employees: dept.employees.map((employee) => ({
            name: `${employee.First_name} ${employee.Middle_name} ${employee.Last_name}`,
            // department: employee.Role,
            _id: employee._id,
            // location: employee.Location_id?.Location_name,
          })),
        }));
        setDepartmentList(departments);
        setFilterData(departments[0]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const organizeEmployeeData = (list, type) => {
    return list.map((item) => ({
      id: item._id,
      name: type === "Cluster" ? item.cluster_Name : item.Department_name,
      employees: item.members
        ? item.members.map((member) => ({
            name: `${member.memberId.First_name} ${member.memberId.Middle_name} ${member.memberId.Last_name}`,
            _id: member.memberId._id,
            location: member.memberId.Location_id?.Location_name,
          }))
        : item.employees.map((employee) => ({
            name: `${employee.First_name} ${employee.Middle_name} ${employee.Last_name}`,
            _id: employee._id,
            location: employee.Location_id?.Location_name,
          })),
    }));
  };

  const renderAccordions = () => {
    const list = selectedOption === "Cluster" ? clusterList : departmentList;
    return list.map((item) => {
      return (
        <Panel header={item.name} key={item.id}>
          {newEmployeeData && (
            <MDBDataTableV5
              entriesOptions={[20, 25, 30]}
              entries={20}
              pagesAmount={4}
              data={{
                columns: [
                  {
                    label: "Employee Name",
                    field: "employee_name",
                    width: 50,
                    sort: "disabled",
                  },
                  {
                    label: "Location",
                    field: "employee_location",
                    width: 50,
                    sort: "disabled",
                  },
                  { label: "Date", field: "Date", width: 50, sort: "disabled" },
                  {
                    label: "Task Id",
                    field: "task_key",
                    width: 50,
                    sort: "disabled",
                  },
                  {
                    label: "Total Hours",
                    field: "TotalHours",
                    width: 50,
                    sort: "disabled",
                  },
                ],
                rows: newEmployeeData.filter((e) => {
                  console.log("Employee Data:", e);
                  return selectedOption === "Cluster"
                    ? item.id === e.cluster_id
                    : item.id === e.department_id;
                }),
              }}
              searchTop
              searchBottom={false}
              className="pro-work-hour-main-tbl directory-list-table employee-data-table"
            />
          )}
        </Panel>
      );
    });
  };

 

  const handleSortingChange = (e) => {
    setSelectedOption(e);
  
    if (e === "Cluster") {
      const organizedClusterData = organizeEmployeeData(clusterList, "Cluster");
      setNewEmployeeData(
        organizedClusterData.flatMap((item) => item.employees)
      );
    } else {
      const organizedDepartmentData = organizeEmployeeData(
        departmentList,
        "Department"
      );
      setNewEmployeeData(
        organizedDepartmentData.flatMap((item) => item.employees)
      );
    }
  
    ProjectDATA(selectedDate);
  };
  

  const handleChange = (event, newValue) => {
    setValueData(newValue);
  };

  useEffect(() => {
    ProjectDATA();
  }, [empId, selectedOption,filterData]);

  // const ProjectDATA = (e) => {
  //   const formData = new URLSearchParams();
  //   if (e) {
  //     formData.append("filterDate", e.format("YYYY-MM-DD"));
  //   } else {
  //     formData.append("filterDate", moment().format("YYYY-MM-DD"));
  //   }

  //   AxiosInstance.post(`auth/projects-current-timer`, formData).then(
  //     async (res) => {
  //       // {console.log(res.data.data,"333")}
  //       setProjectWH(res.data.data);
  //       // ProjectmodifiedDATA();
  //     }
  //   );
  // };


  const ProjectDATA = (date) => {
    const formData = new URLSearchParams();
    const filterDate = date || selectedDate; // Use passed date or fallback to selectedDate state
  
    formData.append("filterDate", filterDate.format("YYYY-MM-DD"));
  
    AxiosInstance.post(`auth/projects-current-timer`, formData).then(
      async (res) => {
        setProjectWH(res.data.data);
      }
    );
  };
  

  const handleHoursClick = (projectEmployeeData) => {
    setModalData(projectEmployeeData);
    setVisible(true);
  };

  const ProjectmodifiedDATA = () => {
    const employeename = [];
    const employeeData = [];
    const projectEmployeeData = {};

    let totalHr = moment.duration();
    let totalhrss = 0;

    ProjectWH.forEach((item) => {
      let taskDuration = moment.duration(
        moment(item?.Check_Out).diff(moment(item.Check_In))
      );

      if (projectEmployeeData[item?.Project_id?._id]) {
        if (
          projectEmployeeData[item.Project_id?._id].Employee_Data.some(
            (e) => e.Employee_id == item?.Employee_id?._id
          )
        ) {
          projectEmployeeData[item.Project_id?._id].Employee_Data.forEach(
            (e) => {
              if (e.Employee_id == item?.Employee_id?._id) {
                e.Task_key.push(item?.Task_id?.Task_key);
                e.Total_seconds += Math.floor(taskDuration.asSeconds());
                e.Total_hours = formatDuration(e.Total_seconds);
              }
            }
          );
        } else {
          projectEmployeeData[item.Project_id?._id].Employee_Data.push({
            Employee_id: item?.Employee_id?._id,
            Employee_name: `${item?.Employee_id?.First_name} ${item?.Employee_id?.Last_name}`,
            Employee_location: item?.Employee_id?.Location_id?.Location_name,
            Task_key: [item?.Task_id?.Task_key],
            Total_seconds: Math.floor(taskDuration.asSeconds()),
            Total_hours: formatDuration(Math.floor(taskDuration.asSeconds())),
          });
        }

        projectEmployeeData[item.Project_id?._id].Project_Total_seconds +=
          Math.floor(taskDuration.asSeconds());
        projectEmployeeData[item.Project_id?._id].Project_Total_hours =
          formatDuration(
            projectEmployeeData[item.Project_id?._id].Project_Total_seconds
          );
      } else {
        projectEmployeeData[item?.Project_id?._id] = {
          Project_name: item?.Project_id?.Project_name,
          Employee_Data: [
            {
              Employee_id: item?.Employee_id?._id,
              Employee_name: `${item?.Employee_id?.First_name} ${item?.Employee_id?.Last_name}`,
              Employee_location: item?.Employee_id?.Location_id?.Location_name,
              Task_key: [item?.Task_id?.Task_key],
              Total_seconds: Math.floor(taskDuration.asSeconds()),
              Total_hours: formatDuration(Math.floor(taskDuration.asSeconds())),
            },
          ],
          Project_Total_seconds: Math.floor(taskDuration.asSeconds()),
          Project_Total_hours: formatDuration(
            Math.floor(taskDuration.asSeconds())
          ),
        };
      }

      totalhrss += taskDuration.asSeconds(); // Accumulate total seconds

      // Format duration for task key
      let taskKey = item?.Task_id?.Task_key;
      let projectLink = (
        <Link
          key={taskKey}
          style={{ fontWeight: "500", marginRight: "10px" }}
          to={`/project/task/${item?.Task_id?._id}/${item?.Project_id?._id}`}
          target="_blank"
        >
          {taskKey}
        </Link>
      );

      let existingEmployee = employeeData.find(
        (emp) =>
          emp.employee_name ===
          item?.Employee_id?.First_name + " " + item?.Employee_id?.Last_name
      );

      if (existingEmployee) {
        existingEmployee.duration.add(taskDuration);
        existingEmployee.TotalHours = formatDuration(
          existingEmployee.duration.asSeconds()
        );

        if (!existingEmployee.task_key.some((link) => link.key === taskKey)) {
          existingEmployee.task_key.push(projectLink);
        }
      } else {
        let employeeDataObj = {
          TotalHours: formatDuration(taskDuration.asSeconds()),
          duration: taskDuration,
          employee_name:
            item?.Employee_id?.First_name + " " + item?.Employee_id?.Last_name,
          employee_location: item?.Employee_id?.Location_id?.Location_name,
          task_key: [projectLink],
          Date: moment(item?.Check_In).format("DD/MM/YYYY"),
          cluster_id: item?.cluster_id,
          department_id: item?.department_id,
        };
        employeeData.push(employeeDataObj);
      }
      setNewEmployeeData(employeeData);

      const obj = employeename.find((o) => o.project_id == item.Project_id._id);
      if (obj) {
        var duration = moment.duration(
          moment(item?.Check_Out).diff(moment(item.Check_In))
        );
        var updatedduration = obj.duration.add(duration);
        var seconds = updatedduration.asSeconds();
        obj.duration = updatedduration;
        obj.durationinSec += duration.asSeconds();

        obj.TotalHours = (
          <Link
            onClick={() =>
              handleHoursClick(projectEmployeeData[item?.Project_id?._id])
            }
          >
            {formatDuration(obj.durationinSec)}
          </Link>
        );
      } else {
        var duration = moment.duration(
          moment(item?.Check_Out).diff(moment(item.Check_In))
        );
        var seconds = duration.asSeconds();

        let dataObj = {
          project_id: item?.Project_id?._id,
          project_name: item?.Project_id?.Project_name,
          duration: duration,
          Date: moment(item?.Check_In).format("DD/MM/YYYY"),
          durationinSec: seconds,
          TotalHours: (
            <Link
              onClick={() =>
                handleHoursClick(projectEmployeeData[item?.Project_id?._id])
              }
            >
              {formatDuration(seconds)}
            </Link>
          ),
        };

        employeename.push(dataObj);
      }
    });

    setTotalHours(formatDuration(totalhrss)); // Set the total hours

    function formatDuration(seconds) {
      let hours = Math.floor(seconds / 3600);
      let minutes = Math.floor((seconds % 3600) / 60);
      let remainingSeconds = Math.floor(seconds % 60);

      minutes = String(minutes).padStart(2, "0");
      remainingSeconds = String(remainingSeconds).padStart(2, "0");

      return `${hours}h ${minutes}m ${remainingSeconds}s`;
    }

    setDatatable({
      columns: [
        {
          label: "Project Name",
          field: "project_name",
          width: 50,
          sort: "disabled",
        },
        {
          label: "Date",
          field: "Date",
          width: 50,
          sort: "disabled",
        },
        {
          label: "Total Hours",
          field: "TotalHours",
          width: 50,
          sort: "disabled",
        },
      ],
      rows: employeename,
    });

    setEmployeetable({
      columns: [
        {
          label: "Employee Name",
          field: "employee_name",
          width: 50,
          sort: "disabled",
        },
        {
          label: "Location",
          field: "employee_location",
          width: 50,
          sort: "disabled",
        },
        {
          label: "Date",
          field: "Date",
          width: 50,
          sort: "disabled",
        },
        {
          label: "Task Id",
          field: "task_key",
          width: 50,
          sort: "disabled",
        },
        {
          label: "Total Hours",
          field: "TotalHours",
          width: 50,
          sort: "disabled",
        },
      ],
      rows: employeeData,
    });
  };

  const DepartmentWiseDATA = () => {
    const departmentdata = [];
    let totalHr = 0;
    let totalhrss = 0;

    ProjectWH.forEach((item) => {
      // if(item?.Check_Out && item?.Check_In ){
      const obj = departmentdata.find((o) => o.Role == item.Employee_id?.Role);
      if (obj) {
        var duration = moment.duration(
          moment(item?.Check_Out).diff(moment(item.Check_In))
        );
        var updatedduration = obj.duration.add(duration);
        var seconds = updatedduration.asSeconds();
        totalhrss += duration.asSeconds();
        obj.duration = updatedduration;
        obj.durationinSec += duration.asSeconds();

        obj.TotalHours =
          Math.floor(obj.durationinSec / 3600) +
          "h " +
          Math.floor((obj.durationinSec % 3600) / 60) +
          "m " +
          Math.floor(obj.durationinSec / 3600) +
          "s ";
      } else {
        // if (projectId == item.Project_id) {
        var duration = moment.duration(
          moment(item?.Check_Out).diff(moment(item.Check_In))
        );
        var seconds = duration.asSeconds();
        totalhrss += duration.asSeconds();

        let dataObj = {
          project_id: item?.Project_id?._id,
          project_name: item?.Project_id?.Project_name,
          Role: item?.Employee_id?.Role,

          duration: duration,
          Date: moment(item?.Check_In).format("DD/MM/YYYY"),
          durationinSec: seconds,
          TotalHours:
            Math.floor(seconds / 3600) + "h " + Math.floor(seconds / 60) + "m ",
        };
        if (totalHr) {
          totalHr = totalHr.add(duration);
        } else {
          totalHr = duration;
        }
        departmentdata.push(dataObj);
      }
    });
    setDepartmentDatatable({
      columns: [
        {
          label: "Department",
          field: "Role",
          width: 50,
          sort: "disabled",
        },
        {
          label: "Date",
          field: "Date",
          width: 50,
          sort: "disabled",
        },
        {
          label: "Total Hours",
          field: "TotalHours",
          width: 50,
          sort: "disabled",
        },
      ],
      rows: departmentdata,
    });
  };

  useEffect(() => {
    ProjectmodifiedDATA();
    DepartmentWiseDATA();
    // ProjectEmployeeModifiedData();
  }, [ProjectWH]);

  const handleCancel = (errorInfo) => {
    setVisible(false);
  };

  return (
    <>
      <div className="">
        <div className="demo main-page-container">
          <MDBCard>
            <div className="page-container">
              <div className="mx-0 create-project">
                <MDBCol size="md" className="col-example">
                  <h3 className="tablHeader">Project Hours {TotalHours}</h3>
                </MDBCol>
                <MDBCol size="md" className="col-example float-right"></MDBCol>
              </div>
            </div>

            <div className="tasklisttbl page-container all-project-list row ">
              {valueData == 0 && (
                <div className="date-picker-custom date-sercha-data">
                  <DatePicker
                    format={"DD/MM/YYYY"}
                    className="date-pick-pro-hour custom-date-pick-pro-hour"
                    style={{ zIndex: "99" }}
                    onChange={(e) => ProjectDATA(e)}
                  />
                </div>
              )}
              <MDBCol size="md-7">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={valueData}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Project Hours" {...a11yProps(0)} />
                    <Tab label="Employee Hours" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <CustomTabPanel
                  className="tabs-custom-hours"
                  value={valueData}
                  index={0}
                >
                  <MDBDataTableV5
                    entriesOptions={[20, 25, 30]}
                    entries={20}
                    pagesAmount={4}
                    data={datatable}
                    searchTop
                    searchBottom={false}
                    className="pro-work-hour-main-tbl directory-list-table employee-data-table"
                  />
                </CustomTabPanel>

                <CustomTabPanel
                  className="tabs-custom-hours"
                  value={valueData}
                  index={1}
                >
                  <div className="employee-hours-header cus-emp-hours-header">
                    <div className=" date-sercha-data">
                      <DatePicker
                        format={"DD/MM/YYYY"}
                        defaultValue={selectedDate}
                        className=" custom-date-pick-pro-hour"
                        style={{ zIndex: "99" }}
                        // onChange={(e) => ProjectDATA(e)}
                        onChange={handleDateChange}
                      />
                    </div>
                    <Select
                      placeholder="Select Cluster or Department"
                      className="select-dropdown cus-select-dropdown cus-emp-hour-dropdown"
                      onChange={handleSortingChange}
                      value={selectedOption}
                    >
                      <Option value="Cluster">Cluster</Option>
                      <Option value="Department">Department</Option>
                    </Select>
                  </div>

                  <Collapse
                    defaultActiveKey={["1"]}
                    accordion
                    className="custom-collapse collapse-child"
                  >
                    {renderAccordions()}
                  </Collapse>
                </CustomTabPanel>
              </MDBCol>

              <MDBCol size="md-1"></MDBCol>
              <MDBCol size="md-4">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tab label="Department Hours" />
                </Box>
                <CustomTabPanel className="tabs-custom-hours">
                  <MDBDataTableV5
                    entriesOptions={[20, 25, 30]}
                    entries={20}
                    pagesAmount={4}
                    data={departmentDatatable}
                    searchTop
                    searchBottom={false}
                    className="pro-work-hour-main-tbl directory-list-table employee-data-table"
                  />
                </CustomTabPanel>
              </MDBCol>
            </div>
          </MDBCard>

          <Modal
            title={`${modalData?.Project_name} - Details`}
            centered
            open={visible}
            onOk={() => setVisible(false)}
            onCancel={handleCancel}
            width={800} /* Increase width as needed */
            className="leave-form modal-back modal-title close-btn model-task-list-custom"
            footer={null}
          >
            <div className="task-list-table-container">
              <table className="task-list-table">
                <thead>
                  <tr>
                    <th>Employee Name</th>
                    <th>Task Key</th>
                    <th>Total Hours</th>
                  </tr>
                </thead>
                <tbody>
                  {modalData &&
                    modalData?.Employee_Data &&
                    modalData.Employee_Data.map((m) => (
                      <tr key={m?.Employee_id}>
                        <td>{m.Employee_name}</td>
                        <td>{m?.Task_key?.join(",")}</td>
                        <td>{m?.Total_hours}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default ProjectWorkingHours;
