import { useEffect, useState } from "react";
import AxiosInstance from "../../service/AxiosInstance";
//checkbox
export const Requirement = [
  { label: "Android", value: "Android" },
  { label: "iOS", value: "iOS" },
  { label: "WebSite", value: "WebSite" },
  // { label: 'Other', value: 'Other' },
];

//#region Employee list
export function EmployeeList() {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    AxiosInstance.get("auth/employees").then(async (res) => {
      const pmID = "61af44fb8fbced36f9e324a1";
      const tpmID = "61b04432da15f03ff00db934";
      const tm11 = "61b8869fd8310625581e52b0";
      if (res.data.status == 1) {
        let EM = await res.data.data.filter(
          (item) => item.Position_id !== pmID && item.Position_id !== tpmID
        );
        let EmList = await EM.map((item) => {
          return {
            label: item.First_name + " " + item.Last_name,
            value: item._id,
            id: item._id,
          };
        });
        setOptions(EmList);
      }
    });
  }, []);
  return options;
}

export function VerticalList() {
  const [verticalData, setVerticalData] = useState([]);
  useEffect(() => {
    AxiosInstance.get("/vertical/getAllVerticals").then((res) => {
      if (res.data.status == 1) {
        let data1 = res.data.data.map((item, index) => {
          return {
            label: item.Vertical_name,
            value: item._id,
            id: item._id,
            status: item.Status,
          };
        });
        data1 = data1.filter((item) => item.status === "Active");
        setVerticalData(data1);
      }
    });
  }, []);
  return verticalData;
}

export function TaskList(pid) {
  const [taskData, setTaskData] = useState([]);
  useEffect(() => {
    AxiosInstance.get(`auth/projecttasks/list/${pid}`).then((res) => {
      if (res.data.status == 1) {
        let data1 = res.data.data.map((item, index) => {
          return {
            label: item.Task_key + " " + item.Short_summary,
            value: item._id,
            id: item._id,
          };
        });
        setTaskData(data1);
      }
    });
  }, []);

  return taskData;
}
// Option Employee List For Given positionName
export function PositionEmployeeListOption(
  positionName,
  withDesignationName,
  department = ""
) {
  const [OptionsList, setOptionsList] = useState([]);
  useEffect(() => {
    const headers = {
      Department: department,
    };

    AxiosInstance.get(`auth/employees/${JSON.stringify(positionName)}`, {
      headers,
    })
      .then(async (res) => {
        let data = res.data.data;
        // console.log(
        //   data?.filter(
        //     (item) => item.Status === "Active" && item?.Role == "Sales"
        //   ),
        //   "data"
        // );

        setOptionsList(() => {
          return data
            .filter(
              (item) => item?.Status === "Active"
              // && tem?.Role === "Sales"
            )
            ?.map((item) => {
              return {
                label: withDesignationName
                  ? `${item.First_name} ${item.Last_name} (${item.Role})`
                  : item.First_name + " " + item.Last_name,
                value: item._id,
              };
            });
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);

  return OptionsList.length ? OptionsList : null;
}

export function PositionEmployeeListSales(
  positionName,
  withDesignationName,
  department = ""
) {
  const [OptionsList, setOptionsList] = useState([]);
  useEffect(() => {
    const headers = {
      Department: department,
    };

    AxiosInstance.get(`auth/employees/${JSON.stringify(positionName)}`, {
      headers,
    })
      .then(async (res) => {
        let data = res.data.data;
        setOptionsList(() => {
          return data
            .filter(
              (item) => item?.Status === "Active" && item?.Role === "Sales"
            )
            ?.map((item) => {
              return {
                label: withDesignationName
                  ? `${item.First_name} ${item.Last_name} (${item.Role})`
                  : item.First_name + " " + item.Last_name,
                value: item._id,
              };
            });
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);

  return OptionsList.length ? OptionsList : null;
}

//#endregion
//#region Employee list Name
export function EmployeeListName() {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    AxiosInstance.get("auth/employees").then(async (res) => {
      const pmID = "61af44fb8fbced36f9e324a1";
      const tpmID = "61b04432da15f03ff00db934";
      if (res.data.status == 1) {
        // let EM = await res.data.data.filter((item)=>item.Position_id !== pmID && item.Position_id !== tpmID )
        let EmList = await res.data.data.map((item) => {
          return {
            label:
              item.First_name + " " + item.Last_name + " (" + item.Role + ")",
            value: item.First_name + " " + item.Last_name,
          };
        });
        setOptions(EmList);
      }
    });
  }, []);
  return options;
}
//#endregion

//#region project manager list
export function ProjectManagerList() {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    AxiosInstance.get("auth/employees").then(async (res) => {
      const pmID = "61af44fb8fbced36f9e324a1";
      const tpmID = "61b04432da15f03ff00db934";
      const tm11 = "61b88642d8310625581e52ae";
      const ctoId = "61b727f037a0b8573dde2d98";
      if (res.data.status == 1) {
        let PM = await res.data.data.filter(
          (item) =>
            item.Position_id == pmID ||
            item.Position_id == tpmID ||
            item.Position_id == tm11
        );

        let PMList = PM.map((pml) => {
          return {
            label:
              pml?.First_name + " " + pml?.Last_name + " (" + pml.Role + ")",
            value: pml?._id,
            id: pml?._id,
          };
        });
        setOptions(PMList);
      }
    });
  }, []);
  return options;
}
//#endregion

//#region
export const techStackOption = [
  { label: "Mobile Application", value: "MobileApplication" },
  { label: " Web Application", value: "WebApplication" },
  { label: "Desktop Application", value: "DesktopApplication" },
  { label: "HTML Emailer", value: "HTMLEmailer" },
  { label: "SEO", value: "SEO" },
  { label: "SMM", value: "SMM" },
  { label: "Blockchain", value: "Blockchain" },

  // { label: 'Other', value: 'Other' },
];

// clientList

//#region  Status
export const ProjectStatus = [
  { label: "All", value: "All" },
  { label: "Open", value: "Open" },
  { label: "InProgress", value: "InProgress" },
  { label: "Hold", value: "Hold" },
  { label: "InClientReview", value: "InClientReview" },
  { label: "Completed", value: "Completed" },
];

//#region  Status
export const Status = [
  { label: "ALL", value: "ALL" },
  { label: "DRAFT", value: "DRAFT" },
  { label: "OPEN", value: "OPEN" },
  { label: "IN PROGRESS", value: "IN PROGRESS" },
  { label: "DEVELOPMENT DONE", value: "DEVELOPMENT DONE" },
  { label: "REJECTED", value: "REJECTED" },
  { label: "ON HOLD", value: "ON HOLD" },
  { label: "READY FOR QA", value: "READY FOR QA" },
  { label: "REOPEN", value: "REOPEN" },
  { label: "READY FOR UAT", value: "READY FOR UAT" },
  { label: "CLOSE", value: "CLOSE" },
];
//#endregion

//#region  IssueType
export const IssueType = [
  { label: "ALL", value: "ALL" },
  { label: "STORY", value: "Story" },
  { label: "TASK", value: "Task" },
  { label: "BUGS", value: "Bug" },
];
//#endregion

//#region  Status
export const Priority = [
  { label: "ALL", value: "ALL" },
  { label: "HIGH", value: "HIGH" },
  { label: "LOW", value: "LOW" },
  { label: "MEDIUM", value: "MEDIUM" },
];
//#endregion
export const LeaveStatus = [
  { label: "Pending", value: "Pending" },
  { label: "Approved", value: "Approved" },
];
//#region  Status
export const AttendanceOption = [
  { label: "ALL", value: "ALL" },
  { label: "ABSENT", value: "ABSENT" },
  { label: "PRESENT", value: "PRESENT" },
];
//#endregion

//#region  Status
export const sortTaskOption = [
  { label: "ALL", value: "ALL" },
  { label: "STORY", value: "Story" },
  { label: "TASK", value: "Task" },
  { label: "BUGS", value: "Bug" },
  { label: "SUGGESTION", value: "Suggestion" },
];
//#endregion
export function DepartmentList() {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    AxiosInstance.get("auth/department/list").then(async (res) => {
      // console.log(res, 'kk');
      if (res.data.status == 1) {
        let EmList = await res.data.data.map((item) => {
          return {
            label: item.Department_name,
            value: item._id,
            id: item._id,
          };
        });
        setOptions(EmList);
      }
    });
  }, []);
  return options;
}

export function PermissionList() {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    AxiosInstance.get("auth/permission/list").then(async (res) => {
      // console.log(res, 'kk');
      if (res.data.status == 1) {
        let EmList = await res.data.data.map((item) => {
          return {
            label: item.Permission_name,
            value: item._id,
            id: item._id,
          };
        });
        setOptions(EmList);
      }
    });
  }, []);
  return options;
}

export function PositionList() {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    AxiosInstance.get("auth/position/list").then(async (res) => {
      // console.log(res, 'kk');
      if (res.data.status == 1) {
        let EmList = await res.data.data.map((item) => {
          return {
            label: item.Position_name,
            value: item._id,
            id: item._id,
          };
        });
        setOptions(EmList);
      }
    });
  }, []);
  return options;
}
