import React, { useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Row,
  Checkbox,
  TimePicker,
} from "antd";
import AxiosInstance from "../../../service/AxiosInstance";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { useEffect } from "react";

const AttendanceChangeModal = (props) => {
  console.log(props);
  const [isRequestOpen, setIsRequestOpen] = useState(false);
  const [attendanceDate, setAttendanceDate] = useState();

  const [form] = Form.useForm();
  const [checkInValue, setCheckInValue] = useState(moment(props.item.checkIn));

  const { items, attendanceList } = props;
  const [presentFirstHalf, setPresentFirstHalf] = useState(props.item.present_first_half);
  const [presentSecondHalf, setPresentSecondHalf] = useState(props.item.present_second_half);
  const [allattendancdeId, setAllattendancdeId] = useState([]);
  const [isApproveOpen, setIsApproveOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDate, setFilteredDate] = useState();
  const [isRejectOpen, setIsRejectOpen] = useState(false);
  const [row, setRow] = useState({});
  const [rejectId, setRejectId] = useState("");
  const [employee, setEmployee] = useState({});
  const [checkOutValue, setCheckOutValue] = useState(
    props?.item.checkOut ? moment(props.item.checkOut) : null
  );
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [attendanceReport, setAttendanceReport] = useState([]);

  const onDateChange = (time) => {
    const selectedDate = moment(time._d).format("DD/MM/YYYY");
    setFilteredDate(selectedDate);
    const filteredReport = attendanceReport.filter((val) => {
      if (val.date === moment(time._d).format("DD/MM/YYYY")) {
        return val;
      }
    });
    setFilteredData(filteredReport);
    setCheckInValue(time);
  };

  const onChangeCheckOut = (time) => {
    console.log(time, "time");
    setCheckOutValue(time);
  };
  // const getAllRequest = () => {
  //   setLoading(true);
  //   AxiosInstance.get("auth/getAllMaunualAttendanceRequest").then((res) => {
  //     setLoading(false);
  //     setResult(res.data.data);
  //   });
  // };

  const handleCancel = (errorInfo) => {
    if (!isApproveOpen) {
      setIsRejectOpen(false);
    }
    setIsApproveOpen(false);
  };

  const handleClick = (items, key) => {
    console.log(key);
    setIsApproveOpen(true);
    if (key == "request") {
      setIsRequestOpen(true);
      setAttendanceDate(items?.date);
      setIsApproveOpen(true);
    }
  };

  const actionMenu = (items, listAction) => {
    return (
      <>
        <Menu
          items={listAction}
          onClick={(e) => handleClick(items, e.key)}
          // disabled={show}
        />
      </>
    );
  };

  let listAction = [
    {
      key: "request",
      label: "Update time",
      items: items,
    },
  ];

  const approveStatus = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("first_half", Boolean(presentFirstHalf));
    urlencoded.append("second_half", Boolean(presentSecondHalf));
    urlencoded.append("date", props.item.date);
    urlencoded.append("employee_id", props.employee_id);
    urlencoded.append("check_in", checkInValue);
    urlencoded.append("check_out", checkOutValue);

    AxiosInstance.post(
      "/auth/attendanceSumamryRequestApproved",
      urlencoded
    ).then((res) => {
      console.log(res.data.data, "data attendance");
      if (res.data.status == 1) {
        // getAllRequest();

        props.setIsAttendanceReqModal(true);
        setIsApproveOpen(false);
      }
    });
  };

  return (
    <>
      <Dropdown.Button
        overlay={actionMenu(items, listAction)}
        // menu={actionMenu(items, listAction)}
      ></Dropdown.Button>
      {/* Request modal */}

      <Modal
        title="Approve request modal"
        centered
        // visible={isApproveOpen}
        open={isApproveOpen}
        onOk={() => {
          handleClick(false);
        }}
        onCancel={handleCancel}
        width={410}
        footer={null}
      >
        {isApproveOpen && (
          <Form form={form}>
            <Form.Item
              label="Check In Time"
              name="checkInTime"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please Select time",
              //   },
              // ]}
            >
              {" "}
              {checkInValue.format("HH:mm")}
              <TimePicker
                onChange={onDateChange}
                // defaultValue={moment(
                //   moment(employee?.check_in_time).format("HH:mm"),
                //   "HH:mm"
                // )}
                defaultValue={checkInValue}
                value={checkInValue}
              />
            </Form.Item>
            <Form.Item
              label="Check Out Time"
              name="checkOutTime"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please Select time",
              //   },
              // ]}
            >
              {" "}
              {checkOutValue?.format("HH:mm")}
              <TimePicker
                onChange={onChangeCheckOut}
                defaultValue={checkOutValue}
                value={checkOutValue}
              />
            </Form.Item>
            <Form.Item label="Present count" name="presentcount">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Present First Half" name="presentFirstHalf">
              <Checkbox
                checked={presentFirstHalf}
                onChange={(e) => setPresentFirstHalf(e.target.checked)}
              />
              {/* <Radio.Group>
                <Radio>True</Radio>
                <Radio>False</Radio>
              </Radio.Group> */}
            </Form.Item>
            <Form.Item label="Present Second Half" name="presentSecondHalf">
              <Checkbox
                checked={presentSecondHalf}
                onChange={(e) => setPresentSecondHalf(e.target.checked)}
              />
            </Form.Item>
            <Button
              htmlType="submit"
              style={{ marginRight: "10px" }}
              onClick={() => approveStatus()}
              className="pms-same-btn"
            >
              Submit2
            </Button>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default AttendanceChangeModal;
