import { Table } from "antd";
import { MDBCard } from "mdbreact";
import { MDBCol } from "mdb-react-ui-kit";
import ReactLoader from "../../../components/common/ReactLoader";
import { useState } from "react";
import ContactBar from "./ContactBar";

interface DataType {
  key: string;
  name: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Related Issue",
    dataIndex: "relatedIssue",
    key: "relatedIssue",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
];

const data: DataType[] = [
  {
    key: "1",
    name: "-",
    relatedIssue: "For any IT (System and Mobile) Software/Hardware",
  },
  {
    key: "2",
    name: "Hardik Trivedi and Krishna Prajapati ",
    relatedIssue:
      "For Operations  (Infrastructure / House Keeping /Facilities ) related Query",
  },
  {
    key: "3",
    name: "Yatri Chauhan",
    relatedIssue: "For Salary / Referral Amount/ Form 16 related Query",
  },
  {
    key: "4",
    name:
      "Simran Gupta and Shivani Jaiswal",
    relatedIssue: "For HR (Documents / Leave / Increment) Related ",
  },
];

function ContactUs() {
  const [loading, setLoading] = useState(false);
  return (
    <>
      <div className="demo">
        <ContactBar />
        <MDBCard className="page-content">
          <MDBCol
            // style={{ marginLeft: '7px' }}
            size="md"
            className="col-example"
          ></MDBCol>
          <div className="create-project">
            <div className="scrool-set mobile-scroll">
              <MDBCol
                style={{ marginTop: "20px" }}
                size="md"
                className="col-example"
              >
                <Table
                  columns={columns}
                  dataSource={data}
                  className="leave-tbl"
                  pagination={false}
                />
              </MDBCol>
            </div>
          </div>
          {loading ? (
            <ReactLoader loading={loading} />
          ) : (
            <div className="page-container-table"></div>
          )}
        </MDBCard>
      </div>
    </>
  );
}
export default ContactUs;
