import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Row,
  Col,
  Radio,
  Select,
  Checkbox,
  Table,
  Calendar,
  Space,
  DatePicker,
  Typography,
  Upload,
  message,
} from "antd";
import AxiosInstance from "../../../../service/AxiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "antd/dist/antd.css";
import { useHistory } from "react-router-dom";
import SelectEmpployee from "../../../../components/common/SelectEmpployee";

// import SelectEmpployee from "../../../../components/common/SelectEmpployee";
import moment from "moment";
import { Alert, Button as Buttonss } from "@mui/material";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import HandBook from "../../vhPolicy/HandBook";

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;
// main component
const LeaveForm = (props) => {
  const showEmployee = props.showEmployee ? props.showEmployee : false;

  const location = useLocation();
  let history = useHistory();

  const [form] = Form.useForm();
  const [form1] = Form.useForm();

  const [visible, setVisible] = useState(false);
  const [openLeavePolicy, setOpenLeavePolicy] = useState(false);
  const [chooseLeavevisible, setChooseLeavevisible] = useState(false);

  const [emergency, setEmergency] = useState(false);

  const [justifyActive, setJustifyActive] = useState("tab1");
  const [value, setValue] = useState();
  const [valueFirstDay, setValueFirstDay] = useState("First Half");
  const [valueSecondDay, setValueSecondDay] = useState("Second Half");
  const [leaveRange, setLeaveRange] = useState("");
  const [employeeId, setEmployeeId] = useState([]);
  const [leaveDates, setLeaveDates] = useState();
  const [notifiedEmployee, setNotifiedEmployee] = useState([]);
  const [status, setStatus] = useState("");
  const [leaveType, setLeaveType] = useState("");
  const [leaveDays, setLeaveDays] = useState("");
  const [dateError, setDateError] = useState("");
  const [leaveDatesError, setLeaveDatesError] = useState("");

  const [uploadError, setUploadError] = useState("");
  const [leaveDateDiffrent, setLeaveDateDiffrent] = useState();
  const [flag, setFlag] = useState(false);
  const [url, setUrl] = useState("");
  const [file, setFile] = useState("");
  const [isHalfLeaveCheck, setIsHalfLeaveCheck] = useState(false);

  const fileHandler = (e) => {
    const File = e[0];
    let urllink = File.name;
    setUrl(urllink);
  };
  let userData = JSON.parse(localStorage.getItem("userdata"));

  const sickleavedata = 5;

  //check days diff for more than 3 days leave
  function datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }
  function parseDate(str) {
    var mdy = str.split("/");
    return new Date(mdy[2], mdy[0] - 1, mdy[1]);
  }
  //this function is for selecting dates

  useEffect(() => {
    if (leaveDates) {
      const startDate = leaveDates?.[0].format("MM/DD/YYYY");
      const endDate = leaveDates?.[1].format("MM/DD/YYYY");
      let totalDays = datediff(parseDate(startDate), parseDate(endDate));
      if (leaveType == "Casual Leave") {
        if (totalDays >= 3) {
          // alert("Please Give Proper Reason")
          setFlag(false);
          // warning();
        } else {
          setFlag(true);
        }
      } else {
        setFlag(true);
      }
    }
  }, [leaveDates, leaveType]);

  const optionsWithDisabled = [
    { label: "First Half", value: "First Half" },
    { label: "Second Half", value: "Second Half" },
  ];
  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onFinish = async (values) => {
    // if  (flag) {
    if (leaveDates == undefined) {
      setLeaveDatesError("Please enter your leave dates");
      return false;
    }

    const startDate = leaveDates[0].format("YYYY-MM-DD");
    const endDate = leaveDates[1].format("YYYY-MM-DD");

    if (showEmployee) {
      if (status == "") {
        alert("Please select status");

        return false;
      }
    }

    if (values && dateError == "") {
      const formData = new FormData();
      var date2 = new Date();
      let fomatedDate = moment(date2).format("MMM DD YYYY hh:mm a");
      formData.append("Email", values.email);
      if (leaveType == "Emergency Leave") {
        formData.append("Leave_Date_to_Date", [moment(), moment()]);
      } else {
        formData.append("Leave_Date_to_Date", values.dateToDate);
      }
      formData.append("StartDate", startDate);
      formData.append("EndDate", endDate);
      formData.append("Reason", values.reason);
      formData.append("Leave_half_from", valueFirstDay);
      formData.append(
        "Leave_half_to",
        isHalfLeaveCheck ? valueFirstDay : valueSecondDay
      );
      formData.append("Notified_Employee", notifiedEmployee);
      formData.append("Create_at", fomatedDate);
      formData.append("Emergency_leave", emergency);
      formData.append("Leave_type", leaveType);
      formData.append("Leave_Days", leaveDateDiffrent);
      // let fileData = file && file[0] ? [...file] : "";
      // if (fileData !== undefined) {
      //   formData.append("image", fileData ? fileData[0] : "");
      // }

      if (
        (leaveDays == "3" || leaveDays == "4" || leaveDays == "5") &&
        leaveType == "Sick Leave"
      ) {
        let fileData = file && file[0] ? [...file] : "";

        if (fileData !== undefined && fileData[0]) {
          formData.append("image", fileData[0]);
        } else {
          setUploadError("Please upload the document");
          return false;
        }
      }
      // formData.append('Employee_ID', userData[0]?._id)

      formData.append(
        "Employee_ID",
        showEmployee ? employeeId[0] : userData[0]?._id
      );

      formData.append("Status", showEmployee ? status : "Pending");
      // if(showEmployee){}

      AxiosInstance.post("auth/leave/add", formData)
        .then((res) => {
          if (res.data.status == 1) {
            // toast.success("Leave applied Successfully");
            message.success("Leave applied Successfully");
            history.push("/me");
            props.propData();
            if (showEmployee) {
              history.push("/leaves-report");
            } else {
              history.push("/leaves");
            }
            handleCancel();
          }
        })
        .catch((err) => toast.error("Internal Error"));
    }

    // props.propData(true);

    form.resetFields();
    setVisible(false);
    // } else {
    //   handleCancel()

    // }
  };
  const onFinishFailed = (errorInfo) => {};

  const onChangeFirstDay = ({ target: { value } }) => {
    if (leaveDays == 0.5 && value == "First Half") {
      // alert("ERROR")
      setLeaveRange("Please select valid First & Second Half");
    } else {
      setLeaveRange("");
    }
    setValueFirstDay(value);
  };
  const onChangeSecondDay = ({ target: { value } }) => {
    if (leaveDateDiffrent !== 0.5 && value !== "Second Half") {
      // alert("ERROR")
      setLeaveRange("Please select valid First & Second Half ");
    } else {
      setLeaveRange("");
    }
    setValueSecondDay(value);
  };

  const handleCancel = (errorInfo) => {
    form.resetFields();
    // form1.resetFields();
    setLeaveType("");
    setLeaveDays("");
    setLeaveDates("");
    setIsHalfLeaveCheck(false);
    setDateError("");
    props.propData();
    setVisible(false);
  };
  // const warning = () => {
  //   Modal.info({
  //     className: "leave-notice-msg",
  //     content: (
  //       <div>
  //         <p style={{ margin: 0 }}>
  //           According to HR rule you can not applied a leave for more than 3
  //           days.
  //         </p>
  //         <p style={{ margin: 0, marginTop: 5 }}>
  //           If you want to applied for more than 3 days then please mention
  //           valid reason.
  //         </p>
  //       </div>
  //     ),
  //   });
  // };
  // olo error message kya sok

  // let applyLeaveDateDisable = {
  //   1: 2,
  //   2: 6,
  //   3: 6,
  //   4: 6,
  //   5: 15,
  //   6: 15,
  //   7: 15,
  //   8: 30,
  // };
  // const fouth4off = "4_SAT_OFF";
  // const secondAndforthoff = "2_&_4_SAT_OFF";

  // const fileHandler = (files) => {
  //   // Your file handling logic here
  //   console.log('Selected files:', files);
  // };

  const handleFileChange = (e) => {
    const files = e.target.files;
    setUploadError("");
    // Check if at least one file is selected
    if (files.length > 0) {
      setFile(files);
      fileHandler(files);
    } else {
      // Validation failed, no files selected
      alert("Please select at least one file.");
      // You can add more detailed validation messages or styles as needed
    }
  };

  // const getDateRange = (firstDate, lastDate) => {
  //   if (moment(firstDate, 'YYYY-MM-DD').isSame(moment(lastDate, 'YYYY-MM-DD'), 'day'))
  //     return [lastDate];
  //   let date = moment(firstDate).format('YYYY-MM-DD')
  //   const dates = [date];
  //   do {
  //       date = moment(date).add(1, 'day');
  //       dates.push(date.format('YYYY-MM-DD'));
  //   } while (moment(date).isBefore(lastDate));
  //   return dates;
  // };

  const getDateRange = (firstDate, lastDate) => {
    if (
      moment(firstDate, "YYYY-MM-DD").isSame(
        moment(lastDate, "YYYY-MM-DD"),
        "day"
      )
    ) {
      return [firstDate];
    }

    let date = moment(firstDate).format("YYYY-MM-DD");
    const dates = [date];

    while (moment(date).isBefore(lastDate)) {
      date = moment(date).add(1, "day").format("YYYY-MM-DD");

      if (moment(lastDate).format("YYYY-MM-DD") >= date) {
        dates.push(date);
      }
    }

    return dates;
  };
  // const fouth4off = "4_SAT_OFF";
  const secondAndforthoff = "2_&_4_SAT_OFF";
  const calculateDisabledDate = (current) => {
    const date = new Date();

    const dayindex = moment(date).day();
    // const dayOfWeek = dayindex.format("dd");

    // check differenece of days from start & end Date == leave days
    let totalDiffDays;
    if (leaveDates?.length) {
      let startDate = leaveDates?.[0];
      let endDate = leaveDates?.[1];
      let dateRange = getDateRange(startDate, endDate);
      let weekendDaysCount = 0;
      if (leaveDays !== "8 or 8 ++") {
        for (let i = 0; i < dateRange.length; i++) {
          let weekDays = moment(dateRange[i]).day();
          // if(leaveDays !== "8 or 8 ++"){

          if (userData[0]?.Shift_type === "4_SAT_OFF") {
            if (weekDays == 0) {
              weekendDaysCount = weekendDaysCount + 1;
            }
          } else if (userData[0]?.Shift_type == "2_&_4_SAT_OFF") {
            if (weekDays == 0) {
              weekendDaysCount = weekendDaysCount + 1;
            }
          } else {
            if (weekDays == 6 || weekDays == 0) {
              weekendDaysCount = weekendDaysCount + 1;
            }
          }
          // }
        }
        let diffBetweenDates = endDate.diff(startDate, "days");
        totalDiffDays = diffBetweenDates - weekendDaysCount + 1;
      }
    }

    if (leaveDays !== "8 or 8 ++") {
      if (totalDiffDays != undefined && totalDiffDays == leaveDays) {
        setLeaveDateDiffrent(totalDiffDays);
        setDateError("");
      } else if (totalDiffDays) {
        if (isHalfLeaveCheck) {
          setDateError(`Please select any 1 days for half leave`);
        } else {
          setDateError(`Please select ${leaveDays} days for leave`);
        }
      }
      if (totalDiffDays == undefined) {
        setDateError("");
      }
    } else if (leaveDays === "8 or 8 ++") {
      if (leaveDates?.length) {
        let startDate = leaveDates?.[0];
        let endDate = leaveDates?.[1];
        let dayBetweenDays = endDate.diff(startDate, "days");
        if (dayBetweenDays < 7) {
          setDateError(`Please select 8 days for leave`);
        } else {
          setLeaveDateDiffrent(dayBetweenDays);
          setDateError("");
        }
      }
    } else if (leaveDays == "0.5") {
      setDateError("");
    }

    if (leaveType === "Emergency Leave") {
      return !current.isSame(moment(), "day");
    } else if (leaveType === "Sick Leave") {
      // Check if the current date is a Saturday or Sunday
      const isWeekend =
        current.day() === 0 /* Sunday */ || current.day() === 6; /* Saturday */

      // Check if the current date is outside the allowed range or is a weekend
      return (
        current < moment().startOf("day") ||
        current > moment().add(6, "days").endOf("day") ||
        isWeekend
      );
    } else if (
      leaveType === "Casual Leave" &&
      userData[0]?.Shift_type == "EVERY_SAT_SUN_OFF" &&
      isHalfLeaveCheck
    ) {
      let _days = dayindex === 5 || dayindex === 4 || dayindex === 3 ? 5 : 3;
      const start = moment().endOf("day");
      let enableDates = [start.format("YYYY-MM-DD")];
      let i = 1;
      while (i < 7) {
        const nextDate = start.clone().add(i, "days").endOf("day");

        if (nextDate.day() != 6 && nextDate.day() != 0) {
          enableDates.push(nextDate.format("YYYY-MM-DD"));
        }
        i++;
      }

      if (!enableDates.includes(current.format("YYYY-MM-DD"))) {
        return true;
      }
    } else if (
      leaveType === "Casual Leave" &&
      userData[0]?.Shift_type == "EVERY_SAT_SUN_OFF" &&
      leaveDays === "1"
    ) {
      let _days = dayindex === 5 || dayindex === 4 || dayindex === 3 ? 5 : 3;
      const start = moment().add(_days, "days").endOf("day");

      let enableDates = [start.format("YYYY-MM-DD")];
      let i = 1;
      while (i < 14) {
        const nextDate = start.clone().add(i, "days").endOf("day");

        if (nextDate.day() != 6 && nextDate.day() != 0) {
          enableDates.push(nextDate.format("YYYY-MM-DD"));
        }
        i++;
      }

      if (!enableDates.includes(current.format("YYYY-MM-DD"))) {
        return true;
      }
    } else if (
      leaveType === "Casual Leave" &&
      userData[0]?.Shift_type === "EVERY_SAT_SUN_OFF" &&
      (leaveDays === "2" || leaveDays === "3" || leaveDays === "4")
    ) {
      let _days = dayindex === 5 ? 10 : 8;
      const start = moment().add(_days, "days").endOf("day");

      let enableDates = [start.format("YYYY-MM-DD")];
      let i = 1;
      while (i < 14) {
        const nextDate = start.clone().add(i, "days").endOf("day");
        if (nextDate.day() != 6 && nextDate.day() != 0) {
          enableDates.push(nextDate.format("YYYY-MM-DD"));
        }
        i++;
      }

      if (!enableDates.includes(current.format("YYYY-MM-DD"))) {
        return true;
      }

      // const start = moment().add(_days, "days").endOf("day");

      // const end = start.clone().add(10, "days").endOf("day");

      // if(current.isBefore(start) || current.isAfter(end))
      // {
      //   return true;
      // }
    } else if (
      leaveType === "Casual Leave" &&
      userData[0]?.Shift_type === "EVERY_SAT_SUN_OFF" &&
      leaveDays === "5"
    ) {
      // const today = moment().format("YYYY-MM-DD");
      let _days = dayindex === 5 ? 17 : 15;
      let thursdayCheck = dayindex === 4;
      const start = moment().add(_days, "days").endOf("day");

      let enableDates = [start.format("YYYY-MM-DD")];
      let i = 1;
      while (i < (thursdayCheck ? 11 : 7)) {
        const nextDate = start.clone().add(i, "days").endOf("day");
        if (nextDate.day() != 6 && nextDate.day() != 0) {
          enableDates.push(nextDate.format("YYYY-MM-DD"));
        }
        i++;
      }

      if (!enableDates.includes(current.format("YYYY-MM-DD"))) {
        return true;
      }
      // const start = moment().add(_days, "days").endOf("day")
      // const end = start.add(4, "days").endOf("day")
      // const start = moment().add(_days, "days").endOf("day");
      // const end = start.clone().add(7, "days").endOf("day");

      // if(current.isBefore(start) || current.isAfter(end))
      // {
      //   return true;
      // }
      // for (let i = 1; i <= 14; i++) {
      //   if (
      //     current.isBefore(moment().startOf("day")) ||
      //     current.isSame(moment(), "day") ||
      //     current.isSame(moment().add(i, "days"), "day")
      //   ) {
      //     return true;
      //   }
      // }
    } else if (
      leaveType === "Casual Leave" &&
      userData[0]?.Shift_type === "EVERY_SAT_SUN_OFF" &&
      leaveDays === "6"
    ) {
      // const today = moment().format("YYYY-MM-DD");
      let _days = dayindex === 5 ? 17 : 15;
      let thursdayCheck = dayindex === 4;
      const start = moment().add(_days, "days").endOf("day");

      let enableDates = [start.format("YYYY-MM-DD")];
      let i = 1;
      while (i < (thursdayCheck ? 11 : 8)) {
        const nextDate = start.clone().add(i, "days").endOf("day");
        if (nextDate.day() != 6 && nextDate.day() != 0) {
          enableDates.push(nextDate.format("YYYY-MM-DD"));
        }
        i++;
      }

      if (!enableDates.includes(current.format("YYYY-MM-DD"))) {
        return true;
      }
      // const start = moment().add(_days, "days").endOf("day")
      // const end = start.add(4, "days").endOf("day")
      // const start = moment().add(_days, "days").endOf("day");
      // const end = start.clone().add(7, "days").endOf("day");

      // if(current.isBefore(start) || current.isAfter(end))
      // {
      //   return true;
      // }
      // for (let i = 1; i <= 14; i++) {
      //   if (
      //     current.isBefore(moment().startOf("day")) ||
      //     current.isSame(moment(), "day") ||
      //     current.isSame(moment().add(i, "days"), "day")
      //   ) {
      //     return true;
      //   }
      // }
    } else if (
      leaveType === "Casual Leave" &&
      userData[0]?.Shift_type === "EVERY_SAT_SUN_OFF" &&
      leaveDays === "7"
    ) {
      // const today = moment().format("YYYY-MM-DD");
      let _days = dayindex === 5 ? 17 : 15;
      let thursdayCheck = dayindex === 4;
      const start = moment().add(_days, "days").endOf("day");

      let enableDates = [start.format("YYYY-MM-DD")];
      let i = 1;
      while (i < (thursdayCheck ? 11 : 9)) {
        const nextDate = start.clone().add(i, "days").endOf("day");
        if (nextDate.day() != 6 && nextDate.day() != 0) {
          enableDates.push(nextDate.format("YYYY-MM-DD"));
        }
        i++;
      }

      if (!enableDates.includes(current.format("YYYY-MM-DD"))) {
        return true;
      }
      // const start = moment().add(_days, "days").endOf("day")
      // const end = start.add(4, "days").endOf("day")
      // const start = moment().add(_days, "days").endOf("day");
      // const end = start.clone().add(7, "days").endOf("day");

      // if(current.isBefore(start) || current.isAfter(end))
      // {
      //   return true;
      // }
      // for (let i = 1; i <= 14; i++) {
      //   if (
      //     current.isBefore(moment().startOf("day")) ||
      //     current.isSame(moment(), "day") ||
      //     current.isSame(moment().add(i, "days"), "day")
      //   ) {
      //     return true;
      //   }
      // }
    } else if (
      leaveType === "Casual Leave" &&
      userData[0]?.Shift_type === "EVERY_SAT_SUN_OFF" &&
      leaveDays === "8 or 8 ++"
    ) {
      let _days = 29;
      // const start = moment().add(_days, "days").endOf("day")
      // const end = start.add(4, "days").endOf("day")
      const start = moment().add(_days, "days").endOf("day");
      const end = start.clone().add(30, "days");

      if (current.isBefore(start)) {
        return true;
      }
    }

    //  4TH SATURDAY OFF BASIS
    if (
      leaveType === "Casual Leave" &&
      userData[0]?.Shift_type == "4_SAT_OFF" &&
      leaveDays === "1"
    ) {
      let _days = dayindex === 5 || dayindex === 4 || dayindex === 6 ? 4 : 3;
      const start = moment().add(_days, "days").endOf("day");

      let enableDates = [start.format("YYYY-MM-DD")];
      let i = 1;
      while (i < 11) {
        const nextDate = start.clone().add(i, "days").endOf("day");

        if (nextDate.day() != 0) {
          enableDates.push(nextDate.format("YYYY-MM-DD"));
        }
        i++;
      }

      if (!enableDates.includes(current.format("YYYY-MM-DD"))) {
        return true;
      }
    } else if (
      leaveType === "Casual Leave" &&
      userData[0]?.Shift_type == "4_SAT_OFF" &&
      (leaveDays === "2" || leaveDays === "3" || leaveDays === "4")
    ) {
      let _days = dayindex === 5 ? 7 : 7;
      let thursdayCheck = dayindex === 4;
      const start = moment().add(_days, "days").endOf("day");

      let enableDates = [start.format("YYYY-MM-DD")];
      let i = 1;
      while (i < (thursdayCheck ? 12 : 12)) {
        const nextDate = start.clone().add(i, "days").endOf("day");
        if (nextDate.day() != 0) {
          enableDates.push(nextDate.format("YYYY-MM-DD"));
        }
        i++;
      }

      if (!enableDates.includes(current.format("YYYY-MM-DD"))) {
        return true;
      }
    } else if (
      leaveType === "Casual Leave" &&
      userData[0]?.Shift_type == "4_SAT_OFF" &&
      leaveDays === "5"
    ) {
      let _days = dayindex === 5 || dayindex === 4 || dayindex === 6 ? 18 : 17;
      let thursdayCheck = dayindex === 4;
      const start = moment().add(_days, "days").endOf("day");

      let enableDates = [start.format("YYYY-MM-DD")];
      let i = 1;
      while (i < (thursdayCheck ? 5 : 6)) {
        const nextDate = start.clone().add(i, "days").endOf("day");
        if (nextDate.day() != 0) {
          enableDates.push(nextDate.format("YYYY-MM-DD"));
        }
        i++;
      }

      if (!enableDates.includes(current.format("YYYY-MM-DD"))) {
        return true;
      }
    } else if (
      leaveType === "Casual Leave" &&
      userData[0]?.Shift_type == "4_SAT_OFF" &&
      leaveDays === "6"
    ) {
      let _days = dayindex === 5 || dayindex === 4 || dayindex === 6 ? 18 : 17;
      let thursdayCheck = dayindex === 4;
      const start = moment().add(_days, "days").endOf("day");

      let enableDates = [start.format("YYYY-MM-DD")];
      let i = 1;
      while (i < (thursdayCheck ? 6 : 7)) {
        const nextDate = start.clone().add(i, "days").endOf("day");
        if (nextDate.day() != 0) {
          enableDates.push(nextDate.format("YYYY-MM-DD"));
        }
        i++;
      }

      if (!enableDates.includes(current.format("YYYY-MM-DD"))) {
        return true;
      }
    } else if (
      leaveType === "Casual Leave" &&
      userData[0]?.Shift_type == "4_SAT_OFF" &&
      leaveDays === "7"
    ) {
      let _days = dayindex === 5 || dayindex === 4 ? 18 : 17;
      let thursdayCheck = dayindex === 4;
      const start = moment().add(_days, "days").endOf("day");

      let enableDates = [start.format("YYYY-MM-DD")];
      let i = 1;
      while (i < (thursdayCheck ? 8 : 8)) {
        const nextDate = start.clone().add(i, "days").endOf("day");
        if (nextDate.day() != 0) {
          enableDates.push(nextDate.format("YYYY-MM-DD"));
        }
        i++;
      }

      if (!enableDates.includes(current.format("YYYY-MM-DD"))) {
        return true;
      }
    } else if (
      leaveType === "Casual Leave" &&
      userData[0]?.Shift_type == "4_SAT_OFF" &&
      leaveDays === "8 or 8 ++"
    ) {
      let _days = 29;
      // const start = moment().add(_days, "days").endOf("day")
      // const end = start.add(4, "days").endOf("day")
      const start = moment().add(_days, "days").endOf("day");
      const end = start.clone().add(30, "days");

      if (current.isBefore(start)) {
        return true;
      }
    }

    // 2 AND 4 TH SATURDAY OFF BASIS
    // secondAndforthoff = "2_&_4_SAT_OFF
    if (
      leaveType === "Casual Leave" &&
      userData[0]?.Shift_type == "2_&_4_SAT_OFF" &&
      leaveDays === "1"
    ) {
      let _days = dayindex === 5 || dayindex === 4 || dayindex === 6 ? 4 : 3;
      const start = moment().add(_days, "days").endOf("day");

      let enableDates = [start.format("YYYY-MM-DD")];
      let i = 1;
      while (i < 11) {
        const nextDate = start.clone().add(i, "days").endOf("day");

        if (nextDate.day() != 0) {
          enableDates.push(nextDate.format("YYYY-MM-DD"));
        }
        i++;
      }

      if (!enableDates.includes(current.format("YYYY-MM-DD"))) {
        return true;
      }
    } else if (
      leaveType === "Casual Leave" &&
      userData[0]?.Shift_type == "2_&_4_SAT_OFF" &&
      (leaveDays === "2" || leaveDays === "3" || leaveDays === "4")
    ) {
      let _days = dayindex === 5 ? 7 : 7;
      let thursdayCheck = dayindex === 4;
      const start = moment().add(_days, "days").endOf("day");

      let enableDates = [start.format("YYYY-MM-DD")];
      let i = 1;
      while (i < (thursdayCheck ? 12 : 12)) {
        const nextDate = start.clone().add(i, "days").endOf("day");
        if (nextDate.day() != 0) {
          enableDates.push(nextDate.format("YYYY-MM-DD"));
        }
        i++;
      }

      if (!enableDates.includes(current.format("YYYY-MM-DD"))) {
        return true;
      }
    } else if (
      leaveType === "Casual Leave" &&
      userData[0]?.Shift_type == "2_&_4_SAT_OFF" &&
      leaveDays === "5"
    ) {
      let _days = dayindex === 5 || dayindex === 4 || dayindex === 6 ? 18 : 17;
      let thursdayCheck = dayindex === 4;
      const start = moment().add(_days, "days").endOf("day");

      let enableDates = [start.format("YYYY-MM-DD")];
      let i = 1;
      while (i < (thursdayCheck ? 5 : 6)) {
        const nextDate = start.clone().add(i, "days").endOf("day");
        if (nextDate.day() != 0) {
          enableDates.push(nextDate.format("YYYY-MM-DD"));
        }
        i++;
      }

      if (!enableDates.includes(current.format("YYYY-MM-DD"))) {
        return true;
      }
    } else if (
      leaveType === "Casual Leave" &&
      userData[0]?.Shift_type == "2_&_4_SAT_OFF" &&
      leaveDays === "6"
    ) {
      let _days = dayindex === 5 || dayindex === 4 || dayindex === 6 ? 18 : 17;
      let thursdayCheck = dayindex === 4;
      const start = moment().add(_days, "days").endOf("day");

      let enableDates = [start.format("YYYY-MM-DD")];
      let i = 1;
      while (i < (thursdayCheck ? 6 : 7)) {
        const nextDate = start.clone().add(i, "days").endOf("day");
        if (nextDate.day() != 0) {
          enableDates.push(nextDate.format("YYYY-MM-DD"));
        }
        i++;
      }

      if (!enableDates.includes(current.format("YYYY-MM-DD"))) {
        return true;
      }
    } else if (
      leaveType === "Casual Leave" &&
      userData[0]?.Shift_type == "2_&_4_SAT_OFF" &&
      leaveDays === "7"
    ) {
      let _days = dayindex === 5 || dayindex === 4 ? 18 : 17;
      let thursdayCheck = dayindex === 4;
      const start = moment().add(_days, "days").endOf("day");

      let enableDates = [start.format("YYYY-MM-DD")];
      let i = 1;
      while (i < (thursdayCheck ? 8 : 8)) {
        const nextDate = start.clone().add(i, "days").endOf("day");
        if (nextDate.day() != 0) {
          enableDates.push(nextDate.format("YYYY-MM-DD"));
        }
        i++;
      }

      if (!enableDates.includes(current.format("YYYY-MM-DD"))) {
        return true;
      }
    } else if (
      leaveType === "Casual Leave" &&
      userData[0]?.Shift_type == "2_&_4_SAT_OFF" &&
      leaveDays === "8 or 8 ++"
    ) {
      let _days = 29;
      // const start = moment().add(_days, "days").endOf("day")
      // const end = start.add(4, "days").endOf("day")
      const start = moment().add(_days, "days").endOf("day");
      const end = start.clone().add(30, "days");

      if (current.isBefore(start)) {
        return true;
      }
    }
  };
  const handleDateChange = (dates) => {
    setLeaveDates(dates);
    setLeaveDatesError(null);
  };

  return (
    <>
      <Button
        className="pms-same-btn leave-btn"
        type="primary"
        onClick={() => setVisible(true)}
      >
        Apply Leave
      </Button>

      <Modal
        title="Employee Leave Form"
        centered
        // visible={visible}
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={handleCancel}
        width={550}
        className="leave-form modal-back modal-title close-btn leave-modal modal-main"
        footer={null}
      >
        {/* <MDBTabsContent>
            <MDBTabsPane show={justifyActive === 'tab1'}> */}
        <div className="choose-leave-modal-body">
          {/* <div className="choose-leave-main">
          <h3 className="modal-title-text">Choose leave Type</h3>
          <div className="choose-box-main">
            <Button className="radio-cust-btn-main active">
              <div className="radio-cust-img-box">
                <img src={Svg.casualleave} className="radio-leave-icon" />
                <h3 className="choose-type-text">Casual Leave</h3>
              </div>
            </Button>

            <Button className="radio-cust-btn-main">
              <div className="radio-cust-img-box">
                <img src={Svg.sickleave} className="radio-leave-icon" />
                <h3 className="choose-type-text">Sick Leave</h3>
              </div>
            </Button>

            <Button className="radio-cust-btn-main">
              <div className="radio-cust-img-box">
                <img src={Svg.emergencyleave} className="radio-leave-icon" />
                <h3 className="choose-type-text">Emergency Leave</h3>
              </div>
            </Button>

          </div>
          </div> */}
        </div>

        <div className="leave-form-main w-100">
          <Form
            form={form}
            name="control-hooks"
            layout="vertical"
            // name="basic"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="left-margin"
          >
            <Row className="eave-rows">
              <Col span={24} className="text-area leave-rows">
                <Form.Item
                  label="Leave Type"
                  name="Leave_type"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Leave Type!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Select
                    onChange={(e) => {
                      setLeaveType(e);
                      if (e === "Emergency Leave") {
                        setLeaveDates([moment(), moment()]);
                      }
                    }}
                    disabled={leaveType != ""}
                  >
                    <Option value="Casual Leave">Casual Leave</Option>
                    <Option value="Sick Leave">Sick Leave</Option>
                    <Option value="Emergency Leave">Emergency Leave</Option>
                  </Select>
                </Form.Item>
                <Checkbox
                  checked={isHalfLeaveCheck}
                  disabled={leaveDays ? true : false}
                  onChange={(e) => setIsHalfLeaveCheck(!isHalfLeaveCheck)}
                />{" "}
                Half Leave
              </Col>
              {leaveType != "" ? (
                <>
                  <Col span={24} className="text-area leave-rows">
                    <Form.Item
                      label="Select days for leave"
                      name="Leave_days"
                      rules={[
                        {
                          required: true,
                          message: "Select days for leave!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Select
                        onChange={(e) => {
                          setLeaveDays(e);
                        }}
                        // disabled={leaveDays !== null}

                        disabled={leaveDays !== ""}
                      >
                        {leaveType === "Sick Leave" ? (
                          <>
                            {isHalfLeaveCheck ? (
                              <Option value="1">0.5</Option>
                            ) : (
                              [...Array(sickleavedata)].map((item, index) => {
                                let val = (index + 1)?.toString();
                                return (
                                  <>
                                    <Option value={val}>{val}</Option>
                                  </>
                                );
                              })
                            )}
                          </>
                        ) : leaveType === "Emergency Leave" ? (
                          isHalfLeaveCheck ? (
                            <Option value="1">0.5</Option>
                          ) : (
                            <Option value="1">1</Option>
                          )
                        ) : isHalfLeaveCheck ? (
                          <Option value="1">0.5</Option>
                        ) : (
                          <>
                            <Option value="1">1</Option>
                            <Option value="2">2</Option>
                            <Option value="3">3</Option>
                            <Option value="4">4</Option>
                            <Option value="5">5</Option>
                            <Option value="6">6</Option>
                            <Option value="7">7</Option>
                            <Option value="8 or 8 ++">8 or 8+</Option>
                          </>
                        )}
                      </Select>
                    </Form.Item>
                  </Col>

                  {showEmployee && leaveDays != "" && (
                    <Col span={24} className="text-area">
                      <Form.Item
                        className="leave-rows"
                        label="Employee"
                        name="reason"
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: false,
                            message: "Please enter the reason",
                          },
                        ]}
                      >
                        <SelectEmpployee
                          className="leave-rows"
                          mode="single"
                          setEmployeeIds={(value) => setEmployeeId(value)}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {leaveDays != "" && (
                    <>
                      <Col className="new-date-leave date-module leave-rows">
                        <Form.Item
                          className="date-input"
                          label="Leave Date"
                          name="dateToDate"
                          // rules={[
                          //   //  ()=> setDateError({
                          //   {
                          //     required: true,
                          //     message: "Please enter your leave dates",
                          //   },
                          //   // }),
                          // ]}
                        >
                          {userData[0]._id == "61b727ca37a0b8573dde2d97" ||
                          userData[0]._id == "637b62187c22a305936a917f" ? (
                            <RangePicker
                              format={"DD/MM/YYYY"}
                              onChange={handleDateChange}
                              // disabledDate={(current) =>
                              //   calculateDisabledDate(current)
                              // }
                              defaultValue={leaveDates}
                            />
                          ) : (
                            <RangePicker
                              format={"DD/MM/YYYY"}
                              onChange={handleDateChange}
                              disabledDate={(current) =>
                                calculateDisabledDate(current)
                              }
                              defaultValue={leaveDates}
                            />
                          )}
                        </Form.Item>
                        <span className="error-msg">
                          {dateError || leaveDatesError}
                        </span>
                      </Col>
                      {isHalfLeaveCheck && (
                        <Col
                          span={24}
                          className="half-leave-section leave-rows center-div"
                        >
                          <Radio.Group
                            options={optionsWithDisabled}
                            onChange={onChangeFirstDay}
                            value={valueFirstDay}
                            optionType="button"
                            buttonStyle="solid"
                            className=""
                          />{" "}
                          {/* <span className="to-text">To</span>
                        <Radio.Group
                          span={24}
                          options={optionsWithDisabled}
                          onChange={onChangeSecondDay}
                          value={valueSecondDay}
                          optionType="button"
                          buttonStyle="solid"
                          className=""f
                        /> */}
                        </Col>
                      )}
                      <span className="error-msg">
                        {leaveRange ? leaveRange : ""}
                      </span>
                      <Col span={24} className="text-area leave-rows">
                        <Form.Item
                          label="Reason For Leave"
                          name="reason"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please enter the reason",
                            },
                          ]}
                        >
                          <TextArea rows={3} />
                        </Form.Item>
                      </Col>
                      <Col span={24} className="text-area leave-rows">
                        <Form.Item
                          label="Notify Employee"
                          name="Notified_Employee"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: false,
                              message: "Please Enter Reason!",
                            },
                          ]}
                        >
                          <SelectEmpployee
                            mode="multiple"
                            setEmployeeIds={(value) =>
                              setNotifiedEmployee(value)
                            }
                          />
                        </Form.Item>
                      </Col>{" "}
                      {showEmployee && (
                        <Col span={24} className="leave-status leave-rows">
                          <Form.Item
                            label="Status"
                            name="Status"
                            style={{ width: "100%" }}
                            rules={[
                              {
                                required: false,
                                message: "Please Enter Status!",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Status"
                              // style={{ paddingLeft: '10px', width: '70%' }}
                              className="select-dropdown leave-4"
                              onChange={(e) => {
                                setStatus(e);
                              }}
                            >
                              <Option value="Approved">Approved</Option>
                              <Option value="Conditional Approved">
                                Conditional Approved
                              </Option>
                              <Option value="Unapproved">Unapproved</Option>
                              <Option value="Rejected">Rejected</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      )}
                      <Col span={24} className="colemwidth leave-rows">
                        <Buttonss
                          variant="contained"
                          component="label"
                          className="bttn-clr pms-same-btn pms-same-btn-upload-new"
                        >
                          <input
                            accept="*/*"
                            multiple
                            hidden
                            type="file"
                            onChange={handleFileChange}
                            // onChange={(e) => {
                            //   setFile(e.target.files);
                            //   fileHandler(e.target.files);
                            // }}
                          />
                          Upload File
                        </Buttonss>
                        {uploadError && (
                          <p style={{ color: "red" }}>{uploadError}</p>
                        )}
                        <p style={{ color: "green" }}>{url}</p>
                      </Col>
                    </>
                  )}
                  {leaveDays != "" && (
                    <Form.Item>
                      {/* <div style={{ textAlign: 'end', marginRight: '60px' }}> */}
                      <div style={{ textAlign: "end", marginTop: "20px" }}>
                        <Button
                          type="primary"
                          className="pms-same-btn mb-0"
                          htmlType="submit"
                          style={{ marginRight: "10px" }}
                        >
                          Request for Leave
                        </Button>
                        <Button
                          onClick={handleCancel}
                          className="pms-same-btn-cancel"
                        >
                          Cancel
                        </Button>
                        {/* </div> */}
                      </div>
                    </Form.Item>
                  )}
                </>
              ) : (
                <></>
              )}
            </Row>
          </Form>
        </div>
      </Modal>

      <Modal
        title="Leave HandBook"
        centered
        // visible={openLeavePolicy}
        open={openLeavePolicy}
        onOk={() => {
          setVisible(true);
          setOpenLeavePolicy(false);
        }}
        onCancel={() => setOpenLeavePolicy(false)}
        width={550}
        className="leave-form modal-back modal-title close-btn leave-modal modal-main handbook-apply"
        footer={
          <Button
            type="primary"
            className="pms-same-btn mb-0"
            htmlType="submit"
            style={{ marginRight: "10px" }}
            onClick={() => {
              setVisible(true);
              setOpenLeavePolicy(false);
            }}
          >
            Ok
          </Button>
        }
      >
        <HandBook />
      </Modal>
    </>
  );
};

export default LeaveForm;
