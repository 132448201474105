import React, { useEffect, useState } from "react";
import { MDBCard, MDBCol } from "mdb-react-ui-kit";
import { MDBDataTableV5 } from "mdbreact";
import ReactLoader from "../../../../components/common/ReactLoader";
import AxiosInstance from "../../../../service/AxiosInstance";
import AddEscalationType from "./AddEscalationType";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Modal, Button, message } from "antd";

function EscalationType() {
  const [isOpen, setIsOpen] = useState(false);
  const [datatable, setDatatable] = useState({});
  const [loading, setLoading] = useState(false);
  const [escalationTypeList, setEscalationTypeList] = useState([]);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [escalationTypeId, setEscalationTypeId] = useState();

  useEffect(() => {
    setDatatable({
      columns: [
        {
          label: "No",
          field: "icon",
          sort: "disabled",
          width: 10,
        },
        {
          label: "Escalation Type",
          field: "Escalation_Type",
          width: 150,
          sort: "disabled",
        },
        {
          label: "Action",
          field: "Action",
          sort: "disabled",
          width: 30,
        },
      ],

      rows: escalationTypeList,
    });
  }, [escalationTypeList]);

  const getAllEscalationType = () => {
    setLoading(true);
    AxiosInstance.get("/auth/get/escalation/type").then((res) => {
      setLoading(false);
      let list1 = res.data.data.map((item, index) => {
        return {
          icon: index + 1,
          Escalation_Type: item?.Escalation_Type,

          Action: (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Button
                type="primary"
                onClick={() => {
                  setEscalationTypeId(item);
                  setIsOpen(true);
                }}
              >
                <EditOutlined />{" "}
              </Button>

              <Button
                danger
                className="dlt-btn"
                type="primary"
                onClick={() => {
                  setEscalationTypeId(item?._id);
                  setIsOpenDeleteModal(true);
                }}
              >
                <DeleteOutlined />
              </Button>
            </div>
          ),
        };
      });
      setEscalationTypeList(list1);
    });
  };

  const deleteEscalation = (id) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    AxiosInstance.post("/auth/delete/escalation/type", urlencoded).then(
      (res) => {
        if (res?.data?.status == 1) {
          message.success(res?.data?.message);
          setIsOpenDeleteModal(false);
          getAllEscalationType();
        } else {
          message.error(res?.data?.message);
        }
      }
    );
  };

  const handleCancel = (errorInfo) => {
    setIsOpenDeleteModal(false);
  };

  useEffect(() => {
    getAllEscalationType();
  }, []);

  const reloadList = () => {
    setIsOpen(false);
    getAllEscalationType();
  };

  return (
    <>
      <Modal
        title="Are you sure to delete this record?"
        centered
        open={isOpenDeleteModal}
        onOk={() => setIsOpenDeleteModal(false)}
        onCancel={handleCancel}
        width={380}
        footer={null}
        className="delete-modal"
      >
        <Button
          className="pms-same-btn"
          type="danger"
          onClick={() => deleteEscalation(escalationTypeId)}
          style={{
            marginRight: "7%",
          }}
        >
          Delete
        </Button>
        <Button onClick={handleCancel} className="pms-same-btn-cancel">
          Cancel
        </Button>
      </Modal>

      <div className="demo">
        <MDBCard className="page-content">
          <div className="create-project">
            <MDBCol size="md" className="col-example">
              <h3 className="tablHeader">
                Escalation Type
                <Button
                  type="primary"
                  onClick={() => {
                    setIsOpen(true);
                    setEscalationTypeId("");
                  }}
                  style={{ float: "right" }}
                  className="pms-same-btn"
                >
                  Add
                </Button>
                {isOpen ? (
                  <AddEscalationType
                    escalationTypeId={escalationTypeId}
                    inSidebar={true}
                    btnStyl={true}
                    reloadList={reloadList}
                    setIsOpen={(a) => {
                      setIsOpen(a);
                    }}
                  />
                ) : null}
              </h3>
            </MDBCol>
          </div>

          {loading ? (
            <ReactLoader loading={loading} />
          ) : (
            <div className="scrool-set">
              <MDBDataTableV5
                hover
                entriesOptions={[10, 20, 25]}
                entries={10}
                pagesAmount={4}
                data={datatable}
                searchTop
                searchBottom={false}
                className="custom-table-escalation-type"
              />
            </div>
          )}
        </MDBCard>
      </div>
    </>
  );
}

export default EscalationType;
